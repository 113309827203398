import { gql } from '@apollo/client'

export const LIST_FOLLOWERS = gql`
  query listFollowers(
    $filter: TableFollowFilterInput
    $limit: Int
    $offset: Int
  ) {
    listFollowers(filter: $filter, limit: $limit, offset: $offset) {
      total
      size
      items {
        user {
          id
          firstName
          lastName
        }
        owner {
          ... on Account {
            id
            name
          }
        }
        type
      }
    }
  }
`
