import React, { useMemo } from 'react'
import { cloneDeep } from 'lodash'
import TextFieldRenderer from './TextFieldRenderer'
import FormSectionRenderer from './FormSectionRenderer'
import SelectField from '../fields/SelectField'
import DynamicSelectFieldRenderer from './DynamicSelectFieldRenderer'
import AutocompleteFieldRenderer from './AutocompleteFieldRenderer'
import SwitchField from '../fields/SwitchField'
import QueryItemRenderer from './QueryItemsRenderer'

const FormFieldRenderer = ({
	field,
	initialState,
	form,
	handleInputChange,
	updateForm,
	updateData,
	pushError,
	record,
	data
}: any) => {
	const computedValue = ((rawValue: any, formatter: any = (context: any) => context) => {
		// console.log({ computedValue: { [field.name]: rawValue } })
		return formatter(rawValue)
	})(form[field.name], field.format)

	const fieldOptions = {
		id: field.name,
		key: field.name,
		hint: field.hint,
		fullWidth: true,
		variant: field.variant,
		name: field.name,
		label: field.label,
		type: field.type,
		value: computedValue,
		onChange: handleInputChange,
		required: field.required
	}

	const type = useMemo(() => field.type || 'text', [field])

	if (field.render) {
		return field.render({
			field: fieldOptions,
			form,
			data,
			record,
			handleInputChange,
			updateForm,
			pushError,
			initialState,
			fieldOptions: field.fieldOptions || {}
		})
	}

	switch (type) {
		case 'section':
			const _field = { ...field }
			delete field.fieldOptions
			const sectionOptions = {
				...field.fieldOptions,
				..._field,
				initialState,
				form,
				handleInputChange,
				updateForm,
				pushError,
				data,
				record
			}
			return <FormSectionRenderer {...sectionOptions} />
		case 'select':
			if (field.query) {
				const dynamicSelectOptions = {
					query: field.query,
					data,
					record,
					...fieldOptions,
					...field.fieldOptions,
					updateForm,
					beforeUpdate: field.beforeUpdate,
					onChange: updateForm
				}
				return <DynamicSelectFieldRenderer {...dynamicSelectOptions} />
			}
			const selectOptions = {
				options: field.options || [],
				...fieldOptions
			}
			return <SelectField {...selectOptions} />

		case 'list':
			const dynamicListOptions = {
				query: field.query,
				data,
				record,
				...fieldOptions,
				...field.fieldOptions,
				updateForm,
				beforeUpdate: field.beforeUpdate,
				onChange: updateForm
			}
			return <QueryItemRenderer {...dynamicListOptions} />

		case 'autocomplete':
			const autocompleteOptions = {
				query: field.query,
				data,
				record,
				...fieldOptions,
				...field.fieldOptions,
				updateForm,
				beforeUpdate: field.beforeUpdate,
				onChange: updateForm
			}
			return <AutocompleteFieldRenderer {...autocompleteOptions} />
		case 'custom':
			const fieldParams: any = {
				...fieldOptions,
				...field.fieldOptions,
				updateForm,
				updateData,
				pushError,
				initialState
			}
			// manipulate a copy of the form data
			const dataProxy = cloneDeep(data)
			if (field.beforeRender) {
				field.beforeRender({
					form,
					data: dataProxy,
					field
				})
			}
			const customFieldOptions = {
				data: dataProxy,
				record,
				form,
				handleInputChange,
				...fieldParams
			}
			return <field.component {...customFieldOptions} />
		case 'switch':
			const switchOptions = {
				data: record,
				form,
				updateForm,
				...fieldOptions,
				...field.fieldOptions
			}
			return <SwitchField {...switchOptions} />
		// // case 'upload':
		// // 	return renderUploadField({
		// // 		field: fieldOptions,
		// // 		form,
		// // 		data,
		// // 		handleInputChange,
		// // 		updateForm,
		// // 		updateData,
		// // 		pushError,
		// // 		initialState,
		// // 		fieldOptions: field.fieldOptions || {}
		// // 	})
		// case 'color':
		// 	const colorFieldOptions = {
		// 		...fieldOptions,
		// 		form,
		// 		data,
		// 		handleInputChange,
		// 		updateForm,
		// 		pushError,
		// 		beforeUpdate: field.beforeUpdate,
		// 		fieldOptions: field.fieldOptions || {}
		// 	}
		// 	return <ColorField {...colorFieldOptions} />
		default:
			const options = {
				form,
				data: record,
				multiline: field.multiline,
				autoComplete: 'off',
				visible: field.visible,
				...field.fieldOptions,
				...fieldOptions
			}
			return <TextFieldRenderer {...options} />
	}
}

export default FormFieldRenderer
