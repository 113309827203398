import { gql } from '@apollo/client'

export const SUBSCRIBE_ALERTS = gql`
  mutation subscribeAlerts($id: ID!) {
    subscribeAlerts(id: $id) {
      account {
        name
        id
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
