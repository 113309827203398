import { useState, useCallback } from 'react'

/**
 * @deprecated
 */
export const useForm = ({
  form,
  onSubmit,
  beforeSubmit,
  onSuccess,
  onFailure
}: any) => {
  const [state, setState]: any = useState({ ...form })
  const [errors, setErrors]: any = useState({})

  const handleInputChange = useCallback(
    ({ target }: any) => {
      setState({ ...state, [target.name]: target.value })
    },
    [state, setState]
  )

  const pushError = useCallback(
    (field: string, message: string) => {
      setErrors({ ...errors, [field]: message })
    },
    [errors, setErrors]
  )

  const clearError = useCallback(
    ({ target }: any) => {
      if (errors[target.name]) {
        const copy: any = { ...errors }
        delete copy[target.name]
        setErrors(copy)
      }
    },
    [errors, setErrors]
  )

  const validateForm = useCallback(
    (e: any) => {
      e.preventDefault()
      onSubmit(state)
    },
    [onSubmit, state]
  )

  return {
    handleInputChange,
    pushError,
    clearError,
    validateForm,
    form: state,
    errors
  }
}
