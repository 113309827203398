import { gql } from '@apollo/client'

export const GET_PHOTO = gql`
	query GetPhoto($id: ID!) {
		getPhoto(id: $id) {
			id
			image
			resource
			originalId
			originalImage
			position
			createdAt
			updatedAt
		}
	}
`
