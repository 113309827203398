import React, { FC } from 'react'
import classNames from 'classnames'
import { Paper, Typography, Box, TypographyVariant, Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Swipeable from '@services/common/components/Swipeable'
import { Loading } from '@services/common/components/Loading'

export interface AdminModuleProps {
	title?: string
	titleVariant?: TypographyVariant
	text?: string
	component?: any
	componentProps?: any
	children?: any
	rootClassName?: string
	scrollable?: boolean
	contentContainerStyles?: any
	classes?: any
	loading?: boolean
	in?: boolean
	styles?: any
}

const AdminModule: FC<AdminModuleProps> = ({
	title,
	titleVariant = 'h3',
	text,
	children,
	component: Component,
	componentProps = {},
	rootClassName,
	scrollable = false,
	contentContainerStyles = {},
	classes: injectedClasses = {},
	loading = false,
	in: visible = true,
	styles = {}
}: AdminModuleProps) => {
	console.log('AdminModule', { componentProps })
	const classes = useStyles()

	const contentStyles: any = { ...contentContainerStyles, ...styles.content }
	if (scrollable) {
		contentStyles.overflowY = 'auto'
	}

	const sectionVisible = ((visibility: any) => {
		if (typeof visibility === 'function') {
			return visibility()
		}
		return visibility
	})(visible)

	return (
		<Paper
			style={styles.root}
			className={classNames(classes.root, rootClassName, injectedClasses.root)}
		>
			<Box
				style={styles.headerRoot}
				className={classNames(classes.headerRoot, injectedClasses.headerRoot)}
			>
				{(title || text) && (
					<Box style={styles.header} className={classNames(classes.header, injectedClasses.header)}>
						{title && (
							<Typography
								className={classNames(classes.title, injectedClasses.title)}
								variant={titleVariant}
							>
								{title}
							</Typography>
						)}
						{text && (
							<Typography
								variant="body2"
								className={classNames(classes.text, injectedClasses.text)}
							>
								{text}
							</Typography>
						)}
					</Box>
				)}
				{Component && <Component {...componentProps} />}
			</Box>

			<Collapse in={sectionVisible} style={{ width: '100%', ...styles.collapse }}>
				<Box style={contentStyles} className={classNames(classes.content, injectedClasses.content)}>
					{loading && <Loading overlay={false} />}
					{children && children.length > 1 ? (
						<Swipeable enableMouseEvents={false}>{children}</Swipeable>
					) : (
						children
					)}
				</Box>
			</Collapse>
		</Paper>
	)
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		// flex: 1,
		flexDirection: 'column',
		backgroundColor: '#282C34',
		// width: '100%'
		width: 'auto',
		// overflow: 'hidden'
		height: '100%'
	},
	content: {
		// overflowY: 'auto'
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	headerRoot: {
		display: 'flex',
		flexDirection: 'column',
		borderBottom: '1px solid rgba(255,255,255,.1)'
	},
	header: {
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		// color: '#fff',
		fontSize: 18,
		padding: '16px 24px'
	},
	text: {
		color: '#adb0bb',
		fontSize: 15,
		fontWeight: 300
	}
})

/**
 * @deprecated use default export for async loading
 */
export { AdminModule }

export default AdminModule
