import { useMemo, useState } from 'react'
import { Box, Chip, Container, Divider, Stack } from '@mui/material'
import PageHeader from '@services/common/components/PageHeader'
import GetawaysPageHeader from '../components/GetawaysPageHeader'
import Getaways from '../components/Getaways'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'
import { useQuery } from '@apollo/client'
import ConciergePromote from '../components/ConciergePromote'
import { LIST_TAXONOMIES } from 'app/services/cms/taxonomy/graphql/list-taxonomies'

const today = Date.now()
const filter = {
	endDate: today
}

const GetawayPage = () => {
	const [category, setCategory]: any = useState(undefined)
	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.getaways'
		}
	})

	const { data: categoriesData }: any = useQuery(LIST_TAXONOMIES, {
		variables: {
			filter: {
				type: 'getaway'
			}
		}
	})

	const categories = useMemo(() => {
		return categoriesData ? categoriesData.listTaxonomies.items : []
	}, [categoriesData])

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content
			})
		}
		return res
	}, [data])

	const queryFilter = useMemo(() => {
		return category
			? {
				...filter,
				categories: category.id
			}
			: filter
	}, [category])

	return (
		<Box sx={styles.root}>
			<PageHeader
				sx={{ title: styles.title, titleBlock: styles.titleBlock, text: styles.text }}
				{...header}
			>
				<GetawaysPageHeader />
			</PageHeader>

			<Divider style={{ margin: '24px 0', width: '100%' }} />
			<Stack gap={4} sx={{ minHeight: 500 }}>
				{categories.length > 0 && (
					<Box
						sx={{
							display: 'flex'
						}}
					>
						<Container>
							<Stack direction="row" gap={1}>
								{categories.map((cat: any) => (
									<Chip
										key={`category-chip-${cat.id}`}
										sx={{
											...(category && category.id === cat.id
												? {
													color: '#fff',
													backgroundColor: '#666',
													borderColor: '#666',
													'&:hover': {
														color: '#666'
													}
												}
												: {})
										}}
										label={cat.name}
										variant="outlined"
										onClick={() => setCategory(cat)}
									/>
								))}
							</Stack>
						</Container>
					</Box>
				)}

				<Getaways
					limit={50}
					filter={queryFilter}
					selectedCategory={category}
					clearFilter={() => setCategory(undefined)}
				/>
				<ConciergePromote />
				<NewsletterSignup />
			</Stack>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: '60px'
	},
	container: {},
	header: {},
	titleBlock: {
		paddingTop: 10
	},
	title: {
		marginBottom: 4
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	}
}

export default GetawayPage
