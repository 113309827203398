import { gql } from '@apollo/client'
import { BASIC_ACCOUNT_INFO_FRAGMENT } from '@services/account/graphql'
import { IMAGE_INFO_FRAGMENT } from '@services/media/graphql/fragments'
import { USER_INFO_FRAGMENT } from './fragments'

export const LIST_USERS = gql`
	query ListUsers($filter: TableUserFilterInput, $limit: Int, $offset: Int) {
		listUsers(filter: $filter, limit: $limit, offset: $offset) {
			items {
				...UserInfo
				featuredImage {
					...ImageInfo
				}
				account {
					...BasicAccountInfo
					featuredImage {
						...ImageInfo
					}
				}
				createdAt
				status
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
	${USER_INFO_FRAGMENT}
	${BASIC_ACCOUNT_INFO_FRAGMENT}
`
