import { FC, useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Button, Chip, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PageSection } from '@services/common/components/PageSection'
import { LIST_EVENTS } from '@services/event/graphql'
import { Loading } from '@services/common/components'
import EventCard from './EventCard'
import { noop } from 'lodash'
// import { logger } from '@services/common/lib/logger'
import { useRedirect } from 'app/services/common/hooks'
import { buildRoutePath } from 'app/services/common/utils/app'
import { DEFAULT_COLORS } from 'app/services/app/constants/app'

const startDate = Date.now()

export interface UpcomingEventsProps {
	limit?: number
	canLoadMore?: boolean
	filter?: any
	selectedCategory?: any
	clearFilter?: any
}

const UpcomingEvents: FC<UpcomingEventsProps> = ({
	limit = 8,
	filter = { startDate },
	canLoadMore,
	selectedCategory,
	clearFilter = noop
}: UpcomingEventsProps) => {
	const classes = useStyles()

	const { data, loading, error }: any = useQuery(LIST_EVENTS, {
		variables: {
			filter,
			limit,
			offset: 0,
			sortAttribute: 'startDate',
			sortDirection: 'asc'
		},
		notifyOnNetworkStatusChange: true
	})

	const loadMoreVisible = useMemo(() => {
		return canLoadMore && data && data.listEvents.total > limit
	}, [canLoadMore, data, limit])

	const { redirect } = useRedirect()

	const loadMore = useCallback(() => {
		redirect(buildRoutePath('events'))
	}, [redirect])

	const items = useMemo(() => {
		return data ? data.listEvents.items : []
	}, [data])

	const FilterControl = () => {
		// logger.log('FilterControl', { filter, selectedCategory, items })

		if (!selectedCategory) {
			return null
		}
		return (
			<Box style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 14, paddingLeft: 24 }}>
				<Chip label={selectedCategory.name} variant="outlined" onDelete={clearFilter} />
			</Box>
		)
	}

	return (
		<PageSection
			header="Upcoming Events"
			classes={{
				header: classes.pageTitle
			}}
			headerComponent={FilterControl}
		>
			<Stack gap={3}>
				<Box style={{ minHeight: '50vh', width: '100%' }}>
					{error && <Typography>{error.message}</Typography>}
					{loading && <Loading overlay={false} />}
					{items.length > 0 && (
						<Grid container spacing={3}>
							{items.map((item: any) => (
								<Grid key={`event-${item.id}`} item md={3}>
									<EventCard item={item} />
								</Grid>
							))}
						</Grid>
					)}
					{items.length === 0 && !loading && (
						<Box
							style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}
						>
							{selectedCategory && (
								<Typography>{`No Events currently for  ${selectedCategory.name}, check back often`}</Typography>
							)}
						</Box>
					)}

					{/* <Grid item md={12}>
					<Button variant="outlined">Load More</Button>
				</Grid> */}
				</Box>
				{loadMoreVisible && (
					<Box style={{ textAlign: 'center' }}>
						<Button
							size="large"
							sx={{
								color: DEFAULT_COLORS.primary,
								// borderColor: DEFAULT_COLORS.primary
								border: `1px solid ${DEFAULT_COLORS.primary}`,
								backgroundColor: 'transparent',
								padding: '1.125rem 2.25rem',
								fontSize: '1.2rem',
								textTransform: 'none',
								margin: '0em auto',
								// borderRadius: '30px',
								'&:hover': {
									backgroundColor: DEFAULT_COLORS.primary,
									color: '#fff',
									borderColor: DEFAULT_COLORS.primary
								}
							}}
							onClick={loadMore}
						>
							Show More
						</Button>
					</Box>
				)}
			</Stack>
		</PageSection>
	)
}

const useStyles = makeStyles(() => ({
	pageTitle: {
		color: '#000'
	}
}))
export default UpcomingEvents
