import { Box, List, ListItemText, ListItemIcon, Divider, ListItemButton } from '@mui/material'
import { useAuth } from '@services/auth/hooks/use-auth'
import { Dashboard, ExitToApp as ExitToAppIcon } from '@mui/icons-material'
import { useRedirect } from '@services/common/hooks'
import { buildRoutePath } from '@services/common/utils/app'

const AccountDropdown = () => {
	const { logout }: any = useAuth()
	const { redirect } = useRedirect()

	return (
		<Box sx={styles.root}>
			<List sx={styles.list}>
				<ListItemButton onClick={() => redirect(buildRoutePath('my-account'))}>
					<ListItemIcon>
						<Dashboard />
					</ListItemIcon>
					<ListItemText primary="My Account" />
				</ListItemButton>
				<Divider style={{ width: '100%' }} />
				<ListItemButton onClick={logout}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItemButton>
			</List>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		width: 300,
		flexDirection: 'column'
	},
	list: {
		padding: 0
	}
}

export default AccountDropdown
