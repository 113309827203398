import { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AssignmentIcon from '@mui/icons-material/Assignment'
import ListItemIcon from '@mui/material/ListItemIcon'
import { buildRoutePath } from '@services/common/utils/app'
import { useRedirect } from '@services/common/hooks'

const basePath = ['my-account', 'listings']

const navigation = [
	{
		icon: ShoppingCartIcon,
		label: 'My Account',
		linkTo: buildRoutePath('my-account'),
		enabled: true
	},
	{
		icon: DashboardIcon,
		label: 'Properties',
		linkTo: buildRoutePath(...basePath, 'properties'),
		enabled: true
	},
	{
		icon: AssignmentIcon,
		label: 'Events',
		linkTo: buildRoutePath(...basePath, 'events'),
		enabled: false
	},
	{
		icon: ShoppingCartIcon,
		label: 'Getaways',
		linkTo: buildRoutePath(...basePath, 'getaways'),
		enabled: false
	}
]

const enabledNavigation = navigation.filter((item: any) => item.enabled)

const Sidebar = () => {
	const [open] = useState(true)
	const { redirect } = useRedirect()

	const paperSx = {
		...styles.drawerPaper
	}
	if (!open) {
		Object.assign(paperSx, styles.drawerPaperClose)
	}

	return (
		<Drawer variant="permanent" sx={paperSx} open={open}>
			<Divider />
			<List>
				{enabledNavigation.map(({ icon: Icon, label, linkTo }: any) => (
					<ListItem key={`list-item-${label}`} button onClick={() => redirect(linkTo)}>
						<ListItemIcon>
							<Icon />
						</ListItemIcon>
						<ListItemText primary={label} />
					</ListItem>
				))}
			</List>
		</Drawer>
	)
}

const drawerWidth = 240
const styles: any = {
	root: {
		display: 'flex'
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px'
		// ...theme.mixins.toolbar
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		'& .MuiPaper-root': {
			width: drawerWidth,
			top: 51
		}
		// transition: theme.transitions.create('width', {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.enteringScreen
		// })
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		// transition: theme.transitions.create('width', {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.leavingScreen
		// }),
		width: 54
		// [theme.breakpoints.up('sm')]: {
		// 	width: theme.spacing(9)
		// }
	}
}

export default Sidebar
