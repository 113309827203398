import { FC, useState } from 'react'
import { Box, Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'
import { Button } from '@services/common/components/Button'

const ITEM_HEIGHT = 48

export interface DropdownAction {
	label?: string
	icon?: any
	divider?: boolean
	data?: any
	event?: string
}

export interface DropdownProps {
	caret?: boolean
	actions?: DropdownAction[]
	onClick?: any
	icon?: any
	label?: string
}

/**
 * Dropdown component
 * @param {DropdownProps} options
 * @param {DropdownAction[]} actions
 * @param {Function} onClick
 */
export const Dropdown: FC<DropdownProps> = ({
	caret = true,
	actions = [],
	onClick = noop,
	label,
	icon: Icon,
	...rest
}: DropdownProps) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const classes: any = useStyles()

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (action: DropdownAction) => {
		setAnchorEl(null)
		onClick(action)
	}

	return (
		<Box display="flex">
			{Icon && !label ? (
				<IconButton
					aria-label="more"
					aria-haspopup="true"
					onClick={handleClick}
					className={classes.button}
				>
					<Icon />
				</IconButton>
			) : (
				<Button
					aria-label={label}
					aria-owns={anchorEl ? 'menu-list' : null}
					aria-haspopup="true"
					{...rest}
					onClick={handleClick}
				>
					{Icon && <Icon />}
					{label}
					{caret && <b className={classes.caret} />}
				</Button>
			)}

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5
					}
				}}
			>
				{actions.map((action: any, idx: number) => {
					if (action.divider) {
						return <Divider key={`divider-${idx}`} />
					}
					return (
						<MenuItem key={action.label} onClick={() => handleClose(action)}>
							{action.icon && (
								<span style={{ display: 'flex', marginRight: '8px' }}>
									<action.icon />
								</span>
							)}
							{action.label}
						</MenuItem>
					)
				})}
			</Menu>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	button: {
		padding: 0
	},
	caret: {
		transition: 'all 150ms ease-in',
		display: 'inline-block',
		width: '0',
		height: '0',
		marginLeft: '4px',
		verticalAlign: 'middle',
		borderTop: '4px solid',
		borderRight: '4px solid transparent',
		borderLeft: '4px solid transparent',
		color: '#adb0bb'
	},
	caretActive: {
		transform: 'rotate(180deg)'
	},
	caretRTL: {
		marginRight: '4px'
	}
}))
