import {
	Box,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Theme,
	Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Button } from '@services/common/components/Button'
import { Image } from '@services/common/components/Image'
import { MediaBlock } from '@services/common/components/MediaBlock'
import { renderDate } from '@services/common/helpers/render-date'
import { renderLocation } from '@services/common/helpers/render-location'
import { useRedirect } from '@services/common/hooks'
import { buildRoutePath } from '@services/common/utils/app'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
// import moment from 'moment'
import { useMemo, useState } from 'react'

export interface GetawayCardProps {
	item?: any
	variant?: 'default' | 'overlay'
}

const GetawayCard = ({ item }: any) => {
	const classes = useStyles()
	const { redirect } = useRedirect()
	const [url]: any = useState(buildRoutePath('getaways', item.id))

	const location = useMemo(() => {
		return item.location
	}, [item])

	const property = useMemo(() => {
		return item && item.property
	}, [item])

	const propertyPhoto = useMemo(() => {
		try {
			const idx = Math.floor(Math.random() * property.photos.length)
			return buildS3ImageUrl('properties', property.photos[idx])
		} catch (e) {
			return ''
		}
	}, [property])

	const getawayPhoto = useMemo(() => {
		try {
			const photoResource = item.photos && item.photos.length > 0 ? 'getaways' : 'properties'
			const photo = photoResource === 'getaways' ? item.photos[0] : property.photos[0]
			return buildS3ImageUrl(photoResource, photo)
		} catch (e) {
			return ''
		}
	}, [property, item])

	return (
		<Card className={classes.root}>
			<CardActionArea
				onClick={() => redirect(url)}
				style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, zIndex: 2 }}
			/>

			{getawayPhoto && (
				<Box className={classes.image}>
					<Image aspectRatio={16 / 9} src={getawayPhoto} />
				</Box>
			)}
			<CardContent className={classes.container}>
				<Box className={classes.content}>
					<Typography className={classes.location}>{renderLocation(location)}</Typography>
					<Typography variant="h3" className={classes.title}>
						{item.title}
					</Typography>
					<time className={classes.date}>{renderDate(item.startDate, item.endDate)}</time>
				</Box>
				<CardActions style={{ padding: 0 }}>
					<Button
						classes={{
							root: classes.button,
							label: classes.buttonLabel
						}}
					>
						Explore
					</Button>
					<Box display="flex" flex={1}>
						{property && (
							<MediaBlock
								classes={{
									root: classes.pip,
									title: classes.pipTitle,
									image: classes.pipImage,
									content: classes.pipContent
								}}
								aspectRatio={1}
								title={property.name}
								image={propertyPhoto}
							/>
						)}
					</Box>
				</CardActions>
			</CardContent>
		</Card>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative'
	},
	container: {
		position: 'absolute',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1
	},
	image: {
		position: 'relative',
		zIndex: 0,
		'&::before': {
			content: '""',
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
			background: 'linear-gradient(180deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 100%)'
		}
		// '&:after': {
		// 	content: '""',
		// 	display: 'block',
		// 	position: 'absolute',
		// 	top: 0,
		// 	left: 0,
		// 	right: 0,
		// 	bottom: 0,
		// 	zIndex: 1,
		// 	background: 'linear-gradient(180deg, rgba(0,0,0,.2) 100%, rgba(0,0,0,0) 0%)'
		// }
	},
	date: {
		color: '#fff',
		fontSize: '.9rem',
		fontWeight: 600
	},
	location: {
		color: '#fff',
		textTransform: 'uppercase',
		fontSize: '.9rem'
	},
	title: {
		color: '#fff',
		fontSize: '1.2rem',
		maxWidth: '50%',
		fontWeight: 600
	},
	button: {
		backgroundColor: '#f97487'
	},
	buttonLabel: {
		fontWeight: 500,
		// fontSize: '.9rem',
		textTransform: 'none'
	},
	pip: {
		flexDirection: 'row-reverse'
	},
	pipTitle: {
		color: '#fff',
		fontSize: '.9rem',
		fontWeight: 500,
		textAlign: 'right'
	},
	pipImage: {
		width: theme.spacing(6),
		height: theme.spacing(6),
		borderRadius: 8,
		border: '2px solid #fff',
		overflow: 'hidden'
	},
	pipContent: {
		justifyContent: 'center',
		padding: 8
	}
}))

export default GetawayCard
