import { Box, Typography } from '@mui/material'

import { wrapParagraph } from '@services/common/helpers/wrap-paragraph'

const BodySection = ({ title, text, children }: any) => {
	return (
		<Box sx={styles.root}>
			{title && <Typography variant="h3">{title}</Typography>}
			{text && wrapParagraph(text)}
			{children}
		</Box>
	)
}

const styles = {
	root: {
		marginBottom: 4
	}
}

export default BodySection
