import { useMemo, useState } from 'react'
import { Box, Card, CardActionArea, CardMedia, Typography } from '@mui/material'
import { AspectRatio } from '@services/common/components/AspectRatio'
import { noop } from 'lodash'
import { Room } from '@mui/icons-material'
import { buildRoutePath } from '@services/common/utils/app'
import { useRedirect } from '@services/common/hooks'
import { renderLocation } from '@services/common/helpers/render-location'
import { renderDate } from '@services/common/helpers/render-date'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'

export interface EventCardProps {
	item?: any
	onClick?: any
	sx?: any
}

const EventCard = ({ item, sx = {}, onClick = noop }: EventCardProps) => {
	const { redirect } = useRedirect()
	const [url]: any = useState(buildRoutePath('events', item.id))

	const location = useMemo(() => {
		return item.location
	}, [item])

	const eventPhoto = useMemo(() => {
		try {
			return item.photos[0]
		} catch (e) {
			return ''
		}
	}, [item])

	return (
		<Box sx={{ ...styles.root, ...sx.root }}>
			<Card
				sx={{
					marginBottom: 2,
					...sx.card
				}}
			>
				<CardActionArea onClick={() => redirect(url)}>
					<AspectRatio ratio={16 / 9}>
						<Box
							sx={{
								position: 'absolute',
								display: 'flex',
								flex: 1,
								top: 0,
								right: 0,
								left: 0,
								bottom: 0,
								padding: 1,
								alignItems: 'flex-end',
								background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 100%)'
							}}
						>
							<Room style={{ color: '#fff', height: 20 }} />
							<Typography
								sx={{
									color: '#fff',
									fontSize: '.9rem',
									textTransform: 'uppercase'
								}}
							>
								{renderLocation(location)}
							</Typography>
						</Box>
						<CardMedia component="img" image={buildS3ImageUrl('events', eventPhoto)} />
					</AspectRatio>
				</CardActionArea>
			</Card>
			<Box>
				<Typography
					variant="h3"
					sx={{
						fontSize: '1.1rem !important',
						fontWeight: 500
					}}
				>
					{item.name}
				</Typography>
				<time style={styles.date}>{renderDate(item.startDate, item.endDate)}</time>
			</Box>
		</Box>
	)
}

const styles = {
	root: {},
	image: {},
	// title: {
	// 	fontSize: '1.1rem !important',
	// 	fontWeight: 500
	// },
	// text: {
	// 	color: '#fff',
	// 	fontSize: '.9rem',
	// 	textTransform: 'uppercase'
	// },
	date: {
		fontSize: '.8rem'
	}
	// card: {
	// 	marginBottom: 16
	// },
	// content: {
	// 	position: 'absolute',
	// 	display: 'flex',
	// 	flex: 1,
	// 	top: 0,
	// 	right: 0,
	// 	left: 0,
	// 	bottom: 0,
	// 	padding: 8,
	// 	alignItems: 'flex-end',
	// 	background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 100%)'
	// }
}

export default EventCard
