import { cloneElement, FC } from 'react'
import { useScrollTrigger } from '@mui/material'

export interface ElevationScrollProps {
	children?: any
	window?: Function
}

export const ElevationScroll: FC<ElevationScrollProps> = ({
	children,
	window
}: ElevationScrollProps) => {
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined
	})

	return cloneElement(children, {
		elevation: trigger ? 4 : 0
	})
}
