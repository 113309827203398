import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'
import { DocumentNode } from 'graphql'
import { Chars, generateToken } from '../utils/string'

const QueryGridItems = ({
	query,
	action,
	filter = {},
	limit = 10,
	offset = 0,
	sortAttribute = 'createdAt',
	sortDirection = 'desc',
	gutter = 3,
	component: Component,
	itemsPerRow = 4,
	// canLoadMore = false,
	sx = {}
}: {
	query: DocumentNode
	action: string
	component: any
	filter?: any
	limit?: number
	offset?: number
	sortAttribute?: string
	sortDirection?: string
	gutter?: number
	itemsPerRow?: 3 | 4 | 6 | 12
	canLoadMore?: boolean
	sx?: {
		root?: any
		container?: any
		item?: any
		component?: any
	}
}) => {
	const [token] = useState(
		generateToken({ length: 32, type: Chars.alphaNumeric, leadWithAlpha: true })
	)
	const { data }: any = useQuery(query, {
		variables: {
			filter,
			limit,
			offset,
			sortAttribute,
			sortDirection
		},
		notifyOnNetworkStatusChange: true
	})

	const items = useMemo(() => {
		return data ? data[action].items : []
	}, [action, data])

	const itemWidth: number = useMemo(() => {
		if (itemsPerRow === 12) {
			return 12
		}
		return 12 / itemsPerRow
	}, [itemsPerRow])

	return (
		<Box sx={sx.root}>
			<Grid container spacing={gutter} sx={sx.container}>
				{items.length > 0 &&
					items.map((item: any) => (
						<Grid key={`${token}-${item.id}`} item xs={12} md={itemWidth} sx={sx.item}>
							<Component item={item} sx={sx.component} />
						</Grid>
					))}
			</Grid>
		</Box>
	)
}
export default QueryGridItems
