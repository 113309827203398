import { ReactNode, useCallback, useMemo } from 'react'
import { useStateReducer } from '@basementscripts/use-state-reducer'
// import { logger } from '@services/common/lib/logger'
import BookingContext from './BookingContext'
import initialState from './state'
import { noop } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { CREATE_PROPERTY_INQUIRY } from '../graphql/create-booking-requiry'
import { useFormState } from '@form/hooks/use-form-state'

export type BookingProviderProps = {
	children?: ReactNode | ReactNode[]
}

const BookingProvider = ({ children }: BookingProviderProps) => {
	const { form, updateForm, resetForm, handleInputChange }: any = useFormState({
		initialState,
		stateManager: useStateReducer
	})

	const client = useApolloClient()

	// useEffect(() => {
	// 	logger.log('BookingProvider', { form })
	// }, [form])

	const onSubmit = useCallback(
		(e: any, onSuccess = noop, onError = noop) => {
			e.preventDefault()
			// logger.log('BookingProvider', { onSubmit: { form } })

			const input: any = { ...form }
			input.rooms = parseInt(form.rooms, 10)
			input.guests = parseInt(form.guests, 10)
			input.startDate = new Date(form.startDate).getTime()
			input.endDate = new Date(form.endDate).getTime()
			input.status = 'pending'

			client
				.mutate({
					mutation: CREATE_PROPERTY_INQUIRY,
					variables: { input }
				})
				.then(({ data }: any) => {
					onSuccess(data.createPropertyInquiry)
				})
				.catch((e: any) => {
					onError(e.message)
				})
		},
		[client, form]
	)

	const context = useMemo(
		() => ({
			form,
			updateForm,
			resetForm,
			handleInputChange,
			onSubmit
		}),
		[form, handleInputChange, onSubmit, resetForm, updateForm]
	)

	return <BookingContext.Provider value={context}>{children}</BookingContext.Provider>
}
export default BookingProvider
