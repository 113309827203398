import { ReactNode } from 'react'
import { MediaContext } from './MediaContext'
import reducers from './reducer'
import { initialState } from './state'
import * as actions from './actions'
import { useStateReducer } from '@basementscripts/use-state-reducer'

export interface MediaProviderProps {
	children: ReactNode | ReactNode[] | null
	socket?: any //SocketIOClient.Socket
}

export const MediaProvider = ({ children }: MediaProviderProps) => {
	const { state, ...rest }: any = useStateReducer({ initialState, reducers, actions })

	const context: any = {
		uploads: state.uploads,
		...rest
	}

	return <MediaContext.Provider value={context}>{children}</MediaContext.Provider>
}
