import { gql } from '@apollo/client'
import { PROPERTY_INFO_FRAG } from './property-info-frag'

export const CREATE_PROPERTY = gql`
	mutation CreateProperty($input: CreatePropertyInput!) {
		createProperty(input: $input) {
			...PropertyInfo
		}
	}
	${PROPERTY_INFO_FRAG}
`
