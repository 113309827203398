import { gql } from '@apollo/client'
import { MARKETING_BLOCK_FRAG } from '../marketing-block/graphql/marketing-block-frag'

export const GET_CONTENT_BY_KEY = gql`
	query getContentByKey($key: String!) {
		getContentByKey(key: $key) {
			... on ContentPage {
				id
				name
				title
				uri
				slug
				key
				type
				description
				content
				status
				access
				layout
				modules
			}
			... on ContentBlock {
				id
				name
				slug
				key
				type
				content
				media {
					id
					image
					originalImage
				}
			}
			... on ContentHero {
				id
				name
				title
				subtitle
				body
				slug
				key
				type
				image {
					id
					image
					originalImage
				}
				meta {
					height
					parallax
					overlay {
						hex
						rgb
						alpha
						gradient {
							angle
							transitions {
								hex
								rgb
								alpha
								offset
							}
						}
					}
				}
				actions {
					label
					event
					variant
					color
					value
				}
			}
			... on ContentNavigation {
				id
				name
				slug
				key
				type
				destination
			}
			... on AttributeSet {
				id
				name
				key
				value
				type
			}
			... on MarketingBlock {
				...MarketingBlockInfo
			}
			... on ContentCollection {
				id
				name
				key
				status
				collection {
					... on ContentBlock {
						id
						name
						slug
						key
						type
						content
						media {
							id
							image
							originalImage
						}
					}
					... on MarketingBlock {
						...MarketingBlockInfo
					}
				}
			}
		}
	}
	${MARKETING_BLOCK_FRAG}
`
