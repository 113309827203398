import { useEffect, useMemo } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useStateReducer } from '@basementscripts/use-state-reducer'
import { dispatch } from '@services/store'
import { HeaderState, setHeader } from '@services/header/reducer'
import { VariantLogo } from '@services/header/types'
import { AppContext } from './AppContext'
import { enabled } from './features'
import { initialState } from './state'
import reducers from './reducer'
import * as actions from './actions'
import { setApp } from '../reducer'

export interface AppHeaderState extends Partial<Omit<HeaderState, 'brandIcon'>> {
	logo?: string | VariantLogo
}
export interface AppProviderProps extends Partial<AppHeaderState> {
	children: any
	autoloadScripts?: any
	description?: string
	features?: Record<string, boolean>
}

export const AppProvider = ({
	children,
	autoloadScripts = [],
	appName,
	description,
	navigation,
	logo,
	features
}: AppProviderProps) => {
	// Set the initial app state
	useEffect(() => {
		dispatch(
			setApp({
				description,
				listWithUsEnabled: features?.listWithUsEnabled
			})
		)
	}, [description, features])
	// SEt the initial header state
	useEffect(() => {
		dispatch(
			setHeader({
				brandIcon: logo,
				appName,
				navigation
			})
		)
	}, [appName, logo, navigation])

	const client: any = useApolloClient()
	const { enqueueSnackbar } = useSnackbar()

	const { state, ...rest }: any = useStateReducer({
		initialState,
		actions,
		reducers,
		client
	})

	const context: any = useMemo(
		() => ({
			state,
			initialState,
			autoloadScripts,
			...rest,
			notify: enqueueSnackbar,
			featureEnabled: enabled
		}),
		[autoloadScripts, enqueueSnackbar, rest, state]
	)

	return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
