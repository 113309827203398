import { buildRoutePath } from '@services/common/utils/app'

const navigation: any[] = [
	{
		label: 'Getaways',
		linkTo: buildRoutePath('getaways'),
		enabled: true
	}
]
export default navigation
