import { gql } from '@apollo/client'

export const UPDATE_PROPERTY_TYPE = gql`
	mutation UpdatePropertyType($input: UpdatePropertyTypeInput!) {
		updatePropertyType(input: $input) {
			id
			name
			__typename
		}
	}
`
