import { FC, ReactNode, ReactNodeArray, useEffect, useRef, useState } from 'react'
import {
	// isEmpty,
	noop
} from 'lodash'
import { useComponentSize } from '@services/common/hooks'
import { logger } from '../../lib/logger'
// import { logger } from "../../lib/logger"

export type ScrollContentProps = {
	children: ReactNode | ReactNodeArray
	onSize?: any
	styles?: any
}

const ScrollContent: FC<ScrollContentProps> = ({
	children,
	onSize = noop,
	styles = {
		root: {
			border: '1px solid #ccc',
			borderRadius: 8,
			overflow: 'hidden'
		}
	}
}: ScrollContentProps) => {
	const [
		_size
		// setSize
	] = useState({})
	const ref = useRef(null)
	const size = useComponentSize(ref)

	useEffect(() => {
		// if (isEmpty(_size) && size.height > 0) {
		// 	setSize(size)
		// 	onSize(size)
		// }
		logger.log('ScrollContent', { size, _size })
	}, [size, _size, onSize])

	return (
		<div ref={ref} style={styles.root}>
			{children}
		</div>
	)
}

export default ScrollContent
