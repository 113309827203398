import { ChangeEvent, useState } from 'react'
import { Box, Container, Tab, Tabs, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { TextBlock } from '../TextBlock'
import { Image } from '../Image'

const a11yProps = (index: any) => {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	}
}

const TabPromote = ({ title, text, tabs = [], classes: injectedClasses = [] }: any) => {
	const [activeTab, setActiveTab]: any = useState(0)
	const classes = useStyles()

	const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
		setActiveTab(newValue)
	}

	return (
		<Box className={classNames(classes.root, injectedClasses.root)}>
			<Container maxWidth="lg" className={classes.container}>
				<Box className={classes.tabControls}>
					<Box className={classes.titleBlock}>
						<Typography variant="h3" className={classes.title}>
							{title}
						</Typography>
						<Typography className={classes.text}>{text}</Typography>

						<Box className={classes.tabNav}>
							<Tabs
								classes={{
									root: classes.tabs,
									indicator: classes.indicator
								}}
								value={activeTab}
								orientation="vertical"
								onChange={handleChange}
								aria-label={title}
							>
								{tabs.length > 0 &&
									tabs.map((tab: any, idx: number) => (
										<Tab
											classes={classNames(classes.tabRoot, classes.tabWrapper)}
											key={`tab-nav-${idx}`}
											label={
												<TextBlock
													classes={{
														root: classes.tab,
														title: classes.tabNavTitle,
														body: classes.tabNavText
													}}
													title={tab.title}
													body={tab.summary}
													component="span"
												/>
											}
											{...a11yProps(idx)}
										/>
									))}
							</Tabs>
						</Box>
					</Box>
				</Box>
				<Box className={classes.content}>
					{tabs.map((tab: any, idx: number) => (
						<div
							key={`tab-panel-${idx}`}
							role="tabpanel"
							hidden={activeTab !== idx}
							id={`nav-tabpanel-${idx}`}
							aria-labelledby={`nav-tab-${idx}`}
							className={classes.tabContent}
						>
							{activeTab === idx && (
								<Image
									classes={{
										root: classes.image
									}}
									src={tab.image}
									aspectRatio={913 / 607}
								/>
							)}
						</div>
					))}
				</Box>
			</Container>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative'
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		zIndex: 1,
		color: '#fff'
	},
	tabs: {
		textAlign: 'left',
		display: 'flex',
		flex: 1
	},
	content: {
		display: 'flex',
		flex: 1.5
	},
	tabControls: {
		display: 'flex',
		flex: 1
	},
	tabNav: {},
	indicator: {
		right: 'auto',
		left: 0,
		backgroundColor: '#44C2D9',
		width: 5
	},
	tabRoot: {
		maxWidth: 'none',
		textAlign: 'left',
		paddingLeft: 18,
		marginBottom: theme.spacing(3)
		// padding: 0
	},
	tabWrapper: {
		alignItems: 'flex-start'
	},
	tab: {
		textAlign: 'left',
		textTransform: 'none'
	},
	tabContent: {
		position: 'absolute',
		maxWidth: '60vw',
		width: '100%'
	},
	tabNavTitle: {
		fontSize: '1.2rem',
		marginBottom: theme.spacing(2),
		fontWeight: 500
	},
	tabNavText: {
		fontSize: '1rem',
		color: '#f2f2f2'
	},
	titleBlock: {
		position: 'relative',
		zIndex: 1,
		paddingRight: 120
	},
	title: {
		fontSize: 26,
		color: '#44C2D9',
		marginBottom: theme.spacing(2)
	},
	text: {
		marginBottom: theme.spacing(6),
		color: '#f2f2f2'
	},
	image: {
		borderRadius: theme.spacing(1),
		overflow: 'hidden'
	}
}))

export default TabPromote
