import { useMemo, useRef } from 'react'
import { Box, Card, CardContent, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Image } from '@services/common/components/Image'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import VideoPlayer from '@services/media/components/VideoPlayer'
import './styles.scss'

export interface DragRaceWinnerProps {
	item?: any
}

const DragRaceWinner = ({ item }: DragRaceWinnerProps) => {
	const playerRef = useRef(null)

	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		sources: [
			{
				src: item.video,
				type: 'video/mp4'
			}
		]
	}

	const handlePlayerReady = (player) => {
		playerRef.current = player

		// You can handle player events here, for example:
		player.on('waiting', () => {
			console.log('player is waiting')
		})

		player.on('dispose', () => {
			console.log('player will dispose')
		})
	}
	const classes = useStyles()

	const backgroundImage = useMemo(() => {
		try {
			return buildS3ImageUrl('dragrace', item.image)
		} catch (e) {
			return ''
		}
	}, [item])

	const winnerImage = useMemo(() => {
		try {
			return buildS3ImageUrl('dragrace', item.winner.image)
		} catch (e) {
			return ''
		}
	}, [item])

	return (
		<Card className={classes.root}>
			{item.video ? (
				<VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
			) : (
				<Image
					src={backgroundImage}
					aspectRatio={1024 / 567}
					classes={{
						root: classes.image
					}}
				/>
			)}

			<CardContent className={classes.content}>
				<Box className={classes.info}>
					<Box className={classes.titleSection}>
						<Typography variant="h2" className={classes.season}>
							Season {item.season}
						</Typography>
					</Box>
					<Box className={classes.winner}>
						<Box className={classes.winnerTitleSection}>
							<Typography variant="h4" className={classes.winnerTitle}>
								The Winner
							</Typography>
							<Typography>{item.winner.name}</Typography>
						</Box>
						<Box className={classes.winnerImage}>
							<Image src={winnerImage} variant="fill" classes={{ root: classes.winnerImageRoot }} />
						</Box>
					</Box>
				</Box>
			</CardContent>
		</Card>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		marginBottom: theme.spacing(8)
	},
	image: {},
	content: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	info: {
		display: 'flex',
		flexDirection: 'row'
	},
	titleSection: {
		display: 'flex',
		flex: 1,
		color: theme.palette.common.white
	},
	season: {
		fontSize: theme.spacing(3),
		fontWeight: 'bold'
	},
	winner: {
		display: 'flex',
		flexDirection: 'row'
	},
	winnerTitleSection: {
		color: theme.palette.common.white,
		marginRight: theme.spacing(2),
		textAlign: 'right'
	},
	winnerTitle: {
		fontSize: theme.spacing(3)
	},
	winnerImage: {
		position: 'relative',
		width: 180,
		height: 180,
		overflow: 'hidden',
		borderRadius: theme.spacing(1)
	},
	winnerImageRoot: {
		width: 180,
		height: 180
	},
	action: {
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
		left: '50%',
		top: '50%'
	}
}))

export default DragRaceWinner
