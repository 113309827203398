import { Fragment, useMemo, useState } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useQuery } from '@apollo/client'
import { Loading } from '@services/common/components'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import { useParams } from 'react-router-dom'
import LocationMap from '@services/location/components/LocationMap'
import ResultSet from '../components/ResultSet'
import resourceMap from '../constants/resource-map'
import { SEARCH_REQUEST } from '../graphql/search'
import './styles.scss'

const SearchPage = () => {
	const [selectedType, setSelectedType] = useState('')
	const params = useParams()
	const [term]: any = useState(params.term)
	// console.log({ SearchPage: params })

	const { data, loading }: any = useQuery(SEARCH_REQUEST, {
		variables: {
			term
		}
	})

	const computedSearchResults = useMemo(() => {
		return data
			? data.search.items.reduce((collection: any, item: any) => {
				const resource = item.__typename.toLowerCase()
				if (!collection[resource]) {
					collection[resource] = []
				}
				collection[resource].push(item)
				return collection
			}, {})
			: undefined
	}, [data])

	const searchResultKeys = useMemo(() => {
		return computedSearchResults ? Object.keys(computedSearchResults) : []
	}, [computedSearchResults])

	const resultSearchStyles = useMemo(() => {
		return searchResultKeys.length > 1 ? { flexWrap: 'nowrap', transform: 'translateZ(0)' } : {}
	}, [searchResultKeys])

	const propertyMarkers = useMemo(() => {
		const properties = computedSearchResults?.property.filter((item: any) => !!item.location).map(({ id, name, location }: any) => {
			return {
				id,
				title: name,
				lat: location.latitude,
				long: location.longitude,
				content: `<div class="g-style-iw-content"><h2>${name}</h2><h3>${location.address}</h3>`
			}
		})
		return properties
	}, [computedSearchResults?.property])

	return (
		<Box sx={styles.root}>
			<Box sx={styles.container}>
				<Box style={styles.results}>
					{
						loading ? <Loading overlay={false} /> : term && (
							<Typography variant="h1" sx={styles.title}>
								Results for "{term}"
							</Typography>
						)}
					<Box sx={{ position: 'relative' }}>
						{selectedType && <Box sx={{ position: 'absolute', backgroundColor: '#fff', zIndex: 9, left: 0, right: 0, top: 0, bottom: 0, overflowY: 'scroll' }}>
							<Button
								onClick={() => setSelectedType('')}
								sx={{
									position: 'absolute',
									right: 24,
									top: 0
								}} size="small">X Close</Button>
							<ResultSet
								title={resourceMap[selectedType].label}
								component={resourceMap[selectedType].component}
								items={computedSearchResults[selectedType]}
								data={resourceMap[selectedType].data || {}}
								columns={searchResultKeys.length > 1 ? 3.2 : 3}
								direction="column"
								sx={{
									component: {
										root: {
											display: 'flex',
											flexDirection: 'row',
											mb: 1
										},
										card: {
											width: 100,
											mb: 0,
											mr: 2
										},
										content: {
											display: 'none'
											// height: 100
										},
										title: {
											display: 'flex',
											alignItems: 'center'
										}
									}
								}}
							// sx={{ root: { ...resultSearchStyles }, component: styles.component, item: styles.item }}
							/>
						</Box>}
						{searchResultKeys.length > 0 &&
							searchResultKeys.map((key: string, idx: number) => (
								<Fragment key={`result-set-${key}`}>
									<ResultSet
										onShowAll={() => setSelectedType(key)}
										title={resourceMap[key].label}
										component={resourceMap[key].component}
										items={computedSearchResults[key]}
										data={resourceMap[key].data || {}}
										columns={searchResultKeys.length > 1 ? 3.2 : 3}
										sx={{ root: resultSearchStyles, component: styles.component, item: styles.item }}
										showAll={true}
									/>
									{idx < searchResultKeys.length - 1 && <Divider style={{ margin: '24px 0' }} />}
								</Fragment>
							))}
					</Box>
				</Box>
				<Box sx={styles.map}><LocationMap searchTerm={term} zoom={14} markers={propertyMarkers} centerMarkers={true} /></Box>
			</Box>
			<NewsletterSignup />
		</Box>
	)
}

const styles: any = {
	root: {
		display: 'flex',
		flex: 1,
		width: '100%',
		flexDirection: 'column',
		paddingTop: '50px'
	},
	container: {
		flexDirection: 'row',
		display: 'flex'
	},
	results: {
		display: 'flex',
		width: '50vw',
		// flex: 1,
		flexDirection: 'column',
		padding: '48px 16px 0'
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold'
	},
	resultSet: {
		paddingTop: 6
	},
	map: {
		display: 'flex',
		width: '50vw',
		// flex: 1,
		position: 'relative',
		minHeight: 'calc(100vh - 50px)'
	},
	item: {
		width: 'calc(50vw / 3.3)'
	},
	component: {
		root: {
			mb: 0
		},
		card: { width: 'calc(50vw / 3.3)' }
	}
}

export default SearchPage
