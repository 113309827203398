import { gql } from '@apollo/client'

export const ACCOUNT_FRAGMENT = gql`
	fragment AccountInfo on Account {
		id
		name
		status
		description
		featuredImage {
			id
			owner
			filename
		}
		images {
			id
			owner
			filename
		}
	}
`
