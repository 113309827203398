export const pageData: any = {
	hero: {
		image:
			'https://photos.mygaygetaway.com/properties/5d8284695bdcfacc3b1ebdae/ed212f87-f491-499d-84c2-794c9bdaa181.jpg',
		title: 'Discover amazing places',
		text: 'Find getaways nearby or in faraway places, access unique hotels, events and places around the world',
		actions: [
			{
				label: 'Start Exploring'
			}
		]
	}
}
