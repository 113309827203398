import { FC, useMemo } from 'react'
import { Box, Link, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'
import { Image } from '@services/common/components/Image'
import { buildRoutePath } from '@services/common/utils/app'
import type { VariantLogo } from '../types'

export interface BrandComponentProps {
	appName: string
	source?: string | VariantLogo
	variant?: keyof VariantLogo
}

const BrandComponent: FC<BrandComponentProps> = ({
	appName,
	variant,
	source
}: BrandComponentProps) => {
	const classes = useStyles()

	const brandIcon = useMemo(() => {
		return source && variant ? source[variant] : ''
	}, [source, variant])

	return (
		<Link className={classes.root} component={RouterLink} to={buildRoutePath()}>
			{brandIcon && (
				<Box component="span" className={classes.icon}>
					<Image src={brandIcon} altText={appName} />
				</Box>
			)}
		</Link>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex', // !important
		marginRight: theme.spacing(1)
	},
	icon: {
		marginRight: theme.spacing(1)
	}
}))

export default BrandComponent
