import { Box, Stack } from '@mui/material'
import EventCategory from './EventCategory'
import { noop } from 'lodash'
import { useMemo } from 'react'

const EventCategories = ({ onClick = noop, categories = [] }: any) => {
	const haveImages = useMemo(() => {
		return categories.filter((category: any) => category.images && category.images.length)
	}, [categories])

	return (
		<Box sx={styles.root}>
			<Stack gap={3} sx={styles.stack}>
				{haveImages.length > 0 &&
					haveImages.map((category: any) => (
						<Box key={`category-${category.id}`} sx={styles.item}>
							<EventCategory item={category} onClick={() => onClick(category)} />
						</Box>
					))}
			</Stack>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		width: '50vw',
		height: '100%',
		position: 'absolute',
		'&:before': {
			content: '""',
			background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
			position: 'absolute',
			left: 0,
			top: 0,
			bottom: 0,
			width: 32,
			zIndex: 3
		},
		'&:after': {
			content: '""',
			background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
			position: 'absolute',
			right: 0,
			top: 0,
			bottom: 0,
			width: 32,
			zIndex: 3
		}
	},
	stack: {
		flexWrap: 'nowrap',
		margin: 0,
		flexDirection: 'row',
		flex: 1,
		overflowY: 'scroll'
	},
	item: {
		display: 'flex',
		width: 200
	}
}

export default EventCategories
