import { FC, ReactNode, ReactNodeArray, useCallback, useMemo, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { useWindowScroll } from '@services/app/hooks/use-window'
import { useComponentSize } from '@services/common/hooks/use-component-size'
import useWindow from '@services/common/hooks/use-window'
// import { logger } from "../../lib/logger"
import ScrollContent from './ScrollContent'

export type StickyContentProps = {
	children?: ReactNode | ReactNodeArray
}

const StickyContent: FC<StickyContentProps> = ({ children }: StickyContentProps) => {
	const [contentSize, setContentSize]: any = useState({})
	const [spacerSize, setSpacerSize] = useState(0)
	const ref: any = useRef()

	const componentSize = useComponentSize(ref)
	const win = useWindow()

	const handleScrollEvent = useCallback(() => {
		const offset = win.scrollY + 50
		const top = ref.current.offsetTop
		const maxScroll = top - 50 + componentSize.height - contentSize.height
		const _size = offset - top > 0 ? offset - top : 0
		// logger.log('StickyContent', { bounds: componentSize, space: _size, top, offset, maxScroll, window: { width: win.innerWidth, height: win.innerHeight } })
		if (_size > 0 && spacerSize !== _size && offset < maxScroll) {
			setSpacerSize(_size)
		}
	}, [contentSize.height, componentSize, spacerSize, win.scrollY])
	useWindowScroll(handleScrollEvent)

	const spacer = useMemo(
		() => ({
			height: spacerSize
		}),
		[spacerSize]
	)

	return (
		<Grid ref={ref} item md={4}>
			<div style={spacer} />
			<ScrollContent onSize={setContentSize}>{children}</ScrollContent>
		</Grid>
	)
}
export default StickyContent
