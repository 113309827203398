import { FormEvent, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Calendar from 'react-calendar'
import classNames from 'classnames'
import moment from 'moment'
import { Box, Divider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ArrowBackOutlined, ArrowForwardOutlined } from '@mui/icons-material'
import { useModal } from '@services/app/hooks/use-modal'
import { Button } from '@services/common/components/Button'
import { logger } from '@services/common/lib/logger'
import { createSelectorOptions } from '@services/property/views/PropertyDetail/utils'
import state from '../BookingProvider/state'
import useBookingForm from '../BookingProvider/use-booking-form'
import { BookingDialog } from './BookingDialog'
// import calendar styles
import './calendar.scss'
import SelectField from '@form/components/fields/SelectField'

const VALID_PARAMS = ['guests', 'rooms', 'startDate', 'endDate']

const BookingCalendar = ({ property }: any) => {
	const { search } = useLocation()
	const classes = useStyles()
	const { showModal, closeModal } = useModal()
	const { form, updateForm, handleInputChange }: any = useBookingForm()

	const params = useMemo(() => {
		const urlParams = new URLSearchParams(search)
		const output: any = {}
		const entries: any = urlParams.entries()
		for (const [key, value] of entries) {
			output[key] = value
		}
		return output
	}, [search])

	useEffect(() => {
		logger.log('BookingCalendar', { params, VALID_PARAMS })
	}, [params])

	const handleDateChange = useCallback(
		([start, end = undefined]: any) => {
			updateForm({
				startDate: start,
				endDate: end
			})
		},
		[updateForm]
	)

	const formatShortWeekday = useCallback((locale: string | unknown, date: Date) => {
		logger.log('BookingCalendar', { [moment(date).format('LLL')]: { locale, date } })
		const stringDate = moment(date).format('dd')
		return stringDate.charAt(0)
	}, [])

	const clear = useCallback(() => {
		logger.log('clear')
		updateForm(state)
	}, [updateForm])

	const clearDisabled = useMemo(() => {
		return !form.startDate && !form.endDate
	}, [form])

	const handleSuccess = useCallback(
		(msg: string) => {
			logger.log('handleSuccess', msg)
			showModal({
				title: 'Success',
				text: 'Your reservation request has been sent. You will recieve a confirmation email short. Thank you.',
				primaryAction: {
					label: 'Ok',
					onClick: () => {
						clear()
						closeModal()
					}
				}
			})
		},
		[clear, closeModal, showModal]
	)

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault()
			logger.log('BookingCalendar', { handleSubmit: form })
			showModal({
				title: 'Reservation Request',
				component: BookingDialog,
				options: {
					fullScreen: true
				},
				data: {
					property,
					onSuccess: handleSuccess
				},
				handleModalClose: () => closeModal()
			})
		},
		[closeModal, form, handleSuccess, property, showModal]
	)

	return (
		<form onSubmit={handleSubmit}>
			<Box className={classes.selectors}>
				<Box className={classes.selector}>
					<SelectField
						label="Guests"
						name="guests"
						value={form.guests}
						options={createSelectorOptions()}
						onChange={handleInputChange}
						// validation={required}
					/>
				</Box>
				<Box className={classes.selector}>
					<SelectField
						label="Rooms"
						name="rooms"
						value={form.rooms}
						options={createSelectorOptions()}
						onChange={handleInputChange}
						// validation={required}
					/>
				</Box>
			</Box>
			<Box className={classes.calendar}>
				<Calendar
					onChange={handleDateChange}
					minDate={new Date()}
					selectRange={true}
					allowPartialRange={true}
					prevLabel={
						<span className={classes.navButton}>
							<ArrowBackOutlined />
						</span>
					}
					nextLabel={
						<span className={classes.navButton}>
							<ArrowForwardOutlined />
						</span>
					}
					formatShortWeekday={formatShortWeekday}
					value={[form.startDate, form.endDate]}
				/>
			</Box>
			<Box className={classes.footer}>
				<Button
					className={classNames(classes.clearButton, classes.button)}
					color="primary"
					onClick={clear}
					disabled={clearDisabled}
				>
					Clear
				</Button>
				<Divider className={classes.divider} />
				<Button
					type="submit"
					size="lg"
					color="primary"
					disabled={clearDisabled}
					className={classNames(classes.button, classes.reservationButton)}
				>
					Send reservation request
				</Button>
			</Box>
		</form>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		border: '1px solid #ccc',
		borderRadius: theme.spacing(1)
	},
	navButton: {
		display: 'flex',
		background: '#f1f1f1',
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 30
	},
	selectors: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(0.5),
		padding: theme.spacing(2, 2, 0),
		marginBottom: theme.spacing(3)
	},
	selector: {
		display: 'flex',
		flex: 1
	},
	calendar: {},
	footer: {
		textAlign: 'center',
		padding: theme.spacing(2)
	},
	clearButton: {
		borderRadius: 30,
		textTransform: 'none'
	},
	divider: {
		margin: '24px 0'
	},
	button: {
		backgroundColor: '#F97487',
		border: '1px solid #F97487',

		'&:hover': {
			backgroundColor: '#fff',
			color: '#F97487'
		},
		'&:active': {
			backgroundColor: '#fff',
			color: '#F97487'
		},
		'&:visited': {
			backgroundColor: '#fff',
			color: '#F97487'
		},
		'&:focus': {
			backgroundColor: '#fff',
			color: '#F97487'
		}
	},
	reservationButton: {
		textTransform: 'none',
		borderRadius: theme.spacing(1),
		width: '100%',
		fontSize: '1.2rem'
	}
}))

export default BookingCalendar
