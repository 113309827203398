import { useQuery } from '@apollo/client'
import { Box, Container, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SearchCard from '@services/app/components/SearchCard/SearchCard'
import RecentArticles from '@services/blog/components/RecentArticles'
import AspectRatio from '@services/common/components/AspectRatio'
import { Image } from '@services/common/components/Image'
import { Loading } from '@services/common/components/Loading'
import { renderDate } from '@services/common/helpers/render-date'
import { renderLocation } from '@services/common/helpers/render-location'
import { wrapParagraph } from '@services/common/helpers/wrap-paragraph'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import FeaturedProperty from '@services/property/components/FeaturedProperty'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { GET_GETAWAY_PROPERTY } from '../graphql'
import GetawayConcierge from '../components/GetawayConcierge'
import { pageData } from '@services/app/constants/homepage'

const GetawayDetail = () => {
	const routeParams: any = useParams()
	console.log('GetawayDetail', { routeParams })

	const { data }: any = useQuery(GET_GETAWAY_PROPERTY, {
		variables: {
			id: routeParams.id
		},
		fetchPolicy: 'cache-first'
	})

	const getaway = useMemo(() => {
		return data ? data.getGetaway : data
	}, [data])

	const property = useMemo(() => {
		return getaway && getaway.property
	}, [getaway])

	const propertyPhoto = useMemo(() => {
		try {
			const idx = Math.floor(Math.random() * property.photos.length)
			return buildS3ImageUrl('properties', property.photos[idx])
		} catch (e) {
			return ''
		}
	}, [property])

	const mainImage = useMemo(() => {
		try {
			const photoResource = getaway.photos && getaway.photos.length > 0 ? 'getaways' : 'properties'
			const photo = photoResource === 'getaways' ? getaway.photos[0] : property.photos[0]
			return buildS3ImageUrl(photoResource, photo)
		} catch (e) {
			return ''
		}
	}, [getaway, property])

	const location = useMemo(() => {
		return getaway ? getaway.location : ''
	}, [getaway])

	const classes = useStyles()

	if (!getaway) {
		return <Loading overlay={false} />
	}

	return (
		<Box display="flex" flex={1} flexDirection="column">
			<Box display="flex" flex={1} flexDirection="column">
				<Container
					classes={{
						root: classes.container
					}}
				>
					<Box className={classes.content}>
						<Typography className={classes.title} variant="h1">
							{getaway.title}
						</Typography>

						<Box className={classes.imageWrap}>
							<Image
								classes={{
									root: classes.image
								}}
								src={mainImage}
								aspectRatio={16 / 9}
							/>
							<Box className={classes.eventInfo}>
								<dl>
									<dt className={classes.eventInfoTitle}>When</dt>
									<dd className={classes.eventInfoBody}>
										{renderDate(getaway.startDate, getaway.endDate)}
									</dd>
									{location && (
										<>
											<dt className={classes.eventInfoTitle}>Location</dt>
											<dd className={classes.eventInfoBody}>{renderLocation(location)}</dd>
										</>
									)}
									<dt className={classes.eventInfoTitle}>Venue</dt>
									<dd className={classes.eventInfoBody}>{getaway.property.name}</dd>
								</dl>
							</Box>
						</Box>

						<Box className={classes.getawayDescription}>
							{getaway.tagline && (
								<Typography
									variant="h2"
									className={classes.getawayDescriptionBlockquote}
								></Typography>
							)}
							<Box className={classes.getawayDescriptionBody}>
								{wrapParagraph(getaway.description)}
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
			<Box className={classes.separatorImage}>
				<Box className={classes.separatorImageBg}>
					<Image src={propertyPhoto} aspectRatio={4 / 3} />
				</Box>
				<AspectRatio ratio={5 / 1.3} />
			</Box>
			<Container
				classes={{
					root: classes.container
				}}
			>
				<Box className={classes.imageWrap}>
					<FeaturedProperty item={property} />
				</Box>

				<GetawayConcierge />
			</Container>
			<SearchCard
				colors={{
					primary: '#44C2D9'
				}}
				title={pageData.hero.title}
				text={pageData.hero.text}
				image="https://photos.mygaygetaway.com/properties/5d8284615bdcfacc3b1eabc2/8b84b0e3-f433-47f9-9687-b91f489e7c79.jpg"
			/>
			<RecentArticles />
			<NewsletterSignup />
		</Box>
	)
}

const useStyles = makeStyles({
	container: {
		// textAlign: 'center',
		padding: '120px 50px 80px',
		backgroundColor: '#fff',
		borderRadius: 12
		// display: 'flex',
		// justifyContent: 'center'
	},
	content: {
		maxWidth: '80%',
		margin: '0em auto',
		textAlign: 'left',
		'& h1': {
			marginBottom: 30,
			maxWidth: 500,
			fontWeight: 500
		}
	},
	title: {
		fontSize: '2rem !important'
	},
	getawayDescription: {
		display: 'flex',
		flexDirection: 'row'
	},
	getawayDescriptionBlockquote: {
		display: 'flex',
		flex: 1
	},
	getawayDescriptionBody: {
		display: 'flex',
		flex: 2,
		flexDirection: 'column'
	},
	imageWrap: {
		position: 'relative',
		marginBottom: 150
	},
	image: {
		borderRadius: 4,
		overflow: 'hidden'
	},
	separatorImage: {
		position: 'relative'
	},
	separatorImageBg: {
		position: 'absolute',
		zIndex: -1,
		transform: 'translateY(-25%)',
		width: '100%',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: -1,
			width: '100%',
			height: 150,
			background:
				'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%,  rgba(255,255,255,0) 100%)',
			zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			// transform: 'translateY(-150px)',
			width: '100%',
			height: 150,
			background:
				'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%,  rgba(255,255,255,0) 100%)',
			zIndex: 1
		}
		// top: 0,
		// right: 0,
		// bottom: 0,
		// left: 0,
		// backgroundImage:
		// 	'url(https://photos.mygaygetaway.com/properties/5d82846c5bdcfacc3b1ec2eb/c43aac40-91df-4f08-9969-fbcf82f82926.jpg)',
		// backgroundSize: 'cover'
	},
	eventInfo: {
		position: 'absolute',
		color: '#fff',
		backgroundColor: '#4D81F7',
		padding: '32px 32px 40px',
		borderRadius: 8,
		transform: 'translateY(100px)',
		bottom: 0,
		right: 60
	},
	eventInfoTitle: {
		fontWeight: 500,
		lineHeight: '1rem',
		opacity: 0.6,
		marginBottom: 8
	},
	eventInfoBody: {
		margin: '0 0 30px',
		fontWeight: 500
	}
})
export default GetawayDetail
