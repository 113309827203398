import { gql } from '@apollo/client'

export const GET_CREATOR_PROFILE_BY_NAME = gql`
  query creatorProfileByName($name: String!) {
    creatorProfileByName(name: $name) {
      account {
        name
        id
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
