import { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Container, Typography } from '@mui/material'
import { Loading } from '@services/common/components'
import { wrapParagraph } from '@services/common/helpers/wrap-paragraph'
import { Image } from '@services/common/components/Image'
import { useRedirect } from '@services/common/hooks'
import { GET_CONTENT_BY_KEY } from '../../graphql'
import Actions from './Actions'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'

export interface MarketingBlockProps {
	contentKey?: string
}

const styles: any = {
	root: {
		padding: [11, 0, 8],
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
		'&:before': {
			content: '""',
			backgroundColor: 'rgba(0,0,0,.2)',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 0
		}
	},
	container: {
		color: '#fff',
		position: 'relative',
		zIndex: 1
	},
	title: {
		fontSize: 64,
		fontWeight: 600
	},
	subtitle: {
		fontSize: 36,
		marginBottom: 3
	},
	text: {
		marginBottom: '0 !important',
		fontSize: 3,
		fontWeight: 500
	},
	textWrap: {
		marginBottom: 4
	},
	body: {},
	image: {
		borderRadius: 1,
		overflow: 'hidden'
	},
	content: {
		display: 'flex',
		flexDirection: 'row'
	},
	contentImage: {
		display: 'flex',
		flex: 1,
		maxWidth: '50%'
	}
}

const MarketingBlock = ({ contentKey }: MarketingBlockProps) => {
	const { redirect }: any = useRedirect()
	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: contentKey
		}
	})
	const content = useMemo(() => {
		return data ? data.getContentByKey : data
	}, [data])

	const contentImage = useMemo(() => {
		return content ? buildS3ImageUrl('content', content.contentImage) : ''
	}, [content])

	const rootStyles = useMemo(() => {
		return content
			? { backgroundImage: `url(${buildS3ImageUrl('content', content.backgroundImage)})` }
			: {}
	}, [content])

	const handleAction = useCallback(
		(evt: any, { event, value }: any) => {
			evt.preventDefault()
			switch (event) {
				case 'redirect':
					redirect(value)
					break
				case 'external':
					window.open(value, '_blank', 'noopener noreferrer')
					break
			}
		},
		[redirect]
	)

	if (!content) {
		return <Loading overlay={false} />
	}

	return (
		<Box sx={{ ...styles.root, ...rootStyles }}>
			<Container sx={styles.container} maxWidth="lg">
				{content.title && (
					<Typography variant="h3" sx={styles.title}>
						{content.title}
					</Typography>
				)}
				{content.subtitle && (
					<Typography variant="h6" sx={styles.subtitle}>
						{content.subtitle}
					</Typography>
				)}
				{content.text && (
					<Box sx={styles.textWrap}>{wrapParagraph(content.text /*, classes.text*/)}</Box>
				)}
				<Box sx={styles.content}>
					{contentImage && (
						<Box sx={styles.contentImage}>
							<Image
								classes={{
									root: styles.image
								}}
								src={contentImage}
								aspectRatio={534 / 334}
							/>
						</Box>
					)}

					<Actions actions={content.actions} onClick={handleAction} />
				</Box>
			</Container>
		</Box>
	)
}

export default MarketingBlock
