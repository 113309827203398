import { Box, Chip, Container } from '@mui/material'
import { Image } from '@services/common/components/Image'
import AspectRatio from '@services/common/components/AspectRatio'
import { dragRaceAssets } from '../constants/assets'

const DragRaceHeader = ({ items = [] }: any) => {
	return (
		<Box sx={styles.root}>
			<AspectRatio ratio={1440 / 493}>
				<Container maxWidth="lg" sx={styles.container}>
					<Box display="flex" flex={1}>
						<Box sx={styles.logoWrap}>
							<Image width="100%" src={dragRaceAssets.headerImage} />
						</Box>
						<Box component="span" sx={styles.overlay}>
							<Image height="100%" src={dragRaceAssets.overlayImage} />
						</Box>
					</Box>
					{items.length > 0 && (
						<Box sx={styles.seasonSelect}>
							{items.map((item: any) => (
								<Chip
									key={`season-${item}`}
									label={`Season ${item}`}
									variant="outlined"
									sx={styles.chip}
								/>
							))}
						</Box>
					)}
				</Container>
			</AspectRatio>
		</Box>
	)
}

const styles = {
	root: {
		background: `url(${dragRaceAssets.backgroundImage}) center no-repeat`,
		backgroundSize: 'cover'
	},
	container: {
		position: 'relative',
		paddingTop: 12
	},
	logoWrap: {
		marginBottom: 6,
		maxWidth: 600
	},
	overlay: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		top: 48
	},
	seasonSelect: {
		display: 'flex',
		flex: 1,
		alignItems: 'flex-end'
	},
	chip: {
		color: '#fff',
		borderColor: '#fff',
		marginRight: 1
	},
	chipRoot: {}
}

export default DragRaceHeader
