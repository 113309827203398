import { useMemo } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { renderDate } from '@services/common/helpers/render-date'
import { renderLocation } from '@services/common/helpers/render-location'

const EventInfo = ({ event }: any) => {
	const classes = useStyles()

	const location = useMemo(() => {
		return event ? event.location : ''
	}, [event])

	return (
		<Box
			sx={{
				position: 'absolute',
				color: '#fff',
				backgroundColor: '#4D81F7',
				padding: '32px 32px 40px',
				borderRadius: 1,
				transform: 'translateY(100px)',
				bottom: 0,
				right: '60px'
			}}
		>
			<dl>
				<dt className={classes.eventInfoTitle}>When</dt>
				<dd className={classes.eventInfoBody}>{renderDate(event.startDate, event.endDate)}</dd>
				{location && (
					<>
						<dt className={classes.eventInfoTitle}>Location</dt>
						<dd className={classes.eventInfoBody}>{renderLocation(location)}</dd>
					</>
				)}
			</dl>
		</Box>
	)
}

const useStyles = makeStyles({
	eventInfoTitle: {
		fontWeight: 500,
		lineHeight: '1rem',
		opacity: 0.6,
		marginBottom: 8
	},
	eventInfoBody: {
		margin: '0 0 30px',
		fontWeight: 500
	}
})

export default EventInfo
