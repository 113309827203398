import { MutableRefObject, useContext } from 'react'
import { useApp } from '@services/app/hooks/use-app'
import { useAuth } from '@services/auth/hooks/use-auth'
import { mockHandler } from '@form/utils'
import { UploadConductor } from '../services/upload-conductor'
import { MediaContext } from '../MediaProvider/MediaContext'
import { authHeaders, filePrompt } from '../utils'
import { logger } from '@services/common/lib/logger'

export interface UploadableRef {
	type: string
	ref: MutableRefObject<any>
	path?: string
}

export interface UploadPromptOptions {
	path?: string
	onComplete?: any
	params?: any
}

export const useUpload = (refs: UploadableRef[]): any => {
	const { authToken }: any = useAuth()
	const { notify }: any = useApp()
	const { bindUpload, updateProgress, clearUpload }: any = useContext(MediaContext)

	const onUploadSuccess = (data: any, cb?: any) => {
		updateProgress(data)
		notify('Upload Complete!')
		clearUpload(data.id, 60)
		cb(data)
	}

	const processFile = ({ path, file, onComplete = mockHandler, params }: any): void => {
		const uploader = UploadConductor.upload(file, {
			...authHeaders(authToken),
			path,
			params
		})
		uploader.on('load', bindUpload)
		uploader.on('progress', updateProgress)
		uploader.on('complete', (instance: UploadConductor, data: any) =>
			onUploadSuccess(data, onComplete)
		)
	}

	const processFiles = ({ path, files, onComplete = mockHandler, params }: any) => {
		Object.values(files).forEach((file: any) => processFile({ path, file, onComplete, params }))
	}

	const showPrompt = ({
		path,
		onComplete = mockHandler,
		params
	}: UploadPromptOptions = {}): void => {
		filePrompt((files: any) => processFiles({ path, files, onComplete, params }))
	}

	const handleDrop =
		({ path, onComplete = mockHandler, params }: UploadPromptOptions = {}) =>
		(e: any) => {
			e.preventDefault()
			const files = e.dataTransfer.files
			logger.log('handleDrop', { files, path, params })
			processFiles({ files, path, onComplete, params })
		}

	return {
		showPrompt,
		handleDrop
	}
}
