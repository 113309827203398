import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box, Chip, Container, Divider, Grid, Typography } from '@mui/material'
import RecentArticles from '@services/blog/components/RecentArticles'
import StickyContent from '@services/common/components/StickyContent'
import PageSeparator from '@services/common/components/PageSeparator'
import Loading from '@services/common/components/Loading'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import Getaways from '@services/getaway/components/Getaways'
import LocationMap from '@services/location/components/LocationMap'
import { GET_PROPERTY } from '../../graphql'
import BodySection from './BodySection'
import PropertyImages from './PropertyImages'
import PropertyAmenities from './PropertyAmenities'
import { PhoneOutlined } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import ContactPreference from '../../components/ContactPreference'
// import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
// import AppHead from '@services/app/components/AppHead'
// import { truncateWords } from '@services/common/helpers/truncate-words'
// import page styles
import './styles.scss'

const PropertyDetail = () => {
	const routeParams: any = useParams()

	const { data }: any = useQuery(GET_PROPERTY, {
		variables: {
			id: routeParams.id
		},
		fetchPolicy: 'cache-first'
	})

	const property = useMemo(() => {
		return data ? data.getProperty : {}
	}, [data])

	const propertyAddressText = useMemo(() => {
		if (!property) {
			return ''
		}
		return property.location ? property.location.address : property.address ? property.address : ''
	}, [property])

	const propertyCords = useMemo(() => {
		if (!property.location) {
			return null
		}
		return {
			latitude: property.location.latitude,
			longitude: property.location.longitude,
		}
	}, [property])

	const propertyMarker = useMemo(() => {
		if (isEmpty(property) || !property.location) {
			return []
		}
		return {
			id: property.id,
			title: property.name,
			lat: property.location.latitude,
			long: property.location.longitude,
			content: `<div class="g-style-iw-content"><h2>${property.name}</h2><h3>${property.location.address}</h3>`
		}
	}, [property])

	// const seoTitle = useMemo(() => {
	// 	return property ? `${property.name} - My Gay Getaway` : ''
	// }, [property])
	// const seoImage = useMemo(() => {
	// 	return property && property.photos && property.photos.length > 0
	// 		? buildS3ImageUrl('property', property.photos[0])
	// 		: ''
	// }, [property])
	// const seoDescription = useMemo(() => {
	// 	return truncateWords(property.information, 100)
	// }, [property.information])

	const categories = useMemo(() => {
		return property && property.types && property.types.length > 0
			? property.types.slice(0, 4)
			: null
	}, [property])

	if (!property) {
		return <Loading overlay={false} />
	}

	console.log({ propertyCords })

	return (
		<Box sx={styles.flexColumn}>
			{/* <AppHead title={seoTitle} description={seoDescription} image={seoImage} /> */}

			<Box sx={styles.flexColumn}>
				<Container sx={styles.container}>
					<Grid container spacing={6}>
						<Grid item md={12}>
							<PropertyImages item={property} />
						</Grid>
						<Grid item md={8}>
							<Box display="flex" flexDirection="column" style={{ marginBottom: 16 }}>
								<Typography variant="h1" sx={styles.title}>
									{property.name}
								</Typography>

								{categories && (
									<Box sx={styles.categories}>
										{categories.map((cat: any) => (
											<Chip
												sx={styles.category}
												key={`cat-${cat.id}`}
												// icon={<Check />}
												label={cat.name}
												variant="outlined"
											/>
										))}
									</Box>
								)}
								<Typography>{propertyAddressText}</Typography>
							</Box>

							{property.phone && (
								<Box sx={styles.contact}>
									<PhoneOutlined />
									<Typography>{`+ ${property.phone}`}</Typography>
								</Box>
							)}
							<Divider sx={styles.divider} />
							{property.review && <BodySection title="Why Stay?" text={property.review} />}
							<BodySection title="Property Info" text={property.information} />
							<PropertyAmenities amenities={property.amenities} />
							{property.promoAmenities && (
								<BodySection title="MGG Bonus" text={property.promoAmenities} />
							)}
						</Grid>
						<StickyContent>
							<ContactPreference property={property} />
						</StickyContent>
					</Grid>
				</Container>
			</Box>
			{propertyAddressText && (
				<PageSeparator>
					<LocationMap
						searchTerm={propertyAddressText}
						// {...propertyCords}
						zoom={15}
						markers={[propertyMarker]}
						firstContentVisible={true}
					/>
				</PageSeparator>
			)}

			<Container sx={styles.bottomContainer}>
				{/* <Box className={classes.imageWrap}>{<GetawayProperty property={property} />}</Box> */}

				<Getaways />
				<Divider style={{ maxWidth: '1180px', margin: '0em auto', width: '100%' }} />
				{/* <GetawayConcierge /> */}

				<RecentArticles />
			</Container>
			{/* <SearchCard
				colors={{
					primary: '#44C2D9'
				}}
				title="Feeling inspired?"
				text="Find adventures nearby or in faraway places, access unique hotels, restaurants and places around the world"
				image="https://photos.mygaygetaway.com/properties/5d8284615bdcfacc3b1eabc2/8b84b0e3-f433-47f9-9687-b91f489e7c79.jpg"
			/> */}
			<NewsletterSignup />
		</Box>
	)
}

const styles = {
	container: {
		// textAlign: 'center',
		padding: '120px 50px 80px',
		backgroundColor: '#fff',
		borderRadius: 3,
		overflow: 'hidden'
		// display: 'flex',
		// justifyContent: 'center'
	},
	bottomContainer: {
		backgroundColor: '#fff',
		borderRadius: 3,
		overflow: 'hidden'
	},
	content: {
		maxWidth: '80%',
		margin: '0em auto',
		textAlign: 'left'
	},
	divider: { margin: '50px 0' },
	title: {
		fontSize: '2rem',
		maxWidth: 500,
		fontWeight: 500
	},
	categories: {
		padding: '16px 0'
	},
	category: {
		marginRight: 0.5
	},
	flexColumn: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	getawayDescription: {
		display: 'flex',
		flexDirection: 'row'
	},
	getawayDescriptionBlockquote: {
		display: 'flex',
		flex: 1
	},
	getawayDescriptionBody: {
		display: 'flex',
		flex: 2,
		flexDirection: 'column'
	},
	imageWrap: {
		position: 'relative',
		marginBottom: 150
	},
	image: {
		borderRadius: 2,
		overflow: 'hidden'
	},
	contact: {
		display: 'flex',
		flexDirection: 'row'
		// justifyContent: 'space-between',
		// paddingBottom: theme.spacing(3)
	},
	eventInfo: {
		position: 'absolute',
		color: '#fff',
		backgroundColor: '#4D81F7',
		padding: '32px 32px 40px',
		borderRadius: 2,
		transform: 'translateY(100px)',
		bottom: 0,
		right: 60
	},
	eventInfoTitle: {
		fontWeight: 500,
		lineHeight: '1rem',
		opacity: 0.6,
		marginBottom: 1
	},
	eventInfoBody: {
		margin: '0 0 30px',
		fontWeight: 500
	}
}
export default PropertyDetail
