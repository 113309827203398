export const CREATE_UPLOAD_QUERY = `
mutation createUpload($input: CreateUploadInput!) {
  createUpload(input: $input) {
    id
    uploadId
    urls
    key
  }
}`

export const COMPLETE_MULTIPART_UPLOAD = `
mutation CompleteUpload($input: CompleteUploadInput!) {
  completeUpload(input: $input) {
    location
  }
}
`
