import { useState, MutableRefObject, useCallback, useLayoutEffect } from 'react'
import { ResizeObserver } from '@juggle/resize-observer'

const getSize = (el: any) => {
  return el
    ? { width: el.offsetWidth, height: el.offsetHeight }
    : { width: 0, height: 0 }
}

export const useComponentSize = (ref: MutableRefObject<any>) => {
  const [size, setSize] = useState(getSize(ref ? ref.current : ref))
  const handleResize = useCallback(() => {
    if (ref.current) {
      setSize(getSize(ref.current))
    }
  }, [ref])

  useLayoutEffect(() => {
    const context = ref.current
    if (!context) {
      return
    }
    if (typeof ResizeObserver === 'function') {
      let observer: any = new ResizeObserver(() => handleResize())
      observer.observe(context)

      return () => {
        observer.disconnect(context)
        observer = null
      }
    } else {
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [ref, handleResize])

  return size
}
