import { Button, Grid } from '@mui/material'
import { PageSection } from '@services/common/components/PageSection'
import ConciergeCard from './ConciergeCard'
import { useQuery } from '@apollo/client'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql'
import { DEFAULT_COLORS } from '@services/app/constants/app'
import { Link as RouteLink } from 'react-router-dom'

const GetawayConcierge = ({
	key = 'collection.getaway-concierge',
	classes: injectedClasses = {
		pageTitle: ''
	}
}: any) => {
	const { data } = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key
		}
	})

	const concierge = data ? data.getContentByKey : null

	if (!concierge || concierge.status !== 'published') {
		return null
	}

	return (
		<PageSection
			header="Getaway Concierge"
			classes={{
				header: injectedClasses.pageTitle
			}}
		>
			<Grid container spacing={3}>
				{concierge.collection.map((item: any, idx: number) => (
					<Grid key={`concierge-card-${idx}`} item md={4}>
						<ConciergeCard {...item} />
					</Grid>
				))}

				<Grid item md={12} sx={{ textAlign: 'center' }}>
					{/* <RouteLink
						component={Link}
						to="/contact"
					>
						Find out more
					</RouteLink> */}
					<Button
						component={RouteLink}
						role="button"
						to="/contact/getaway-concierge"
						sx={{
							color: DEFAULT_COLORS.primary,
							// borderColor: DEFAULT_COLORS.primary
							border: `1px solid ${DEFAULT_COLORS.primary}`,
							backgroundColor: 'transparent',
							padding: '1.125rem 2.25rem',
							fontSize: '1.2rem',
							textTransform: 'none',
							margin: '0em auto',
							// borderRadius: '30px',
							'&:hover': {
								backgroundColor: DEFAULT_COLORS.primary,
								color: '#fff',
								borderColor: DEFAULT_COLORS.primary
							}
						}}
						// variant="outlined"
					>
						Find out more
					</Button>
				</Grid>
			</Grid>
		</PageSection>
	)
}

export default GetawayConcierge
