import { gql } from '@apollo/client'

export const UNSUBSCRIBE = gql`
  mutation unsubscribe($id: ID!) {
    unsubscribe(id: $id) {
      account {
        name
        id
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
