import { FC, Suspense, useMemo } from 'react'
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	IconButton,
	Typography,
	Theme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
// import { TransitionProps } from '@mui/material/transitions/transition'
import { isEmpty, noop } from 'lodash'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import { Loading } from '@services/common/components'
import { useModal } from '@services/app/hooks/use-modal'

export interface ModalProps {}

const useStyles: any = makeStyles((theme: Theme) => ({
	contentRoot: {
		padding: `0 !important`
	},
	contentPaper: {
		width: '100%'
	},
	defaultText: {
		padding: theme.spacing(3)
	}
}))

// const Transition = forwardRef(
// 	(
// 		{ children, ...props }: TransitionProps & { children?: React.ReactElement },
// 		ref: React.Ref<unknown>
// 	) => {
// 		return (
// 			<Slide direction="up" ref={ref} {...props}>
// 				<>{children}</>
// 			</Slide>
// 		)
// 	}
// )

const Modal = () => {
	const { modal, closeModal }: any = useModal()
	const classes: any = useStyles()
	const showModal = useMemo(() => !isEmpty(modal), [modal])

	const handleClose = modal.handleModalClose || noop

	const options = useMemo(() => {
		const config: any = {
			fullWidth: false,
			open: showModal,
			onClose: handleClose,
			maxWidth: 'sm'
			// TransitionComponent: Transition
		}
		if (modal.options) {
			if (modal.options.fullWidth) {
				delete config.maxWidth
			} else if (modal.options.fullScreen) {
				delete config.maxWidth
				delete config.fullWidth
			}
			Object.assign(config, modal.options)
		}
		return config
	}, [modal, showModal, handleClose])
	// console.log('ModalConductor', options)

	const modalClasses = useMemo(() => {
		return modal.classes || {}
	}, [modal])

	const modalStyles = useMemo(() => {
		return modal.styles || {}
	}, [modal])

	if (!showModal) {
		return null
	}

	const RenderModal: FC<ModalProps> = (props: any) => {
		return (
			<Suspense fallback={<Loading overlay={false} />}>
				<modal.component close={closeModal} {...props} />
			</Suspense>
		)
	}

	return (
		<Dialog {...options} classes={{ paper: classes.contentPaper }}>
			<DialogTitle>
				{modal.title}
				<IconButton
					edge="end"
					color="inherit"
					onClick={handleClose}
					aria-label="close"
					style={{ float: 'right' }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			{(modal.component || modal.text) && (
				<DialogContent
					style={modalStyles.contentRoot}
					classes={{ root: classNames(classes.contentRoot, modalClasses.contentRoot) }}
				>
					<Box display="flex" justifyContent="center" alignItems="center">
						{modal.component && <RenderModal {...modal.data} />}
						{modal.text && (
							<div className={classes.defaultText}>
								<Typography>{modal.text}</Typography>
							</div>
						)}
					</Box>
				</DialogContent>
			)}
			{(modal.primaryAction || modal.secondaryAction) && (
				<DialogActions>
					{modal.secondaryAction && (
						<Button onClick={modal.secondaryAction.onClick}>{modal.secondaryAction.label}</Button>
					)}
					{modal.primaryAction && (
						<Button onClick={modal.primaryAction.onClick}>{modal.primaryAction.label}</Button>
					)}
				</DialogActions>
			)}
		</Dialog>
	)
}

const ModalConductor = () => {
	return createPortal(<Modal />, document.body)
}

export default ModalConductor
