import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
	// Circle, 
	GoogleMap,
	// InfoWindow, 
	useJsApiLoader
} from '@react-google-maps/api'
import { useConfig } from 'app/hooks/use-config'
import { logger } from '@services/common/lib/logger'
import { noop } from 'lodash'

const win: any = window

const libraries: any = ['places']

// const options = {
// 	strokeColor: '#F97487',
// 	strokeOpacity: 0.2,
// 	strokeWeight: 0,
// 	fillColor: '#F97487',
// 	fillOpacity: 0.2,
// 	clickable: false,
// 	draggable: false,
// 	editable: false,
// 	visible: true,
// 	zIndex: 1
// }

export interface LocationMapProps {
	latitude?: number
	longitude?: number
	zoom?: number
	searchTerm?: string
	markers?: any
	onHover?: any
	onSelected?: any
	firstContentVisible?: boolean
	centerMarkers?: boolean
}

const LocationMap = ({
	latitude: defaultLatitude = 34.0522,
	longitude: defaultLongitude = -118.2437,
	zoom = 10,
	searchTerm,
	markers = [],
	onHover = noop,
	onSelected = noop,
	firstContentVisible,
	centerMarkers
}:
	any) => {
	const [bounds, setBounds]: any = useState(null)
	const [{ latitude, longitude }, setPosition]: any = useState({
		latitude: typeof defaultLatitude === 'string' ? +defaultLatitude : defaultLatitude,
		longitude: typeof defaultLongitude === 'string' ? +defaultLongitude : defaultLongitude
	})
	const classes = useStyles()
	// const [service, setService]: any = useState(undefined)
	const { mapKey }: any = useConfig()
	const { isLoaded } = useJsApiLoader({
		id: 'map',
		googleMapsApiKey: mapKey,
		libraries
	})

	const [map, setMap]: any = useState(null)

	useEffect(() => {
		logger.log('LocationMap', { map })
	}, [map])

	const onSearchResponse = useCallback(
		(results: any, status: any) => {
			if (status === win.google.maps.places.PlacesServiceStatus.OK && results) {
				const place = results[0]
				if (!place.geometry || !place.geometry.location) {
					return
				}

				setPosition({
					latitude: place.geometry.location.lat(),
					longitude: place.geometry.location.lng()
				})
			}
		},
		[setPosition]
	)

	const connectPlaces = useCallback(
		(map: any) => {
			if (searchTerm) {
				const service = new win.google.maps.places.PlacesService(map)
				service.findPlaceFromQuery(
					{
						query: searchTerm,
						fields: ['name', 'geometry']
					},
					onSearchResponse
				)
			}
		},
		[searchTerm, onSearchResponse]
	)

	const onLoad = useCallback(
		(map: any) => {
			if (win.google.maps.places) {
				connectPlaces(map)
			}
			const _bounds = new win.google.maps.LatLngBounds()
			logger.log('LocationMap', { bounds: _bounds })
			// !centerMarkers && map.fitBounds(_bounds)
			setMap(map)
			setBounds(_bounds)
		},
		[connectPlaces]
	)

	const onUnmount = useCallback((map: any) => {
		setMap(null)
	}, [])

	const loadMarkers = useCallback(() => {
		const infoWindow: any = new google.maps.InfoWindow()
		markers.length > 0 && markers.forEach(({ id, lat, long, title, content }: any, idx: number) => {
			const position = new google.maps.LatLng(lat, long)
			bounds.extend(position)
			const marker = new google.maps.Marker({
				position,
				map,
				title,
				// icon: `<img className={classes.marker} src="http://localhost:3000/assets/images/hotel-solid.svg" width="20" height="20" alt=${title} />`
			})

			google.maps.event.addListener(marker, 'click', function () {
				infoWindow.setContent(content)
				infoWindow.open(map, marker)
				onSelected(id)
			})
			google.maps.event.addListener(marker, 'mouseover', function () {
				onHover(id)
			})

			if (firstContentVisible && idx === 0) {
				infoWindow.setContent(content)
				infoWindow.open(map, marker)
			}
		})

		if (centerMarkers) {
			map.fitBounds(bounds)
		}
	}, [bounds, centerMarkers, firstContentVisible, map, markers, onHover, onSelected])

	useEffect(() => {
		markers.length > 0 && bounds && loadMarkers()
	}, [bounds, loadMarkers, markers])

	const center = {
		lat: latitude,
		lng: longitude
	}
	logger.log('LocationMap', { center, zoom })

	return (
		<Box className={classes.root}>
			{isLoaded && latitude && longitude && (
				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '100%'
					}}
					center={center}
					zoom={zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{/* <img className={classes.marker} src="/assets/images/hotel-solid.svg" alt="map" />
					<Circle
						radius={5000}
						center={center}
						options={{
							// strokeColor: '#F97487',
							// strokeOpacity: 0.2,
							strokeWeight: 0,
							fillColor: '#F97487',
							fillOpacity: 0.6,
							clickable: false,
							draggable: false,
							editable: false,
							visible: true,
							zIndex: 1
						}}
					/>
					<Circle radius={10000} center={center} options={options} /> */}
				</GoogleMap>
			)}
		</Box>
	)
}
const useStyles = makeStyles({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0
	},
	marker: {
		position: 'absolute',
		width: 30,
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)'
	}
})
export default LocationMap
