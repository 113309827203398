import { gql } from '@apollo/client'
import { ACCOUNT_FRAGMENT } from '../account-frag'

export const UPDATE_USER_ACCOUNT = gql`
	mutation UpdateUserAccount($input: UpdateUserAccountInput!) {
		updateUserAccount(input: $input) {
			...AccountInfo
		}
	}
	${ACCOUNT_FRAGMENT}
`
