import config from 'app/config/environment'

export const initialState: any = {
	user: undefined,
	error: '',
	token: '',
	inviteToken: '',
	postAuthentication: undefined,
	loginEnabled: config.features.loginEnabled
}
