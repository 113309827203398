import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AspectRatio from '@services/common/components/AspectRatio'
import { Button } from '@services/common/components/Button'
import { Image } from '@services/common/components/Image'
import { buildRoutePath } from '@services/common/utils/app'
import { DEFAULT_COLORS } from '@services/app/constants/app'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
// import classNames from 'classnames'

const FeaturedProperty = ({ item = {} }: any) => {
	const imageOne = useMemo(() => {
		try {
			return buildS3ImageUrl('properties', item.photos[0])
		} catch (e) {
			return ''
		}
	}, [item])

	const imageTwo = useMemo(() => {
		try {
			return buildS3ImageUrl('properties', item.photos[1])
		} catch (e) {
			return ''
		}
	}, [item])

	const imageThree = useMemo(() => {
		try {
			return buildS3ImageUrl('properties', item.photos[2])
		} catch (e) {
			return ''
		}
	}, [item])

	const url = useMemo(() => {
		return item ? buildRoutePath('properties', item.id) : ''
	}, [item])

	const location = useMemo(() => {
		try {
			const parts: string[] = []
			if (item.location.state) {
				parts.push(item.location.state)
			}
			if (item.location.country) {
				parts.push(item.location.country)
			}

			return parts.join(', ')
		} catch (e) {
			return ''
		}
	}, [item])

	const imageStyles = makeStyles({
		image: {
			backgroundSize: 'cover'
		},
		imageOne: {
			display: 'flex',
			flex: 1,
			marginRight: 1,
			position: 'relative'
		},
		imageOneFilled: {
			left: '50%',
			transform: 'translateX(50%)'
		},
		imageTwo: {
			marginBottom: 1
		}
	})
	const imageClasses = imageStyles()

	return (
		<Box sx={styles.root}>
			<Box sx={styles.images}>
				<AspectRatio
					ratio={652 / 359}
					styles={{
						wrap: {
							gap: 8
						}
					}}
				>
					<Image
						variant="fill"
						classes={{
							root: imageClasses.imageOne
							// filled: imageClasses.imageOneFilled
						}}
						// aspectRatio={284 / 359}
						src={imageOne}
					/>
					{/* <Box style={{ display: 'flex', flex: 1, backgroundColor: '#555', marginRight: 8 }} /> */}
					<Box display="flex" flex={1.3} flexDirection="column" gap={1}>
						<Image
							classes={{
								root: imageClasses.imageTwo
							}}
							aspectRatio={361 / 175}
							src={imageTwo}
						/>
						<Image aspectRatio={361 / 175} src={imageThree} />
					</Box>
				</AspectRatio>
			</Box>
			<Box sx={styles.content}>
				<Typography sx={styles.sectionTitle}>
					Where to <span style={styles.sectionTitleHighlight}>Book</span>
				</Typography>
				<Box
					display="flex"
					flex={1}
					justifyContent="center"
					alignItems="center"
					flexDirection="column"
				>
					<Typography variant="h2" sx={styles.propertyName}>
						{item.name}
					</Typography>
					<Typography variant="h4" sx={styles.subtitle}>
						{location}
					</Typography>
				</Box>
				<Button
					to={url}
					component={Link}
					size="lg"
					sx={{ ...styles.salomonButton, ...styles.largeText }}
				>
					Explore Property
				</Button>
			</Box>
		</Box>
	)
}
const styles = {
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row'
	},
	images: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
		borderRadius: 2.5,
		gap: 1,
		overflow: 'hidden'
		// flexWrap: 'wrap'
	},
	content: {
		display: 'flex',
		flex: 1,
		padding: '16px 0',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center'
	},
	sectionTitle: {
		color: '#979797',
		fontWeight: 500,
		fontSize: '1.3rem'
	},
	sectionTitleHighlight: {
		color: '#f97487'
	},
	propertyName: {
		fontSize: '2rem',
		fontWeight: 600
	},
	subtitle: {
		fontSize: '1.3rem',
		fontWeight: 500,
		color: '#979797'
	},
	largeText: {
		fontSize: '1.5rem',
		padding: '1.125rem 2.25rem'
	},
	salomonButton: {
		color: '#fff',
		backgroundColor: '#f97487',
		textTransform: 'none',
		border: '2px solid ' + DEFAULT_COLORS.primary,
		'&:hover': {
			color: DEFAULT_COLORS.primary,
			borderColor: DEFAULT_COLORS.primary
		}
	},
	cornerTopLeft: {},
	cornerTopRight: {},
	cornerBottomLeft: {},
	cornerBottomRight: {}
}
export default FeaturedProperty
