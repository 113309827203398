import updateLocationField from '@services/location/utils/update-location-field'

const beforeSave = (instance: any, data: any) => {
	const copy = { ...instance }
	console.log({ copy })
	!copy.id && delete copy.id
	if (typeof copy.location === 'string') {
		delete copy.location
	} else if (typeof copy.location === 'object') {
		// copy.location = data.location
		updateLocationField(copy)
	}
	return {
		type: "property",
		listing: copy
	}
}
export default beforeSave
