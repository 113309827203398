import { thumbnailSize } from "./constants"

const cssSize = (sizes: number[]): string[] => {
  return sizes.map((size: number) => `${size}px`)
}

const options = {
  settings: {
    overlayColor: 'rgba(0,0,0,.95)',
    autoplaySpeed: 1500,
    transitionSpeed: 900
  },
  buttons: {
    backgroundColor: 'rgba(255,255,255,.2)',
    iconColor: '#fff'
  },
  caption: {
    captionColor: '#a6cfa5',
    captionFontFamily: 'Raleway, sans-serif',
    captionFontWeight: '300',
    captionTextTransform: 'uppercase'
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'transparent',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '0 1px',
    thumbnailsIconColor: '#ffffff',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: cssSize(thumbnailSize)
  }
}
export default options