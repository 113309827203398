import { Box, Typography } from "@mui/material"
import BookingCalendar from "@services/booking/components/BookingCalendar"

const ContactPreference = ({ property }: any) => {
  if (!property) {
    return null
  }

  if (property?.contactPreference) {
    if (property.contactPreference === 'calendar') {
      return <BookingCalendar property={property} />
    } else {
      return <Box
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: '#F97487',
          color: '#fff',
          overflow: 'hidden',
          height: 300
        }}
      >
        {property.contactPreference === 'phone' && <>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            Call us at
          </Typography>
          <Typography style={{ textAlign: 'center' }}>{property.phone}</Typography>
        </>}
        {property.contactPreference === 'email' && <>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            Email us at
          </Typography>
          <Typography style={{ textAlign: 'center' }}>{property.email}</Typography>
        </>}

      </Box>
    }
  }
  console.log({ ContactPreference: property?.contactPreference })
  return (
    <>
      {property.phone ? (
        <Box
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#F97487',
            color: '#fff',
            overflow: 'hidden',
            height: 300
          }}
        >
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            Call us at
          </Typography>
          <Typography style={{ textAlign: 'center' }}>{property.phone}</Typography>
        </Box>
      ) : null}
    </>
  )
}
export default ContactPreference