import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GridOverlay } from '@mui/x-data-grid'

const useStyles = makeStyles(() => ({
	wrap: {
		position: 'absolute',
		top: 0,
		width: '100%'
	}
}))

export const LoadingOverlay = () => {
	const classes = useStyles()
	return (
		<GridOverlay>
			<div className={classes.wrap}>
				<LinearProgress />
			</div>
		</GridOverlay>
	)
}
