import { Link } from 'react-router-dom'
import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Image } from '@services/common/components/Image'
import { buildRoutePath } from '@services/common/utils/app'
import useImage from '@services/common/hooks/use-image'

const PropertyCard = ({
	item,
	imageOptions = {},
	url = buildRoutePath('/properties', item.id)
}: any) => {
	const classes = useStyles()
	const propertyPhoto = useImage('properties', item.photos[0])
	return (
		<Box className={classes.root}>
			<Card classes={{ root: classes.card }}>
				<CardActionArea component={Link} to={url}>
					<Image src={propertyPhoto || '/assets/images/no-image.webp'} aspectRatio={16 / 9} />
				</CardActionArea>
			</Card>
			<Box className={classes.content}>
				<Typography variant="h6" className={classes.name}>
					{item.name}
				</Typography>
			</Box>
		</Box>
	)
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
		flexDirection: 'column'
	},
	card: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	content: {
		zIndex: 1,
		textAlign: 'left'
	},
	name: {
		fontSize: 13
	},
	button: {
		backgroundColor: '#f97487'
	},
	buttonLabel: {
		fontWeight: 500,
		// fontSize: '.9rem',
		textTransform: 'none'
	}
}))

export default PropertyCard
