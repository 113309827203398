import { useState, useCallback, FC, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { noop } from 'lodash'
import { Grid } from '@mui/material'
import { logger } from '@services/common/lib/logger'

export interface DropzoneProps {
	children?: any
	onDrop?: any
	dropzoneClassName?: string
	dropzoneContainerOptions?: any
	sx?: any
}

export const Dropzone: FC<DropzoneProps> = ({
	children,
	onDrop = noop,
	dropzoneClassName,
	dropzoneContainerOptions = {},
	sx = {}
}: DropzoneProps) => {
	const [state, setState]: any = useState({
		status: 'deactivated'
	})

	useEffect(() => {
		logger.log('DROPZONE', state)
	}, [state])

	const dragEnter = useCallback(
		(e: any) => {
			e.preventDefault()
			setState({ status: 'dragging' })
		},
		[setState]
	)

	const dragLeave = useCallback(
		(e: any) => {
			e.preventDefault()
			setState({ status: 'deactivated' })
		},
		[setState]
	)

	const dragOver = useCallback(
		(e: any) => {
			e.preventDefault()
			setState({ status: 'activated' })
		},
		[setState]
	)

	const drop = useCallback(
		(e: any) => {
			e.preventDefault()
			onDrop(e.dataTransfer.files)
			setState({ status: 'deactivated' })
		},
		[onDrop, setState]
	)

	const containerParams = useMemo(
		() => ({
			sx: sx.root,
			className: classNames(dropzoneClassName),
			...dropzoneContainerOptions,
			onDragOver: dragOver,
			onDragLeave: dragLeave,
			onDragEnter: dragEnter,
			onDrop: drop
		}),
		[sx, dropzoneClassName, dropzoneContainerOptions, dragOver, dragLeave, dragEnter, drop]
	)

	return (
		<Grid container {...containerParams}>
			{children}
		</Grid>
	)
}

export default Dropzone
