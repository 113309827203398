import { useMemo } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Box, Chip, Container, Typography } from '@mui/material'
import RecentArticles from '@services/blog/components/RecentArticles'
import { Image } from '@services/common/components/Image'
import { Loading } from '@services/common/components/Loading'
import { wrapParagraph } from '@services/common/helpers/wrap-paragraph'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import Getaways from '@services/getaway/components/Getaways'
import { GET_ARTICLE } from '../graphql/get-article'
import ConciergePromote from '@services/getaway/components/ConciergePromote'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'

const ArticleDetail = () => {
	const routeParams: any = useParams()

	const { data }: any = useQuery(GET_ARTICLE, {
		variables: {
			id: routeParams.id
		},
		fetchPolicy: 'cache-first'
	})

	const article = useMemo(() => {
		return data ? data.getArticle : data
	}, [data])

	const computedBody = useMemo(() => {
		return article ? (article.bodyJson ? article.bodyJson.html : article.body) : ''
	}, [article])

	const mainImage = useMemo(() => {
		try {
			return article.photos[0]
		} catch (e) {
			return ''
		}
	}, [article])

	const secondaryImages = useMemo(() => {
		try {
			return article.photos.slice(1)
		} catch (e) {
			return []
		}
	}, [article])

	if (!article) {
		return <Loading overlay={false} />
	}

	return (
		<Box display="flex" flex={1} flexDirection="column">
			<Box display="flex" flex={1} flexDirection="column">
				<Container sx={styles.container}>
					<Box sx={styles.content}>
						<Typography sx={styles.title} variant="h1">
							{article.title}
						</Typography>

						{article.author && <Typography sx={{ margin: '0em auto', color: '#666', fontSize: '.9rem', textAlign: 'center' }}>By: {article.author}</Typography>}

						<Typography sx={styles.date}>{moment(article.date).format('DD MMM YY')}</Typography>

						{article.categories && article.categories.length > 0 && (
							<Box sx={styles.categories}>
								{article.categories.map((category: any) => (
									<Chip key={`article-category-${category.id}`} label={category.name} />
								))}
							</Box>
						)}

						{mainImage && (
							<Box sx={styles.imageWrap}>
								<Image
									sx={{
										root: styles.image
									}}
									src={buildS3ImageUrl('blog', mainImage)}
									aspectRatio={16 / 9}
								/>
							</Box>
						)}

						<Box sx={styles.body}>{wrapParagraph(computedBody)}</Box>
						{secondaryImages.length > 0 && (
							<Box sx={styles.secondaryImages}>
								{secondaryImages.map((image: any) => (
									<Image
										key={`article-image-${image.id}`}
										sx={{
											root: styles.image
										}}
										src={buildS3ImageUrl('blog', image)}
									/>
								))}
							</Box>
						)}
					</Box>
				</Container>
			</Box>

			<Getaways />
			<ConciergePromote />
			<RecentArticles />
			<NewsletterSignup />
		</Box>
	)
}

const styles = {
	container: {
		// textAlign: 'center',
		padding: '120px 50px 80px',
		backgroundColor: '#fff',
		borderRadius: 3
		// display: 'flex',
		// justifyContent: 'center'
	},
	content: {
		maxWidth: '80%',
		margin: '0em auto',
		textAlign: 'left'
	},
	title: {
		fontSize: '2rem',
		maxWidth: 800,
		fontWeight: 500,
		margin: '0em auto 4px',
		textAlign: 'center'
	},
	body: {
		maxWidth: 622,
		margin: '0 auto'
	},
	categories: {
		marginBottom: 3,
		display: 'flex',
		justifyContent: 'center',
		gap: 1
	},
	imageWrap: {
		position: 'relative',
		marginBottom: 8
	},
	image: {
		borderRadius: 4,
		overflow: 'hidden'
	},
	secondaryImages: {
		'& img': {
			width: '100%',
			marginBottom: 3,
			borderRadius: 1
		}
	},
	date: {
		fontWeight: 'bold',
		fontSize: '.9rem',
		marginBottom: 4,
		textAlign: 'center'
	},
	tabRoot: {
		background:
			'url(https://photos.mygaygetaway.com/site/21fd42c6-03b2-425b-b152-f02e91f81327.png) center no-repeat',
		// background:
		// 'url(https://photos.mygaygetaway.com/properties/5d8284855bdcfacc3b1ee9c2/d7a2bf0f-851f-4f1c-a8ec-02856f4fda01.jpg) center no-repeat',
		backgroundSize: 'cover',
		padding: '88px 0',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundColor: '#0B2238',
			opacity: 0.8,
			zIndex: 0
		}
	}
}
export default ArticleDetail
