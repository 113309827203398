import { FC } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Image } from '@services/common/components/Image'
import { MediaBlockContent } from './MediaBlockContent'
import classNames from 'classnames'
import type {
	BaseCssClasses,
	ContentCssClasses,
	TextCssClasses,
	TitleCssClasses,
	ImageCssClasses,
	BodyCssClasses
} from '../../types/base'

export type MediaBlockClasses = BaseCssClasses &
	TitleCssClasses &
	TextCssClasses &
	ContentCssClasses &
	ImageCssClasses &
	BodyCssClasses

export interface MediaBlockProps {
	image?: string
	title?: string
	content?: string
	component?: any
	/**
	 * @deprecated use classes.root
	 */
	rootClassName?: string
	/**
	 * @deprecated use classes.image
	 */
	imageClassName?: string
	/**
	 * @deprecated use classes.content
	 */
	contentClassName?: string
	aspectRatio?: number
	data?: any
	classes?: MediaBlockClasses
	loading?: boolean
	children?: any
	sx?: any
}

export const MediaBlock: FC<MediaBlockProps> = ({
	image,
	title,
	content,
	component: Component,
	rootClassName,
	imageClassName,
	contentClassName,
	aspectRatio,
	data,
	classes: injectedClasses = {},
	loading = false,
	children,
	sx = {}
}: MediaBlockProps) => {
	const classes: any = useStyles()

	return (
		<Box sx={sx.root} className={classNames(classes.root, rootClassName, injectedClasses.root)}>
			{image && (
				<Box
					sx={sx.image}
					className={classNames(classes.image, imageClassName, injectedClasses.image)}
				>
					<Image aspectRatio={aspectRatio} styles={{ width: '100%' }} src={image} />
				</Box>
			)}
			{Component ? (
				<Component
					sx={sx.content}
					rootClassName={classNames(classes.content, contentClassName, injectedClasses.content)}
					{...data}
				/>
			) : (
				<MediaBlockContent
					title={title}
					body={content}
					classes={{
						root: classNames(classes.content, injectedClasses.content),
						title: injectedClasses.title,
						body: injectedClasses.body
					}}
					sx={{
						root: sx.content,
						title: sx.title,
						body: sx.body
					}}
				>
					{children}
				</MediaBlockContent>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row'
	},
	image: {
		display: 'flex'
	},
	content: {
		display: 'flex',
		flex: 1,
		padding: theme.spacing(2)
	}
}))
