import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Fields, FieldValue, FormErrors } from './types'
import { resetState } from './utils'

export interface FormState {
	loading?: boolean
	fields?: Fields
	raw?: any
	errors?: FormErrors
	hasDirty?: boolean
}

const initialState: FormState = {
	loading: false,
	fields: {},
	raw: {},
	errors: {},
	hasDirty: false
}

const slice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		setInitialFieldState(state: FormState, { payload }: PayloadAction<Record<string, FieldValue>>) {
			state.fields = payload
		},
		fieldChange(state: any, { payload }: PayloadAction<{ name: string; value: FieldValue }>) {
			state.fields[payload.name] = payload.value
			if (!state.hasDirty) {
				state.hasDirty = true
			}
		},
		updateFields(state: FormState, { payload }: PayloadAction<Record<string, FieldValue>>) {
			state.fields = { ...state.fields, ...payload }
		},
		reset(state: FormState) {
			resetState(state, initialState)
		},
		setError(state: FormState, { payload }: PayloadAction<{ field: string; error: string }>) {
			state.errors && (state.errors[payload.field] = payload.error)
		},
		clearError(state: FormState, { payload }: PayloadAction<string | null | undefined>) {
			if (payload && state.errors) {
				delete state.errors[payload]
			} else if (!payload) {
				state.errors = {}
			}
		}
	}
})

export default slice.reducer

export const { setInitialFieldState, fieldChange, updateFields, setError, clearError, reset } =
	slice.actions
