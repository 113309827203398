import { FC, useMemo } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { green } from '@mui/material/colors'
import { noop } from 'lodash'
import { Theme } from '@mui/system'
// import { Button } from '../ui/Button'

export type ButtonAction = {
	label?: string
	variant?: 'primary' | 'secondary'
}

export interface FormActionsProps {
	/**
	 * @deprecated use position
	 */
	fixed?: boolean
	onCancel?: any
	/**
	 * @deprecated
	 */
	position?: string
	/**
	 * @deprecated
	 */
	variant?: 'default' | 'floating'
	/**
	 * @deprecated
	 */
	background?: boolean
	disabled?: boolean
	primaryActionLabel?: string
	secondaryActionLabel?: string
	loading?: boolean
	classes?: any
	styles?: any
}

const FormActions: FC<FormActionsProps> = ({
	onCancel = noop,
	fixed = true,
	position = 'fixed',
	background = true,
	disabled = false,
	primaryActionLabel = 'Save',
	secondaryActionLabel = 'Cancel',
	loading = false,
	classes: propClasses = {},
	styles: propStyles = {}
}: FormActionsProps) => {
	const classes: any = useStyles()
	const saveBtnProps: any = useMemo(
		() => ({
			disabled,
			type: 'submit',
			variant: 'contained',
			color: 'secondary',
			sx: {
				ml: 1
			}
		}),
		[disabled]
	)

	return (
		<Box className={classes.formActions}>
			<Box className={classes.buttonContainer}>
				<Button
					variant="outlined"
					onClick={onCancel}
					className={classes.cancelButton}
					color="secondary"
				>
					{secondaryActionLabel}
				</Button>
				<span style={{ position: 'relative', display: 'inline-flex' }}>
					<Button {...saveBtnProps}>{primaryActionLabel}</Button>

					{loading && <CircularProgress size={24} className={classes.progress} />}
				</span>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	progress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: theme.spacing(-1.5),
		marginLeft: theme.spacing(-1.5)
	},
	buttonContainer: {
		textAlign: 'right'
		// backgroundColor: '#282C34',
		// padding: '16px 24px'
	},
	cancelButton: {
		// marginRight: 8
	}
}))

/**
 * @deprecated use default export for async loading
 */
export { FormActions }
export default FormActions
