export const truncateWords = (str: string, limit = 50, append = '...') => {
	if (!str) return str
	const words = str.split(' ')
	let output = words.slice(0, limit).join(' ')
	if (words.length > limit) {
		output += append
	}

	return output
}
