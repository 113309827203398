import { forwardRef } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { Button as BaseButton } from '@mui/material'

import { buttonStyle } from './styles'
import { UIColor } from '@services/app/types'

const makeComponentStyles = makeStyles(() => ({
	...(buttonStyle as any)
}))

export interface ButtonProps extends Record<string, unknown> {
	color?: UIColor
	size?: 'sm' | 'lg'
	simple?: boolean
	round?: boolean
	fullWidth?: boolean
	disabled?: boolean
	block?: boolean
	link?: boolean
	justIcon?: boolean
	children?: any
	className?: string
	component?: any
	to?: string
	onClick?: any
}

export const Button = forwardRef((props: ButtonProps, ref: any) => {
	const {
		color = UIColor.Default,
		round,
		children,
		fullWidth,
		disabled,
		simple,
		size = 'sm',
		block,
		link,
		justIcon,
		className = '',
		...rest
	} = props

	const classes: any = makeComponentStyles()

	const btnClasses = classNames({
		[classes.button]: true,
		[classes[size]]: size,
		[classes[color]]: color,
		[classes.round]: round,
		[classes.fullWidth]: fullWidth,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[className]: className
	})
	return (
		<BaseButton {...rest} ref={ref} className={btnClasses}>
			{children}
		</BaseButton>
	)
})
