import React, { FC } from 'react'
import { Box } from '@mui/material'

export interface TabPanelProps {
	children: any
	index: number
	value: any
	rootProps?: any
}

const TabPanel: FC<TabPanelProps> = ({
	children,
	index,
	value,
	rootProps = {},
	...other
}: TabPanelProps) => {
	console.log({ TabPanel: { index, value } })
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box {...rootProps}>{children}</Box>}
		</div>
	)
}
export default TabPanel
