import { gql } from '@apollo/client'
import { USER_INFO_FRAGMENT } from '@services/user/graphql/fragments'
import { IMAGE_INFO_FRAGMENT } from './fragments'

export const LIST_MEDIA = gql`
	query listMedia($filter: TableMediaFilterInput, $limit: Int, $offset: Int) {
		listMedia(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				... on Image {
					...ImageInfo
					type
					title
					access
					account {
						id
						name
						featuredImage {
							id
						}
					}
					originalFilename
					size
					status
					createdAt
					updatedAt
				}
				... on Video {
					id
					owner
					filename
					access
					account {
						id
						name
					}
					bitrate
					categories
					description
					intensity
					level
					status
					title
					uploadedBy {
						...UserInfo
					}
					images {
						...ImageInfo
					}
					featuredImage {
						...ImageInfo
					}
					uri
					type
					createdAt
					updatedAt
				}
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
	${USER_INFO_FRAGMENT}
`
