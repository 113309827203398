import { DocumentNode, useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import { useAuth } from '@services/auth/hooks/use-auth'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'
import { logger } from '@services/common/lib/logger'
import { buildRoutePath } from '@services/common/utils/app'
import { LIST_LISTING_REQUESTS } from '@services/listing/graphql/list-listing-requests'
// import EventCard from '@services/event/components/EventCard'
// import { LIST_EVENTS } from '@services/event/graphql'
// import GetawayCard from '@services/getaway/components/GetawayCard'
// import { LIST_GETAWAYS } from '@services/getaway/graphql'
import PropertyCard from '@services/property/components/PropertyCard'
import { useMemo } from 'react'
import Hero from '../components/Hero'

const Row = ({
	header,
	query,
	action,
	component: Component
}: {
	header: string
	query: DocumentNode
	action: string
	component: any
}) => {

	const { contextUser } = useAuth()
	const { data } = useQuery(query, {
		variables: {
			limit: 3,
			filter: {
				account: contextUser.account?.id
			}
		}
	})

	const items = useMemo(() => {
		return data ? data[action].items : []
	}, [action, data])

	return (
		<Box sx={{ marginBottom: 3, width: '100%' }}>
			<Grid container spacing={3}>
				<Grid item md={12}>
					<Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600 }}>
						{header}
					</Typography>
				</Grid>

				{items.length > 0 ?
					items.map((item: any) => (
						<Grid item md={4} key={item.id}>
							<Component item={item.listing} url={buildRoutePath('my-account', 'listings', 'edit', 'property', item.listingId)} />
						</Grid>
					)) : <Grid item md={12}><Typography>You currently have no listings.</Typography></Grid>}
			</Grid>
		</Box>
	)
}

const MyAccount = ({ openAdminNav, ...props }: any) => {
	logger.log('MyAccount', props)


	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.my-account.dashboard'
		}
	})

	const heroContent = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content,
				image: 'https://photos.mygaygetaway.com/properties/5d82847c5bdcfacc3b1edd31/997eed4a-36b1-423d-861b-e3d7d52b7e91.jpg'
			})
		}
		return res
	}, [data])

	return (
		<Box sx={styles.root}>
			<Hero {...heroContent} />

			<Grid container spacing={3}>
				<Grid item md={12}>
					<Row
						header="Properties"
						query={LIST_LISTING_REQUESTS}
						action="listListingRequests"
						component={PropertyCard}
					/>
					{/* <Divider sx={{ width: '100%', margin: '0 16px 24px' }} />
						<Row header="Events" query={LIST_EVENTS} action="listEvents" component={EventCard} />
						<Divider sx={{ width: '100%', margin: '0 16px 24px' }} />
						<Row
							header="Getaways"
							query={LIST_GETAWAYS}
							action="listGetaways"
							component={GetawayCard}
						/> */}
				</Grid>
			</Grid>
		</Box>
	)
}

const styles: any = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '50px'
	}
}

export default MyAccount
