import { useQuery } from '@apollo/client'
import { Box, Button, ImageList, ImageListItem, Stack } from '@mui/material'
import { PageSection } from '@services/common/components/PageSection'
import { LIST_GETAWAYS_PROPERTIES } from '@services/getaway/graphql'
import { DEFAULT_COLORS } from 'app/services/app/constants/app'
import { useRedirect } from 'app/services/common/hooks'
import { buildRoutePath } from 'app/services/common/utils/app'
import { useMemo } from 'react'
import GetawaySearchCard from './GetawaySearchCard'

const startDate = Date.now()

export interface HotGetawaysProps {
	itemsVisible?: number
	spacing?: number
}

const HotGetaways = ({ itemsVisible = 4, spacing = 16 }: HotGetawaysProps) => {
	const { data }: any = useQuery(LIST_GETAWAYS_PROPERTIES, {
		variables: {
			filter: {
				startDate
			},
			limit: 4,
			sortAttribute: 'startDate',
			sortDirection: 'asc'
		}
	})

	const items = useMemo(() => {
		return data ? data.listGetaways.items : []
	}, [data])

	const { redirect } = useRedirect()

	const goTo = () => {
		redirect(buildRoutePath('getaways'))
	}

	return (
		<PageSection
			header="Hot Getaways"
			sx={{
				root: styles.root
			}}
			maxWidth={false}
		>
			<Stack gap={3}>
				<Box sx={styles.container}>
					{items.length > 0 ? (
						<ImageList
							rowHeight="auto"
							gap={spacing}
							cols={itemsVisible}
							style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }}
						>
							{items.map((item: any, idx: number) => (
								<ImageListItem key={`tile-${idx}`}>
									<GetawaySearchCard item={item} />
								</ImageListItem>
							))}
						</ImageList>
					) : (
						'No Items'
					)}
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<Button
						type="button"
						onClick={goTo}
						sx={{
							backgroundColor: DEFAULT_COLORS.primary,
							color: '#fff',
							padding: '1.125rem 2.25rem',
							fontSize: '1.2rem',
							textTransform: 'none',
							margin: '0em auto',
							// borderRadius: '30px',
							border: `1px solid ${DEFAULT_COLORS.primary}`,
							'&:hover': {
								color: DEFAULT_COLORS.primary,
								// borderColor: DEFAULT_COLORS.primary
								borderColor: DEFAULT_COLORS.primary,
								backgroundColor: 'transparent'
							}
						}}
						size="medium"
					>
						Discover More Getaways
					</Button>
				</Box>
			</Stack>
		</PageSection>
	)
}

const styles = {
	root: {
		backgroundColor: 'rgba(249,116,135,.05)'
	},
	container: {},
	title: {
		color: '#000'
	}
}
export default HotGetaways
