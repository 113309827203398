import { useMemo } from 'react'
import { Box, Container, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PageHeader from '@services/common/components/PageHeader'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'
import { useQuery } from '@apollo/client'

const TermsPage = () => {
	const classes = useStyles()

	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.terms-conditions'
		}
	})

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content
			})
		}
		return res
	}, [data])

	return (
		<Box className={classes.root}>
			<PageHeader
				classes={{ title: classes.title, titleBlock: classes.titleBlock, text: classes.text }}
				{...header}
			/>
			{/* <Divider style={{ margin: '24px 0', width: '100%' }} /> */}

			<Container maxWidth="md">
				<h5>1. Acceptance of Terms</h5>

				<p>
					<strong>MyGayGetaway.com</strong> provides its service ("Service") to you, subject to the
					following Terms of Use ("Terms"), which may be updated by us from time to time without
					notice to you. In addition, when using MyGayGetaway services, you and MyGayGetaway shall
					be subject to any posted guidelines or rules applicable to such services which may be
					posted from time to time. All such guidelines or rules (including but not limited to our
					Review Guidelines) are hereby incorporated by reference into the Terms. By using the
					Service in any way, you are agreeing to comply with these Terms. Should you object to any
					term or condition of these Terms, any guidelines, or any subsequent modifications thereto
					or become dissatisfied with MyGayGetaway in any way, your only recourse is to immediately
					discontinue use of <strong>MyGayGetaway.com</strong>.
				</p>

				<h5>2. Permitted Use</h5>

				<p>
					You agree that you are only authorized to visit, view, and retain a copy of pages of
					MyGayGetaway for your own personal use, and that you shall not duplicate, download,
					publish, modify, or otherwise distribute the material on MyGayGetawayfor any commercial
					use, or for any purpose other than as described in these Terms.
				</p>

				<h5>3. Content</h5>

				<p>
					You understand that all postings, messages, text, files, images, photos, video, sounds, or
					other materials ("Content") posted on, transmitted through, or linked from the Service,
					are the sole responsibility of the person from whom such Content originated. More
					specifically, you are entirely responsible for all Content that you post, email or
					otherwise make available via the Service. You understand that MyGayGetaway does not
					control, and is not responsible for Content made available through the Service, and that
					by using the Service, you may be exposed to Content that is offensive, indecent,
					inaccurate, misleading, or otherwise objectionable. Furthermore, the MyGayGetaway site and
					Content available through the Service may contain links to other websites, which are
					completely independent of MyGayGetaway. MyGayGetaway makes no representation or warranty
					as to the accuracy, completeness or authenticity of the information contained in any such
					site. Your linking to any other webites is at your own risk. You agree that you must
					evaluate, and bear all risks associated with, the use of any Content, that you may not
					rely on said Content, and that under no circumstances will MyGayGetaway be liable in any
					way for any Content or for any loss or damage of any kind incurred as a result of the use
					of any Content posted, emailed or otherwise made available via the Service. You
					acknowledge that MyGayGetaway may or may not pre-screen or approve Content,{' '}
					<u>
						but that MyGayGetaway shall have the right (but not the obligation) in its sole
						discretion to refuse, delete or move any Content that is available via the Service, for
						violating the letter or spirit of the Terms or for any other reason.
					</u>
				</p>

				<h5>4. Privacy and Information Disclosure</h5>

				<p>
					MyGayGetaway has established a Privacy Policy to explain to users should their information
					be collected or used. Your use of the MyGayGetaway website or the Service signifies
					acknowledgement of and agreement to our Privacy Policy. You understand that through your
					use of the Service you consent to the collection and use (as set forth in the Privacy
					Policy) of this information, including the transfer of this information to the United
					States and/or other countries for storage, processing, and use by MyGayGetaway and its
					affiliates.
				</p>

				<h5>5. Reviews and Conduct</h5>

				<p>You agree not to post, submit a review, email, or otherwise make available Content:</p>

				<ul>
					<li>
						that is unlawful, harmful, threatening, abusive, harassing, defamatory, pornographic,
						libelous, invasive of another's privacy, or harms minors in any way;
					</li>
					<li>
						that harasses, degrades, intimidates or is hateful toward an individual or group of
						individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age,
						or disability;
					</li>
					<li>
						that impersonates any person or entity, including, but not limited to, a MyGayGetaway
						employee, or falsely states or otherwise misrepresents your affiliation with a person or
						entity;
					</li>
					<li>
						that includes personal or identifying information about another person without that
						person's explicit consent.
					</li>
					<li>
						that is false, deceptive, misleading, deceitful, misinformative, or constitutes "bait
						and switch";
					</li>
					<li>
						that infringes any patent, trademark, trade secret, copyright or other proprietary
						rights of any party, or Content that you do not have a right to make available under any
						law or under contractual or fiduciary relationships.
					</li>
					<li>
						that constitutes or contains "affiliate marketing," "link referral code," "junk mail,"
						"spam," "chain letters," "pyramid schemes," or unsolicited commercial advertisement.
					</li>
					<li>
						that constitutes or contains any form of advertising or solicitation if (1) posted in
						areas of the MyGayGetaway sites which are not designated for such purposes; or (2)
						emailed to MyGayGetaway users who have requested not to be contacted about other
						services, products or commercial interests.
					</li>
					<li>
						that includes links to commercial services or web sites, except as allowed in
						"services".
					</li>
					<li>
						that contains software viruses or any other computer code, files or programs designed to
						interrupt, destroy or limit the functionality of any computer software or hardware or
						telecommunications equipment;
					</li>
					<li>
						that disrupts the normal flow of dialogue with an excessive number of messages (flooding
						attack) to the Service, or that otherwise negatively affects other users' ability to use
						the Service; or
					</li>
					<li>
						that employs misleading email addresses, or forged headers or otherwise manipulated
						identifiers in order to disguise the origin of Content transmitted through the Service.
					</li>
				</ul>

				<p>Additionally, you agree not to:</p>

				<ul>
					<li>contact anyone who has asked not to be contacted;</li>
					<li>"stalk" or otherwise harrass anyone;</li>
					<li>collect personal data about other users for commercial or unlawful purposes;</li>
					<li>
						use automated means, including spiders, robots, crawlers, data mining tools, or the like
						to download data from the Service - exception is made for internet search engines (e.g.
						Google) and non-commercial public archives (e.g. archive.org) that comply with our
						robots.txt file;
					</li>
					<li>
						post irrelevant Content, repeatedly post the same or similar Content or otherwise impose
						an unreasonable or disproportionately large load on our infrastructure;
					</li>
					<li>
						attempt to gain unauthorized access to MyGayGetaway's computer systems or engage in any
						activity that disrupts, diminishes the quality of, interferes with the performance of,
						or impairs the functionality of, the Service or the MyGayGetaway website.
					</li>
				</ul>

				<p>
					MYGAYGETAWAY reserves the right to remove any comments from the website not deemed
					suitable or truthful. Any user or poster can and will be asked to leave, or even blocked,
					from the site for any and all offensive language or behavior. No pornographic or offensive
					language is allowed. NO SPAMMING on the website will be allowed. Any spam comment will be
					removed.
				</p>

				<h5>6. No Spam Policy</h5>

				<p>
					You understand and agree that sending unsolicited email advertisements to
					MyGayGetaway/Stays4Gays email addresses or through MyGayGetaway computer systems, which is
					expressly prohibited by these Terms, will use or cause to be used servers located in
					California. Any unauthorized use of MyGayGetaway computer systems is a violation of these
					Terms and certain federal and state laws, including without limitation the Computer Fraud
					and Abuse Act (18 U.S.C. &#167; 1030 et seq.), Section 502 of the California Penal Code
					and Section 17538.45 of the California Business and Professions Code. Such violations may
					subject the sender and his or her agents to civil and criminal penalties.
				</p>

				<h5>7. Limitations on service</h5>

				<p>
					You acknowledge that MyGayGetaway may establish limits concerning use of the Service,
					including the maximum number of days that Content will be retained by the Service, the
					maximum number and size of reviews and postings, email messages, or other Content that may
					be transmitted or stored by the Service, and the frequency with which you may access the
					Service. You agree that MyGayGetaway has no responsibility or liability for the deletion
					or failure to store any Content maintained or transmitted by the Service. You acknowledge
					that MyGayGetaway reserves the right at any time to modify or discontinue the Service (or
					any part thereof) with or without notice, and that MyGayGetaway shall not be liable to you
					or to any third party for any modification, suspension or discontinuance of the Service.
				</p>

				<h5>8. Termination of service</h5>

				<p>
					You agree that MyGayGetaway, in its sole discretion, has the right (but not the
					obligation) to delete any comment, block your email or IP address, or otherwise terminate
					your access to or use of the Service (or any part thereof), immediately and without
					notice, and remove and discard any Content within the Service, for any reason, including,
					without limitation, if MyGayGetaway believes that you have acted inconsistently with the
					letter or spirit of the Terms. Further, you agree that MyGayGetaway shall not be liable to
					you or any third-party for any termination of your access to the Service. Further, you
					agree not to attempt to use the Service after said termination. Sections 2-4, 6 and 9-12
					shall survive termination of these Terms.
				</p>

				<h5>9. Proprietary rights</h5>

				<p>
					You acknowledge and agree that the Service and any necessary software used in connection
					with the Service ("Software") contain proprietary and confidential information that is
					protected by applicable intellectual property and other laws. You further acknowledge and
					agree that Content contained in sponsor advertisements or information presented to you
					through the Service or advertisers is protected by copyrights, trademarks, service marks,
					patents or other proprietary rights and laws. Except as expressly authorized by
					MyGayGetaway or advertisers, you agree not to modify, rent, lease, loan, sell, distribute,
					exploit for any commercial purposes or create derivative works based on the Service or the
					Software, in whole or in part. You agree not to access the Service by any means other than
					through the interface that is provided by MyGayGetaway for use in accessing the Service.
					You may not decompile or disassemble, reverse engineer or otherwise attempt to discover
					any source code contained in the Service.
				</p>

				<p>
					Content that is submitted to MyGayGetaway shall be owned exclusively and in perpetuity by
					MyGayGetaway. Such exclusive ownership means that MyGayGetaway, its parent, subsidiary,
					and/or affiliated companies have the unrestricted, perpetual, and exclusive right to use,
					reproduce, modify, translate, transmit, create derivative works of, incorporate into other
					works, and distribute any and all materials and communications regarding and including
					submitted Content.
				</p>

				<h5>10. Disclaimer of Warranties</h5>

				<p>
					YOU EXPRESSLY UNDERSTAND AND AGREE THAT USE OF THE MYGAYGETAWAY SITE AND THE SERVICE IS
					ENTIRELY AT YOUR OWN RISK. THE MYGAYGETAWAY SITE AND THE SERVICE ARE PROVIDED ON AN "AS
					IS" OR "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ALL EXPRESS AND IMPLIED
					WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR
					A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS ARE EXPRESSLY DISCLAIMED
					TO THE FULLEST EXTENT PERMITTED BY LAW. TO THE FULLEST EXTENT PERMITTED BY LAW, GAYCITIES
					DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, ACCURACY, AND
					PERFORMANCE OF THE GAYCITIES SITE AND THE SERVICE. TO THE FULLEST EXTENT PERMITTED BY LAW,
					GAYCITIES DISCLAIMS ANY WARRANTIES FOR OTHER SERVICES OR GOODS RECEIVED THROUGH OR
					ADVERTISED ON THE MYGAYGETAWAY SITE OR THE SITES OR SERVICE, OR ACCESSED THROUGH ANY LINKS
					ON THE GAYCITIES SITE. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
					THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
					RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
					DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
					BY YOU FROM GAYCITIES OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT
					EXPRESSLY STATED IN THE TERMS.
				</p>

				<p>
					Some jurisdictions do not allow the disclaimer of implied warranties. In such
					jurisdictions, some of the foregoing disclaimers may not apply to you insofar as they
					relate to implied warranties.
				</p>

				<h5>11. Limitations of Liability</h5>

				<p>
					YOU EXPRESSLY UNDERSTAND AND AGREE THAT MYGAYGETAWAY SHALL NOT BE LIABLE TO YOU FOR
					DIRECT, INDIRECT,INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES INCLUDING BUT NOT
					LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
					(EVEN IF MYGAYGETAWAY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
					FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF
					SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
					PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM
					THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
					(iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER
					RELATING TO THE SERVICE. In some jurisdictions, limitations of liability are not
					permitted. In such jurisdictions, some of the foregoing limitation may not apply to you.
				</p>

				<h5>12. Indemnity</h5>

				<p>
					You agree to indemnify and hold <strong>MyGayGetaway.com</strong> and Stays4Gays Media,
					its officers, subsidiaries, affiliates, successors, assigns, directors, officers, agents,
					service providers, suppliers and employees, harmless from any claim or demand, including
					reasonable attorney fees and court costs, made by any third party due to or arising out of
					Content you submit, post or make available through the Service, your use of the Service,
					your violation of the Terms, your breach of any of the representations and warranties
					herein, or your violation of any rights of another.
				</p>

				<p>
					<strong>Reviews/Postings are not endorsed by MyGayGetaways.</strong> Reviews do not
					reflect the views of MyGayGetaway, its subsidiaries, affiliates, successors, assigns,
					directors, officers, agents, service providers, suppliers and employees. MyGayGetaway does
					not assume responsibility or liability for any Review or for any claims, damages, or
					losses resulting from any use of the Site or the materials contained therein.
				</p>

				<h5>
					13. Notice and Procedure for making claims of copyright or intellectual property
					infringement
				</h5>

				<p>
					MyGayGetaway respects the intellectual property of others, and we ask our users to do the
					same. If you believe that your work has been copied in a way that constitutes copyright
					infringement, or your intellectual property rights have been otherwise violated, please
					contact us and provide the following Notice:
				</p>

				<ul>
					<li>
						Identify the copyrighted work or other intellectual property that you claim has been
						infringed;
					</li>
					<li>
						Identify the material on the MyGayGetaway site that you claim is infringing, with enough
						detail so that we may locate it on the website;
					</li>
					<li>
						A statement by you that you have a good faith belief that the disputed use is not
						authorized by the copyright owner, its agent, or the law;
					</li>
					<li>
						A statement by you declaring under penalty of perjury that (a) the above information in
						your Notice is accurate, and (b) that you are the owner of the copyright interest
						involved or that you are authorized to act on behalf of that owner;
					</li>
					<li>Your address, telephone number, and email address; and</li>
					<li>Your physical or electronic signature.</li>
				</ul>

				<h5>14. General information</h5>

				<p>
					The Terms constitute the entire agreement between you and MyGayGetaway and govern your use
					of the Service, superceding any prior agreements between you and MyGayGetaway. The Terms
					and the relationship between you and MyGayGetaway shall be governed by the laws of the
					State of California without regard to its conflict of law provisions. The failure of
					MyGayGetaway to exercise or enforce any right or provision of the Terms shall not
					constitute a waiver of such right or provision. If any provision of the Terms is found by
					a court of competent jurisdiction to be invalid, the parties nevertheless agree that the
					court should endeavor to give effect to the parties' intentions as reflected in the
					provision, and the other provisions of the Terms remain in full force and effect. You
					agree that regardless of any statute or law to the contrary, any claim or cause of action
					arising out of or related to use of the Service or the Terms must be filed within one (1)
					year after such claim or cause of action arose or be forever barred.
				</p>

				<h5>15. Overall</h5>

				<p>
					<strong>MyGayGetaway.com</strong> aims to provide the user with the most current and
					up-to-date information possible. However, hotel accommodation information, availability,
					rates, amenities, deposits, etc, whether displayed or not, are subject to change without
					prior notice and are NOT under the control of <strong>MyGayGetaway.com</strong> but are
					set by the property's establishment. Therefore, you are urged to familiarize yourself with
					such information by A. visiting the website of the property, B. calling and speaking with
					the property and C. emailing the property. Thank you.
				</p>

				<p>
					THE INFORMATION ON THE <strong>MyGayGetaway.com</strong> WEB SITE(S) IS PROVIDED WITHOUT
					ANY REPRESENTATION OR WARRANTY AND IN NO EVENT SHALL <strong>MyGayGetaway.com</strong>,
					THE WEB DEVELOPERS, OR ANY OF THEIR SUBSIDIARIES, THEIR ASSOCIATES, CONTRACTORS, AND/OR
					SHAREHOLDERS BE HELD LIABLE IN CONNECTION WITH THE USE OF THE INFORMATION MADE AVAILABLE,
					UNLESS SPECIFICALLY STATED OTHERWISE.
				</p>

				<p>
					By accessing & using the <strong>MyGayGetaway.com</strong> Web Site, you agree to the
					following terms & conditions:
				</p>

				<p>
					<strong>MyGayGetaway.com</strong> reserves the right to make changes and/or updates with
					respect to the information contained on the Web Sites at any time without notice and in a
					timely manner it deems appropriate.
				</p>

				<p>
					<strong>MyGayGetaway.com</strong> does not warrant that the Web Sites or the servers which
					make these Web Sites available, are free from viruses or any other harmful elements.
				</p>

				<p>
					<strong>MyGayGetaway.com</strong> accepts no responsibility for the information presented
					on linked sites. We make every effort to ensure that information is current and viable.
				</p>

				<p>
					The Trademarks, information, photos and logos displayed on this website are property of
					the respective trademark owners and should be respected. The use of the{' '}
					<strong>MyGayGetaway.com</strong> logo is strictly prohibited without express written
					consent. Copyright of the website(s) and their graphic contents rests with both
					Stays4Gays, Inc. and <strong>MyGayGetaway.com</strong>, Inc. with an unlimited use of
					license granted to stays4gays.com licensors.
				</p>

				<p>
					WE USE REASONABLE CARE AND SKILL TO ENSURE THE ACCURACY OF THE INFORMATION WE PROVIDE ON
					THE SITE. SOME INFORMATION ON THIS SITE IS PROVIDED BY THIRD PARTIES AND WE PASS THIS
					INFORMATION ON “AS IS”. WE CANNOT GUARANTEE THIS SITE WILL BE 100% ACCURATE OR CONTINUOUS
					AT ALL TIMES. THE INFORMATION ON THIS SITE IS GENERAL AND MAY NOT BE SUITABLE FOR YOUR
					PARTICULAR NEEDS. YOU SHOULD VERIFY CRITICAL INFORMATION (LIKE ADVICE ABOUT VISAS AND
					HEALTH AND SAFETY) INDEPENDENTLY BEFORE YOU TRAVEL.
				</p>
			</Container>
			<NewsletterSignup />
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: 60
	},
	scrollBehind: {
		position: 'absolute',
		zIndex: -1,
		top: 0,
		right: 0,
		left: 0,
		'&:before': {
			// content: '""',
			// position: 'absolute',
			// top: 0,
			// right: 0,
			// bottom: 0,
			// left: 0,
			// background: 'rgba(0,0,0,.4)',
			// zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: 0,
			left: 0,
			height: '20%',
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
		}
	},
	container: {},
	content: {},
	header: {},
	titleBlock: {
		paddingTop: theme.spacing(10)
	},
	title: {
		marginBottom: theme.spacing(4)
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	}
}))

export default TermsPage
