import { buildRoutePath } from '@services/common/utils/app'

const navigation: any = [
	{
		label: 'Partners',
		linkTo: buildRoutePath('partners'),
		enabled: true
	},
	{
		label: 'Terms & Conditions',
		linkTo: buildRoutePath('terms'),
		enabled: true
	},
	{
		label: 'Contact Us',
		linkTo: buildRoutePath('contact'),
		enabled: true
	},
	{
		label: 'Advertise With Us',
		// href: 'mailto:sales@mygaygetaway.com?subject=Sales Inquiry',
		linkTo: buildRoutePath('contact', 'advertise-with-us'),
		enabled: true
	}
]

export default navigation
