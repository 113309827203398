import { gql } from '@apollo/client'
import { DRAG_RACE_FRAG } from './drag-race-frag'

export const LIST_DRAG_RACES = gql`
	query ListDragRaces(
		$filter: TableDragRaceFilterInput
		$limit: Int
		$offset: Int
		$sortAttribute: String
		$sortDirection: String
	) {
		listDragRaces(
			filter: $filter
			limit: $limit
			offset: $offset
			sortAttribute: $sortAttribute
			sortDirection: $sortDirection
		) {
			total
			size
			items {
				...DragRaceInfo
			}
		}
	}
	${DRAG_RACE_FRAG}
`
