import { useCallback, useContext, useState } from 'react'
import { Stack, Box, Button, Typography } from '@mui/material'
import { useRedirect } from '@services/common/hooks'
// import { useAuth } from '@services/auth/hooks/use-auth'
import { buildRoutePath } from '@services/common/utils/app'
import LocationWidget from '../../location/components/LocationWidget'
import { parseAddress } from '@services/location/utils/parse-address'
import { ContactContext } from '@services/app/views/ContactContext'

// const pathParts = ['my-account', 'listings', 'new', 'property']
const pathParts = ['contact', 'list-with-us']

const ListForm = () => {
	const [address, setAddress] = useState(null)
	const [type] = useState('property')
	const [error, setError] = useState('')
	const { redirect } = useRedirect()
	const { setMessage } = useContext(ContactContext)
	// const { isLoggedIn, showAuthDialog } = useAuth()

	// const postAuth = useCallback(() => {
	// 	redirect(buildRoutePath.apply(pathParts))
	// }, [redirect])

	const startListing = useCallback(
		(e: any) => {
			e.preventDefault()
			if (!address) {
				setError('Address is required')
				return
			} else if (!type) {
				setError('Please select a type')
				return
			} else {
				error && setError('')
			}

			console.log('startListing', address, type)
			address && setMessage((address as any).address)

			// if (isLoggedIn) {
			redirect(buildRoutePath(...pathParts), {
				state: {
					data: address
				}
			})
			// } else {
			// 	showAuthDialog({
			// 		form: 'login',
			// 		postAuth
			// 	})
			// }
		},
		[address, error, redirect, setMessage, type]
	)

	const handleLocationChange = useCallback((data: google.maps.places.PlaceResult) => {
		const addy = parseAddress(data)
		setAddress(addy)
		console.log('handleLocationChange', addy)
	}, [])

	return (
		<Box sx={styles.root}>
			<Stack gap={3}>
				<>
					<Typography>To get started, tell us a little about your.</Typography>
					{error && <Typography sx={styles.error}>{error}</Typography>}
				</>

				<LocationWidget label="Location" onChange={handleLocationChange} />

				{/* <SelectField
					options={availableTypes}
					name="type"
					label=""
					placeholder="Select type..."
					fullWidth
					variant="standard"
					onChange={({ target }: any) => setType(target.value)}
					value={type}
					rootClassName={classNames(
						classes.field,
						classes.noLine,
						classes.noBorder,
						classes.select
					)}
				/> */}
				<Button
					sx={styles.button}
					variant="contained"
					onClick={startListing}
					type="button"
					disableElevation
				>
					Start your listing
				</Button>
			</Stack>
		</Box>
	)
}

const styles = {
	root: {
		backgroundColor: '#fff',
		borderRadius: 3,
		padding: 3
	},
	field: {
		'& .MuiInput-input': {
			padding: '16px 8px'
		},
		'& .MuiInputLabel-formControl': {
			left: 8
		}
	},
	select: {
		'& .MuiInput-input': {
			opacity: 0.4
		},
		'&:has(.MuiSelect-nativeInput[value*="property"])': {
			'& .MuiInputBase-input': {
				opacity: 1
			}
		},
		'&:has(.MuiSelect-nativeInput[value*="event"])': {
			'& .MuiInputBase-input': {
				opacity: 1
			}
		},
		'&:has(.MuiSelect-nativeInput[value*="getaway"])': {
			'& .MuiInputBase-input': {
				opacity: 1
			}
		}
	},
	noLine: {
		'& .MuiInput-underline': {
			'&:before': {
				display: 'none'
			}
		}
	},
	noBorder: {
		'& .MuiInput-underline': {
			'&:after': {
				display: 'none'
			}
		}
	},
	button: {
		width: '100%',
		backgroundColor: '#F97487',
		color: '#fff',
		fontWeight: 600,
		fontSize: '1.1rem',
		border: '1px solid #F97487',
		padding: '12px 16px',
		borderRadius: 2,
		'&:hover': {
			color: '#F97487',
			// borderColor: '#F97487',
			backgroundColor: '#fff'
		}
	},
	error: {
		color: '#f00'
	}
}

export default ListForm
