import { gql } from '@apollo/client'
import { LISTING_REQUEST_FRAG } from './listing-fragment'

export const LIST_LISTING_REQUESTS = gql`
	query ListListingRequests($limit: Int, $offset: Int, $filter: TableListingRequestFilterInput) {
		listListingRequests(limit: $limit, offset: $offset, filter: $filter) {
			total
			size
			items {
				...ListingRequestFrag
			}
		}
	}
	${LISTING_REQUEST_FRAG}
`
