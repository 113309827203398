import { gql } from '@apollo/client'

export const EVENT_INFO_FULL_FRAG = gql`
	fragment EventInfoFull on Event {
		id
		name
		bucket
		information
		link
		featured
		startDate
		endDate
		# country {
		# 	id
		# 	name
		# 	__typename
		# }
		# state {
		# 	id
		# 	name
		# 	__typename
		# }
		# city
		location {
			streetNumber
			route
			googleMap
			address
			country
			state
			city
			zip
			latitude
			longitude
		}
		properties {
			id
			name
			__typename
			photos {
				id
				resource
				image
				originalImage
				__typename
			}
			location {
				id
				streetNumber
				route
				zip
				state
				city
				country
			}
		}
		photos {
			id
			resource
			image
			originalImage
			__typename
		}
		createdAt
		updatedAt
		__typename
	}
`
