// import { logger } from "@services/common/lib/logger"
import buildS3Url from "./build-s3-url"

const buildS3ImageUrl = (resource: string, image: any, size?: number | string) => {
  if (!image || !image.id) {
    return ''
  }
  // logger.log('buildS3ImageUrl', { image })
  if (!size) {
    return buildS3Url('images', `${resource}/${image.id}`, image.image)
  }
  return buildS3Url(
    'images',
    `${resource}/${image.id}`,
    image.image,
    typeof size === 'string' ? size : `${size}x${size}`
  )
}

export default buildS3ImageUrl