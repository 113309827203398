import { gql } from "@apollo/client";
import { PROPERTY_INFO_FRAG } from "@services/property/graphql/property-info-frag";

export const CREATE_LISTING = gql`
mutation CreateListing($input: CreateListingInput!) {
    createListing(input: $input) {
      ... on Property {
        ...PropertyInfo
        __typename
      }
    }
  }
	${PROPERTY_INFO_FRAG}
`