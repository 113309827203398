import { gql } from '@apollo/client'

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      description
      name
      featuredImage {
        id
        owner
        filename
      }
    }
  }
`
