import { gql } from '@apollo/client'

export const GETAWAY_WITH_PROP_PHOTOS_FRAG = gql`
	fragment GetawayWithPropPhotos on Getaway {
		id
		title
		startDate
		endDate
		bucket
		link
		photos {
			id
			image
			originalImage
		}
		# settings
		description
		location {
			streetNumber
			route
			googleMap
			address
			country
			state
			city
			zip
			latitude
			longitude
		}
		property {
			id
			name
			photos {
				id
				image
				originalImage
			}
			location {
				id
				streetNumber
				route
				zip
				state
				city
				country
			}
		}
		# status
		featured
		# tagline
		createdAt
		updatedAt
		__typename
	}
`
