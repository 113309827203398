import { gql } from '@apollo/client'

export const RESTORE_AUTH = gql`
	mutation restoreAuth($token: String) {
		restoreAuth(token: $token) {
			id
			firstName
			lastName
			email
			account {
				id
				name
			}
		}
	}
`
