import { gql } from '@apollo/client'

export const UNFOLLOW = gql`
  mutation unfollow($id: ID!) {
    unfollow(id: $id) {
      account {
        name
        id
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
