import { Account } from './codegen'

export interface PageableRequestOptions<T> {
  filter: T
  limit: number
  offset: number
  sortAttribute: string
  sortDirection: string
}

export interface ApiHookInterface {
  loading?: boolean
  data?: any
  fetch?: any
  error?: any
  fetchMore?: any
}

export interface IAdminPage {
  account: Account
}

export enum UIColor {
  Primary = 'primary',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Transparent = 'transparent',
  White = 'white',
  Rose = 'rose',
  Dark = 'dark',
  Default = 'default'
}
