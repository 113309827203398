import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetState } from '../form/utils'

/**
 * @deprecated
 */
interface DrawerState {
	bottomDrawer?: any
	topDrawer?: any
	rightDrawer?: any
	leftDrawer?: any
}

export interface AppState extends DrawerState {
	loading: boolean
	name?: string
	location?: any
	description?: string
	loadedScripts?: any
	listWithUsEnabled?: boolean
}

const initialState = {
	loading: false,
	name: '',
	location: {},
	description: '',
	loadedScripts: [],
	bottomDrawer: {},
	topDrawer: {},
	rightDrawer: {},
	leftDrawer: {},
	listWithUsEnabled: false
}

const slice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setApp(state: AppState, { payload }: PayloadAction<Partial<AppState>>) {
			for (const [key, value] of Object.entries(payload)) {
				state[key] = value
			}
		},
		setLoading(state: AppState, { payload }: PayloadAction<boolean>) {
			state.loading = payload
		},
		reset(state: AppState) {
			resetState(state, initialState)
		}
	}
})

export default slice.reducer

export const { setApp, reset, setLoading } = slice.actions
