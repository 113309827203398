import { useCallback, useRef, useMemo, FC, useState } from 'react'
import {
	AppBar,
	Toolbar,
	// IconButton,
	MenuItem,
	Menu
	// alpha,
	// Input as InputBase,
	// InputAdornment
} from '@mui/material'
import {
	AccountCircle
	// More as MoreIcon
	// Search as SearchIcon
} from '@mui/icons-material'
import { useWindowScroll } from '@services/app/hooks/use-window'
import { useAuth } from '@services/auth/hooks/use-auth'
import { UIColor } from '@services/app/types'
import { ElevationScroll } from '@services/common/components/ElevationScroll'
// import { logger } from '@services/common/lib/logger'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
// import MobileMenu from './MobileMenu'
import NavItem from './NavItem'
import NavModule from './NavModule'
import AccountDropdown from './AccountDropdown'
import BrandComponent from './BrandComponent'
import { useRedirect } from '@services/common/hooks'
import { buildRoutePath } from '@services/common/utils/app'
import { dispatch, RootState, useSelector } from '@services/store'
import { setHeader } from '../reducer'
// import Search from './Search'
// import { useConfig } from 'app/hooks/use-config'

const origin: any = { vertical: 'top', horizontal: 'right' }

export interface ColorChangeProps {
	height: number
	color: UIColor
	variant?: 'light' | 'dark' | 'transparent'
}

export interface HeaderProps {
	color?: UIColor
	brand?: string
	fixed?: boolean
	absolute?: boolean
	variant?: 'light' | 'dark' | 'transparent'
	changeColorOnScroll?: ColorChangeProps
}

const menuId = 'primary-search-account-menu'

export const Header: FC<HeaderProps> = ({ changeColorOnScroll }: HeaderProps) => {
	// const config = useConfig()
	// create element ref
	const ref = useRef(null)
	const { redirect } = useRedirect()
	const appState: any = useSelector((state: RootState) => state.app)
	const headerState: any = useSelector((state: RootState) => state.header)
	const [anchorEl, setAnchorEl] = useState(null)
	// const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
	const { isLoggedIn, contextUser, showAuthDialog, loginEnabled }: any = useAuth()

	const isMenuOpen = Boolean(anchorEl)
	// const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

	// const handleProfileMenuOpen = (event: any) => {
	//   setAnchorEl(event.currentTarget)
	// }

	// const handleMobileMenuClose = () => {
	// 	setMobileMoreAnchorEl(null)
	// }

	const handleMenuClose = () => {
		setAnchorEl(null)
		// handleMobileMenuClose()
	}

	// const handleMobileMenuOpen = useCallback(
	// 	(event: any) => {
	// 		setMobileMoreAnchorEl(event.currentTarget)
	// 	},
	// 	[setMobileMoreAnchorEl]
	// )
	// fix
	const headerColorChange = useCallback(() => {
		// page y offset
		const windowsScrollTop = window.pageYOffset
		if (!headerState) {
			return
		}
		if (windowsScrollTop > changeColorOnScroll!.height) {
			if (!headerState.lock && headerState.variant !== changeColorOnScroll!.variant) {
				// setHeader({ state: 'on' })
				// toggleHeaderState('on')
				dispatch(setHeader({ variant: 'light' }))
			}
		} else {
			if (!headerState.lock && headerState.variant === changeColorOnScroll?.variant) {
				// setHeader({ state: 'off' })
				// toggleHeaderState('off')
				dispatch(setHeader({ variant: 'transparent' }))
			}
		}
	}, [changeColorOnScroll, headerState])

	useWindowScroll(headerColorChange)

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={origin}
			id={menuId}
			keepMounted
			transformOrigin={origin}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleMenuClose}>My account</MenuItem>
		</Menu>
	)

	const mainNaivation = useMemo(() => {
		return (
			(headerState &&
				headerState.mode === 'full' &&
				headerState.navigation &&
				headerState.navigation.filter((x: any) => x.enabled)) ||
			[]
		)
	}, [headerState])

	const avatar: string = useMemo(() => {
		return (
			(contextUser &&
				contextUser.account &&
				contextUser.account.featuredImage &&
				buildS3ImageUrl(contextUser.account.featuredImage, 40)) ||
			''
		)
	}, [contextUser])

	const appBarClassName = useMemo(() => {
		return { ...styles.appBar, ...styles[headerState.variant] }
	}, [headerState.variant])

	// logger.log('Header', { appBarClassName })

	// const mobileMenuId = 'primary-search-account-menu-mobile'

	const navItemStyles = useMemo(() => {
		const itemStyles = {
			root: {
				borderRadius: 1,
				fontWeight: 'bold',
				'&:hover': {
					color: '#525252'
				},
				'&:active': {
					color: '#525252'
				}
			}
		}
		if (headerState.variant === 'transparent') {
			Object.assign(itemStyles.root, styles.lightText)
		} else {
			Object.assign(itemStyles.root, styles.darkText)
		}
		return itemStyles
	}, [headerState.variant])

	const listWithUsStyles = useMemo(() => {
		const css = { ...styles.pillButton, ...styles.listWithUsNav, mr: 1 }
		if (headerState.variant === 'transparent') {
			Object.assign(css, {
				borderColor: '#fff',
				...styles.lightText
			})
		}
		return css
	}, [headerState.variant])

	return (
		<div style={styles.root}>
			<ElevationScroll>
				<AppBar ref={ref} sx={appBarClassName}>
					<Toolbar variant="dense">
						<BrandComponent
							appName={headerState.appName}
							source={headerState.brandIcon}
							variant={headerState.variant === 'transparent' ? 'dark' : 'light'}
						/>

						<div style={styles.grow} />

						{mainNaivation.length > 0 &&
							mainNaivation.map((item: any, idx: number) => (
								<NavItem key={`main-${idx}`} sx={navItemStyles} color="secondary" {...item} />
							))}

						{/* <Search /> */}
						{appState.listWithUsEnabled && (
							<NavItem
								key="list-with-button"
								label="List With Us"
								color="secondary"
								variant="outlined"
								onClick={() => redirect(buildRoutePath('list-with-us'))}
								sx={{
									root: listWithUsStyles
								}}
							/>
						)}
						{loginEnabled &&
							(isLoggedIn ? (
								<NavModule
									key="account-dropdown"
									icon={AccountCircle}
									source={avatar}
									variant={headerState.variant}
									component={AccountDropdown}
								/>
							) : (
								<NavItem
									key="login-button"
									label="Login"
									color="secondary"
									variant="contained"
									onClick={() => showAuthDialog({ form: 'login' })}
									sx={{
										root: { ...styles.pillButton, ...styles.loginNav }
									}}
									styles={{ marginLeft: 8 }}
								/>
							))}
						{/* </div> */}
						{/* <div style={styles.sectionMobile}>
							<IconButton
								aria-label="show more"
								aria-controls={mobileMenuId}
								aria-haspopup="true"
								// onClick={handleMobileMenuOpen}
								color="inherit"
							>
								<MoreIcon />
							</IconButton>
						</div> */}
					</Toolbar>
				</AppBar>
			</ElevationScroll>
			{/* <MobileMenu
				id={mobileMenuId}
				anchorEl={mobileMoreAnchorEl}
				open={isMobileMenuOpen}
				handleClose={handleMobileMenuClose}
			/> */}
			{renderMenu}
		</div>
	)
}

const styles: any = {
	root: {
		zIndex: 1100
	},
	active: {},
	grow: {
		flexGrow: 1
	},
	appBar: {
		// backgroundColor: 'transparent'
	},
	menuButton: {
		marginRight: 2
	},
	title: {
		display: 'flex!important',
		marginRight: 1
		// [theme.breakpoints.up('sm')]: {
		// 	display: 'block'
		// }
	},
	sectionDesktop: {
		display: 'none'
		// [theme.breakpoints.up('md')]: {
		// 	display: 'flex'
		// }
	},
	sectionMobile: {
		display: 'flex'
		// [theme.breakpoints.up('md')]: {
		// 	display: 'none'
		// }
	},
	transparent: {
		backgroundColor: 'transparent !important',
		boxShadow: 'none',
		// paddingTop: '25px',
		padding: 0,
		color: '#FFFFFF'
	},
	dark: {
		backgroundColor: 'rgba(0,0,0,.8) !important'
	},
	light: {
		backgroundColor: '#fff !important'
	},
	scrolling: {
		boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)'
	},
	lightText: {
		color: '#FFFFFF'
	},
	darkText: {
		color: '#525252'
	},
	pillButton: {
		borderRadius: '30px',
		borderWidth: 2,
		padding: '4px 24px',
		'&:hover': {
			borderWidth: 2
		}
	},
	listWithUsNav: {
		marginLeft: 8,
		border: '2px solid #525252',
		'&:Hover': {
			color: '#F97487',
			borderColor: '#F97487',
			backgroundColor: 'transparent'
		}
	},
	loginNav: {
		color: '#fff',
		borderColor: '#F97487',
		border: '2px solid #F97487',
		boxShadow: 'none',
		backgroundColor: '#F97487',
		'&:hover': {
			color: '#F97487',
			border: '2px solid #F97487',
			boxShadow: 'none'
		}
	}
}
