export const concierge = [
	{
		title: 'Ready To Go',
		text: `
    MyGayGetaway has exclusive getaways that you can't find anywhere else and we've included some of the best original travel experiences in each destination.

    From the Galapagos to Cambodia, from Australia to Canada, we cover almost every location on earth. All of our getaways have been built to give you inspiration and put experiential travel back into the heart of travel. For too long people have been missing out on the heart of a destination. We're here to change that. Immerse yourself in a destination.`,
		summary:
			"MyGayGetaway has exclusive getaways that you can't find anywhere else and we've included some of the best original travel experiences in each destination.",
		image: '/assets/images/fpo/er185194218.jpg'
	},
	{
		title: 'Gay Friendly',
		text: `
    MyGayGetaway prides itself in working only with suppliers who welcome everyone, no matter who you love. All of our getaways are built from the ground up with gay people in mind but open to all.

    It's important that everyone feels welcome on their trip and MyGayGetaway was set up to ensure inclusivity and a welcome anyone on earth. We understand LGBT travel better than most. Many of our team have travelled the world and have hints and tips aplenty to make the most of your trip. We want to open the world to all - and that means you.`,
		summary:
			'MyGayGetaway prides itself in working only with suppliers who welcome everyone, no matter who you love.',
		image: '/assets/images/fpo/er66088058.jpg'
	},
	{
		title: 'Just for You',
		text: `
    We know that everyone is different, has unique interests and individual requirements. Our getaways are designed to give you ideas, but we are also able to customise almost everything.

    With us you'll be paired with one of our expert getaway concierge team who will be with you from your first search through your travels. From private drivers to private jets, we can arrange it.

    Simply Search Now and start building your getaway with us.
    `,
		summary:
			'We know that everyone is different, has unique interests and individual requirements.',
		image: '/assets/images/fpo/er165702805.jpg'
	}
]

export const GETAWAY_PAGE = {
	title: 'Getaways',
	text: "We know that it's difficult to choose the perfect destination sometimes. We all need a little inspiration every now and then and so we've picked a selection of our favourite getaways to help you decide. All our getaways are here as a starting point."
}
