import getaways from '@services/getaway/constants/navigation'
import events from '@services/event/constants/navigation'
import blog from '@services/blog/constants/navigation'
import app from '@services/app/constants/navigation'
import { buildRoutePath } from '@services/common/utils/app'

export const mainNavigation: any = [
	...getaways,
	...events,
	...blog,
	{
		label: 'Contact Us',
		linkTo: buildRoutePath('contact'),
		enabled: true
	}
]

export const footerNavigation: any = [
	{
		label: 'Quick Links',
		enabled: true,
		links: [
			...blog,
			...getaways,
			...events,
			// {
			// 	label: 'Experiences',
			// 	linkTo: '/experiences',
			// 	enabled: false
			// },
			{
				label: 'List your property or Experience',
				linkTo: '/list-it',
				enabled: false
			}
		]
	},
	{
		label: 'Company',
		enabled: true,
		links: app
	},
	{
		label: 'Follow Us On',
		enabled: true,
		links: [
			{
				label: 'Facebook',
				href: 'http://facebook.com/mygaygetaways',
				enabled: true
			},
			{
				label: 'Twitter',
				href: 'http://twitter.com/#!/mygaygetaways',
				enabled: true
			},
			{
				label: 'Instagram',
				href: 'https://instagram.com/mygaygetawaytravel',
				enabled: true
			},
			{
				label: 'YouTube',
				linkTo: '',
				enabled: false
			}
		]
	}
]
