import React, { cloneElement, FC, ReactElement, useMemo } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'

export interface Query {
	children: ReactElement
	query: DocumentNode
	action: string
	variables: Record<string, unknown> | Function
	data: any
}

const QueryList: FC<Query> = ({ children, query, action, variables = {}, data = {} }: Query) => {
	const formVariables = useMemo(() => {
		if (typeof variables === 'function') {
			return variables(data)
		}
		return variables
	}, [variables, data])

	const { data: queryData, loading }: any = useQuery(query, { variables: formVariables })
	const rendered = useMemo(() => {
		const items: any = []
		console.log({ [action]: queryData })
		if (queryData && queryData[action]) {
			items.push(...queryData[action].items)
		}
		return cloneElement(children, {
			items
		})
	}, [children, queryData, action])

	if (loading || !queryData) {
		return null
	}

	return <>{rendered}</>
}

export default QueryList
