// import { LIST_COUNTRIES, LIST_STATES } from '@services/location/graphql'
import graphql from './graphql'
import fields from './fields'
import initialState from './state'
import beforeSave from './before-save'
import { buildRoutePath } from '@services/common/utils/app'

// const getStateVariables = ({ country }: any) => {
// 	const filter: any = {}
// 	if (country) {
// 		filter.country = country.id
// 	}
// 	return { limit: 1000, filter }
// }

const form: any = {
	initialState,
	name: 'property',
	header: {
		title: 'Property'
	},
	beforeSave,
	onSuccess(data: any) {
		return buildRoutePath('my-account', 'listings', 'edit', 'property', data.id)
	},
	...graphql,
	fields
}

export default form
