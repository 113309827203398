import { useMemo } from 'react'
import { Box, Card, CardActionArea, Container, Divider, Grid, Typography } from '@mui/material'
import PageHeader from '@services/common/components/PageHeader'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import AspectRatio from '@services/common/components/AspectRatio'
import Image from '@services/common/components/Image'
import { useQuery } from '@apollo/client'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'

const PartnersPage = ({ limit = 24 }: any) => {
	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.partners'
		}
	})

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content
			})
		}
		return res
	}, [data])

	return (
		<Box sx={styles.root}>
			<PageHeader
				sx={{ title: styles.title, titleBlock: styles.titleBlock, text: styles.text }}
				{...header}
			/>
			<Divider style={{ margin: '24px 0', width: '100%' }} />

			<Container maxWidth="md" style={{ paddingBottom: 60, minHeight: '80vh' }}>
				<Typography variant="h5" style={{ textAlign: 'center', marginBottom: 24 }}>
					Our partners and sponsors change regularly so check back often!
				</Typography>
				<Grid container spacing={3}>
					<Grid item md={4}>
						<Card>
							<CardActionArea
								href="http://www.axelhotels.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Box sx={styles.image}>
									<AspectRatio ratio={16 / 9}>
										<Box flex={1} display="flex" alignItems="center" justifyContent="center">
											<Image
												src="/assets/images/partners/axel-hotels.png"
												styles={{ image: { width: '100%' } }}
											/>
										</Box>
									</AspectRatio>
								</Box>
							</CardActionArea>
						</Card>
					</Grid>

					<Grid item md={4}>
						<Card>
							<CardActionArea
								href="https://outtv.ca/show/pinksixty-news/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Box sx={styles.image}>
									<AspectRatio ratio={16 / 9}>
										<Box flex={1} display="flex" alignItems="center" justifyContent="center">
											<Image
												src="/assets/images/partners/pinksixty-news.png"
												styles={{ image: { width: '100%' } }}
											/>
										</Box>
									</AspectRatio>
								</Box>
							</CardActionArea>
						</Card>
					</Grid>

					<Grid item md={4}>
						<Card>
							<CardActionArea
								href="https://www.ihg.com/kimptonhotels/hotels/us/en/reservation"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Box sx={{ ...styles.image, backgroundColor: '#000' }}>
									<AspectRatio ratio={16 / 9}>
										<Box flex={1} display="flex" alignItems="center" justifyContent="center">
											<Image
												src="/assets/images/partners/kimpton-hotels.png"
												styles={{ image: { width: '100%' } }}
											/>
										</Box>
									</AspectRatio>
								</Box>
							</CardActionArea>
						</Card>
					</Grid>
				</Grid>
			</Container>
			<NewsletterSignup />
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: 8
	},
	scrollBehind: {
		position: 'absolute',
		zIndex: -1,
		top: 0,
		right: 0,
		left: 0,
		'&:before': {
			// content: '""',
			// position: 'absolute',
			// top: 0,
			// right: 0,
			// bottom: 0,
			// left: 0,
			// background: 'rgba(0,0,0,.4)',
			// zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: 0,
			left: 0,
			height: '20%',
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
		}
	},
	container: {},
	content: {},
	header: {},
	titleBlock: {
		paddingTop: 10
	},
	title: {
		marginBottom: 4
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	},
	partner: {
		listStyle: 'none',
		flex: 1
	},
	image: {
		padding: 3
	}
}

export default PartnersPage
