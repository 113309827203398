import { gql } from '@apollo/client'

export const LIST_DESTINATIONS = gql`
	query ListDestinations($filter: TableDestinationFilterInput, $limit: Int, $offset: Int) {
		listDestinations(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				id
				location {
					country
					state
					city
					latitude
					longitude
				}
				images {
					id
					image
					originalImage
				}
				status
				createdAt
				updatedAt
			}
		}
	}
`
