import { useEffect, useState } from 'react'
import { Drawer, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { logger } from '@services/common/lib/logger'
import { DrawerPosition } from '@services/app/AppProvider/types'
import { drawerPositionMap } from '@services/app/constants/drawer'
import { DrawerHook, useDrawer } from '@services/app/hooks/use-drawer'

export interface DrawerConductorProps {
	position: DrawerPosition
}

export const DrawerConductor = ({ position }: DrawerConductorProps) => {
	const classes: any = useStyles()
	const { drawer, isOpen }: DrawerHook = useDrawer({
		position
	})
	const [drawerProps, setDrawerProps]: any = useState({
		anchor: drawerPositionMap[position],
		variant: 'persistent'
	})

	useEffect(() => {
		if (drawer && drawer.options) {
			logger.log('drawer has options', drawer.options)
			const newState = { ...drawerProps, ...drawer.options }
			if (JSON.stringify(drawerProps) !== JSON.stringify(newState)) {
				setDrawerProps(newState)
			}
		}
	}, [drawer, drawerProps, setDrawerProps])

	return (
		<Drawer open={isOpen} {...drawerProps} classes={classes}>
			{drawer && drawer.component && <drawer.component {...drawer.data} />}
		</Drawer>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		zIndex: 9999
	}
}))
