import { FC } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

export interface DialogTextProps {
	icon?: any
	text?: string | string[]
}

export const DialogText: FC<DialogTextProps> = ({ icon: Icon, text }: DialogTextProps) => {
	const classes: any = useStyles()

	return (
		<Box display="flex" flex={1} className={classes.root}>
			{Icon && (
				<Box display="flex" className={classes.text}>
					{Icon}
				</Box>
			)}
			{text && <Typography className={classes.text}>{text}</Typography>}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(4)
	},
	text: {}
}))
