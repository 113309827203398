import { gql } from '@apollo/client'
import { GETAWAY_INFO_FRAG } from './getaway-info-frag'

export const UPDATE_GETAWAY = gql`
	mutation UpdateGetaway($input: UpdateGetawayInput!) {
		updateGetaway(input: $input) {
			...GetawayInfo
		}
	}
	${GETAWAY_INFO_FRAG}
`
