import { Typography } from '@mui/material'

export const wrapParagraph = (str: string, className = '') => {
	if (!str) return str
	const alreadyWrapped = str.match(/<[p][\s\S]*>/gi)
	if (alreadyWrapped && alreadyWrapped.length > 0) {
		return <div className={className} dangerouslySetInnerHTML={{ __html: str }} />
	}
	const prefix = Math.floor(Math.random() * Date.now())
	return str
		.split('\n')
		.map((block: string, idx: number) => (
			<Typography
				className={className}
				key={`${prefix}-${idx}`}
				style={{ marginBottom: 16 }}
				dangerouslySetInnerHTML={{ __html: block }}
			/>
		))
}
