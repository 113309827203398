import { gql } from '@apollo/client'

export const CREATE_PROPERTY_TYPE = gql`
	mutation CreatePropertyType($input: CreatePropertyTypeInput!) {
		createPropertyType(input: $input) {
			id
			name
			__typename
		}
	}
`
