import { gql } from '@apollo/client'
import { ARTICLE_INFO_FRAG } from './article-info-frag'

export const GET_ARTICLE = gql`
	query GetArticle($id: ID!) {
		getArticle(id: $id) {
			...ArticleInfo
		}
	}
	${ARTICLE_INFO_FRAG}
`
