import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resetState } from '../form/utils'
import type { VariantLogo } from './types'

export interface HeaderState {
	mode: string
	onScrollBackgroundColor: string
	state: string
	lock: boolean
	navigation: any
	variant: string
	variantOnScroll: string
	appName?: string
	brandIcon?: string | VariantLogo
}

const initialState: HeaderState = {
	mode: 'full',
	onScrollBackgroundColor: 'light',
	state: 'off',
	lock: true,
	navigation: [],
	variant: 'light',
	variantOnScroll: 'light',
	appName: '',
	brandIcon: ''
}

const slice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setHeader(state: HeaderState, { payload }: PayloadAction<Partial<HeaderState>>) {
			for (const [key, value] of Object.entries(payload)) {
				state[key] = value
			}
		},
		reset(state: HeaderState) {
			resetState(state, initialState)
		}
	}
})

export default slice.reducer

export const { reset, setHeader } = slice.actions
