import { gql } from '@apollo/client'

export const LIST_CONTENT = gql`
	query listContent($filter: TableContentFilterInput, $limit: Int, $offset: Int) {
		listContent(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				... on ContentPage {
					id
					name
					title
					uri
					slug
					key
					type
					description
					content
					status
					access
					layout
					modules
					createdAt
					updatedAt
				}
				... on ContentBlock {
					id
					name
					slug
					key
					type
					createdAt
					updatedAt
				}
				... on ContentHero {
					id
					name
					title
					subtitle
					body
					slug
					key
					type
					status
					image {
						id
						owner
						filename
					}
					actions {
						label
						event
						variant
						color
						value
					}
					meta {
						height
						parallax
						overlay {
							hex
							rgb
							alpha
							gradient {
								angle
								transitions {
									hex
									rgb
									alpha
									offset
								}
							}
						}
					}
					createdAt
					updatedAt
				}
				... on ContentNavigation {
					id
					name
					slug
					key
					type
					destination
				}
				... on AttributeSet {
					id
					name
					key
					value
					type
				}
			}
		}
	}
`
