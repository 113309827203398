import { gql } from '@apollo/client'
import { WHITELIST_FRAGMENT } from './whitelist-frag'

export const DELETE_WHITELIST = gql`
  mutation DeleteWhitelist($input: DeleteWhitelistInput!) {
    deleteWhitelist(input: $input) {
      ...WhitelistInfo
    }
  }
  ${WHITELIST_FRAGMENT}
`
