import { useCallback, useMemo, useRef, FC } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useQuery } from '@apollo/client'
import {
	DataGrid
	// PageChangeParams,
	// SortModelParams
} from '@mui/x-data-grid'
import { logger } from '@services/common/lib/logger'
import { LoadingOverlay } from './LoadingOverlay'

export interface DataTableParams {
	title?: string
	query?: any
	subscription?: any
	filter?: any
	resource: string
	rowCount?: number
	columns?: any
	defaultSortField?: string
	onRowClick?: any
	headerComponent?: any
	footerComponent?: any
	emptyListComponent?: any
	errorComponent?: any
	rowComponent?: any
	handleRowAction?: any
}

export const DataTable: FC<DataTableParams> = ({
	query,
	filter,
	resource,
	rowCount = 10,
	columns
}: DataTableParams) => {
	const classes: any = useStyles()

	const tableRef = useRef(null)
	const {
		data,
		loading,
		fetchMore,
		variables: queryVars
	}: any = useQuery(query, {
		notifyOnNetworkStatusChange: true,
		variables: {
			filter,
			limit: rowCount,
			sortAttribute: 'createdAt',
			sortDirection: 'asc',
			offset: 0
		}
	})

	const handleTableError = useCallback((args: any) => {
		logger.log('handleTableError', args)
	}, [])

	const loadMoreRecords = useCallback(
		(variables: any) => {
			fetchMore({
				variables,
				updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
					if (!fetchMoreResult) {
						return previousResult
					}
					return fetchMoreResult
				}
			})
		},
		[fetchMore]
	)

	const handleSortChange = useCallback(
		({ sortModel }: any) => {
			if (sortModel[0]) {
				const variables = {
					...queryVars,
					sortAttribute: sortModel[0].field,
					sortDirection: [sortModel[0].sort]
				}
				loadMoreRecords(variables)
			}
		},
		[queryVars, loadMoreRecords]
	)

	const handlePageUpdate = useCallback(
		(params: any) => {
			const variables = {
				...queryVars,
				limit: params.pageSize,
				offset: params.page * params.pageSize
			}
			loadMoreRecords(variables)
		},
		[queryVars, loadMoreRecords]
	)

	const handleRowClick = useCallback(({ row }: any) => {
		logger.log('handlerowClick', row)
	}, [])

	const rowData: any = useMemo(() => {
		return data && data[resource] ? data[resource].items : []
	}, [data, resource])

	const total: number = useMemo(() => {
		return data && data[resource] ? data[resource].total : 0
	}, [data, resource])

	return (
		<Box className={classes.root}>
			<DataGrid
				ref={tableRef}
				// autoPageSize
				// checkboxSelection
				autoHeight={true}
				pagination
				paginationMode="server"
				sortingMode="server"
				loading={loading}
				rowsPerPageOptions={[10, 25, 50, 100]}
				rowCount={total}
				pageSize={10}
				rows={rowData}
				columns={columns}
				onPageChange={handlePageUpdate}
				onPageSizeChange={handlePageUpdate}
				onSortModelChange={handleSortChange}
				onError={handleTableError}
				onRowClick={handleRowClick}
				components={{
					LoadingOverlay
				}}
			/>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flex: 1,
		'flex-grow': 1,
		width: '100%',
		height: 700
	}
}))
