import React from 'react'
import AutocompleteField from '../fields/Autocomplete'
import Query from '../Query'

const AutocompleteFieldRenderer = ({ data, query, record, form, ...rest }: any) => {
	return (
		<Query data={data} {...query}>
			<AutocompleteField items={data.items} form={form} {...rest} data={record} />
		</Query>
	)
}
export default AutocompleteFieldRenderer
