import { combineReducers } from 'redux'
import appReducer from '@services/app/reducer'
import formReducer from '@services/form/reducer'
import headerReducer from '@services/header/reducer'
import pageReducer from '@services/page/reducer'

const reducers = combineReducers({
	app: appReducer,
	header: headerReducer,
	form: formReducer,
	page: pageReducer
})

export default reducers
