import { initialState } from './state'

export const onAuthenticateSuccess = (state: any) => {
	return state
}

export const updateState = (state: any, payload: any) => ({
	...state,
	...payload
})

export const updateAccountImage = (state: any, payload: any) => {
	const user = state.user
	user.account.featuredImage = payload
	return {
		...state,
		user
	}
}

export const updateAccount = (state: any, payload: any) => {
	const user = state.user
	user.account = payload
	return {
		...state,
		user
	}
}

export const reset = () => {
	return { ...initialState }
}
