export const COMPONENT_FORM: Record<string, string> = {
	street_number: 'short_name',
	route: 'long_name',
	locality: 'long_name',
	administrative_area_level_1: 'short_name', // 'long_name'
	country: 'short_name', // 'long_name'
	postal_code: 'short_name'
}

export const LOC_MAP: Record<string, string> = {
	street_number: 'streetNumber',
	route: 'route',
	locality: 'city',
	administrative_area_level_1: 'state',
	country: 'country',
	postal_code: 'zip',
	formatted_address: 'address'
}

export const replacementMap = {
	'US': 'USA'
}

export const PLACES_FIELDS_RESPONSE = ['geometry', 'address_components', 'formatted_address', 'url']

export const locationState: any = Object.freeze({
	streetNumber: '',
	route: '',
	city: '',
	state: '',
	country: '',
	zip: '',
	address: '',
	latitude: '',
	longitude: '',
	googleMap: ''
})
