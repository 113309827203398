import { gql } from '@apollo/client'

export const DELETE_GETAWAY = gql`
	mutation DeleteGetaway($input: DeleteGetawayInput!) {
		deleteGetaway(input: $input) {
			id
			__typename
		}
	}
`
