import { CREATE_LISTING } from '@services/listing/graphql/create-listing'
import { UPDATE_LISTING } from '@services/listing/graphql/update-listing'
import { GET_PROPERTY } from '../../graphql/get-property'
import { PROPERTY_INFO_FRAG } from '../../graphql/property-info-frag'

const graphql = {
	queries: {
		get: {
			query: GET_PROPERTY,
			action: 'getProperty'
		},
		list: {
			action: 'listProperties'
		},
		fragment: {
			query: PROPERTY_INFO_FRAG
		}
	},
	mutations: {
		create: {
			mutation: CREATE_LISTING,
			action: 'createListing'
		},
		update: {
			mutation: UPDATE_LISTING,
			action: 'updateListing'
		}
	},
	fetchAction: 'listProperties',
	fragment: PROPERTY_INFO_FRAG
}
export default graphql
