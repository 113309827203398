import { gql } from '@apollo/client'

export const GET_ACCOUNT_BY_NAME = gql`
  query accountByName($name: String!) {
    accountByName(name: $name) {
      id
      name
      description
      featuredImage {
        id
        owner
        filename
      }
    }
  }
`
