import * as Actions from './events'

export const updateProgress = (payload: any) => (dispatch: any) => {
	dispatch(Actions.PROGRESS, payload)
}

export const bindUpload = (payload: any) => (dispatch: any) => {
	dispatch(Actions.BIND, payload)
}

export const clearUpload =
	(id: string, timeout: number = 0) =>
	(dispatch: any) => {
		setTimeout(() => {
			dispatch(Actions.CLEAR, id)
		}, timeout)
	}
