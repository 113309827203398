import { gql } from '@apollo/client'

export const LIST_ACCOUNTS = gql`
  query listAccounts(
    $filter: TableAccountFilterInput
    $limit: Int
    $offset: Int
  ) {
    listAccounts(filter: $filter, limit: $limit, offset: $offset) {
      total
      size
      items {
        id
        name
        description
        featuredImage {
          id
          owner
          filename
        }
        images {
          id
          owner
          filename
        }
      }
    }
  }
`
