import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { Box, Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DocumentNode, useApolloClient } from '@apollo/client'
import { useStateReducer } from '@basementscripts/use-state-reducer'
import { useFormState } from '../hooks/use-form-state'
import classNames from 'classnames'
import { noop } from 'lodash'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { mockHandler, mockTransformer } from '../utils'
import { FormMutation } from '../types'
// import stripNullOrEmpty from '../utils/strip-null-empty'
import { useHandleSubmit } from '../hooks/use-handle-submit'
import { FormActions } from './FormActions'
import FormSidebar from './FormSidebar'
import { FormRows } from './FormRows'
import { TabForm } from './TabForm'

const stripNullOrEmpty = (data: any) => {
	if (!data.id) {
		delete data.id
	}
	return data
}

export type FormDisabled = (form: any) => boolean
export type FormClasses = {
	root?: string
}

export interface FormProps {
	type?: 'flat' | 'tabs'
	saving?: boolean
	name?: string
	record?: any
	initialState?: any
	fields?: any
	mutations: FormMutation
	onCancel?: any
	onComplete?: any
	onSuccess?: any
	onError?: any
	children?: ReactNode | ReactNode[]
	actionsPosition?: string
	actionsBackground?: boolean
	divider?: boolean
	transform?: any
	prepareState?: any
	beforeSave?: any
	disabled?: boolean | FormDisabled
	data?: any
	classes?: FormClasses
	fragment?: DocumentNode
	fetchAction?: string
	styles?: any
	retentionStrategy?: any
}

const mockPrepareState = (data: any) => data

const Form: FC<FormProps> = ({
	saving = false,
	name,
	fields,
	type = 'flat',
	record = {},
	data: injectedData = {},
	initialState = {},
	mutations,
	classes: injectedClasses = {},
	children,
	actionsPosition = 'relative',
	actionsBackground = false,
	divider = false,
	onCancel = noop,
	onSuccess = noop,
	disabled = false,
	transform = mockTransformer,
	beforeSave = mockHandler,
	prepareState = mockPrepareState,
	onError = noop,
	fragment,
	fetchAction,
	styles = {},
	retentionStrategy = []
}: FormProps) => {
	const client = useApolloClient()

	const initialConfig: any = {
		readOnly: false,
		namespace: 'MyGayGetaway',
		onError: (error: Error) => {
			throw error
		}
	}

	const {
		form,
		data,
		updateForm: pushFormData,
		updateData,
		pushError,
		clearError,
		handleInputChange
	}: any = useFormState({
		initialState,
		data: injectedData,
		record,
		prepareState,
		retentionRules: retentionStrategy,
		stateManager: useStateReducer
	})
	const [useTabs]: any = useState(type === 'tabs')
	const FormRenderer: any = useTabs ? TabForm : FormRows

	useEffect(() => {
		console.log('Form', { form, data, useTabs })
	}, [form, data, useTabs])

	const disabledMemorized = useMemo(() => {
		return typeof disabled === 'function' ? disabled(form) : disabled
	}, [disabled, form])

	const submitHandler: any = useHandleSubmit({ mutations, fetchAction, fragment })
	const classes = useStyles()

	const fieldsProxy = useMemo(() => {
		return fields.reduce(
			(collection: any, field: any) => {
				console.log('fieldsProxy', field)
				if (!field.position || field.position === 'main') {
					collection.main.push(field)
				} else if (field.position && field.position === 'sidebar') {
					collection.sidebar.push(field)
				}

				return collection
			},
			{ main: [], sidebar: [] }
		)
	}, [fields])
	// const { current: fieldsProxy } = useRef(fields)

	// update form data
	const updateForm: any = (update: any, value?: any) => {
		if (typeof update === 'string') {
			const _value: any = transform(update, value)
			pushFormData({ [update]: _value })
		} else if (typeof update === 'object') {
			if (update.formData && update.data) {
				updateData(update.data)
				pushFormData(update.formData)
			} else {
				pushFormData(update)
			}
		}
	}

	const handleOnSubmit = (e: any) => {
		e.preventDefault()

		const processed = stripNullOrEmpty(beforeSave(form))
		// const processed = beforeSave(form, data)
		submitHandler({ form: processed, onError, onSuccess })
	}

	const fieldOptions = {
		handleInputChange,
		updateForm,
		updateData,
		form,
		data,
		record,
		initialState,
		client,
		pushError,
		clearError
	}

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<form
				id={name}
				style={styles.root}
				onSubmit={handleOnSubmit}
				className={classNames(classes.root, injectedClasses.root)}
			>
				{children}
				<Box className={classes.form}>
					{fieldsProxy.main.length > 0 && (
						<Box className={classes.main}>
							<Box className={classNames(classes.fieldsWrap, { [classes.tabs]: useTabs })}>
								<FormRenderer fields={fieldsProxy.main} options={fieldOptions} />
							</Box>
						</Box>
					)}
					<FormSidebar fields={fieldsProxy.sidebar} options={fieldOptions} />
				</Box>
				{divider && <Divider style={{ margin: '16px 0' }} />}
				<Box className={classes.actions}>
					<FormActions
						loading={saving}
						disabled={disabledMemorized}
						onCancel={onCancel}
						position={actionsPosition}
						background={actionsBackground}
					/>
				</Box>
			</form>
		</LexicalComposer>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		// display: 'flex',
		// flex: 1,
		// flexDirection: 'column'
	},
	form: {
		display: 'flex',
		flexDirection: 'row',
		gap: 24
	},
	main: {
		display: 'flex',
		flex: 2
	},
	sidebar: {
		// width: 300,
		display: 'flex',
		flex: 1
	},
	fieldsWrap: {
		width: '100%'
		// display: 'flex',
		// flex: 1
	},
	tabs: {
		// flexDirection: 'column'
	},
	actions: {}
}))

/**
 * @deprecated use default export
 */
export { Form }
export default Form
