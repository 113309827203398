import React from 'react'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Grid from '@mui/material/Grid'
import { Dropdown } from '@services/common/components/Dropdown'
import { noop } from 'lodash'

const SortableItem = ({ id, children, actions = [], handleCardAction = noop, sx = {} }: any) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		...sx.root
	}

	return (
		<Grid item md={3} ref={setNodeRef} style={style}>
			<div style={{ position: 'relative', ...sx.container }}>
				<div {...attributes} {...listeners} style={sx.content}>
					{children}
				</div>
				{actions && actions.length > 0 && (
					<div style={{ position: 'absolute', bottom: 0, right: 0, ...sx.actions }}>
						<Dropdown
							caret={false}
							onClick={handleCardAction}
							icon={MoreVertIcon}
							// size='sm'
							// color='transparent'
							actions={actions}
						/>
					</div>
				)}
			</div>
		</Grid>
	)
}

export default SortableItem
