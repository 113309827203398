import { useEffect, useRef } from "react"
import { TextField } from "@mui/material"
import { noop } from "lodash"
import { useJsApiLoader } from '@react-google-maps/api'
import useWindow from '@services/common/hooks/use-window'
import config from "@core/environment"

const libraries: any = ['places']

const LocationWidget = ({ label, onChange = noop }: any) => {
  const ref: any = useRef(null)
  const win = useWindow()

  const { isLoaded } = useJsApiLoader({
    id: 'map',
    googleMapsApiKey: config.mapKey,
    libraries
  })

  useEffect(() => {
    if (ref.current && isLoaded) {
      const service = new win.google.maps.places.Autocomplete(ref.current, { fields: ['ALL'] })
      win.google.maps.event.addListener(service, 'place_changed', () => {
        const place = service.getPlace()
        onChange(place)
      })
    }
  }, [isLoaded, onChange, win])

  return <TextField sx={{ width: '100%' }} inputRef={ref} type="text" name="location" variant="outlined" label={label} />
}
export default LocationWidget