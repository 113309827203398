import { gql } from '@apollo/client'

export const SUBSCRIBE = gql`
  mutation subscribe($id: ID!) {
    subscribe(id: $id) {
      account {
        id
        name
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
