import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export interface GeolocationPosition {
	coords: GeolocationCoordinates
	timestamp: number
}

export interface GeolocationCoordinates {
	latitude: number
	longitude: number
	altitude: number
	accuracy: number
	altitudeAccuracy: number
	heading: number
	speed: number
}

export interface AppNavigation {
	sections?: AppNavigationSection[]
}

export interface AppNavigationSection {
	section?: string
	items?: AppNavigationItem[]
	enabled?: boolean
}

export interface AppNavigationItem {
	label?: string
	icon?: SvgIcon
	linkTo?: string
	enabled?: boolean
}

export type SvgIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>>

export enum DrawerPosition {
	Top = 'topDrawer',
	Right = 'rightDrawer',
	Left = 'leftDrawer',
	Bottom = 'bottomDrawer'
}
