import { FC, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { noop } from 'lodash'

export interface SelectableProps {
	open?: boolean
	items?: any
	title?: string
	rootClassName?: string
	component?: any
	onSelected?: any
	onCancel?: any
	itemOptions?: any
	selected?: any
}

const Selectable: FC<SelectableProps> = ({
	open = false,
	title = 'Select',
	items = [],
	rootClassName,
	component: Component,
	itemOptions = {},
	onSelected = noop,
	onCancel = noop,
	selected = []
}: SelectableProps) => {
	const [itemsProxy, setItemsProxy]: any = useState(
		items.map((item: any) => ({ selected: selected.includes(item.id), item }))
	)

	const classes = useStyles()

	const toggleSelected = (index: number) => {
		const update = [...itemsProxy]
		update[index].selected = !update[index].selected
		setItemsProxy(update)
	}

	const reset = () => {
		const update = itemsProxy.map((x: any) => {
			x.selected = false
			return x
		})
		setItemsProxy(update)
	}

	const cancelSelection = () => {
		onCancel()
		reset()
	}

	const selectedProxy = useMemo(() => {
		return itemsProxy.filter((x: any) => x.selected).map(({ item }: any) => item)
	}, [itemsProxy])

	const handleSelected = (e: any) => {
		e.preventDefault()
		onSelected([...selectedProxy])
		reset()
	}

	console.log({ itemsProxy })

	return (
		<Box display="flex" flex={1} flexDirection="column">
			<Box flex={1}>
				{open && itemsProxy.length > 0 && (
					<Grid container spacing={2}>
						{title && (
							<Grid item md={12}>
								<Typography variant="h5" component="h2">
									{title}
								</Typography>
							</Grid>
						)}
						{itemsProxy.map(({ selected, item }: any, idx: number) => (
							<Grid key={`map-a-${item.id}`} item md={2}>
								<div
									className={classNames({
										[classes.selected]: selected
									})}
								>
									<Component
										item={item}
										variant="icon"
										iconSize={30}
										{...itemOptions}
										onClick={() => toggleSelected(idx)}
									/>
								</div>
							</Grid>
						))}
					</Grid>
				)}
			</Box>
			<Box style={{ width: '100%' }}>
				<Box
					style={{
						textAlign: 'right'
						// backgroundColor: '#282C34',
						// padding: '16px 24px'
					}}
				>
					<Button variant="outlined" onClick={cancelSelection} style={{ marginRight: 8 }}>
						Cancel
					</Button>
					<span style={{ position: 'relative', display: 'inline-flex' }}>
						<Button variant="contained" color="primary" onClick={handleSelected}>
							Select
						</Button>
					</span>
				</Box>
			</Box>
		</Box>
	)
}

const useStyles = makeStyles({
	root: {
		position: 'absolute',
		zIndex: 9,
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		flex: 1,
		flexDirection: 'column',
		backgroundColor: 'rgba(0,0,0,.9)'
	},
	selected: {
		border: '3px solid white',
		margin: -3,
		// padding: 1,
		borderRadius: 8
	}
})

export default Selectable
