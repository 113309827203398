import { FC, useMemo } from 'react'
import { Grid } from '@mui/material'
import { noop } from 'lodash'
import FormFieldRenderer from './renderers/FormFieldRenderer'

const MAX_COLUMNS = 12
const OFFSET = 3

export interface FormRowProps {
	fields: any
	record?: any
	data?: any
	form: any
	handleInputChange?: any
	pushError?: any
	updateForm?: any
	initialState?: any
}

const FormRow: FC<FormRowProps> = ({
	fields = [],
	record,
	data = {},
	form,
	handleInputChange = noop,
	pushError = noop,
	updateForm = noop,
	initialState = {}
}: FormRowProps) => {
	const fieldProxy = useMemo(() => {
		return Array.isArray(fields) ? fields : [fields]
	}, [fields])

	const formOptions = {
		form,
		data,
		handleInputChange,
		updateForm,
		pushError,
		record,
		initialState
	}

	return (
		<Grid container spacing={3}>
			{fieldProxy.map((field: any, idx: any) => {
				const columns: any = field.width ? field.width * OFFSET : MAX_COLUMNS
				const fieldContext: any = field.type === 'section' ? 'section' : 'field'
				const fieldKey = [fieldContext]
				if (fieldContext === 'field') {
					fieldKey.push(field.name)
				}
				fieldKey.push(idx)

				return (
					<Grid key={fieldKey.join('-')} item md={columns}>
						<FormFieldRenderer {...formOptions} field={field} />
					</Grid>
				)
			})}
		</Grid>
	)
}

export default FormRow
