import { gql } from '@apollo/client'
import { EVENT_INFO_FRAG } from './event-info-frag'

export const LIST_EVENTS = gql`
	query ListEvents(
		$filter: TableEventFilterInput
		$limit: Int
		$offset: Int
		$sortAttribute: String
		$sortDirection: String
	) {
		listEvents(
			filter: $filter
			limit: $limit
			offset: $offset
			sortAttribute: $sortAttribute
			sortDirection: $sortDirection
		) {
			total
			size
			items {
				...EventInfo
			}
		}
	}
	${EVENT_INFO_FRAG}
`
