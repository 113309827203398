import propertyFormConfig from '@services/property/constants/form/property-form'

export const CONFIG_MAP: { [key: string]: any } = {
	property: propertyFormConfig
}

export const PROPERTY_TYPE_BLOCKS: { image: string; title: string }[] = [
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d82845c5bdcfacc3b1e9dc2/799cfdaf-98e4-4efa-b158-8050393f9ef8.jpg',
		title: 'House'
	},
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d8284855bdcfacc3b1ee9ac/1692a6d0-116d-44ad-8927-c81c3b69d7fa.jpg',
		title: 'Chique'
	},
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d8284945bdcfacc3b1efb93/86fa87fa-2ec8-40e1-ac62-b68dff9c3812.jpg',
		title: 'Inns'
	},
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d8284635bdcfacc3b1eaf0d/f7664f9f-5315-4b8c-892e-6996d3023f77.jpg',
		title: 'Clothing Optional'
	},
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d82849d5bdcfacc3b1f05b1/5db3dc91-cb3e-4d6d-80f4-4d974e96a62b.jpg',
		title: 'Fabulous'
	},
	{
		image:
			'https://photos.mygaygetaway.com/properties/5d82848b5bdcfacc3b1ef01b/b999bfea-9b18-4a46-bfd7-84239bf03ba4.jpg',
		title: 'Resorts'
	}
]
