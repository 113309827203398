/**
 * Extract Data Objects - take input data
 * and extract only values of Array or Object
 * type
 * @param item input data
 * @param map map output value to attribute
 * @returns
 */
export const extractDataObjects = (item: any, map?: any) => {
	return Object.keys(item).reduce((collection: any, attr: string) => {
		const key = map && map[attr] ? map[attr] : attr
		const value = item[attr]
		// console.log({ extractDataObjects: { attr, is: !!value && typeof value === 'object' } })
		if (Array.isArray(value) && value.length > 0) {
			collection[key] = value
		} else if (!!value && typeof value === 'object') {
			collection[key] = value
		}
		return collection
	}, {})
}
