import { gql } from '@apollo/client'

export const LIST_SUBSCRIBERS = gql`
  query ListSubscribers($account: ID, $limit: Int, $offset: Int) {
    listSubscribers(account: $account, limit: $limit, offset: $offset) {
      total
      size
      items {
        user {
          id
          firstName
          lastName
        }
        account {
          id
          name
          featuredImage {
            id
            owner
            filename
          }
        }
      }
    }
  }
`
