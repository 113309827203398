import { useMemo } from 'react'
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Chip,
	Divider,
	Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Image } from '@services/common/components/Image'
import { truncateWords } from '@services/common/helpers/truncate-words'
import { buildRoutePath } from '@services/common/utils/app'
import useImage from '@services/common/hooks/use-image'
import { stripHtml } from '@services/common/utils/strip-html'

const ArticleCard = ({ item }: any) => {
	const classes = useStyles()

	const image = useImage('blog', item.photos[0])

	const url = useMemo(() => {
		return buildRoutePath('blog', item.id)
	}, [item])

	const computedBody = useMemo(() => {
		return item.bodyJson ? stripHtml(item.bodyJson.html) : item.body
	}, [item])

	const renderCategory = () => {
		const cat = item.categories[0]
		if (!cat) {
			return null
		}
		return (
			<Chip
				sx={{
					borderColor: '#979797',
					color: '#979797',
					fontSize: '.8rem',
					'&-label': {
						paddingLeft: 3,
						paddingRight: 3
					}
				}}
				variant="outlined"
				label={cat.name}
			/>
		)
	}

	return (
		<Card
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<CardActionArea component={Link} to={url}>
				<Image aspectRatio={16 / 9} src={image} />
			</CardActionArea>
			<CardContent
				sx={{
					display: 'flex',
					flex: 1,
					flexDirection: 'column'
				}}
			>
				<Typography
					variant="h4"
					sx={{
						fontSize: '1.3rem',
						fontWeight: 700,
						color: '#525252'
					}}
				>
					{item.title}
				</Typography>
				<Divider
					sx={{
						marginTop: 2,
						marginBottom: 2
					}}
				/>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 4
					}}
				>
					<time className={classes.date}>
						{moment(item.date || item.createdAt).format('DD MMM YY')}
					</time>
					{item.categories && renderCategory()}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flex: 1
					}}
				>
					<Typography
						sx={{
							fontSize: '.9rem',
							color: '#979797'
						}}
					>
						{truncateWords(item.teaser || computedBody, 20)}
					</Typography>
				</Box>
			</CardContent>
			<Divider style={{ margin: '0 16px' }} />
			<CardActions>
				<Button
					sx={{
						color: 'rgba(0,0,0,.87)'
					}}
					type="text"
					component={Link}
					to={url}
				>
					Continue Reading
				</Button>
			</CardActions>
		</Card>
	)
}
const useStyles = makeStyles({
	date: {
		fontSize: '.8rem',
		color: '#525252'
	}
})

export default ArticleCard
