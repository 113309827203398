import { gql } from '@apollo/client'
import { EVENT_INFO_FULL_FRAG } from './event-info-full-frag'

export const GET_EVENT = gql`
	query GetEvent($id: ID!) {
		getEvent(id: $id) {
			...EventInfoFull
		}
	}
	${EVENT_INFO_FULL_FRAG}
`
