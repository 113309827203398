import { createContext, useState } from 'react'

export const ContactContext = createContext({
	setMessage: (message: string) => {},
	clearMessage: () => {},
	useContactMessage: () => ''
})

export const ContactProvider = ({ children }: any) => {
	const [formMessage, setFormMessage] = useState('')

	const useContactMessage: any = () => {
		return formMessage
	}

	return (
		<ContactContext.Provider
			value={{
				setMessage: setFormMessage,
				useContactMessage,
				clearMessage: () => setFormMessage('')
			}}
		>
			{children}
		</ContactContext.Provider>
	)
}
