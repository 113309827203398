import React, { useRef, useState, useCallback, FC, useMemo } from 'react'
import { noop } from 'lodash'
import { Box, Grid } from '@mui/material'
import { Media } from '@services/app/types'
import { useMediaSelector } from '@services/media/hooks/use-media-selector'
import { useLoadFormData } from '@form/hooks/use-load-form-data'
import { useUpload } from '@services/media/hooks/use-upload'
import MediaManagerCard from '@services/media/components/MediaManagerCard'
import { GET_PHOTO } from '@services/media/graphql/get-photo'
import DropUpload from './DropUpload'
import Droppable from '../dnd/Droppable'
import {
	DndContext,
	useSensors,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import SortableItem from '../dnd/Sortable'

export interface FormMediaPanelProps {
	droppable?: boolean
	name: string
	resource: string
	form?: any
	data: any
	updateForm?: Function
	aspectRatio?: number
	updateData?: any
	errors?: any
	pushError?: Function
	clearError?: Function
	mediaActions?: any
	mediaSelected?: any
	handleMediaAction?: any
	displayFilename?: boolean
	resourceMap?: Record<string, string>
}

const FormMediaPanel: FC<FormMediaPanelProps> = ({
	droppable = false,
	resource,
	form,
	name,
	updateForm = noop,
	updateData = noop,
	aspectRatio = 1,
	displayFilename = false,
	data = {},
	pushError = noop, // errors = {},
	clearError = noop,
	mediaActions = [],
	mediaSelected = noop,
	handleMediaAction = noop,
	resourceMap = {}
}: FormMediaPanelProps) => {
	const dropRef: any = useRef(null)

	const clickRef: any = useRef(null)

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	)
	// ref for dropzone upload
	const [uploadResource]: any = useState(resourceMap[resource] || resource)

	const { showPrompt, handleDrop }: any = useUpload([dropRef, clickRef])

	const value = useMemo(() => {
		return form[name]
	}, [form, name])

	const images = useMemo(() => {
		const dataRef = data[name]
		return value && value.length > 0
			? value.map((id: string) => dataRef.find((ref: any) => ref.id === id))
			: []
	}, [data, name, value])

	/**
	 * push the uploaded image to the local state and form
	 * @params {object} image
	 */
	const pushImage = useCallback(
		(image) => {
			// check to see if the the record already exists
			const found: any = (images.length > 0 && images.find((x: any) => x.id === image.id)) || false
			// if not found go ahead and push it up the food chain
			if (!found) {
				const newState: any = [...images, image]
				// push to the local component state
				updateData(newState)
				// extract ids from the images
				const formImages: any = newState.map((x: any) => x.id)
				// update the form data
				updateForm({ [name]: formImages })
			}
		},
		[updateForm, updateData, images, name]
	)

	const {
		// open: showMediaSelector,
		close: hideMediaSelector
	}: any = useMediaSelector({
		onSelected: (e: any, item: Media) => {
			pushImage(item)
			hideMediaSelector()
		}
	})

	const pushPhoto = useCallback(
		(fetchedData: any) => {
			const newImages = [...(data[name] || []), fetchedData[name]]
			const ids = newImages.map((image: any) => image.id)
			console.log('pushPhoto', { fetchedData, name, data, form, newImages, ids })
			updateForm({
				formData: { [name]: ids },
				data: { [name]: newImages }
			})
		},
		[updateForm, name, data, form]
	)

	const { fetchFormData }: any = useLoadFormData({
		onComplete: pushPhoto
	})

	const handleCardAction = useCallback(
		(action: any, image: any) => {
			action && updateForm(handleMediaAction({ action, name, item: image, form, data }))
			console.log({ handleCardAction: { action, image } })
		},
		[updateForm, handleMediaAction, name, form, data]
	)

	const handleUploadComplete = useCallback(
		(data: any) => {
			fetchFormData({
				query: GET_PHOTO,
				variables: {
					id: data.id
				},
				action: 'getPhoto',
				attribute: name
			})
		},
		[fetchFormData, name]
	)

	const uploadProps = useMemo(
		() => ({
			path: uploadResource,
			onComplete: handleUploadComplete
		}),
		[handleUploadComplete, uploadResource]
	)

	const handleDragEnd = useCallback(
		({ active, over }: any) => {
			console.log('handleDragEnd', {
				active,
				over,
				contextIndex: value.indexOf(active.id),
				refIndex: value.indexOf(over.id)
			})
			if (active.id !== over.id) {
				const oldIndex = value.indexOf(active.id)
				const newIndex = value.indexOf(over.id)
				const order = arrayMove(value, oldIndex, newIndex)
				console.log({ order, [name]: value })
				updateForm({ [name]: order })
			}
		},
		[name, updateForm, value]
	)

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			<SortableContext items={images}>
				<Box display="flex" sx={styles.container}>
					{images && images.length > 0 && (
						<Droppable id="photos">
							<Grid container spacing={2} style={{ marginBottom: 24 }}>
								{images.map((image: any) => (
									<SortableItem
										key={`image-${image.id}`}
										id={image.id}
										actions={mediaActions}
										handleCardAction={({ event }: any) => handleCardAction(event, image)}
										sx={{
											actions: {
												zIndex: 9
											}
										}}
									>
										<MediaManagerCard
											resource={resource}
											aspectRatio={aspectRatio}
											item={image}
											onClick={mediaSelected}
											displayFilename={displayFilename}
											actions={mediaActions}
											sx={{
												text: styles.text
											}}
										/>
									</SortableItem>
								))}
							</Grid>
						</Droppable>
					)}

					<DropUpload
						uploadRef={dropRef}
						onClick={() => showPrompt(uploadProps)}
						onDrop={handleDrop(uploadProps)}
					/>
				</Box>
			</SortableContext>
		</DndContext>
	)
}

const styles: any = {
	formContent: {},
	formControl: {},
	container: {
		// width: '100%'
		flexDirection: 'column'
	},
	inputField: {
		color: '#666'
	},
	roundButton: {
		position: 'absolute',
		top: 20,
		left: 20,
		borderRadius: 12
	},
	closeIcon: {
		height: 40,
		width: 'auto'
	},

	previewImage: {
		maxHeight: '100vh'
	},
	dialog: {
		color: '#666',
		backgroundColor: '#000',
		'& .MuiDialogContentText-root': {
			color: '#666'
		}
	},
	dialogConfirm: {
		color: '#666'
	},
	input: {
		display: 'none'
	},
	text: {
		width: '100%'
	}
}

export default FormMediaPanel
