import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Container, Grid, Typography } from '@mui/material'
import PageHeader from '@services/common/components/PageHeader'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'
import Promote from '../components/Promote'
import ListForm from '../components/ListForm'
import ListPromote from '../components/ListPromote'
import { PROPERTY_TYPE_BLOCKS } from '../constants'

const ListWithUs = () => {
	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.list-with-us'
		}
	})

	const { data: contentData }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.were-exited-to-see-your-listing'
		}
	})

	const content = useMemo(() => {
		return contentData?.getContentByKey || {}
	}, [contentData])

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content
			})
		}
		return res
	}, [data])

	return (
		<Box sx={styles.root}>
			<PageHeader
				sx={{
					root: styles.header,
					title: styles.title,
					titleBlock: styles.titleBlock,
					text: styles.text
				}}
				{...header}
			/>

			<Promote />

			<ListPromote
				title="From castles to condos, guests book it all"
				items={PROPERTY_TYPE_BLOCKS}
			/>

			<div style={{ backgroundColor: '#F2ECE2' }}>
				<Container style={{ padding: '100px 0' }}>
					<Grid container>
						<Grid item md={7}>
							<Typography sx={{ textTransform: 'uppercase' }}>{content.name}</Typography>
							<Typography
								variant="h3"
								sx={{ '& p': { margin: 0 } }}
								dangerouslySetInnerHTML={{ __html: content.content }}
							/>
						</Grid>
						<Grid item md={1} />
						<Grid item md={4}>
							<ListForm />
						</Grid>
					</Grid>
				</Container>
			</div>
		</Box>
	)
}

const styles: any = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: '60px'
	},
	scrollBehind: {
		position: 'absolute',
		zIndex: -1,
		top: 0,
		right: 0,
		left: 0,
		'&:before': {
			// content: '""',
			// position: 'absolute',
			// top: 0,
			// right: 0,
			// bottom: 0,
			// left: 0,
			// background: 'rgba(0,0,0,.4)',
			// zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: 0,
			left: 0,
			height: '20%',
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
		}
	},
	container: {},
	content: {},
	header: {
		paddingBottom: '100px'
	},
	titleBlock: {
		paddingTop: 10
	},
	title: {
		marginBottom: 4
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	},
	partner: {
		listStyle: 'none',
		flex: 1
	},
	image: {
		padding: 3
	},
	blocks: {
		display: 'flex',
		flexDirection: 'row'
	}
}

export default ListWithUs
