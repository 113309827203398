import { gql } from '@apollo/client'
import { GETAWAY_INFO_FRAG } from './getaway-info-frag'
import { GETAWAY_WITH_PROP_PHOTOS_FRAG } from './getaway-with-prop-photos-frag'

export const LIST_GETAWAYS = gql`
	query ListGetawaysListGetaways(
		$filter: TableGetawayFilterInput
		$limit: Int
		$offset: Int
		$sortAttribute: String
		$sortDirection: String
	) {
		listGetaways(
			filter: $filter
			limit: $limit
			offset: $offset
			sortAttribute: $sortAttribute
			sortDirection: $sortDirection
		) {
			total
			size
			items {
				...GetawayInfo
			}
		}
	}
	${GETAWAY_INFO_FRAG}
`

export const LIST_GETAWAYS_PROPERTIES = gql`
	query ListGetawaysListGetaways(
		$filter: TableGetawayFilterInput
		$limit: Int
		$offset: Int
		$sortAttribute: String
		$sortDirection: String
	) {
		listGetaways(
			filter: $filter
			limit: $limit
			offset: $offset
			sortAttribute: $sortAttribute
			sortDirection: $sortDirection
		) {
			total
			size
			items {
				...GetawayWithPropPhotos
			}
		}
	}
	${GETAWAY_WITH_PROP_PHOTOS_FRAG}
`
