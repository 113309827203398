const extractValue = (value: any) => {
	if (!value) {
		return value
	}
	if (Array.isArray(value)) {
		return value.map((x: any) => x.id || x)
	} else if (typeof value === 'object') {
		return value.id
	}
	return value
}
type RetentionStrategy = boolean | string[]

export const pluck = (pattern: any, context: any, retain: RetentionStrategy = false) => {
	let projection
	if (Array.isArray(pattern)) {
		projection = pattern
	} else if (typeof pattern === 'object') {
		projection = Object.keys(pattern)
	} else if (typeof pattern === 'string') {
		return { [pattern]: context[pattern] }
	} else {
		throw new Error('Invalid pattern')
	}

	const simpleRetentionStrategy = typeof retain === 'boolean'
	const granularRetentionStrategy = Array.isArray(retain) && retain.length > 0
	return projection.reduce((output: any, attr: string) => {
		const value =
			(simpleRetentionStrategy && retain) || (granularRetentionStrategy && retain.includes(attr))
				? context[attr]
				: extractValue(context[attr])

		if (value) {
			output[attr] = value
		}
		return output
	}, {})
}
