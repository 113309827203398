import * as Actions from './events'
import { initialState } from './state'

const updateProgress = (state: any, payload: any) => {
	const newState: any = { ...state }
	newState.uploads[payload.id].bytesLoaded = payload.bytesLoaded
	if (payload.bytesLoaded === payload.totalBytes) {
		newState.uploads[payload.id].complete = true
	}
	return state
}

const bindUpload = (state: any, payload: any) => {
	const newState: any = { ...state }
	newState.uploads[payload.id] = {
		bytesLoaded: payload.bytesLoaded,
		totalBytes: payload.totalBytes,
		complete: false
	}
	return newState
}

const clearUpload = (state: any, id: string) => {
	const uploads: any = { ...state.uploads }
	delete uploads[id]
	return { ...state, uploads }
}

const updateState = (state: any, payload: any) => {
	return { ...state, ...payload }
}
/**
 * reset state
 */
const reset = () => {
	return initialState
}

const reducers = {
	[Actions.BIND]: bindUpload,
	[Actions.PROGRESS]: updateProgress,
	[Actions.UPDATE]: updateState,
	[Actions.CLEAR]: clearUpload,
	[Actions.RESET]: reset
}

export default reducers
