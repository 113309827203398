import { noop } from 'lodash'

export const filePrompt = (onChange: any = noop): void => {
	const el = document.createElement('div')
	el.innerHTML = '<input type="file" multiple>'
	const fileInput: any = el.firstChild

	// console.log(media)
	fileInput.addEventListener('change', () => onChange(fileInput.files))
	fileInput.click()
	fileInput.removeEventListener('change', onChange)
}
