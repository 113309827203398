import { InMemoryCache } from '@apollo/client'

const readField =
	(field: string) =>
		(existing: any, { args: { offset, limit } }: any) => {
			return existing
				? { items: existing.items.slice(offset, offset + limit), limit, offset }
				: existing
		}

const mergeField =
	(field: string) =>
		(
			existing: any,
			incoming: any,
			{ args: { offset = 0, limit } }: any
		) => {
			const merged = existing ? existing.items.slice(0) : []
			const newItems = incoming.items.length
			incoming.items.forEach((item: any, idx: number) => {
				merged[offset + idx] = item
			})
			return {
				size: limit,
				items: merged,
				total: existing ? existing.total + newItems : incoming.total
			}
		}

const createFieldPolicies = (field: string) =>
({
	keyArgs: ['filter', 'sortAttribute', 'sortDirection'],
	read: readField(field),
	merge: mergeField(field)
} as any)

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				listGetaways: createFieldPolicies('listGetaways'),
				listEvents: createFieldPolicies('listEvents'),
				listArticles: createFieldPolicies('listArticles'),
				listDestinations: createFieldPolicies('listDestinations'),
				listListingRequest: createFieldPolicies('listListingRequest')
			}
		}
	}
})
export default cache
