import { gql } from '@apollo/client'

export const ARTICLE_INFO_FRAG = gql`
	fragment ArticleInfo on Article {
		id
		title
		link
		bucket
		author
		body
		bodyJson
		date
		photos {
			id
			originalImage
			image
		}
		categories {
			id
			name
			type
		}
		status
		createdAt
		updatedAt
	}
`
