import { Box } from '@mui/material'
import ItemGrid from '@services/common/components/ItemGrid'
import FeaturedProperty from '@services/property/components/FeaturedProperty'
import PropertyCard from '@services/property/components/PropertyCard'

const FeaturedProperties = ({ items }: any) => {
	return (
		<Box
		// sx={{
		// 	borderRadius: 2.5,
		// 	overflow: 'hidden'
		// }}
		>
			{items.length > 1 ? (
				<ItemGrid items={items} component={PropertyCard} />
			) : (
				<FeaturedProperty item={items[0]} />
			)}
		</Box>
	)
}

export default FeaturedProperties
