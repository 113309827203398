import { useContext, useMemo } from 'react'
import { AppContext } from '@services/app/AppProvider/AppContext'

export const useModal = (context: any = AppContext): any => {
	const { showModal, closeModal, state }: any = useContext(context)

	const modal = useMemo(() => {
		return state ? state.modal : {}
	}, [state])

	return {
		modal,
		showModal,
		closeModal
	}
}
