import { Box } from '@mui/material'
import { Image } from '@services/common/components/Image'

const GetawaysPageHeader = () => {
	return (
		<Box sx={styles.root}>
			<Box flex={1.6} sx={{ ...styles.box, ...styles.marginRight }}>
				<Image
					src="https://photos.mygaygetaway.com/properties/5d8284a45bdcfacc3b1f0c6e/5a5ba9db-ec86-4c1c-a98b-f0e83b480e2f.jpg"
					aspectRatio={310 / 206}
					sx={{
						root: styles.image
					}}
				/>
			</Box>
			<Box sx={{ ...styles.box, ...styles.marginRight, flexDirection: 'column' }}>
				<Image
					src="https://photos.mygaygetaway.com/properties/5d8284615bdcfacc3b1eaad4/b2722dbc-1fce-46ee-9d29-1d4ed417213e.jpg"
					aspectRatio={199 / 132}
					sx={{
						root: { ...styles.image, ...styles.marginBottom }
					}}
				/>
				<Image
					src="https://photos.mygaygetaway.com/properties/5d8284af5bdcfacc3b1f16ef/ff868195-80e0-4c9d-8053-cc5853cddfae.jpg"
					aspectRatio={199 / 132}
					sx={{
						root: styles.image
					}}
				/>
			</Box>
			<Box sx={styles.box} style={{ flex: 1.2 }}>
				<Image
					src="https://photos.mygaygetaway.com/properties/5d8284af5bdcfacc3b1f16ee/a7cd71a6-8fa0-48ad-999b-137403f1aff8.jpg"
					aspectRatio={248 / 165}
					sx={{
						root: styles.image
					}}
				/>
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row'
	},
	marginBottom: {
		marginBottom: 1
	},
	marginRight: {
		marginRight: 1
	},
	box: {
		display: 'flex',
		flex: 1,
		alignItems: 'center'
	},
	image: {
		borderRadius: 2,
		overflow: 'hidden'
	}
}

export default GetawaysPageHeader
