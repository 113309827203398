import { useState, useRef, useEffect, FC, useCallback, useMemo } from 'react'
import {
	Button,
	Popper,
	Paper,
	Grow,
	ClickAwayListener,
	Box,
	IconButton,
	Typography,
	Avatar
} from '@mui/material'
import { noop } from 'lodash'

export interface NavModuleProps {
	icon?: any
	label?: string
	source?: string
	component?: any
	placement?: string
	open?: boolean
	onOpen?: any
	handleClose?: any
	variant?: 'light' | 'dark'
}

const NavModule = ({
	label,
	icon: Icon,
	source,
	component: Component,
	placement = 'bottom',
	onOpen = noop,
	variant
}: any) => {
	// const classes = useStyles()
	const [open, setOpen] = useState(false)
	const anchorRef: any = useRef(null)
	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open)

	const handleToggle = useCallback(() => {
		setOpen((prevOpen) => !prevOpen)
		onOpen(!prevOpen.current)
	}, [prevOpen, onOpen])

	const handleClose = (event: any) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	// function handleListKeyDown(event: any) {
	//   if (event.key === 'Tab') {
	//     event.preventDefault()
	//     setOpen(false)
	//   }
	// }

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus()
		}

		prevOpen.current = open
	}, [open])

	const buttonStyles = useMemo(() => {
		const button: any = { color: '#fff ' }
		if (variant === 'light') {
			button.color = '#ccc'
		}
		return button
	}, [variant])

	console.log({ buttonStyles, variant })

	const RenderModule: FC<NavModuleProps> = (props: any) => <Component {...props} />

	return (
		<Box>
			{Icon ? (
				[
					<IconButton
						ref={anchorRef}
						key="nav-icon"
						aria-label="show 4 new mails"
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={handleToggle}
						sx={buttonStyles}
					>
						{source ? <Avatar sx={{ width: 24, height: 24 }} src={source} /> : <Icon />}
					</IconButton>,
					label && <Typography key="nav-label">{label}</Typography>
				]
			) : (
				<Button
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
				>
					{label}
				</Button>
			)}
			{Component && anchorRef.current && (
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
					placement={placement}
					style={{ top: 60 }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<div>
										<RenderModule />
									</div>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
		</Box>
	)
}

export default NavModule
