import { gql } from '@apollo/client'

export const LIST_PERMISSIONS = gql`
	query listPermissions($filter: TablePermissionFilterInput, $limit: Int, $offset: Int) {
		listPermissions(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				id
				name
				key
				status
				createdBy {
					id
					firstName
					lastName
				}
				createdAt
				updatedAt
			}
		}
	}
`
