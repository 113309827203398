import { gql } from '@apollo/client'
import { EVENT_INFO_FULL_FRAG } from '@services/event/graphql/event-info-full-frag'
import { GETAWAY_WITH_PROP_PHOTOS_FRAG } from '@services/getaway/graphql/getaway-with-prop-photos-frag'
import { PROPERTY_INFO_FRAG } from '@services/property/graphql/property-info-frag'

export const SEARCH_REQUEST = gql`
	query Search($term: String, $limit: Int, $offset: Int) {
		search(term: $term, limit: $limit, offset: $offset) {
			# total
			# size
			items {
				... on Property {
					...PropertyInfo
				}
				... on Getaway {
					...GetawayWithPropPhotos
				}
				... on Event {
					...EventInfoFull
				}
			}
		}
	}
	${PROPERTY_INFO_FRAG}
	${GETAWAY_WITH_PROP_PHOTOS_FRAG}
	${EVENT_INFO_FULL_FRAG}
`
