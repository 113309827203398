// clean up some of the null values that may comeback
export const nullDefault = (state: any, item: any) => {
	const mux: any = { ...item }

	Object.keys(mux).forEach((key: string) => {
		if (!mux[key]) {
			mux[key] = state[key]
		}
	})
	return mux
}
