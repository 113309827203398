import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Image } from '@services/common/components/Image'
import { buildRoutePath } from '@services/common/utils/app'
import useImage from '@services/common/hooks/use-image'

const Destination = ({ item }: any) => {
	const image = useImage('destinations', item.images[0])

	const location = useMemo(() => {
		return item.location.city || item.location.state || item.location.country
	}, [item])

	const searchLink = useMemo(() => {
		const loc = item.location
		if (loc.city && loc.country) {
			return `${loc.city},${loc.country}`
		}
		return item.location.city || item.location.state || item.location.country
	}, [item])


	return (
		<Box sx={styles.root}>
			<Link to={buildRoutePath('search', searchLink)}>
				<Image
					src={image}
					aspectRatio={1}
					sx={{
						root: styles.image
					}}
				/>
			</Link>

			<Typography
				variant="h5"
				sx={{
					fontSize: 15,
					fontWeight: 'bold',
					textAlign: 'center'
				}}
			>
				{location}
			</Typography>
		</Box>
	)
}

const styles = {
	root: {
		position: 'relative'
	},
	image: {
		borderRadius: '50%',
		overflow: 'hidden',
		marginBottom: 2
	}
}

export default Destination
