export interface MediaUpload {
	id: string
	status: string
	upload: MediaUploadProtocol
}

export type MediaUploadProtocol = MediaUploadDefault | MediaMultipartUpload

export interface MediaUploadDefault {
	size: number
}

export interface MediaMultipartUpload {
	urls: string[]
	parts: MediaMultipartUploadPart[]
}

export interface MediaMultipartUploadPart {
	eTag: string
	size: number
	part: number
}

export interface MediaState {
	uploads?: any
}
export const initialState: any = {
	uploads: {}
}
