import { gql } from '@apollo/client'
import { IMAGE_INFO_FRAGMENT } from '@services/media/graphql/fragments'
import { BASIC_USER_INFO_FRAGMENT, USER_INFO_FRAGMENT } from './fragments'

export * from './list-users'

export const GET_ALL_USERS = gql`
	query listUsers($filter: TableUserFilterInput, $limit: Int, $nextToken: String) {
		listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				...UserInfo
			}
		}
	}
	${USER_INFO_FRAGMENT}
`
/**
 * @deprecated use LIST_USERS
 */
export const GET_USERS = gql`
	query listUsers($filter: TableUserFilterInput, $limit: Int, $offset: Int) {
		listUsers(filter: $filter, limit: $limit, offset: $offset) {
			items {
				...UserInfo
				featuredImage {
					...ImageInfo
				}
			}
		}
	}
	${IMAGE_INFO_FRAGMENT}
	${USER_INFO_FRAGMENT}
`

export const ME_QUERY = gql`
	query {
		me @client {
			...BasicUserInfo
		}
	}
	${IMAGE_INFO_FRAGMENT}
	${BASIC_USER_INFO_FRAGMENT}
`

export const GET_USER = gql`
	query getUser($id: ID!) {
		getUser(id: $id) {
			...UserInfo
		}
	}
	${IMAGE_INFO_FRAGMENT}
	${USER_INFO_FRAGMENT}
`

export const CONTEXT_USER = gql`
	query {
		me {
			id
			firstName
			lastName
			email
			account {
				id
				name
			}
		}
	}
`

export const UPDATE_USER = gql`
	mutation updateUser($input: CreateUserInput!) {
		updateUser(input: $input) {
			id
			firstName
			lastName
			email
		}
	}
`

export const GET_CACHE_USER = gql`
	query {
		user
	}
`

export const SET_LOCATION = gql`
	mutation setUserLocation($input: CreateUserLocationInput!) {
		setUserLocation(input: $input) {
			id
			latitude
			longitude
		}
	}
`
