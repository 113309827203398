const updateLocationField = (data: any) => {
	if (data.location) {
		const location: any = { ...data.location }
		if (data.location.latitude) {
			location.latitude = data.location.latitude.toString()
		}
		if (data.location.longitude) {
			location.longitude = data.location.longitude.toString()
		}
		delete location.__typename
		data.location = location
	}
}
export default updateLocationField
