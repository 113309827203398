import { useState, useCallback, FC } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LoginForm } from './LoginForm'
import { SignUpForm } from './SignUpForm'
import { useAuth } from '@services/auth/hooks/use-auth'
import { Loading } from '@services/common/components'

const screens: string[] = ['login', 'signup', 'password']

export interface TabPanelProps {
	children?: any
	index: any
	value: any
}

export const TabPanel: FC<TabPanelProps> = ({
	children,
	index,
	value,
	...other
}: TabPanelProps) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	)
}

export interface AuthDialogProps {
	form?: any
}

export const AuthDialog: FC<AuthDialogProps> = ({ form, ...other }: AuthDialogProps) => {
	// console.log('auth dialog', data)
	const [value, setValue] = useState(form ? screens.indexOf(form) : 0)
	const auth: any = useAuth()
	const classes: any = useStyles()

	const switchForm = useCallback(
		(form: string, e: any) => {
			e.preventDefault()
			const idx: number = screens.indexOf(form)
			// console.log('switch form', { form, idx })
			if (idx !== -1 && idx !== value) {
				setValue(idx)
			}
		},
		[value, setValue]
	)

	return (
		<div className={classes.root}>
			{auth.loading && <Loading />}
			<TabPanel value={value} index={0}>
				<LoginForm {...other} switchForm={switchForm} />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<SignUpForm {...other} switchForm={switchForm} />
			</TabPanel>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 0,
		position: 'relative'
	}
}))
