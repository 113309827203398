import { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/lab'
import classNames from 'classnames'
import { BaseCssClasses, TitleCssClasses, BodyCssClasses } from '../../types/base'

export type MediaBlockContentClasses = BaseCssClasses & TitleCssClasses & BodyCssClasses

export interface MediaBlockContentProps {
	title?: string
	body?: string
	/**
	 * @deprecated use classes.root
	 */
	rootClassName?: string
	/**
	 * @deprecated use classes.title
	 */
	titleClassName?: string
	/**
	 * @deprecated use classes.body
	 */
	bodyClassName?: string
	loading?: boolean
	classes?: MediaBlockContentClasses
	component?: any
	children?: any
	sx?: any
}

export const MediaBlockContent: FC<MediaBlockContentProps> = ({
	title,
	body,
	rootClassName,
	titleClassName,
	bodyClassName,
	loading = false,
	component: Component,
	classes: injectedClasses = {},
	children,
	sx = {}
}: MediaBlockContentProps) => {
	const classes: any = useStyles()

	return (
		<Box sx={sx.root} className={classNames(classes.root, rootClassName, injectedClasses.root)}>
			{title &&
				(loading ? (
					<Skeleton sx={sx.loadingTitle} className={classes.loadingTitle} />
				) : (
					<Typography
						variant="h4"
						sx={sx.title}
						className={classNames(injectedClasses.title, classes.title, titleClassName)}
					>
						{title}
					</Typography>
				))}
			{body &&
				(loading ? (
					<Skeleton sx={sx.loadingBody} className={classes.loadingBody} />
				) : (
					<Typography
						sx={sx.body}
						className={classNames(classes.body, bodyClassName, injectedClasses.body)}
					>
						{body}
					</Typography>
				))}
			{Component && <Component />}
			{children}
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	title: {},
	body: {},
	loadingTitle: {},
	loadingBody: {
		height: 88
	}
}))
