import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { FC } from 'react'

export interface TitleProps {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}

export interface TextBlockClasses {
	root?: string
	title?: string
	text?: string
	/**
	 * @deprecated use text property
	 */
	body?: string
}

export interface TextBlockProps {
	title?: string
	text?: string
	/**
	 * @deprecated use text property
	 */
	body?: string
	classes?: TextBlockClasses
	component?: any
	/**
	 * @deprecated
	 */
	rootClassName?: string
	/**
	 * @deprecated
	 */
	titleClassName?: string
	/**
	 * @deprecated
	 */
	bodyClassName?: string
	titleProps?: TitleProps
}

const TextBlock: FC<TextBlockProps> = ({
	title,
	body,
	text = body,
	rootClassName,
	titleClassName,
	bodyClassName,
	classes: injectedClasses = {},
	component = 'div',
	titleProps = {
		variant: 'h4'
	}
}: TextBlockProps) => {
	const classes = useStyles()

	return (
		<Box
			component={component}
			className={classNames(injectedClasses.root, classes.root, rootClassName)}
		>
			<Typography
				variant={titleProps.variant}
				className={classNames(injectedClasses.title, classes.title, titleClassName)}
			>
				{title}
			</Typography>
			<Typography
				className={classNames(
					injectedClasses.text,
					injectedClasses.body,
					classes.text,
					bodyClassName
				)}
			>
				{text}
			</Typography>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {},
	text: {}
}))
/**
 * @deprecated use default export
 */
export { TextBlock }
export default TextBlock
