import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { noop } from 'lodash'
import { Box, Button, Collapse, TextField, Theme, Autocomplete } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LocationFields from './LocationFields'
import { COMPONENT_FORM, locationState, LOC_MAP, PLACES_FIELDS_RESPONSE } from '../constants/form'

const getOptionLabel = (option: any) => {
	return option.text || option
}

export interface AutoCompleteFieldProps {
	label?: string
	initialState?: string
	fieldsVisible?: boolean
	canEditFields?: boolean
	map?: any
	locationUpdated?: any
	defaultFormState?: any
	handleInputChange?: any
}

const AutoCompleteField = forwardRef(
	(
		{
			defaultFormState,
			label = 'Address',
			initialState = '',
			fieldsVisible: defaultFieldsVisible = false,
			canEditFields = false,
			map,
			locationUpdated = noop
		}: AutoCompleteFieldProps,
		ref: any
	) => {
		const [autocompleteService, setAutocompleteService]: any = useState(undefined)
		const [fieldsVisible, setFieldsVisible]: any = useState(defaultFieldsVisible)
		const [placesService, setPlacesService]: any = useState(undefined)
		const [form, setForm]: any = useState(defaultFormState || locationState)
		const [searchTerm, setSearchTerm]: any = useState(initialState)
		const [data, setData]: any = useState([])

		const classes = useStyles()

		const connectPlaces = useCallback(() => {
			if (!placesService) {
				const service = new google.maps.places.PlacesService(map)
				setPlacesService(service)
			}
		}, [placesService, setPlacesService, map])

		const connectAutocomplete = useCallback(() => {
			if (!autocompleteService) {
				const service = new google.maps.places.AutocompleteService()
				setAutocompleteService(service)
			}
		}, [autocompleteService, setAutocompleteService])

		useEffect(() => {
			if (map) {
				connectPlaces()
			}
		}, [connectPlaces, map])

		useEffect(() => {
			connectAutocomplete()
		}, [connectAutocomplete])

		useEffect(() => {
			console.log({ placesService, autocompleteService })
		}, [placesService, autocompleteService])

		const onPredictionChange = useCallback(
			(predictions: google.maps.places.AutocompletePrediction) => {
				if (predictions) {
					setData(predictions)
				}
			},
			[setData]
		)

		const onInputChange = useCallback(
			(e: any) => {
				if (!e || !e.target) {
					return
				}
				const { target } = e
				if (form.address === target.value.text) {
					return
				}
				const criteria = target.value
				console.log('onInputChange', { criteria })
				if (searchTerm !== criteria) {
					setSearchTerm(criteria)
					autocompleteService.getPlacePredictions(
						{
							input: criteria
						},
						onPredictionChange
					)
				}
			},
			[form.address, searchTerm, autocompleteService, onPredictionChange]
		)

		const onPlaceDetails = useCallback(
			(place: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
				const address: any = {}

				if (place.address_components) {
					Object.assign(
						address,
						place.address_components.reduce((o: any, i: any) => {
							const type: string = i.types[0]
							if (COMPONENT_FORM[type]) {
								const val = i[COMPONENT_FORM[type]]
								o[LOC_MAP[type]] = val
							}
							return o
						}, {})
					)
				}
				if (place.url) {
					address.googleMap = place.url
				}
				if (place.geometry) {
					const location: any = place.geometry.location
					address.latitude = location.lat()
					address.longitude = location.lng()
				}
				// console.log('place', place);
				if (place.formatted_address) {
					address.address = place.formatted_address
				}
				setForm(address)
				locationUpdated(address)
			},
			[setForm, locationUpdated]
		)

		const handleInputChange = useCallback(
			({ target }) => {
				const address = { ...form }
				address[target.name] = target.value
				setForm(address)
				locationUpdated(address)
			},
			[form, setForm, locationUpdated]
		)

		const onChange = useCallback(
			(evt: any, { value, text }: any, reason) => {
				console.log('onChange', { evt, value, text, reason })
				if (!value || !value.place_id) {
					return
				}
				placesService.getDetails(
					{
						placeId: value.place_id,
						fields: PLACES_FIELDS_RESPONSE
					},
					onPlaceDetails
				)
			},
			[placesService, onPlaceDetails]
		)

		const toggleFields = useCallback(() => {
			setFieldsVisible(!fieldsVisible)
		}, [fieldsVisible, setFieldsVisible])

		const defaultValueProxy = useMemo(() => {
			return form.address
				? {
					text: form.address,
					value: {
						description: form.address
					}
				}
				: undefined
		}, [form])

		const dataProxy = useMemo(() => {
			return data.length === 0
				? defaultValueProxy
					? [defaultValueProxy]
					: data
				: data.map((item: any) => ({
					text: item.description,
					value: item
				}))
		}, [data, defaultValueProxy])
		// console.log('AutocompleteField', { defaultValueProxy, defaultFormState, data, dataProxy })
		return (
			<Box className={classes.root}>
				{form && (
					<>
						<Autocomplete
							ref={ref}
							options={dataProxy}
							onInputChange={onInputChange}
							onChange={onChange}
							getOptionLabel={getOptionLabel}
							isOptionEqualToValue={(option: any, value: any) => value === option.text}
							value={form.address}
							renderInput={(renderProps: any) => (
								<TextField {...renderProps} name="location" label={label} variant="outlined" />
							)}
						/>
						{canEditFields &&
							<>
								<Button color="secondary" onClick={toggleFields} className={classes.button}>
									{fieldsVisible ? 'Hide' : 'Show'} Address Fields
								</Button>
								<Collapse in={fieldsVisible}>
									<LocationFields disabled={false} handleInputChange={handleInputChange} form={form} />
								</Collapse>
							</>
						}
					</>
				)}
			</Box>
		)
	}
)

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	button: {
		marginTop: theme.spacing(1)
	}
}))

export default AutoCompleteField
