import { FC } from 'react'
import { Backdrop, CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'

export interface AppLoaderProps {
	open?: boolean
	handleClose?: any
}

export const AppLoader: FC<AppLoaderProps> = ({
	open = false,
	handleClose = noop
}: AppLoaderProps) => {
	const classes = useStyles()

	return (
		<Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
			{open && <CircularProgress color="inherit" />}
		</Backdrop>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	}
}))
