import { useMemo, useRef } from 'react'
import * as actions from './actions'
import reducers from './reducers'
import { FormData, FormStateData, StateManager, StateManagerOptions } from './types'
import { prepareFormState } from './utils'
import { defaultStateManager } from './utils/default-handlers'

export interface UseFormStateProps {
	initialState: FormData
	data?: FormData
	record?: FormData
	prepareState?: (data: FormData) => FormStateData
	stateManager?: (options: StateManagerOptions) => StateManager
	client?: any
	retentionRules?: string[]
}

export const useFormState = ({
	initialState = {},
	data = {},
	record = {},
	prepareState = (formData: any) => formData,
	stateManager = defaultStateManager,
	client = undefined,
	retentionRules = []
}): any => {
	const stateRef: any = useRef(
		prepareState(prepareFormState({ record, initialState, data, retentionRules }))
	)
	const { state, ...rest } = stateManager({
		initialState: stateRef.current,
		actions,
		reducers,
		client
	})

	const formMemorized = useMemo(() => {
		return state.form
	}, [state.form])

	const dataMemorized = useMemo(() => {
		return state.data
	}, [state.data])

	const errorsMemorized = useMemo(() => {
		return state.errors
	}, [state.errors])

	return {
		form: formMemorized,
		data: dataMemorized,
		errors: errorsMemorized,
		...rest
	}
}
