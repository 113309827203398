import { gql } from '@apollo/client'

export const LIST_PROPERTY_TYPES = gql`
	query ListPropertyTypes($filter: TablePropertyTypeFilterInput, $limit: Int, $offset: Int) {
		listPropertyTypes(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				id
				name
				createdAt
				updatedAt
			}
		}
	}
`
