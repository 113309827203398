import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import client from '@graphql/client'
import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@mui/styles'
import { createTheme } from '@mui/material'
import Lightbox from 'simple-react-lightbox'
import { App } from '@services/app/views/App'
import { AppProvider } from '@services/app/AppProvider/AppProvider'
import { AuthProvider } from '@services/auth/AuthProvider/AuthProvider'
import { MediaProvider } from '@services/media/MediaProvider/MediaProvider'
import BookingProvider from '@services/booking/BookingProvider'
import ScrollTop from '@services/app/components/ScrollTop'
import { ContactProvider } from '@services/app/views/ContactContext'
import { theme as baseTheme } from '@theme'
import { store } from '@services/store'
import config from '@core/environment'
import { mainNavigation } from './constants/navigation'

import 'app/styles/app.scss'

const theme = createTheme(baseTheme)
const container: any = document.getElementById('root')
const root = createRoot(container)

root.render(
	<ReduxProvider store={store}>
		<ApolloProvider key="graphql-provider" client={client}>
			<BrowserRouter>
				<SnackbarProvider maxSnack={5}>
					<ContactProvider>
						<BookingProvider>
							<AppProvider
								key="app-provider"
								appName={config.name}
								description={config.description}
								navigation={mainNavigation}
								logo={config.brandIcon}
								features={config.features}
							>
								<AuthProvider key="auth-provider">
									<MediaProvider key="media-provider">
										<ThemeProvider theme={theme}>
											<Lightbox>
												<ScrollTop>
													<App />
												</ScrollTop>
											</Lightbox>
										</ThemeProvider>
									</MediaProvider>
								</AuthProvider>
							</AppProvider>
						</BookingProvider>
					</ContactProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</ApolloProvider>
	</ReduxProvider>
)
