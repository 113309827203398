import { Button, Box, Typography, Link, Collapse, Alert, Stack } from '@mui/material'
import { noop } from 'lodash'
import * as rules from '@form/utils/validation'
import { useAuth } from '@services/auth/hooks/use-auth'
import { useForm } from 'app/hooks/use-form'
import TextField from '@form/components/fields/TextField'

const initialState: any = {
	email: '',
	password: ''
}

export interface LoginFormProps {
	header?: string
	rootClassName?: string
	headerClassName?: string
	switchForm?: Function
	signupEnabled?: boolean
}

export const LoginForm = ({
	switchForm = noop,
	data,
	header = 'Sign In',
	rootClassName,
	headerClassName,
	signupEnabled
}: any) => {

	const { login, error: authenticationError }: any = useAuth()
	const { form, handleInputChange, pushError, clearError, errors, validateForm }: any = useForm({
		form: initialState,
		onSubmit: login
	})

	return (
		<Box display="flex" flex={1} sx={styles.root} className={rootClassName}>
			<Box sx={styles.header}>
				<Typography variant="h2" sx={styles.header} className={headerClassName}>
					{header}
				</Typography>

				<Collapse in={!!authenticationError}>
					<Alert severity="error">Invalid Email / Password</Alert>
				</Collapse>
			</Box>
			<form style={styles.form} noValidate onSubmit={validateForm}>
				<Stack gap={2}>
					<TextField
						sx={styles.input}
						value={form.email}
						autoFocus
						required
						fullWidth
						label="Email Address"
						name="email"
						variant="outlined"
						error={!!errors.email}
						onChange={handleInputChange}
						validation={rules.required}
						errorMessage="Please enter your Email Address"
						onFocus={clearError}
						onError={pushError}
					/>
					<TextField
						sx={styles.input}
						value={form.password}
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						variant="outlined"
						error={!!errors.password}
						onChange={handleInputChange}
						validation={rules.required}
						errorMessage="Please enter your Password"
						onFocus={clearError}
						onError={pushError}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						sx={styles.submit}
					>
						Sign In
					</Button>

					{signupEnabled && (
						<>
							{/* <Link
								sx={styles.link}
								onClick={switchForm.bind(null, 'password')}
								color="textPrimary"
							>
								Forgot password?
							</Link> */}
							<Link
								sx={styles.link}
								onClick={switchForm.bind(null, 'signup')}
								color="textPrimary"
							>
								Don't have an account? Sign Up
							</Link>
						</>
					)}
				</Stack>
			</form>
		</Box>
	)
}

const styles = {
	root: {
		display: 'inline-block',
		flex: 'none'
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	header: {
		fontSize: 24,
		marginBottom: 3,
		fontWeight: 800
	},
	formWrap: {
		justifyContent: 'center',
		flex: 1,
		maxWidth: 300,
		borderRadius: 2
	},
	avatar: {
		margin: 8,
		backgroundColor: '#666',
		display: 'inline-flex'
	},
	input: {
		'&:-webkit-autofill': {
			WebkitTransitionDelay: '9999999s'
		}
	},
	form: {
		width: 300
	},
	socialLogin: {
		color: '#fff'
	},
	submit: {
		// margin: [3, 0, 2],
		padding: [1.5, 2],
		borderRadius: 2,
		backgroundColor: '#f50057',
		fontWeight: 600,
		border: '2px solid #f50057',
		'&:hover': {
			border: '2px solid #f50057',
			backgroundColor: '#fff',
			color: '#f50057'
		}
	},
	link: {
		color: '#90CAF9',
		fontSize: 13,
		textDecoration: 'none'
	}
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid green;
//   -webkit-text-fill-color: green;
//   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
//   transition: background-color 5000s ease-in-out 0s;
// }
