import { gql } from '@apollo/client'
import { PROPERTY_BASIC_FRAG } from './property-basic-frag'
import { PROPERTY_INFO_FRAG } from './property-info-frag'

export const LIST_PROPERTIES = gql`
	query ListProperties($filter: TablePropertyFilterInput, $limit: Int, $offset: Int) {
		listProperties(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				...PropertyInfo
			}
		}
	}
	${PROPERTY_INFO_FRAG}
`
export const LIST_PROPERTIES_BASIC = gql`
	query ListProperties($filter: TablePropertyFilterInput, $limit: Int, $offset: Int) {
		listProperties(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				...PropertyBasic
			}
		}
	}
	${PROPERTY_BASIC_FRAG}
`
