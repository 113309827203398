import { isLoggedInResolver } from './is-logged-in'

export const resolvers = {
  Mutation: {},
  Query: {
    isLoggedIn: isLoggedInResolver
  },
  User: {
    session() {
      return {
        __typename: 'Session',
        isLoggedIn: () => !!localStorage.getItem('token'),
        connected: () => true,
        errors: () => []
      }
    }
  }
}
