import { useMemo, useState } from 'react'
import { Box, Card, CardActionArea, CardMedia, Typography } from '@mui/material'
import { AspectRatio } from '@services/common/components/AspectRatio'
import { noop } from 'lodash'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import { Room } from '@mui/icons-material'
import { buildRoutePath } from '@services/common/utils/app'
import { useRedirect } from '@services/common/hooks'
import { renderLocation } from '@services/common/helpers/render-location'

export interface PropertyCardProps {
	item?: any
	onClick?: any
	sx?: any
	showAddress?: boolean
}

const PropertyCard = ({ item, sx = {}, onClick = noop, showAddress }: PropertyCardProps) => {
	const { redirect } = useRedirect()
	const [url]: any = useState(buildRoutePath('properties', item.id))
	console.log({ PropertyCard: item })

	const location = useMemo(() => {
		return item.location
	}, [item])

	const image = useMemo(() => {
		try {
			return item.photos[0]
		} catch (e) {
			return ''
		}
	}, [item])

	return (
		<Box sx={{ ...styles.root, ...sx.root }}>
			<Card sx={{ ...styles.card, ...sx.card }}>
				<CardActionArea onClick={() => redirect(url)} sx={sx.cardActionArea}>
					<AspectRatio ratio={16 / 9} sx={sx.aspectRatio}>
						<Box sx={{ ...styles.content, ...sx.content }}>
							<Room style={{ color: '#fff', height: 20 }} />
							<Typography sx={{ ...styles.text, ...sx.text }}>{renderLocation(location)}</Typography>
						</Box>
						<CardMedia component="img" image={buildS3ImageUrl('properties', image)} />
					</AspectRatio>
				</CardActionArea>
			</Card>
			<Box>
				<Typography variant="h3" sx={{ ...styles.title, ...sx.title }}>
					{item.name}
				</Typography>
				{showAddress && item.location && item.location.address && <Typography sx={{ fontSize: '.8rem' }}>{item.location.address}</Typography>}
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		marginBottom: 3
	},
	image: {},
	title: {
		fontSize: '1.1rem',
		fontWeight: 500
	},
	text: {
		color: '#fff',
		fontSize: '.9rem',
		textTransform: 'uppercase'
	},
	date: {
		fontSize: '.8rem'
	},
	card: {
		marginBottom: 2
	},
	content: {
		position: 'absolute',
		display: 'flex',
		flex: 1,
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		padding: 1,
		alignItems: 'flex-end',
		background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 100%)'
	}
}

export default PropertyCard
