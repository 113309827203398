import moment from 'moment'
import config from '@core/environment'

const NAMESPACE = 'mygaygetaway'

const prefix = (args: any, status: string = '') => {
	const ts: string = moment().toISOString()
	const app = `[${NAMESPACE}]`
	const strFormat: string = typeof args[0] === 'string' ? args.shift() : ''
	return [ts, app, status, strFormat].filter((x: any) => !!x).join(' ')
}

export class Logger {
	private stack: any
	private enabled: boolean

	constructor(enabled: boolean) {
		this.stack = []
		this.enabled = true
	}

	log(...args: any) {
		// this.stack.push([new Date(), ...args])
		if (this.enabled) {
			console.log(prefix(args), ...args)
		}
	}

	error(...args: any) {
		// this.stack.push([new Date(), 'ERROR', ...args])
		if (this.enabled) {
			console.error(prefix(args, 'ERROR'), ...args)
		}
	}

	debug(...args: any) {
		// this.stack.push([new Date(), ...args])
		if (this.enabled) {
			console.debug(prefix(args), ...args)
		}
	}

	success(...args: any) {
		this.log(`%c ${JSON.stringify(args)}`, 'color: green;')
	}

	get print(): any {
		if (this.enabled) {
			const { stack } = this
			for (let i = 0, n = stack.length; i < n; i++) {
				console.log(stack[i])
			}
		}
		return JSON.stringify(this.stack, null, 2)
	}
}

export const logger = new Logger(config.environment === 'development')
export default Logger