import { gql } from '@apollo/client'
import { GETAWAY_INFO_FRAG } from './getaway-info-frag'
import { GETAWAY_WITH_PROP_PHOTOS_FRAG } from './getaway-with-prop-photos-frag'

export const GET_GETAWAY = gql`
	query getGetaway($id: ID!) {
		getGetaway(id: $id) {
			...GetawayInfo
		}
	}
	${GETAWAY_INFO_FRAG}
`
export const GET_GETAWAY_PROPERTY = gql`
	query getGetaway($id: ID!) {
		getGetaway(id: $id) {
			...GetawayWithPropPhotos
		}
	}
	${GETAWAY_WITH_PROP_PHOTOS_FRAG}
`
