import Typography from '@mui/material/Typography'
import Image from '@services/common/components/Image'

const Block = ({ image, title, ratio = 16 / 10 }: any) => {
	return (
		<div style={styles.root}>
			<Image
				aspectRatio={ratio}
				sx={{
					root: styles.ratio,
					filled: styles.image
				}}
				src={image}
			/>

			<Typography variant="h4" sx={styles.title}>
				{title}
			</Typography>
		</div>
	)
}

const styles: any = {
	root: {
		width: 'calc(((100%) - (32px * 6)) / 7)'
	},
	ratio: {
		borderRadius: 8,
		overflow: 'hidden',
		marginBottom: 16
	},
	image: {
		width: '100%',
		height: '100%'
	},
	title: {
		fontSize: '1rem',
		fontWeight: 'bold'
	}
}

export default Block
