import { Box, Button, Stack, Typography } from '@mui/material'
import { DEFAULT_COLORS } from '@services/app/constants/app'
import { noop } from 'lodash'

const ResultSet = ({
	title,
	items = [],
	component: Component,
	data,
	columns = 3,
	style = {},
	sx = {},
	direction = 'row',
	onShowAll = noop,
	showAll = false
}: any) => {
	return (
		<Box sx={{ ...styles.root, ...sx.root }}>
			<Box sx={styles.container}>
				<Typography variant="h4" sx={styles.title}>
					{title} {
						showAll &&
						<Button
							variant="outlined"
							size="small"
							sx={{
								borderColor: DEFAULT_COLORS.primary,
								color: DEFAULT_COLORS.primary,
								'&:hover': {
									borderColor: DEFAULT_COLORS.primary,
									backgroundColor: DEFAULT_COLORS.primary,
									color: '#fff'
								}
							}}
							onClick={onShowAll}>
							Show all {title}
						</Button>
					}
				</Typography>
				<Box sx={styles.list}>
					<Stack direction={direction} gap={1} sx={styles.list}>
						{items.map((item: any) => (
							<Box key={`result-item-${item.id}`} sx={sx.item}>
								<Component sx={sx.component} item={item} {...data} />
							</Box>
						))}
					</Stack>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	root: {
		// marginBottom: theme.spacing(6)
	},
	container: {},
	title: {
		fontSize: 19,
		fontWeight: 600,
		marginBottom: 2,
		display: 'flex',
		justifyContent: 'space-between'
	},
	list: {
		width: '100%',
		overflowX: 'scroll'
	}
}

export default ResultSet
