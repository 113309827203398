import { ReactNode } from 'react'
import { Box, Container, Typography } from '@mui/material'

export interface PageHeaderProps {
	children?: ReactNode | ReactNode[]
	classes?: any
	title?: string
	text?: string
	sx?: any
}

const PageHeader = ({
	children,
	classes: injectedClasses = {},
	title,
	text,
	sx = {}
}: PageHeaderProps) => {
	return (
		<Box sx={{ ...styles.root, ...sx.root }} className={injectedClasses.root}>
			<Container
				maxWidth="lg"
				sx={{ ...styles.container, ...sx.container }}
				className={injectedClasses.container}
			>
				<Box
					sx={{
						...styles.titleBlock,
						...sx.titleBlock
					}}
					className={injectedClasses.titleBlock}
				>
					{title && (
						<Typography
							variant="h1"
							sx={{
								...styles.title,
								...sx.title
							}}
							className={injectedClasses.title}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
					)}
					{text && (
						<Typography
							sx={{ ...styles.text, ...sx.text }}
							className={injectedClasses.text}
							dangerouslySetInnerHTML={{ __html: text }}
						/>
					)}
				</Box>
				<Box sx={{ ...styles.content, ...sx.content }} className={injectedClasses.content}>
					{children}
				</Box>
			</Container>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row'
	},
	titleBlock: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column'
	},
	content: {
		display: 'flex',
		flex: 1
	},
	title: {
		fontSize: '2.2rem',
		fontWeight: 'bold'
	},
	text: {}
}

export default PageHeader
