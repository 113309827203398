import { gql } from '@apollo/client'

export const EVENT_INFO_FRAG = gql`
	fragment EventInfo on Event {
		id
		name
		bucket
		information
		link
		featured
		startDate
		endDate
		# country {
		# 	id
		# 	name
		# 	__typename
		# }
		# state {
		# 	id
		# 	name
		# 	__typename
		# }
		# city
		location {
			streetNumber
			route
			googleMap
			address
			country
			state
			city
			zip
			latitude
			longitude
		}
		properties {
			id
			name
			__typename
		}
		photos {
			id
			resource
			image
			originalImage
			__typename
		}
		categories {
			id
			name
		}
		createdAt
		updatedAt
		__typename
	}
`
