import { Typography, Container, Stack } from '@mui/material'
import Block from './Block'

const ListPromote = ({ title, items = [] }: any) => {
	return (
		<div style={{}}>
			<Container sx={{ padding: '100px 0' }}>
				<Typography
					variant="h3"
					style={{ marginBottom: 24, fontWeight: 'bold', fontSize: '2.3rem' }}
				>
					{title}
				</Typography>
				{items.length > 0 && (
					<Stack
						style={{
							display: 'flex',
							flexDirection: 'row'
						}}
						gap={2}
					>
						{items.map((item: any) => (
							<Block key={`block-item-${item.title}`} {...item} />
						))}
					</Stack>
				)}
			</Container>
		</div>
	)
}
export default ListPromote
