import { Fragment, Suspense } from 'react'
import Grid from '@mui/material/Grid'
import { useRoutes } from 'react-router-dom'
import { DrawerPosition } from '@services/app/AppProvider/types'
import ModalConductor from '@services/common/components/ModalConductor'
import { AppLoader } from '@services/common/components/AppLoader'
import { Loading } from '@services/common/components'
import { DrawerConductor } from '@services/common/components/DrawerConuctor'
import { useApp } from '../hooks/use-app'
import { Header } from '../../header/components/Header'
import Footer from './Footer'
import routes from '../constants/routes'

const AppRouter = (props: any) => {
	const { loading, handleClose }: any = useApp()
	const elements = useRoutes(routes)

	return (
		<Fragment>
			<Grid container>
				<Header
					fixed
					changeColorOnScroll={{
						height: 40,
						variant: 'light'
					}}
					{...props}
				/>
				<main style={{ width: '100%' }}>
					<Suspense fallback={<Loading overlay={false} />}>{elements}</Suspense>
				</main>
				<Footer />
			</Grid>
			<ModalConductor />

			<DrawerConductor position={DrawerPosition.Right} />
			<DrawerConductor position={DrawerPosition.Bottom} />

			<AppLoader open={loading} handleClose={handleClose} />
		</Fragment>
	)
}

export default AppRouter
