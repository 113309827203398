import { ReactNode, useEffect, useCallback, useMemo } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { useRedirect } from '@services/common/hooks/use-redirect'
// import { logger } from '@services/common/lib/logger'
import { useModal } from '@services/app/hooks/use-modal'
import { AuthContext } from './AuthContext'
import * as reducers from './reducer'
import * as actions from './actions'
import { initialState } from './state'
import { AuthDialog } from '../components/AuthDialog'
import { useStateReducer } from '@basementscripts/use-state-reducer'
import { SIGN_UP, AUTHENTICATE } from '@services/auth/graphql'
import { CONTEXT_USER } from '@services/user/graphql'
import { buildRoutePath } from '@services/common/utils/app'
import config from '@core/environment'

export interface AuthProviderProps {
	children: ReactNode | ReactNode[] | null
}

const persistToken = (token: string) => localStorage.setItem('token', token)
const clearToken = () => localStorage.removeItem('token')
const getToken = () => localStorage.getItem('token')
const getError = ([err]: any) => {
	return err.graphQLErrors[0].message
}

const Errors: any = {
	InvalidRequest: 'Invalid Request',
	Unauthorized: 'Unauthorized',
	InvalidEmail: 'Invalid Email',
	UserExists: 'That Email has already been used',
	AccountExists: 'That Account name already Exists'
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const {
		state,
		setInviteToken,
		setPostAuthentication,
		pushState,
		resetState,
		updateAccount
	}: any = useStateReducer({
		initialState,
		actions,
		reducers
	})
	const hasAuthToken = !!localStorage.getItem('token')

	const { inviteToken, loginEnabled }: any = state
	// query string hook
	const query: any = useLocation()
	const { showModal, closeModal }: any = useModal()
	// modal hook
	const { redirect }: any = useRedirect()

	// load the context user
	const [
		restoreLoggedInState,
		{ loading: loadingContext, data: dataContext, error: restoreAuthStateError }
	]: any = useLazyQuery(CONTEXT_USER)

	// signup mutation
	const [createAccount, { loading: registering }] = useMutation(SIGN_UP)
	// authenticate mutation
	const [authenticate, { loading: authenticating }] = useMutation(AUTHENTICATE)

	// auth events
	// is logged in memo
	const isLoggedIn: boolean = useMemo(() => {
		return state && !!state.token
	}, [state])
	// loading memo
	const loading = useMemo(() => {
		return authenticating || loadingContext || registering || (hasAuthToken && !isLoggedIn)
	}, [authenticating, loadingContext, registering, hasAuthToken, isLoggedIn])
	// context user memo
	const contextUser: any = useMemo(() => {
		return state.user
	}, [state])
	// post authentication memo
	const postAuthentication: any = useMemo(() => {
		return state.postAuthentication
	}, [state])
	const authToken: string = useMemo(() => {
		return state.token
	}, [state])
	// error memo
	const error = useMemo(() => {
		// const err = authenticationError || registrationError
		// return err ? extractError(err) : err
		return state.error
	}, [state]) // authenticationError, registrationError

	const queryParams = useMemo(() => {
		return new URLSearchParams(query.search)
	}, [query])
	// pending invite memo
	const pendingInvite = useMemo(() => {
		return queryParams.get('invite')
	}, [queryParams])

	const setAuthState = useCallback(
		(auth: any, user: any) => {
			persistToken(auth.authToken)
			pushState({
				token: auth.authToken,
				user
			})
			if (postAuthentication) {
				postAuthentication()
				pushState({
					postAuthentication: undefined,
					inviteToken: ''
				})
			} else if (pendingInvite) {
				pushState({
					inviteToken: ''
				})
			} else {
				redirect(buildRoutePath())
			}
			// connectSocket()
			// getLocation()
		},
		[
			postAuthentication,
			pushState,
			pendingInvite,
			redirect
			// connectSocket
			// getLocation
		]
	)

	/**
	 * signup success callback
	 * @param {GraphQLQueryResponse} response
	 */
	const onSignupSuccess = useCallback(
		({ data }: any) => {
			const { auth, user }: any = data.signUp
			// push auth to state
			setAuthState(auth, user)
			// close the modal
			closeModal()
		},
		[closeModal, setAuthState]
	)

	const onSignupError = useCallback(
		(...args: any) => {
			// logger.log('onSignupError', args)
			try {
				pushState({ error: Errors[getError(args)] })
			} catch (e) { }
		},
		[pushState]
	)
	/**
	 * signup action
	 * @param {any} auth
	 */
	const signup = useCallback(
		(auth: any) => {
			createAccount({
				variables: {
					input: auth
				}
			})
				.then(onSignupSuccess)
				.catch(onSignupError)
		},
		[createAccount, onSignupSuccess, onSignupError]
	)

	/**
	 * on authentication success
	 * @param {GraphQLQueryResponse} response
	 */
	const onAuthenticateSuccess = useCallback(
		({ data }: any) => {
			const { auth, user }: any = data.authenticate
			closeModal()
			setAuthState(auth, user)
		},
		[closeModal, setAuthState]
	)

	const onAuthenticateError = useCallback(
		(...args: any) => {
			// logger.log('onSignupError', getError(args))
			pushState({ error: Errors[getError(args)] })
		},
		[pushState]
	)
	/**
	 * login user
	 * @param {any} auth
	 */
	const login = useCallback(
		(auth: any) => {
			authenticate({
				variables: auth
			})
				.then(onAuthenticateSuccess)
				.catch(onAuthenticateError)
		},
		[authenticate, onAuthenticateSuccess, onAuthenticateError]
	)
	// login form
	const showAuthDialog = useCallback(
		({ form = 'login', data = {}, postAuth, classes = {} }: any) => {
			postAuth && setPostAuthentication(postAuth)
			// show the auth dialog
			showModal({
				component: AuthDialog,
				data: {
					form,
					data,
					signupEnabled: config.features.signupEabled
				},
				handleModalClose: closeModal,
				// maxWidth: 'xs'
				options: {
					fullScreen: true
				},
				classes,
				styles: {
					contentRoot: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}
				}
			})
		},
		[setPostAuthentication, showModal, closeModal]
	)
	// logout
	const logout = useCallback(() => {
		clearToken()
		resetState()
		redirect(buildRoutePath())
	}, [resetState, redirect])

	useEffect(() => {
		if (pendingInvite && !inviteToken) {
			setInviteToken(pendingInvite)
			redirect(buildRoutePath())
			// setPostAuthentication(() => redirect('/'))
			showAuthDialog({
				form: 'signup',
				data: { token: pendingInvite }
			})
		}
	}, [redirect, inviteToken, pendingInvite, setInviteToken, showAuthDialog, setPostAuthentication])

	useEffect(() => {
		if (restoreAuthStateError && restoreAuthStateError.graphQLErrors) {
			const { message }: any = restoreAuthStateError.graphQLErrors[0]
			if (message === 'Unauthorized') {
				logout()
			}
		}
	}, [restoreAuthStateError, logout])

	useEffect(() => {
		// logger.log('AuthProvider', { isLoggedIn })
		const token = getToken()
		if (token && !isLoggedIn) {
			// logger.log('Token found Restoring Auth State')
			restoreLoggedInState()
		}
	}, [isLoggedIn, restoreLoggedInState])
	// auto load user if exist
	useEffect(() => {
		if (dataContext && dataContext.me && !contextUser) {
			pushState({
				token: localStorage.getItem('token'),
				user: dataContext.me
			})
			// getLocation()
		}
	}, [authToken, contextUser, pushState, dataContext])

	const context: any = {
		loginEnabled,
		isLoggedIn,
		hasAuthToken,
		authToken,
		contextUser,
		updateAccount,
		loading,
		error,
		login,
		logout,
		signup,
		showAuthDialog
	}
	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
