import { Box } from '@mui/material'
import PageHeader from '@services/app/components/PageHeader'
import FormPage from 'app/services/form/views/FormPage'
import { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CONFIG_MAP } from '../constants'

const ListingForm = (...props: any) => {
	const { state }: any = useLocation()
	const params: { action?: string; type?: string; id?: string | undefined } = useParams()
	console.log("ListingForm", { props, params, state })

	const [configState] = useState(() => {
		const config = params.type ? CONFIG_MAP[params.type] : null
		if (params?.type === 'property' && state?.data) {
			return {
				...config,
				data: {
					location: state.data
				}
			}
		}
		return config
	})

	const title = useMemo(() => {
		if (params.id) {
			return 'Manage Property Listing'
		}
		return 'Add a new Property Listing'
	}, [params])

	return (
		<Box sx={{ pb: 4 }}>
			<PageHeader
				title={title}
				text=''
				sx={{
					titleBlock: {
						pb: 3
					}
				}}
			// breadcrumbs={[
			// 	{
			// 		label: 'Listings',
			// 		linkTo: buildRoutePath('my-account', 'listings')
			// 	},
			// 	{
			// 		label: 'Properties',
			// 		linkTo: buildRoutePath('my-account', 'listings', 'properties')
			// 	},
			// 	{
			// 		label: "New"
			// 	}
			// ]}
			/>
			<FormPage {...configState} />
		</Box>
	)
}

export default ListingForm
