import { useCallback, useContext, useMemo } from 'react'
import { DrawerPosition } from '@services/app/AppProvider/types'

import { AppContext } from '../AppProvider/AppContext'

export interface DrawerHookProps {
	position?: DrawerPosition
	component?: any
	classes?: any
}

export interface DrawerHook {
	drawer: any
	show: Function
	hide: Function
	isOpen: boolean
}

export const useDrawer = ({ component, position }: DrawerHookProps = {}): DrawerHook => {
	const { state, setDrawer, clearDrawer }: any = useContext(AppContext)

	const drawer = useMemo(() => {
		return state && position && state[position] && state[position].component
			? state[position]
			: undefined
	}, [state, position])

	const isOpen = useMemo(() => {
		return !!drawer
	}, [drawer])

	const show = useCallback(
		(data: any, injectedComponent?: any) => {
			setDrawer(position, {
				data,
				component: injectedComponent || component
			})
		},
		[setDrawer, component, position]
	)

	return {
		drawer,
		show,
		hide: clearDrawer,
		isOpen
	}
}
