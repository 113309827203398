import { gql } from '@apollo/client'
import { TAXONOMY_FRAGMENT } from './taxonomy-info-frag'

export const LIST_TAXONOMIES = gql`
	query ListTaxonomies(
		$filter: TableTaxonomyFilterInput
		$limit: Int
		$offset: Int
		$sortAttribute: String
		$sortDirection: String
	) {
		listTaxonomies(
			filter: $filter
			limit: $limit
			offset: $offset
			sortAttribute: $sortAttribute
			sortDirection: $sortDirection
		) {
			total
			size
			items {
				...TaxonomyInfo
				createdAt
				updatedAt
			}
		}
	}
	${TAXONOMY_FRAGMENT}
`
