import {
	ApolloCache,
	ApolloClient,
	DocumentNode,
	FetchResult,
	useApolloClient
} from '@apollo/client'
import { noop } from 'lodash'
import { FormMutation } from '../types'
import { getFormMutation } from '../utils/get-form-mutation'
import { parseGraphQLError } from '../utils/parse-graphql-error'

export type SuccessHandler = (event: string, data: any) => void
export type ErrorHandler = (error: string) => void
export interface HandleFormSubmitRequest {
	form: any
	client: ApolloClient<any>
	onError: ErrorHandler
	onSuccess: SuccessHandler
}

export type HandleSubmitHookProps = {
	mutations: FormMutation
	fetchAction?: string
	fragment?: DocumentNode
}

const sortItems = (a: any, b: any) => {
	return b.createdAt - a.createdAt
}

const createFieldsUpdate = ({ cache, data, action, fetchAction, fragment }: any) => ({
	fields: {
		[fetchAction](existing: any = {}) {
			const newRef = cache.writeFragment({
				data: data[action],
				fragment
			})
			const items = [...existing.items, newRef].sort(sortItems).slice(0, existing.size)
			return {
				size: existing.size,
				items: items,
				total: existing.total + 1
			}
		}
	}
})

export const useHandleSubmit = ({ mutations, fetchAction, fragment }: HandleSubmitHookProps) => {
	const client = useApolloClient()
	return ({ form, onError = noop, onSuccess = noop }: HandleFormSubmitRequest) => {
		const { mutation, action }: any = getFormMutation(form, mutations)
		const canUpdate = !!fragment && !!fetchAction && !form.id

		client
			.mutate({
				mutation,
				variables: { input: form },
				update(cache: ApolloCache<any>, { data }: FetchResult) {
					canUpdate &&
						cache.modify(createFieldsUpdate({ cache, data, action, fetchAction, fragment }))
				}
			})
			.then(({ data }: any) => onSuccess(form.id ? 'Updated' : 'Created', data[action]))
			.catch((e: any) => onError(parseGraphQLError(e)))
	}
}
