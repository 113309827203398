import { BaseSyntheticEvent } from 'react'
import { CLEAR_ERROR, PUSH_ERROR, UPDATE_DATA, UPDATE_FORM } from './events'
import { FormData } from './types'

export const updateForm = (data: FormData) => (dispatch: any) => {
	dispatch(UPDATE_FORM, data)
}

export const updateData = (data: FormData) => (dispatch: any) => {
	dispatch(UPDATE_DATA, data)
}

export const handleInputChange = (e: BaseSyntheticEvent) => (dispatch: any) => {
	e.preventDefault()
	dispatch(UPDATE_FORM, { [e.target.name]: e.target.value })
}

export const pushError = (error: FormData) => (dispatch: any) => {
	dispatch(PUSH_ERROR, error)
}

export const clearError = (name: string) => (dispatch: any) => {
	dispatch(CLEAR_ERROR, name)
}

export const resetForm = () => (dispatch: any, initialState: FormData) => {
	dispatch(UPDATE_FORM, initialState)
}
