import React, { useMemo } from 'react'
import { Box, Switch, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

const SwitchField = ({
	form,
	data,
	name,
	label,
	hint,
	checked,
	updateForm,
	checkedValue = true,
	uncheckedValue = false,
	classes: injectedClasses = {},
	statusLabelEnabled = true
}: any) => {
	const classes = useStyles()

	const computedChecked = useMemo(() => {
		if (typeof checked === 'function') {
			return checked(form, data)
		}
		return form[name] === checkedValue
	}, [checked, form, data, name, checkedValue])

	const fieldValue = useMemo(() => {
		return form[name]
	}, [form, name])

	const handleChanged = () => {
		const updatedValue = fieldValue === checkedValue ? uncheckedValue : checkedValue
		updateForm({ [name]: updatedValue })
	}

	const computedLabel = useMemo(() => {
		let checkedValueLabel = typeof checkedValue === 'boolean' ? 'enabled' : checkedValue
		let uncheckedValueLabel = typeof uncheckedValue === 'boolean' ? 'disabled' : uncheckedValue
		return computedChecked ? checkedValueLabel : uncheckedValueLabel
	}, [computedChecked, checkedValue, uncheckedValue])

	return (
		<Box className={classNames(classes.root, injectedClasses.root)}>
			{(label || hint) && (
				<Box>
					{label && (
						<Typography className={classNames(classes.label, injectedClasses.label)}>
							{label}
						</Typography>
					)}
					{hint && <Typography className={classes.hint}>{hint}</Typography>}
				</Box>
			)}
			<Box alignItems="flex-end" display="flex" flexDirection="column">
				<Switch name={name} color="primary" checked={computedChecked} onChange={handleChanged} />
				{statusLabelEnabled && (
					<Typography
						style={{
							fontSize: 12,
							color: computedChecked ? 'green' : '#ccc',
							textAlign: 'right',
							paddingRight: 12
						}}
					>
						{computedLabel}
					</Typography>
				)}
			</Box>
		</Box>
	)
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between'
	},
	label: {},
	hint: {
		fontSize: 10,
		color: '#666666'
	}
})

export default SwitchField
