import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Chip, Stack } from '@mui/material'
import PageHeader from '@services/common/components/PageHeader'
import { PageSection } from '@services/common/components/PageSection'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import AspectRatio from '@services/common/components/AspectRatio'
import { LIST_ARTICLES } from '../graphql'
import ArticleCard from '../components/ArticleCard'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql/get-content-by-key'
import { LIST_TAXONOMIES } from 'app/services/cms/taxonomy/graphql/list-taxonomies'
import QueryGridItems from 'app/services/common/components/QueryGridItems'
import { setHeader } from 'app/services/header/reducer'
import { dispatch } from 'app/services/store'

const filter = { status: 'enabled' }

const ArticlesPage = ({ limit = 24 }: any) => {
	useEffect(() => {
		dispatch(
			setHeader({
				variant: 'transparent',
				lock: false
			})
		)
		return () => {
			dispatch(
				setHeader({
					variant: 'light',
					lock: true
				})
			)
		}
	}, [])
	const [category, setCategory]: any = useState(undefined)
	const { data: headerContent }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.travel-blog'
		}
	})

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (headerContent) {
			const { name, content }: any = headerContent.getContentByKey
			Object.assign(res, {
				title: name,
				text: content
			})
		}
		return res
	}, [headerContent])

	const { data: categoriesData }: any = useQuery(LIST_TAXONOMIES, {
		variables: {
			filter: {
				type: 'article'
			}
		}
	})

	const categories = useMemo(() => {
		return categoriesData ? categoriesData.listTaxonomies.items : []
	}, [categoriesData])

	const queryFilter = useMemo(() => {
		return category
			? {
				...filter,
				categories: category.id
			}
			: filter
	}, [category])

	const FilterControl = () => {
		// console.log('FilterControl', { filter, category })

		if (!category) {
			return null
		}
		return (
			<Box sx={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 1, paddingLeft: 3 }}>
				<Chip
					label={category.name}
					variant="outlined"
					onDelete={() => setCategory(undefined)}
					sx={{
						color: '#fff',
						borderColor: '#fff',
						borderWidth: 2,
						fontWeight: 800,
						'& .MuiSvgIcon-root': {
							color: '#fff'
						}
					}}
				/>
			</Box>
		)
	}

	return (
		<Box sx={styles.root}>
			<Box sx={styles.scrollBehind}>
				<AspectRatio ratio={747 / 350}>
					<video autoPlay={true} loop style={{ width: '100%', height: '100%' }}>
						<source src="https://s3-us-west-1.amazonaws.com/mygaygetaway-assets/videos/mgg-scroll-behind-01.mp4" />
					</video>
				</AspectRatio>
			</Box>
			<PageHeader
				sx={{
					root: styles.header,
					title: styles.title,
					titleBlock: styles.titleBlock,
					text: styles.text,
					content: {
						flex: 4
					}
				}}
				{...header}
			>
				<FilterControl />
			</PageHeader>
			{/* <Divider style={{ margin: '24px 0', width: '100%' }} /> */}

			<PageSection
				// header="Travel Blog"
				sx={{
					root: styles.root,
					container: {
						pt: 3,
						minHeight: 500,
						backgroundColor: '#fff',
						borderTopRightRadius: 12,
						borderTopLeftRadius: 12
					}
				}}
			>
				<Stack gap={4}>
					{categories.length > 0 && (
						<Box
							sx={{
								display: 'flex'
							}}
						>
							<Stack direction="row" gap={1}>
								{categories.map((cat: any) => (
									<Chip
										key={`category-chip-${cat.id}`}
										sx={{
											...(category && category.id === cat.id
												? {
													color: '#fff',
													backgroundColor: '#666',
													borderColor: '#666',
													'&:hover': {
														color: '#666'
													}
												}
												: {})
										}}
										label={cat.name}
										variant="outlined"
										onClick={() => setCategory(cat)}
									/>
								))}
							</Stack>
						</Box>
					)}
					<QueryGridItems
						query={LIST_ARTICLES}
						action="listArticles"
						component={ArticleCard}
						filter={queryFilter}
						limit={limit}
						sortAttribute="date"
						sortDirection="desc"
					/>
				</Stack>
			</PageSection>
			<NewsletterSignup />
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: '60px'
	},
	container: {
		backgroundColor: '#fff'
	},
	scrollBehind: {
		position: 'absolute',
		zIndex: -1,
		top: 0,
		right: 0,
		left: 0,
		'&:before': {
			// content: '""',
			// position: 'absolute',
			// top: 0,
			// right: 0,
			// bottom: 0,
			// left: 0,
			// background: 'rgba(0,0,0,.4)',
			// zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			right: 0,
			bottom: 0,
			left: 0,
			height: '20%',
			background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
		}
	},
	content: {},
	header: {
		paddingTop: '200px'
	},
	titleBlock: {
		paddingTop: 10
	},
	title: {
		// marginBottom: 4
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	}
}

export default ArticlesPage
