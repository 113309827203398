import { useQuery } from '@apollo/client'
import { Box, Chip, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PageSection } from '@services/common/components/PageSection'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { LIST_GETAWAYS_PROPERTIES } from '../graphql'
import GetawaySearchCard from './GetawaySearchCard'


const startDate = Date.now()

const Getaways = ({
	limit = 4,
	filter = {
		startDate
	},
	selectedCategory,
	clearFilter = noop
}: any) => {

	const classes = useStyles()
	// const { loading, items = [] }: any = useScrollLoad({
	// 	ref,
	// 	query: LIST_GETAWAYS_PROPERTIES,
	// 	resource: 'listGetaways',
	// 	variables: {
	// 		limit,
	// 		offset: 0,
	// 		filter,
	// 		sortAttribute: 'startDate',
	// 		sortDirection: 'asc'
	// 	},
	// 	loadMoreOnScroll: true
	// })

	const { data }: any = useQuery(LIST_GETAWAYS_PROPERTIES, {
		variables: {
			limit,
			filter,
			sortAttribute: 'startDate',
			sortDirection: 'asc'
		},
		notifyOnNetworkStatusChange: true
	})

	// const totalGetaways = useMemo(() => {
	// 	return data ? data.listGetaways.total : 0
	// }, [data])

	// const canLoadMore = useMemo(() => {
	// 	return totalGetaways > offset
	// }, [offset, totalGetaways])

	const getaways = useMemo(() => {
		return data ? data.listGetaways.items : []
	}, [data])

	// useEffect(() => {
	// 	offset > 0 && fetchMore({
	// 		variables: {
	// 			filter,
	// 			offset,
	// 			limit
	// 		},
	// 		updateQuery() {

	// 		}
	// 	})
	// }, [fetchMore, filter, limit, offset])

	// const loadMore = useCallback(() => {
	// 	setOffset(offset + data.listGetaways.size)
	// }, [data, offset])

	const FilterControl = () => {
		if (!selectedCategory) {
			return null
		}
		return (
			<Box style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 14, paddingLeft: 24 }}>
				<Chip label={selectedCategory.name} variant="outlined" onDelete={clearFilter} />
			</Box>
		)
	}

	return (
		<PageSection
			header="Recommended Getaways"
			classes={{
				root: classes.root
			}}
			headerComponent={FilterControl}
		>
			{/* {loading ? (
				<Loading overlay={false} />
			) : ( */}
			<Box className={classes.content}>
				<Grid container spacing={3} >
					{getaways.length > 0 &&
						getaways.map((getaway: any) => (
							<Grid key={`getaway-${getaway.id}`} item md={3} className={classes.card}>
								<GetawaySearchCard item={getaway} />
							</Grid>
						))}
					{/* {canLoadMore && <Grid item md={12} sx={{ textAlign: 'center' }}>
						<Button variant="contained"
							onClick={loadMore}
							sx={{
								backgroundColor: DEFAULT_COLORS.primary,
								color: '#fff',
								padding: '1rem 2rem',
								fontSize: '1.2rem',
								textTransform: 'none',
								margin: '0em auto',
								// borderRadius: '30px',
								border: `1px solid ${DEFAULT_COLORS.primary}`,
								'&:hover': {
									color: DEFAULT_COLORS.primary,
									// borderColor: DEFAULT_COLORS.primary
									borderColor: DEFAULT_COLORS.primary,
									backgroundColor: 'transparent'
								}
							}}
						>Load More Getaways</Button>
					</Grid>} */}
				</Grid>

			</Box>
			{/* )} */}
		</PageSection>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		// padding: theme.spacing(6, 3)
		minHeight: 500
	},
	container: {},
	content: {},
	card: {
		marginBottom: theme.spacing(6)
	}
}))

export default Getaways
