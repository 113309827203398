import { DocumentNode } from 'graphql'
import { FormMutation } from '../types'

export const getFormMutation = (
	context: any,
	{ create, update, delete: deleteMutation }: FormMutation = {}
): DocumentNode => {
	if (context.id && update) {
		return update
	} else if (create) {
		return create
	} else if (deleteMutation) {
		delete context.markedForDelete
		return deleteMutation
	}
	throw new Error('InvalidRequest')
}
