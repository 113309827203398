import { FC } from 'react'
import { makeStyles } from '@mui/styles'
import BodySection from './BodySection'

export type PropertyAmenitiesProps = {
	amenities: any
}

const PropertyAmenities: FC<PropertyAmenitiesProps> = ({
	amenities = []
}: PropertyAmenitiesProps) => {
	const classes = useStyles()

	if (amenities.length === 0) {
		return null
	}

	return (
		<BodySection title="Amenitites">
			<ul className={classes.list}>
				{amenities.map((amenity: any) => (
					<li key={`amenity-${amenity.id}`} className={classes.item}>
						{amenity.name}
					</li>
				))}
			</ul>
		</BodySection>
	)
}

const useStyles = makeStyles(() => ({
	list: {
		paddingLeft: 18,
		overflow: 'hidden'
	},
	item: {
		width: '50%',
		float: 'left',
		marginBottom: 8
	}
}))

export default PropertyAmenities
