import { useEffect } from 'react'
import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import { setHeader } from 'app/services/header/reducer'
import { dispatch } from 'app/services/store'
import { useAuth } from '@services/auth/hooks/use-auth'
import { useRedirect } from '@services/common/hooks'

const Layout = () => {
	const { contextUser, isLoggedIn, hasAuthToken }: any = useAuth()
	const { redirect } = useRedirect()

	useEffect(() => {
		if (!hasAuthToken) {
			redirect('/')
		}
	}, [redirect, hasAuthToken])

	useEffect(() => {
		dispatch(
			setHeader({
				variant: 'light',
				lock: true
			})
		)
	}, [])

	if (!isLoggedIn || !contextUser.id) {
		return null
	}

	return (
		<Box sx={styles.root}>
			<Sidebar />
			<Container sx={{ paddingTop: 3 }}>
				<Outlet />
			</Container>
		</Box>
	)
}

const styles: any = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		paddingTop: '50px',
		backgroundColor: '#f1f1f1'
	}
}

export default Layout
