import { ApolloClient } from '@apollo/client'
import { logger } from '@services/common/lib/logger'
import { SET_LOCATION } from '@services/user/graphql'
import { initialState } from './state'
import { DrawerPosition } from './types'
import { pluck } from '@services/common/utils/object'
// import { GeolocationPosition } from './types'

export enum Actions {
	SHOW_MODAL = 'modal.show',
	CLEAR_MODAL = 'modal.clear',
	CLEAR = 'clear',
	PUSH = 'push.state',
	SET = 'set.state'
}

/**
 * show modal
 * @param data
 */
export const showModal = (data: any) => (dispatch: any) => {
	dispatch(Actions.PUSH, { modal: data })
}
/**
 * close modal
 */
export const closeModal = () => (dispatch: any) => {
	dispatch(Actions.PUSH, { modal: {} })
}

export const clearAlert = (alert: string) => (dispatch: any, state: any) => {
	const alerts: any = [...state.alerts]
	const idx: number = alerts.indexOf(alert)
	if (idx !== -1) {
		alerts.shift(idx, 1)
		dispatch(Actions.PUSH, { alerts })
	}
}

export const setLoading = () => (dispatch: any) => {
	dispatch(Actions.PUSH, { loading: true })
}

export const cancelLoading = () => (dispatch: any) => {
	dispatch(Actions.PUSH, { loading: false })
}

export const setHeader = (options: any) => (dispatch: any) => {
	dispatch(Actions.PUSH, { header: options })
}

export const getLocation = () => (dispatch: any, state: any, client: ApolloClient<any>) => {
	if (navigator.geolocation) {
		navigator.geolocation.watchPosition(
			({ coords, timestamp }: any) => {
				//GeolocationPosition
				const location: any = {
					timestamp,
					...pluck(
						[
							'latitude',
							'longitude',
							'accuracy',
							'altitude',
							'altitudeAccuracy',
							'heading',
							'speed'
						],
						coords
					)
				}

				dispatch(Actions.PUSH, { location })
				//persist location to User table
				client.mutate({
					mutation: SET_LOCATION,
					variables: {
						input: location
					}
				})
			},
			(err: any) => {
				logger.log('AppProvider.actions.getLocation:error', err)
			},
			{
				enableHighAccuracy: true
			}
		)
	}
}

export const setDrawer = (position: DrawerPosition, options: any) => (dispatch: any) => {
	logger.log('AppProvider.actions.setDrawer', position, options)
	dispatch(Actions.PUSH, { [position]: options })
}

export const clearDrawer = (position: DrawerPosition) => (dispatch: any) => {
	logger.log('AppProvider.actions.clearDrawer', position)
	dispatch(Actions.SET, { prop: position, value: {} })
}

export const setLoadedScripts = (scripts: any) => (dispatch: any) => {
	dispatch(Actions.PUSH, { setLoadedScripts: scripts })
}

export const clear = () => (dispatch: any) => {
	dispatch(Actions.PUSH, initialState)
}
