import { ReactNode, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Form } from '@form'
import { useRedirect } from '@services/common/hooks/use-redirect'
import { Loading } from '@services/common/components/Loading'

export interface AdminFormPageProps {
	children?: ReactNode | ReactNode[]
	header?: any
	dataList?: any
}

// const data = {}
// const loading = false
// const called = true

// type DataLink = () => string
// type CancelRedirect = 'string' | DataLink

const FormPage = ({ header = {}, queries, onSuccess, onCancel, ...rest }: any) => {
	const { redirect } = useRedirect()
	const { state, pathname }: any = useLocation()
	const params = useParams()
	const [fetch, { data, loading, called }] = useLazyQuery(queries?.get?.query)

	useEffect(() => {
		console.log('FormPage', { state, params, rest })
	}, [params, rest, state])

	useEffect(() => {
		if (params.id && !called) {
			fetch({
				variables: {
					id: params.id
				}
			})
		}
	}, [called, fetch, params])

	const formData = useMemo(() => {
		if (data) {
			return { record: data[queries.get.action] }
		}
		if (state) {
			return { record: state.item }
		}
		return {}
	}, [data, state, queries])

	const handleOnSuccess = (event: string, data: any): void => {
		console.log(`${data.__typename} ${event}`)
		const path = onSuccess
			? typeof onSuccess === 'string'
				? onSuccess
				: onSuccess(data)
			: `/${pathname.substr(1).split('/')[0]}/edit/${data.id}`

		redirect(path)
	}

	const handleOnError = (data: string): void => {
		console.log(data)
	}

	const handleOnCancel = () => {
		console.log('Canceled')
		const path = onCancel
			? typeof onCancel === 'string'
				? onCancel
				: onCancel()
			: `/${pathname.substr(1).split('/')[0]}`
		redirect(path)
	}

	return (
		<>
			{loading ? (
				<Loading overlay={false} />
			) : (
				<Form
					{...rest}
					{...formData}
					loading={loading}
					onError={handleOnError}
					onSuccess={handleOnSuccess}
					onCancel={handleOnCancel}
				/>
			)}
		</>
	)
}

export default FormPage
