import Events from '../views/Events'
import Getaways from '../views/Getaways'
import MyAccount from '../views/MyAccount'
import Properties from '../../listing/views/Properties'
import Layout from '../views/Layout'
import ListingForm from '@services/listing/views/ListingForm'

const routes = [
	{
		path: 'my-account',
		element: <Layout />,
		children: [
			{
				index: true,
				element: <MyAccount />
			},
			{
				path: 'listings',
				children: [
					{
						path: 'properties',
						children: [
							{
								index: true,
								element: <Properties />
							}
						]
					},
					{
						path: 'events',
						element: <Events />
					},
					{
						path: 'getaways',
						element: <Getaways />
					},
					{
						path: ':action',
						children: [
							{
								index: true,
								element: <ListingForm />
							},
							{
								path: ':type',
								children: [
									{
										index: true,
										element: <ListingForm />
									},
									{
										path: ':id',
										element: <ListingForm />
									}
								]
							}
						]
					}
				]
			}
		]
	}
]
export default routes
