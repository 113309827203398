import TabPromote from '@services/common/components/TabPromote/TabPromote'
import { concierge } from '../constants/fixtures'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useConfig } from 'app/hooks/use-config'

const ConciergePromote = () => {
	const config = useConfig()
	const classes = useStyles()

	if (!config.features.concierge) {
		return null
	}

	return (
		<TabPromote
			title="Getaway Concierge"
			text="Your personal getaway expert, at your fingertips. Duis aute irure dolor in reprehenderit in"
			tabs={concierge}
			classes={{
				root: classes.tabRoot
			}}
		/>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tabRoot: {
		background:
			'url(https://photos.mygaygetaway.com/site/21fd42c6-03b2-425b-b152-f02e91f81327.png) center no-repeat',
		// background:
		// 'url(https://photos.mygaygetaway.com/properties/5d8284855bdcfacc3b1ee9c2/d7a2bf0f-851f-4f1c-a8ec-02856f4fda01.jpg) center no-repeat',
		backgroundSize: 'cover',
		padding: theme.spacing(11, 0),
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundColor: '#0B2238',
			opacity: 0.8,
			zIndex: 0
		}
	}
}))
export default ConciergePromote
