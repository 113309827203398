import { useQuery } from '@apollo/client'
import { Box, ImageList, ImageListItem, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useMemo } from 'react'
import { LIST_DESTINATIONS } from '../graphql/list-destinations'
import Destination from './Destination'

const Trending = () => {
	const classes = useStyles()

	const { data }: any = useQuery(LIST_DESTINATIONS, {
		variables: {
			limit: 9,
			filter: {
				status: 'enabled'
			}
		}
	})

	const destinations = useMemo(() => {
		return data ? data.listDestinations.items : []
	}, [data])

	return (
		<Box className={classes.root}>
			{destinations.length > 0 && (
				<ImageList
					rowHeight="auto"
					gap={32}
					cols={9}
					style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }}
				>
					{destinations.map((destination: any) => (
						<ImageListItem key={`destination-${destination.id}`}>
							<Destination item={destination} />
						</ImageListItem>
					))}
				</ImageList>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3, 0)
	}
}))

export default Trending
