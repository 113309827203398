import { HydrateEvent, PageableResult } from './types'

/**
 * GraphQL Util to Add item to App Cache
 * @param {any} state The previous state to manage
 * @param {any} item The item to add to cache
 */
export const addToCache = (state: any, item: any): PageableResult => {
  const items = [...state.items]
  let total = state.total
  if (Array.isArray(item)) {
    items.push(...item)
    total += item.length
  } else {
    items.push(item)
    total += 1
  }
  return {
    items,
    total,
    size: state.size
  }
}

const findIndex = (arr: any, attr: string, key: any) => {
  return arr.reduce((n: number, _item: any, index: number) => {
    if (_item[attr] === key) {
      n = index
    }
    return n
  }, 0)
}
/**
 * GraphQL Util to update item in App Cache
 * @param {any} state The previous state to manage
 * @param {any} item The item to add to cache
 */
export const updateCache = (state: any, item: any): PageableResult => {
  const items = [...state.items]

  if (Array.isArray(item)) {
    item.forEach((_item: any) => {
      const idx = findIndex(items, 'id', _item.id)
      items[idx] = _item
    })
  } else {
    const idx = findIndex(items, 'id', item.id)
    items[idx] = item
  }

  return {
    items,
    total: state.total,
    size: state.size
  }
}
/**
 * GraphQL Util to Remove item from App Cache
 * @param {any} state The previous state to manage
 * @param {any} item The item to remove from cache
 */
export const removeFromCache = (state: any, item: any): PageableResult => {
  const items = state.items.filter((x: any) => x.id !== item.id)
  return {
    items,
    total: state.total - 1,
    size: state.size
  }
}

export const getHandler = (event: HydrateEvent) => {
  switch (event) {
    case HydrateEvent.Update:
      return updateCache
    case HydrateEvent.Remove:
      return removeFromCache
    default:
      return addToCache
  }
}
