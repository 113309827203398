import { gql } from '@apollo/client'
import { PROPERTY_INFO_FRAG } from './property-info-frag'

export const UPDATE_PROPERTY = gql`
	mutation UpdateProperty($input: UpdatePropertyInput!) {
		updateProperty(input: $input) {
			...PropertyInfo
		}
	}
	${PROPERTY_INFO_FRAG}
`
