import { gql } from '@apollo/client'

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      auth {
        authToken
        expiresAt
      }
      user {
        id
        firstName
        lastName
        email
        account {
          id
          name
        }
      }
    }
  }
`
