// Credit Card Validations

/**
 * validate American Express
 * @param {string} str
 * @returns {boolean}
 */
export const validateAmericanExpress = (str: string) =>
  !!str.match(/^(?:3[47][0-9]{13})$/)
/**
 * validate Visa
 * @param {string} str
 * @returns {boolean}
 */
export const validateVisa = (str: string) =>
  !!str.match(/^(?:4[0-9]{12}(?:[0-9]{3})?)$/)
/**
 * validate Master Card
 * @param {string} str
 * @returns {boolean}
 */
export const validateMasterCard = (str: string) =>
  !!str.match(/^(?:5[1-5][0-9]{14})$/)
/**
 * validate Discover
 * @param {string} str
 * @returns {boolean}
 */
export const validateDiscover = (str: string) =>
  !!str.match(/^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/)
/**
 * validate Diners
 * @param {string} str
 * @returns {boolean}
 */
export const validateDiners = (str: string) =>
  !!str.match(/^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/)
/**
 * validate JCB
 * @param {string} str
 * @returns {boolean}
 */
export const validateJCB = (str: string) =>
  !!str.match(/^(?:(?:2131|1800|35\d{3})\d{11})$/)

export const validCreditCard = (str: string): boolean => {
  return (
    validateAmericanExpress(str) ||
    validateVisa(str) ||
    validateMasterCard(str) ||
    validateDiscover(str) ||
    validateDiners(str) ||
    validateJCB(str)
  )
}

/**
 * validate max length of string
 * @param {string} str
 * @returns {boolean}
 */
export const maxLength = (max: number, str: string): boolean =>
  str.length > 0 && str.length <= max
/**
 * validate min length of string
 * @param {string} str
 * @returns {boolean}
 */
export const minLength = (min: number, str: string): boolean =>
  str.length >= min
/**
 * requried length
 * @param {string} str
 * @returns {boolean}
 */
export const requiredLength = (length: number, str: string): boolean =>
  str.length === length
/**
 * validate required string
 * @param {string} str
 * @returns {boolean}
 */
export const required = (str: string): boolean => !!str
/**
 * validate email string
 * @param {string} str
 * @returns {boolean}
 */
export const email = (str: string): boolean => /\S+@\S+/.test(str)
export const validEmail = (str: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  )
/**
 * validate password string
 * @param {string} str
 * @returns {boolean}
 */
export const password = (str: string): boolean =>
  !/^(?=.*[0-9])(?=.*[A-Z])(?=.*[A-Z])(?=.{7,128})/.test(str)
/**
 * validate numeric only string
 * @param {string} str
 * @returns {boolean}
 */
export const numericOnly = (str: string): boolean => !/[0-9]/.test(str)
/**
 * validate numeric only string
 * @param {string} str
 * @returns {boolean}
 */
export const alphaOnly = (str: string): boolean => !/[a-zA-Z']/.test(str)

/**
 * validate has lower case char
 * @param {string} str
 * @returns {boolean}
 */
export const hasLowerCase = (str: string): boolean => !!str.match(/[a-z]/g)
/**
 * validate has upper case char
 * @param {string} str
 * @returns {boolean}
 */
export const hasUpperCase = (str: string): boolean => !!str.match(/[A-Z]/g)
/**
 * validate has number char
 * @param {string} str
 * @returns {boolean}
 */
export const hasNumber = (str: string): boolean => !!str.match(/[0-9]/g)
/**
 * validate has symbol char
 * @param {string} str
 * @returns {boolean}
 */
export const hasSymbol = (str: string): boolean => !!str.match(/[!@#$%^&*()]/g)

/**
 * validate valid password
 * @param {string} str
 * @returns {boolean}
 */
export const validPassword = (str: any): boolean =>
  hasUpperCase(str) && hasNumber(str) && hasSymbol(str)
