import { useConfig } from 'app/hooks/use-config'
import { LoginPage } from '@services/auth/components/LoginPage'
import { Loading } from '@services/common/components'
import { useAuth } from '@services/auth/hooks/use-auth'
import AppRouter from '../../components/AppRouter'
import './App.scss'

export const App = (props: any) => {
	const { isLoggedIn, loading }: any = useAuth()
	const config = useConfig()

	if (loading) {
		return <Loading />
	}

	if ((!config.authRequired || isLoggedIn) && !loading) {
		return <AppRouter />
	}

	return <LoginPage />
}
