import gql from 'graphql-tag'

export const CREATE_PERMISSION = gql`
	mutation CreatePermission($input: CreatePermissionInput!) {
		createPermission(input: $input) {
			id
			name
			key
			status
			createdBy {
				id
				firstName
				lastName
			}
			createdAt
			updatedAt
		}
	}
`
