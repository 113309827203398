import { gql } from '@apollo/client'
import { EVENT_INFO_FRAG } from './event-info-frag'

export const CREATE_EVENT = gql`
	mutation CreateEvent($input: CreateEventInput!) {
		createEvent(input: $input) {
			...EventInfo
		}
	}
	${EVENT_INFO_FRAG}
`
