import AdminModule from '@services/admin/components/AdminModule'

const renderFormModule = (fields, options = {}) => ({
	type: 'section',
	component: AdminModule,
	styles: {
		header: {
			color: '#666'
		},
		content: {
			padding: 24
			// marginBottom: 24
		}
	},
	titleVariant: 'h6',
	fields,
	...options
})
export default renderFormModule
