export const PROPERTY_INFO_FRAG = `
	fragment PropertyInfo on Property {
		id
		name
		slug
		originalId
		information
		link
		contactPreference
		country {
			id
			name
		}
		state {
			id
			name
		}
		city
		originalImage
		region
		email
		phone
		currency
		bucket
		address
		location {
			id
			streetNumber
			route
			googleMap
			address
			country
			state
			city
			zip
			latitude
			longitude
		}
		photos {
			id
			image
			originalImage
			position
		}
		amenities {
			id
			name
		}
		# settings {
		# 	key
		# 	value
		# }
		promoAmenities
		review
		types {
			id
			name
		}
		isFeatured
		isHotStay
		mggFeatured
		status
		createdAt
		updatedAt
		__typename
	}
`
