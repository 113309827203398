import { useCallback, useMemo, useState } from 'react'
import { Box, Button, Container, IconButton, Input, Typography } from '@mui/material'
import { ArrowForwardOutlined } from '@mui/icons-material'
import classNames from 'classnames'
// import { Button } from '@services/common/components/Button'
import { useRedirect } from '@services/common/hooks'
import { buildRoutePath } from '@services/common/utils/app'
import { DEFAULT_COLORS } from '../../constants/app'
import './styles.css'

export type TextProps = {
	title?: string
	text?: string
}

export type ImageProps = {
	image?: string
}

export interface SearchCardProps extends TextProps, ImageProps {
	actions?: SearchCardCta[]
	color?: SearchCardColors
	placeholder?: string
	classes?: any
	sx?: any
}

export interface SearchCardColors {
	primary?: string
	secondary?: string
	title?: string
	text?: string
	placeholder?: string
}

export interface SearchCardCta {
	label?: string
	variant?: string
	color?: string
	textColor?: string
}

export interface SearchCardOverlay extends TextProps, ImageProps {
	classes?: any
}

const SearchCard = ({
	title,
	text,
	image,
	actions = [],
	colors = {},
	placeholder = 'Where Do You Want To Go...',
	classes: injectedClasses = {
		// root: '',
		// container: ''
	},
	sx = {}
}: any) => {
	const [searchText, setSearchText]: any = useState('')
	const { redirect }: any = useRedirect()

	const handleInputChange = useCallback(
		({ target }: any) => {
			setSearchText(target.value)
		},
		[setSearchText]
	)

	const redirectSearch = useCallback(() => {
		redirect(buildRoutePath('search', searchText))
	}, [searchText, redirect])

	const handleSubmit = useCallback(
		(e: any) => {
			e.preventDefault()
			redirectSearch()
		},
		[redirectSearch]
	)

	const computedColors = useMemo(
		() => ({
			primary: colors.primary || DEFAULT_COLORS.primary,
			secondary: colors.secondary || DEFAULT_COLORS.secondary,
			title: colors.title || DEFAULT_COLORS.title,
			text: colors.text || DEFAULT_COLORS.text,
			placeholder: colors.placeholder || DEFAULT_COLORS.placeholder
		}),
		[colors]
	)
	const rootStyle = useMemo(() => {
		const styles: any = {}
		if (image) {
			styles.backgroundImage = `url(${image})`
		}
		return styles
	}, [image])

	return (
		<Box
			style={rootStyle}
			sx={{ ...styles.root, ...sx.root }}
			className={classNames('hero', injectedClasses.root)}
		>
			<Container
				sx={{ ...styles.container, ...sx.container }}
				className={classNames(injectedClasses.container)}
			>
				<form onSubmit={handleSubmit} style={styles.form}>
					<Input
						placeholder={placeholder}
						fullWidth
						onChange={handleInputChange}
						sx={{
							border: 'none',
							mb: 2,
							'&:before': {
								borderBottom: `2px solid ${DEFAULT_COLORS.primary}`
							},
							'&:hover:not(.Mui-disabled, .Mui-error)': {
								'&:before': {
									borderBottom: `2px solid #fff`
								}
							},
							'&:after': {
								borderBottom: `2px solid ${DEFAULT_COLORS.primary}`
							},
							'& label.Mui-focused': {},
							'& .MuiInput-underline:after': {
								borderBottomColor: 'white'
							},
							'& input': {
								paddingRight: 60,
								color: computedColors.text,
								fontSize: '2rem'
							}
						}}
					/>

					<IconButton
						onClick={redirectSearch}
						sx={{
							position: 'absolute',
							top: 0,
							right: 0,
							transform: 'translateY(-12px)',
							color: computedColors.primary
						}}
					>
						<ArrowForwardOutlined />
					</IconButton>
				</form>
				<Box sx={styles.content}>
					{title && (
						<Typography
							sx={{
								color: computedColors.title,
								fontSize: '4rem',
								fontWeight: 800,
								mb: 2
							}}
						>
							{title}
						</Typography>
					)}
					{text && (
						<Typography
							sx={{
								color: computedColors.text,
								fontSize: '1.3rem',
								fontWeight: 500,
								mb: 4
							}}
						>
							{text}
						</Typography>
					)}

					{actions.length > 0 &&
						actions.map((action: SearchCardCta, idx: number) => (
							<Button
								key={`cta-${idx}`}
								sx={{
									backgroundColor: DEFAULT_COLORS.primary,
									color: '#fff',
									padding: '1.125rem 2.25rem',
									fontSize: '1.2rem',
									textTransform: 'none',
									border: `1px solid ${DEFAULT_COLORS.primary}`,
									'&:hover': {
										// color: DEFAULT_COLORS.primary,
										// borderColor: DEFAULT_COLORS.primary
										borderColor: '#fff'
										// backgroundColor: '#fff'
									}
								}}
								size="large"
								onClick={redirectSearch}
							>
								{action.label}
							</Button>
						))}
				</Box>
			</Container>
		</Box>
	)
}

const styles: any = {
	root: {
		// background: `url(${image}) center`,
		position: 'relative',
		display: 'flex',
		backgroundSize: 'cover',
		backgroundPosition: 'center'
		// padding: '40px!important',
		// height: `calc(100vh - 150px)`,
	},
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-end',
		paddingTop: 12,
		paddingBottom: 12,
		position: 'relative',
		zIndex: 1
	},
	content: {
		// maxWidth: '60%',
	},
	form: {
		width: '100%',
		position: 'relative'
	}
	// button: {
	// 	backgroundColor: computedColors.primary,
	// 	paddingTop: '1rem',
	// 	paddingBottom: '1rem'
	// },
	// buttonLabel: {
	// 	// fontWeight: 500,
	// 	fontSize: '1.2rem',
	// 	textTransform: 'none'
	// }
}

export default SearchCard
