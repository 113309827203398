import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { Autocomplete, Box, Chip, TextField } from '@mui/material'
import { noop } from 'lodash'
import { mockHandler } from '../../utils'
import { SelectVariant } from './SelectField/SelectField'

const getOptionLabel = (option: any) => {
	return option.text || option
}

const DEFAULT_FIELD_PROPS = {
	items: [],
	value: '',
	label: '',
	onChange: noop,
	name: '',
	optionLabelPath: 'label',
	optionValuePath: 'value'
}

export interface AutocompleteFieldProps {
	items?: any
	value: string | string[]
	label: string
	onChange?: any
	variant?: SelectVariant
	updateForm?: any
	name: string
	beforeUpdate?: any
	optionLabelPath?: string
	optionValuePath?: string
	component?: ReactElement
	required?: boolean
	disabled?: boolean
	multiSelect?: boolean
}

const AutocompleteField = ({
	items = [],
	value,
	label,
	onChange = noop,
	updateForm = noop,
	name,
	variant = 'standard',
	beforeUpdate = mockHandler,
	optionLabelPath = 'label',
	optionValuePath = 'value',
	component,
	required = false,
	disabled = false,
	multiSelect = true
}: AutocompleteFieldProps = DEFAULT_FIELD_PROPS) => {
	const [isArray]: any = useState(Array.isArray(value))
	const pushItemsChange = useCallback(
		(key: string) => {
			const item = items.find((x: any) => x[optionLabelPath] === key)
			if (item && !value.includes(item[optionValuePath])) {
				updateForm(beforeUpdate({ [name]: [...(value as string[]), item[optionValuePath]] }))
			}
		},
		[value, items, optionLabelPath, updateForm, beforeUpdate, name, optionValuePath]
	)

	const removeItem = useCallback(
		(item: any) => {
			const newState = (value as string[]).filter((x: any) => x !== item.id)
			updateForm({ [name]: newState })
		},
		[updateForm, name, value]
	)

	const dataProxy: any = useMemo(() => {
		return items.length > 0
			? items
					.reduce((collection: any, item: any) => {
						if (
							!collection.find((ref: any) => ref.name === item.name) &&
							!!item.name &&
							!value.includes(item[optionValuePath])
						) {
							collection.push(item)
						}
						return collection
					}, [])
					.map((item: any) => ({
						text: item[optionLabelPath],
						value: item[optionValuePath]
					}))
			: []
	}, [items, optionLabelPath, optionValuePath, value])

	const handleChange = useCallback(
		(e: any, input: any) => {
			console.log('handleChanage', { e, input })
			if (input) {
				if (input.value) {
					pushItemsChange(input.value)
				} else {
					pushItemsChange(input)
				}
			}
		},
		[pushItemsChange]
	)

	const computedValue: string = useMemo(() => {
		if (isArray) {
			return ''
		}
		const context = items.find((item: any) => item.id === value)
		if (!context) {
			return ''
		}
		return context[optionLabelPath]
	}, [isArray, items, optionLabelPath, value])

	if (items.length === 0) {
		return null
	}
	return (
		<Box display="flex" flex="1" flexDirection="column">
			<Autocomplete
				options={dataProxy}
				fullWidth={true}
				noOptionsText="No Options"
				onInputChange={handleChange}
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={() => true}
				value={computedValue}
				renderInput={(renderProps: any) => (
					<TextField
						{...renderProps}
						variant={variant}
						required={required}
						name={name}
						label={label}
						value={computedValue}
						disabled={disabled}
					/>
				)}
			/>
			{items.length > 0 && isArray && value.length > 0 && (
				<Box pt={1}>
					{(value as string[]).map((id: string) => {
						const item = items.find((x: any) => x.id === id)
						if (!item) {
							return null
						}
						return (
							<Chip
								key={`chip-${name}-${id}`}
								label={item[optionLabelPath]}
								style={{ marginRight: 8, marginBottom: 8 }}
								onDelete={() => removeItem(item)}
							/>
						)
					})}
				</Box>
			)}
		</Box>
	)
}

export default AutocompleteField
