import { noop } from 'lodash'
import { parseResponseHeaders } from './parse-response-heasers'

export interface HttpRquestObject {
	[key: string]: string
}
export interface HttpRquestOptions {
	method?: string
	body?: string
	headers?: HttpRquestObject
}
export interface HttpRequest {
	url?: string
	options?: HttpRquestOptions
	onProgress?: Function
	onComplete?: Function
	onLoad?: Function
}
export const httpRequest = async ({
	url,
	options = { method: 'get', headers: {} },
	onProgress = noop,
	onComplete = noop,
	onLoad = noop
}: any) =>
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest()
		xhr.open(options.method, url)
		for (const k in options.headers) {
			xhr.setRequestHeader(k, options.headers[k])
		}
		xhr.onload = onLoad
		xhr.onloadend = (evt: ProgressEvent) => {
			const headers = parseResponseHeaders(xhr.getAllResponseHeaders())
			onComplete({
				headers
			})
			resolve(evt)
		}
		xhr.onerror = reject
		if (xhr.upload) {
			xhr.upload.onprogress = (evt: any) => {
				onProgress(evt)
			}
		}
		xhr.send(options.body)
	})
