const initialState: any = {
	id: '',
	name: '',
	information: '',
	link: '',
	country: '',
	state: '',
	city: '',
	isFeatured: false,
	currency: '',
	status: false,
	amenities: [],
	photos: [],
	types: [],
	phone: '',
	email: '',
	canBook: false,
	promoAmenities: '',
	review: '',
	location: {}
}
export default initialState
