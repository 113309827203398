import gql from 'graphql-tag'

export const GET_PERMISSION = gql`
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      key
      status
      createdAt
    }
  }
`
