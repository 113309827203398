import { InputAdornment } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { TextField, TextFieldProps } from './TextField'

export const fieldResolver: Record<string, any> = {}

enum FieldType {
	Text = 'text',
	Select = 'select',
	Typeahead = 'typeahead'
}

type FieldProps = TextFieldProps & {
	form: any
	type?: 'text' | 'select' | 'date' | 'time' | 'switch' | 'typeahead'
	name: string
	validation?: any
	mask?: string
	startAdornment?: any
	endAdonrment?: any
}

const applyAdornment = (value: any, position: 'start' | 'end' = 'end') => ({
	InputProps: {
		[position === 'start' ? 'startAdornment' : 'endAdornment']: (
			<InputAdornment position={position}>{value}</InputAdornment>
		)
	}
})

export const Field: FC<FieldProps> = ({
	form,
	type = FieldType.Text,
	name,
	validation = (...rest: any) => true,
	mask = '',
	startAdornment,
	endAdonrment,
	...props
}: FieldProps) => {
	const fieldValue: any = useMemo(() => {
		return form && form.form ? form.form[name] : ''
	}, [form, name])

	const fieldProps: any = useMemo(() => {
		const options: any = {
			...props,
			name,
			value: fieldValue,
			onChange: props.onChange || form.handleInputChange
		}
		if (startAdornment) {
			Object.assign(options, applyAdornment(startAdornment, 'start'))
		}
		if (endAdonrment) {
			Object.assign(options, applyAdornment(endAdonrment, 'end'))
		}
		return options
	}, [props, fieldValue, endAdonrment, name, startAdornment, form.handleInputChange])

	return <TextField {...fieldProps} />
}
