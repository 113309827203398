export const parseAttr = (attr: any): any => {
	if (!attr) {
		return ''
	} else if (Array.isArray(attr)) {
		return attr.map(parseAttr)
	} else if (typeof attr === 'object') {
		return attr.id || attr
	} else {
		return attr
	}
}
