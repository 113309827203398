import { gql } from '@apollo/client'
import { ARTICLE_INFO_FRAG } from './article-info-frag'

export const LIST_ARTICLES = gql`
	query ListArticles($filter: TableArticleFilterInput, $limit: Int, $offset: Int) {
		listArticles(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				...ArticleInfo
			}
		}
	}
	${ARTICLE_INFO_FRAG}
`
