import config from 'app/config/environment'

export interface Feature {
	name: string
	key: string
	enabled: boolean
}

export const features: Feature[] = config.features

export const enabled = (key: string) => {
	const found: Feature = features.find((feature: Feature) => feature.key === key) as Feature
	if (!found) {
		throw new Error(`feature key does not exist ${key}`)
	}
	return found.enabled
}
