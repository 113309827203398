import { gql } from '@apollo/client'

export const BASIC_USER_INFO_FRAGMENT = gql`
	fragment BasicUserInfo on User {
		id
		firstName
		lastName
		__typename
	}
`

export const USER_INFO_FRAGMENT = gql`
	fragment UserInfo on User {
		...BasicUserInfo
		fullName
		email
		account {
			id
			name
		}
	}
	${BASIC_USER_INFO_FRAGMENT}
`

