import { gql } from '@apollo/client'

export const WHITELIST_FRAGMENT = gql`
  fragment WhitelistInfo on Whitelist {
    id
    email
    status
    __typename
  }
`
