import { FC, useMemo } from 'react'
import { Card, CardMedia, Box, CardContent, Typography, CardActionArea } from '@mui/material'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import { noop } from 'lodash'
import { AspectRatio } from '@services/common/components/AspectRatio'

const DEFAULT_ASPECT_RATIO = 16 / 9

export interface MediaManagerCardProps {
	item?: any
	resource: string
	selected?: boolean
	displayFilename?: boolean
	aspectRatio?: number
	actions?: any
	onClick?: any
	/**
	 * @deprecated
	 */
	handleCardAction?: Function
	/**
	 * @deprecated use classes.root
	 */
	rootClassName?: string
	classes?: any
	sx?: any
}

const MediaManagerCard: FC<MediaManagerCardProps> = ({
	item,
	resource,
	selected = false,
	displayFilename = false,
	aspectRatio = DEFAULT_ASPECT_RATIO,
	actions,
	onClick = noop,
	handleCardAction = noop,
	rootClassName,
	classes: injectedClasses = {},
	sx = {}
}: MediaManagerCardProps) => {
	const img = useMemo(() => {
		if (!item) {
			return ''
		}
		return buildS3ImageUrl(resource, item) //, 250
	}, [item, resource])

	return (
		<Card sx={{ ...styles.root, ...sx.root }} className={rootClassName}>
			<CardActionArea onClick={(e) => onClick(e, item)}>
				<AspectRatio ratio={aspectRatio}>
					{img && <CardMedia sx={{ ...styles.media, ...sx.media }} image={img} />}
				</AspectRatio>
			</CardActionArea>
			<CardContent sx={{ ...styles.content, ...sx.content }}>
				<Box sx={{ ...styles.info, ...sx.info }}>
					{item && item.text && (
						<Typography sx={{ ...styles.text, ...sx.text }} className={injectedClasses.text}>
							{item.text}
						</Typography>
					)}
					<Box sx={{ ...styles.innerContent, ...sx.innerContent }}>
						{/* {item && item.originalImage && (
							<Box display="flex" flex={1}>
								{displayFilename && item!.originalImage && (
									<Typography>{item!.originalImage}</Typography>
								)}
							</Box>
						)} */}

						{/* {actions && actions.length > 0 && (
							<Dropdown
								caret={false}
								onClick={handleClose}
								icon={MoreVertIcon}
								// size='sm'
								// color='transparent'
								actions={actions}
							/>
						)} */}
					</Box>
				</Box>
			</CardContent>
		</Card>
	)
}

export { MediaManagerCard }

export default MediaManagerCard

const styles = {
	root: {
		padding: 0,
		position: 'relative',
		maxWidth: 345
	},
	media: {
		width: '100%',
		height: '100%'
	},
	selected: {},
	content: {
		padding: '0 !important',
		position: 'absolute',
		zIndex: 9,
		bottom: 0,
		left: 0,
		right: 0,
		overflow: 'hidden',
		borderRadius: 1
	},
	innerContent: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end'
	},
	info: {
		display: 'flex',
		flex: 1,
		padding: 1,
		borderRadius: 1,
		background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
		flexDirection: 'column'
	},
	text: {},
	navButton: {
		padding: 0
	}
}
