import { gql } from '@apollo/client'
import { EVENT_INFO_FRAG } from './event-info-frag'

export const UPDATE_EVENT = gql`
	mutation UpdateEvent($input: UpdateEventInput!) {
		updateEvent(input: $input) {
			...EventInfo
		}
	}
	${EVENT_INFO_FRAG}
`
