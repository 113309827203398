import { gql } from "@apollo/client";
import { PROPERTY_INFO_FRAG } from "@services/property/graphql/property-info-frag";

export const LISTING_REQUEST_FRAG = gql`
  fragment ListingRequestFrag on ListingRequest {
    id
    account {
        id
        name
    }
    createdBy {
        id
        firstName
        lastName
        email
    }
    type
    listingId
    listing {
      ... on Property {
        ...PropertyInfo
      }
    }
    status
    createdAt
    updatedAt
    __typename
  }
  ${PROPERTY_INFO_FRAG}
`
