const extractValue = (value: any) => {
	if (!value) {
		return value
	}
	if (Array.isArray(value)) {
		return value.map((x: any) => (x.id ? x.id : x))
	} else if (typeof value === 'object') {
		return value.id ? value.id : value
	}
	return value
}

export const pluck = (pattern: any, context: any, retain: boolean = false) => {
	let projection
	if (Array.isArray(pattern)) {
		projection = pattern
	} else if (typeof pattern === 'object') {
		projection = Object.keys(pattern)
	} else if (typeof pattern === 'string') {
		return { [pattern]: context[pattern] }
	} else {
		throw new Error('Invalid pattern')
	}

	return projection.reduce((output: any, attr: string) => {
		const value = retain ? context[attr] : extractValue(context[attr])
		if (value) {
			output[attr] = value
		}
		return output
	}, {})
}

export const empty = (obj: any): boolean => {
	return Object.values(obj).filter((x: any) => (Array.isArray(x) ? x.length : !!x)).length === 0
}

export const stripNullOrEmpty = (data: any) => {
	return Object.keys(data).reduce((output: any, attr: string) => {
		const value: any = data[attr]
		if (value) {
			if (Array.isArray(value)) {
				if (value.length) {
					output[attr] = value
				}
			} else if (typeof value === 'object') {
				output[attr] = stripNullOrEmpty(value)
			} else {
				output[attr] = value
			}
		}
		return output
	}, {})
}
