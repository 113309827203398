import { gql } from '@apollo/client'
import { PROPERTY_INFO_FRAG } from '@services/property/graphql/property-info-frag'

export const CREATE_PROPERTY_INQUIRY = gql`
	mutation CreatePropertyInquiry($input: CreatePropertyInquiryInput!) {
		createPropertyInquiry(input: $input) {
			id
			firstName
			lastName
			email
			phoneNumber
			rooms
			guests
			startDate
			endDate
			property {
				...PropertyInfo
			}
			status
		}
	}
	${PROPERTY_INFO_FRAG}
`
