import { FC } from 'react'
import { CircularProgress, Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

export interface LoadingProps {
	message?: string
	overlay?: boolean
	variant?: 'default' | 'brand'
}

const Loading: FC<LoadingProps> = ({
	message,
	overlay = true,
	variant = 'default'
}: LoadingProps) => {
	const classes: any = useStyles()
	const styles: any = {}
	if (overlay) {
		Object.assign(styles, {
			backgroundColor: 'rgba(0,0,0,.8)'
		})
	}

	const renderIndicator = () => {
		switch (variant) {
			case 'brand':
			default:
				return <CircularProgress />
		}
	}

	return (
		<Box style={styles} className={classes.loading}>
			<Box display="flex">
				{renderIndicator()}
				{message && <Typography variant="h3">{message}</Typography>}
			</Box>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	loading: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		zIndex: theme.zIndex.drawer + 2
	}
}))

/**
 * @deprecated use default export
 */
export { Loading }
export default Loading
