import { DocumentNode, useQuery } from '@apollo/client'
import { Box, Container, Grid, Link, Paper, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { logger } from '@services/common/lib/logger'
import EventCard from '@services/event/components/EventCard'
import { LIST_EVENTS } from '@services/event/graphql'
import { useMemo } from 'react'
import Sidebar from '../components/Sidebar'

const Row = ({
	header,
	query,
	action,
	component: Component
}: {
	header: string
	query: DocumentNode
	action: string
	component: any
}) => {
	const { data } = useQuery(query, {
		variables: {
			limit: 3
		}
	})

	const items = useMemo(() => {
		return data ? data[action].items : []
	}, [action, data])

	return (
		<Box style={{ marginBottom: 24, width: '100%', padding: '0 16px' }}>
			<Grid container spacing={3}>
				<Grid item md={12}>
					<Typography variant="h3" style={{ fontSize: '2rem', fontWeight: 600 }}>
						{header}
					</Typography>
				</Grid>

				{items.length > 0 &&
					items.map((item: any) => (
						<Grid item md={4} key={item.id}>
							<Component item={item} />
						</Grid>
					))}
			</Grid>
		</Box>
	)
}

const Events = ({ openAdminNav, ...props }: any) => {
	const classes = useStyles()
	logger.log('MyAccount', props)

	return (
		<Box className={classes.root}>
			<Sidebar />
			<Container style={{ paddingTop: 70 }}>
				<Paper className={classes.mainFeaturedPost}>
					<div className={classes.overlay} />
					<Grid container>
						<Grid item md={6}>
							<div className={classes.mainFeaturedPostContent}>
								<Typography component="h1" variant="h3" color="inherit" gutterBottom>
									Welcome to your Account homepage
								</Typography>
								<Typography variant="h5" color="inherit" paragraph>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ultricies tincidunt
									neque. Quisque gravida libero ut lacus ultrices, convallis bibendum augue sodales.
								</Typography>
								<Link variant="subtitle1" href="#">
									Learn more
								</Link>
							</div>
						</Grid>
					</Grid>
				</Paper>

				<Grid container spacing={3}>
					<Row header="Events" query={LIST_EVENTS} action="listEvents" component={EventCard} />
				</Grid>
			</Container>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		paddingTop: 50
	},
	mainFeaturedPost: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(6),
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		borderRadius: theme.spacing(1),
		overflow: 'hidden'
	},
	overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0,.3)'
	},
	mainFeaturedPostContent: {
		position: 'relative',
		padding: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(6),
			paddingRight: 0
		}
	}
}))

export default Events
