import { Link as LinkTo } from 'react-router-dom'
import { Box, Button, Container, Grid, Link } from '@mui/material'
import { Image } from '@services/common/components/Image'
import { buildRoutePath } from '@services/common/utils/app'

const DragRace = () => {
	return (
		<Box display="flex" flex={1} sx={styles.root}>
			<Box sx={styles.wrap}>
				<Container maxWidth="lg" sx={styles.container}>
					<Box sx={styles.logoWrap}>
						<Image width="100%" src="https://photos.mygaygetaway.com/site/rupaul-header.png" />
					</Box>
					<Grid container>
						<Grid item md={8} sx={styles.imageWrap}>
							<Image
								sx={{
									root: styles.image
								}}
								aspectRatio={16 / 9}
								src="https://photos.mygaygetaway.com/site/5b11f901-1ab4-4211-b9a5-26dd833f0a1d.png"
							/>
							<Button
								component={LinkTo}
								to={buildRoutePath('dragrace')}
								variant="outlined"
								size="large"
								sx={styles.button}
							>
								Learn More
							</Button>
						</Grid>
					</Grid>
					<Box component="span" sx={styles.overlay}>
						<Image height="100%" src="https://photos.mygaygetaway.com/site/rupaul-overlay.png" />
					</Box>
				</Container>
			</Box>
			<Box sx={styles.logoContainer}>
				<Link component={LinkTo} to="" sx={styles.logos}>
					<Image height="63px" src="https://photos.mygaygetaway.com/site/logo-logo.png" />
				</Link>
				<Link component={LinkTo} to="" sx={styles.logos}>
					<Image height="63px" src="https://photos.mygaygetaway.com/site/vh1.png" />
				</Link>
				<Link component={LinkTo} to="" sx={styles.logos}>
					<Image height="63px" src="https://photos.mygaygetaway.com/site/world-of-wonder.png" />
				</Link>
			</Box>
		</Box>
	)
}
const styles = {
	root: {
		flexDirection: 'column',
		position: 'relative'
	},
	wrap: {
		background:
			'url(https://photos.mygaygetaway.com/site/a3a39846-ef30-4f7d-8a6c-b543392be534.png) center no-repeat',
		backgroundSize: 'cover'
	},
	container: {
		padding: '48px 24px',
		// maxWidth: 'none',
		position: 'relative'
	},
	logoWrap: {
		marginBottom: 3,
		maxWidth: 600,
		textAlign: 'center'
	},
	logo: {
		width: '100%'
	},
	image: {
		borderRadius: 2,
		overflow: 'hidden',
		marginBottom: 24
	},
	imageWrap: {
		position: 'relative',
		textAlign: 'center'
	},
	overlay: {
		position: 'absolute',
		right: 0,
		bottom: 0,
		top: 0
	},
	logoContainer: {
		backgroundColor: '#000',
		padding: '40px 0',
		textAlign: 'center',
		maxHeight: 63
	},
	logos: {
		margin: '0 48px'
	},
	button: {
		color: '#fff',
		borderColor: '#fff',
		margin: '0em auto',
		display: 'inline-block'
	}
}
export default DragRace
