import React from 'react'
import { Collapse, Grid } from '@mui/material'
import { FieldProps } from '../../types'
import FormRow from '../FormRow'

export interface FormSectionProps extends FieldProps {
	title?: string
	visible?: boolean
	collapsible?: boolean
	fields?: any
	styles?: any
}

const FormSectionRenderer = ({
	// title = '',
	visible = true,
	collapsible = true,
	fields = [],
	record = {},
	initialState,
	form,
	data = {},
	handleInputChange,
	updateForm,
	errors,
	pushError,
	component: Component = Collapse,
	...rest
}: any) => {
	const fieldOptions = {
		handleInputChange,
		pushError,
		updateForm,
		form,
		record,
		data,
		initialState
	}

	const sectionVisible = ((visibility: any) => {
		if (typeof visibility === 'function') {
			return visibility(form)
		}
		return visibility
	})(visible)

	console.log('renderFormSection', {
		// title,
		fields,
		visible: sectionVisible,
		collapsible,
		fieldOptions
	})

	console.log({ rest })

	return (
		<Component in={sectionVisible} sx={{ width: '100%' }} {...rest}>
			{sectionVisible && fields.length > 0 && (
				<Grid container spacing={3}>
					{fields.map((field: any, idx: number) => (
						<Grid key={`module-${idx}`} item md={12}>
							<FormRow fields={field} {...fieldOptions} />
						</Grid>
					))}
				</Grid>
			)}
		</Component>
	)
}
export default FormSectionRenderer
