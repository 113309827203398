import { Box, Card, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AspectRatio } from '@services/common/components/AspectRatio'
import { Image } from '@services/common/components/Image'
import useImage from '@services/common/hooks/use-image'

const ConciergeCard = ({ media, name, content }: any) => {
	const classes = useStyles()
	const image = useImage('content', media[0])

	return (
		<Card className={classes.root}>
			<Box>
				<AspectRatio ratio={5 / 2}>
					<Box
						position="absolute"
						top={0}
						left={0}
						right={0}
						bottom={0}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h3" style={{ color: '#fff', zIndex: 1 }}>
							{name}
						</Typography>
					</Box>
					<Image classes={{ root: classes.image }} src={image} variant="fill" />
					{/* <CardMedia component="img" image={image} /> */}
				</AspectRatio>
			</Box>

			<CardContent>
				<Typography gutterBottom={false} dangerouslySetInnerHTML={{ __html: content }} />
			</CardContent>
		</Card>
	)
}
const useStyles = makeStyles({
	root: {
		height: '100%'
	},
	image: {
		zIndex: 0,
		'&:before': {
			position: 'absolute',
			content: '""',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			background: 'rgba(0,0,0,.2)',
			zIndex: 1
		}
	}
})

export default ConciergeCard
