import { gql } from '@apollo/client'
import { WHITELIST_FRAGMENT } from './whitelist-frag'

export const CREATE_WHITELIST = gql`
	mutation CreateWhitelist($input: CreateWhitelistInput!) {
		createWhitelist(input: $input) {
			...WhitelistInfo
			createdAt
			updatedAt
		}
	}
	${WHITELIST_FRAGMENT}
`
