import { createSlice } from "@reduxjs/toolkit"

const initialState = {

}

const slice = createSlice({
  initialState,
  name: 'page',
  reducers: {

  }
})

export default slice.reducer

