import { CLEAR_ERROR, PUSH_ERROR, UPDATE_DATA, UPDATE_FORM } from './events'
import { FormData } from './types'

const pushState = (state: any, payload: FormData) => {
	return { ...state, ...payload }
}

const updateForm = (state: any, payload: FormData) => {
	return pushState(state, { form: { ...state.form, ...payload } })
}

const updateData = (state: any, payload: FormData) => {
	return pushState(state, { data: { ...state.data, ...payload } })
}

const pushError = (state: any, payload: FormData) => {
	return pushState(state, { errors: { ...state.errors, ...payload } })
}

const clearError = (state: any, payload: string) => {
	const errors = { ...state.errors }
	delete errors[payload]
	return pushState(state, { errors })
}

const reducers: any = {
	[UPDATE_FORM]: updateForm,
	[UPDATE_DATA]: updateData,
	[PUSH_ERROR]: pushError,
	[CLEAR_ERROR]: clearError
}

export default reducers
