import { Box, Container, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Image } from '@services/common/components/Image'
import { useConfig } from 'app/hooks/use-config'
import { footerNavigation } from '../../../../constants/navigation'
import { Link } from 'react-router-dom'
// import { useRedirect } from '@services/common/hooks'
import { kebabCase } from 'lodash'

const Footer = () => {
	const classes = useStyles()
	const { copyright, brandIcon } = useConfig()
	// const { redirect }: any = useRedirect()

	return (
		<Box className={classes.root}>
			<Container className={classes.container} maxWidth="lg">
				<Box className={classes.footer}>
					{footerNavigation.map((column: any) => (
						<ul key={`column-${kebabCase(column.label)}`} className={classes.column}>
							<li>
								<h6 className={classes.header}>{column.label}</h6>
							</li>
							{column.links &&
								column.links.length > 0 &&
								column.links
									.filter((link: any) => link.enabled)
									.map((link: any) => (
										<li key={`footer-link-${kebabCase(link.label)}`}>
											{link.href && (
												<a
													className={classes.link}
													href={link.href}
													target="_blank"
													rel="noopener noreferrer"
												>
													{link.label}
												</a>
											)}
											{link.linkTo && (
												<Link to={link.linkTo || '/'} className={classes.link}>
													{link.label}
												</Link>
											)}
										</li>
									))}
						</ul>
					))}
				</Box>

				<Box className={classes.copyrightWrap}>
					<Image styles={{ marginBottom: 8 }} src={brandIcon.light} />
					{copyright && <Typography className={classes.copyright}>{copyright}</Typography>}
				</Box>
			</Container>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1
	},
	container: {
		padding: theme.spacing(4, 3)
	},
	footer: {
		display: 'flex',
		flexDirection: 'row',
		maxWidth: 800,
		margin: '0em auto 50px'
	},
	column: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		listStyle: 'none',
		padding: 0,
		margin: 0
	},
	header: {
		color: '#909090',
		fontSize: '1rem',
		marginBottom: theme.spacing(3)
	},
	link: {
		fontSize: '.8rem',
		color: '#47C0E1',
		textDecoration: 'none',
		fontWeight: 600
	},
	copyright: {
		textAlign: 'center',
		color: '#909090',
		fontSize: '.8rem'
	},
	logo: {
		marginBottom: theme.spacing(3)
	},
	copyrightWrap: {
		textAlign: 'center'
	}
}))

export default Footer
