const sort = (arr: any): void => {
	if(arr && arr.length) {
		arr.sort((a: any, b: any) => a.position - b.position)
	}
}

export const beforeMediaRender =
	(actions: any = []) =>
	({ form, data, field }: any) => {
		const fieldName = field.name
		if (form.settings) {
			const keys = Object.keys(form.settings)
			const keyMap: any = Object.values(form.settings).reduce(
				(collection: any, value: any, idx: number) => {
					if (value) {
						collection[value] = keys[idx]
					}
					return collection
				},
				{}
			)
			if (data && data[fieldName] && data[fieldName].length > 0) {
				data[fieldName].forEach((image: any) => {
					const token = keyMap[image.id]
					if (token) {
						const { text }: any = actions.find((a: any) => a.event === token)
						image.text = text
					}
				})
			}
		}

		sort(data[fieldName])
	}
