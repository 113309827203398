import { FC, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Box, IconButton, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { noop } from 'lodash'

export interface SwipableProps {
	children: any
	enableMouseEvents?: boolean
	activeView?: number
	onChangeIndex?: any
	controlsEnabled?: boolean
}

const Swipable: FC<SwipableProps> = ({
	children,
	enableMouseEvents = true,
	activeView,
	onChangeIndex = noop,
	controlsEnabled = true
}: SwipableProps) => {
	const [activeIndex, setActiveIndex] = useState(activeView || 0)
	const [totalViews] = useState(children.length)

	const classes = useStyles()

	const next = () => {
		const nextIndex = activeIndex + 1
		nextIndex <= totalViews - 1 ? setActiveIndex(nextIndex) : setActiveIndex(0)
	}

	const prev = () => {
		const prevIndex = activeIndex - 1
		prevIndex >= 0 ? setActiveIndex(prevIndex) : setActiveIndex(totalViews - 1)
	}

	return (
		<Box className={classes.root}>
			{controlsEnabled && (
				<Box className={classes.controls}>
					<IconButton className={classes.button} disabled={activeIndex === 0} onClick={prev}>
						<ChevronLeft />
					</IconButton>
					<IconButton
						className={classes.button}
						disabled={activeIndex === totalViews - 1}
						onClick={next}
					>
						<ChevronRight />
					</IconButton>
				</Box>
			)}
			<SwipeableViews
				enableMouseEvents={enableMouseEvents}
				index={activeIndex}
				onChange={onChangeIndex}
			>
				{children}
			</SwipeableViews>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		position: 'relative'
	},
	controls: {
		position: 'absolute',
		right: theme.spacing(3),
		top: theme.spacing(3),
		zIndex: 9
	},
	button: {
		padding: 0
	}
}))

export default Swipable
