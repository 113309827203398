import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

export const useRedirect = () => {
	const navigate = useNavigate()

	const redirect = useCallback(
		(path: string, options = {}) => {
			navigate(path, options)
		},
		[navigate]
	)

	return { redirect }
}
