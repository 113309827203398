import EventCard from '@services/event/components/EventCard'
import GetawaySearchCard from '@services/getaway/components/GetawaySearchCard'
import PropertyCard from '../components/PropertyCard'

const resourceMap: any = {
	property: {
		label: 'Properties',
		component: PropertyCard,
		data: {
			showAddress: true
		}
	},
	event: {
		label: 'Events',
		component: EventCard
	},
	getaway: {
		label: 'Getaways',
		component: GetawaySearchCard,
		data: {
			propertyVisible: false
		}
	}
}
export default resourceMap
