import GetawayDetail from '../views/GetawayDetail'
import GetawaysPage from '../views/GetawaysPage'

const routes = [
	{
		path: 'getaways',
		children: [
			{
				index: true,
				element: <GetawaysPage />
			},
			{
				path: ':id',
				element: <GetawayDetail />
			}
		]
	}
]
export default routes
