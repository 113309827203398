import { MenuItem, IconButton } from '@mui/material'
import { noop } from 'lodash'
// import { logger } from '@services/common/lib/logger'
import { useRedirect } from 'app/services/common/hooks'
import { Button } from '@services/common/components/Button'

export interface NavItemProps {
	label?: string
	linkTo?: string
	onClick?: any
	variant?: string
	color?: string
	icon?: any
	component?: any
	styles?: any
	sx?: any
}

const NavItem = ({
	label,
	linkTo,
	onClick = noop,
	color = 'default',
	icon: Icon,
	variant = 'text',
	styles = {},
	component: Component,
	classes: injectedClasses = {},
	sx = {}
}: any) => {
	const { redirect }: any = useRedirect()
	const disableRipple: boolean = Icon && label

	const buttonStyles: any = {}
	if (disableRipple) {
		buttonStyles.backgroundColor = 'transparent'
	}
	// logger.log('NavItem', { injectedClasses })
	const startIcon = Icon ? <Icon /> : ''

	const options: any = {
		component: Button,
		dense: true,
		color,
		variant,
		disableElevation: true,
		sx: {
			...injectedClasses.root,
			..._styles.root,
			...sx.root
		},
		style: styles
	}

	if (linkTo) {
		options.onClick = () => redirect(linkTo)
	} else {
		options.onClick = onClick
	}
	if (Icon && label) {
		options.startIcon = startIcon
	}

	// logger.log('NavItem', { options })
	const RenderIcon =
		Icon && !label ? (
			<IconButton {...{ color, onClick }}>
				<Icon />
			</IconButton>
		) : (
			''
		)

	return RenderIcon || <MenuItem {...options}>{label}</MenuItem>
}

const _styles = {
	root: {
		fontSize: 18,
		color: '#525252',
		textTransform: 'none',
		fontWeight: 500
	},
	label: {
		fontSize: 18
	}
}

export default NavItem
