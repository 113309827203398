import React, { forwardRef, useMemo, useCallback, ReactNode, useState } from 'react'
import { TextFieldProps as TextFieldPropsBase, TextField as BaseTextField } from '@mui/material'
import { conformToMask } from 'react-text-mask'
import { noop } from 'lodash'

const MASK_OPTIONS = { guide: false, keepCharPositions: false }
const DEFAULT_ERROR_MESSAGE = 'Invalid'

export type TextFieldProps = TextFieldPropsBase & {
	form?: any
	children?: ReactNode
	placeholder?: string
	variant?: string
	value?: any
	required?: boolean
	fullWidth?: boolean
	validation?: any
	helperText?: string
	mask?: any
	error?: boolean
	errorMessage?: string
	onBlur?: any
	onError?: any
	beforeValidate?: any
	maxLength?: number
	sx?: any
}

const TextField = forwardRef(
	(
		{
			form,
			name,
			children,
			beforeValidate = (value: any) => value,
			validation = () => true,
			mask,
			value: initialValue,
			errorMessage = DEFAULT_ERROR_MESSAGE,
			onBlur: forwardOnBlur = noop,
			onChange = noop,
			onError = noop,
			onFocus = noop,
			autoComplete = 'off',
			maxLength,
			sx = {},
			...rest
		}: TextFieldProps,
		ref: any
	) => {
		const [value, setValue] = useState(initialValue)
		const renderedValue = useMemo(() => {
			if (!mask) {
				return value
			}
			const { conformedValue } = conformToMask(value, mask, MASK_OPTIONS)
			return conformedValue
		}, [mask, value])
		const onBlur = useCallback(
			(e: any) => {
				if (typeof validation === 'function' && !validation(beforeValidate(e.target.value))) {
					onError(e.target.name, 'ValidationFailure')
				}
				forwardOnBlur(e)
			},
			[validation, onError, beforeValidate, forwardOnBlur]
		)

		const handleChange = useCallback(
			(e: any) => {
				setValue(e.target.value)
				onChange(e)
			},
			[onChange]
		)

		const fieldOptions = useMemo(() => {
			const options: any = {
				autoComplete,
				sx,
				...rest,
				ref,
				onChange: handleChange,
				onBlur,
				name,
				value: renderedValue
			}
			Object.assign(options, { id: options.name })
			if (options.error) {
				Object.assign(options, { helperText: errorMessage })
			}
			return options
		}, [autoComplete, sx, rest, ref, handleChange, onBlur, name, renderedValue, errorMessage])

		return <BaseTextField {...fieldOptions}>{children}</BaseTextField>
	}
)

/**
 * @deprecated use default export for async loading
 */
export { TextField }
export default TextField
