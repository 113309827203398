import { EventEmitter } from 'events'
import config from 'app/config/environment'
import { httpRequest } from '@services/common/utils'
import { COMPLETE_MULTIPART_UPLOAD, CREATE_UPLOAD_QUERY } from '../MediaProvider/constants'
import { logger } from '@services/common/lib/logger'
import { toMb } from '../utils/to-mb'

export const multipartUploadThreshold = toMb(5)

export interface UploadConductorConfiguration {
	namespace?: string
	octets?: boolean
	path?: string
	client?: any
	headers?: any
	params?: any
}

export class UploadConductor extends EventEmitter {
	private _id: string = ''
	private namespace: string
	private serializedOctets: boolean

	private _file: any
	private uploadPath: string
	private headers: any
	private isMultiPart: boolean
	private etags: any = {}
	private _progress: any = {}
	private uploadParams: any

	private client: any

	private loaded: number = 0
	private size: number

	private startTimestamp: number = Date.now()
	private endTimestamp?: number

	constructor(
		file: any,
		{
			namespace = '',
			octets = false,
			path = '',
			client,
			headers = {},
			params = {}
		}: UploadConductorConfiguration
	) {
		// call the EventEmitter
		super()
		// set the file context for later consumption
		this._file = file
		// set the namespace
		this.namespace = namespace
		this.serializedOctets = octets
		this.uploadPath = path
		this.client = client
		this.headers = headers
		this.size = file.size
		this.isMultiPart = file.size > multipartUploadThreshold
		this.uploadParams = params

		Object.assign(
			this.headers,
			{
				'Content-Type': 'application/json'
			},
			headers
		)

		this.init()
	}

	get bytesLoaded(): number {
		return Object.keys(this._progress).reduce((total: number, part: string) => {
			total += this._progress[part].loaded
			return total
		}, 0)
	}

	get totalParts(): number {
		return Math.ceil(this.size / multipartUploadThreshold)
	}

	get totalTime() {
		return this.endTimestamp ? ((this.endTimestamp - this.startTimestamp) / 60).toFixed(2) : 0
	}

	get status() {
		return {
			id: this._id,
			bytesLoaded: this.bytesLoaded,
			totalBytes: this.size,
			start: this.startTimestamp,
			end: this.endTimestamp,
			totalTime: this.totalTime
		}
	}

	private init() {
		;(async () => {
			if (this.isMultiPart) {
				await this.createMultipartUpload()
			} else {
				await this.uploadFile()
			}
		})()
	}

	private async uploadFile(): Promise<void> {
		const { urls, id }: any = await this.createUpload(1)
		// set the media id from the create upload response
		this._id = id
		this.onLoad()
		console.log(this._file)
		await this.uploadToServer(urls['1'].url, this._file, 1)
	}

	private async createMultipartUpload() {
		logger.log('createMultipartUpload')
		let reader: any = new FileReader()
		if (reader._readReader) {
			reader = reader._realReader
		}
		const { urls, uploadId, key, id }: any = await this.createUpload(this.totalParts)
		this._id = id

		this.onLoad()
		const promises = Array.from(Array(this.totalParts).keys()).map((part: number) => {
			const partNo = part + 1
			const offset = part * multipartUploadThreshold
			const chunk = this._file.slice(
				offset,
				Math.min(offset + multipartUploadThreshold, this._file.size)
			)

			return this.uploadToServer(urls[partNo], chunk, partNo)
		})

		const results: any = await Promise.all(promises)
		const uploadedParts: any = results.map((part: any, idx: number) => ({
			ETag: this.etags[`${idx + 1}`],
			PartNumber: idx + 1
		}))

		return await this.completeMultipartUpload({
			key,
			uploadId,
			parts: uploadedParts
		})
	}

	private async completeMultipartUpload(input: any) {
		const response = await fetch(config.graphQLHost, {
			method: 'POST',
			headers: {
				...this.headers
			},
			body: JSON.stringify({
				query: COMPLETE_MULTIPART_UPLOAD,
				variables: { input }
			})
		})
		try {
			const { data } = await response.json()
			return data.completeMultipartUpload
		} catch (e) {
			throw e
		}
	}
	/**
	 * create upload signed urls
	 * @param {numbers} parts
	 * @returns {Promise<IUploadConfiguration>}
	 */
	private async createUpload(parts: number) {
		const file = this._file
		logger.log('creatUpload', file)
		const response = await fetch(config.graphQLHost, {
			method: 'POST',
			headers: {
				...this.headers
			},
			body: JSON.stringify({
				query: CREATE_UPLOAD_QUERY,
				variables: {
					input: {
						path: this.uploadPath,
						filename: file.name,
						parts,
						size: file.size,
						type: file.type,
						...this.uploadParams
					}
				}
			})
		})
		try {
			const { data } = await response.json()
			return data.createUpload
		} catch (e) {
			throw e
		}
	}

	private async uploadToServer(url: string, data: any, part?: number) {
		this._progress[`${part}`] = { loaded: 0, total: 0 }
		try {
			return await httpRequest({
				url,
				options: {
					// headers: { Authorization: `Bearer ${localStorage.token}` },
					method: 'PUT',
					body: data
				},
				onProgress: (e: any) => this.onProgress(e, part),
				onComplete: (data: any) => this.onComplete(data, part)
			})
		} catch (e) {
			throw e
		}
	}

	private onLoad() {
		this.emit('load', this.status)
	}

	private onProgress({ loaded, total }: ProgressEvent, part?: number) {
		logger.log('onprogress', loaded, total, loaded === total)
		this._progress[`${part}`] = {
			loaded,
			total
		}
		// this.loaded += loaded
		if (this.bytesLoaded < this.size) {
			logger.log(this.status)
			this.emit('progress', this.status)
		} else if (this.bytesLoaded >= this.size) {
			logger.log('complete', this.status)
			this.emit('complete', this, this.status)
		}
	}

	private onComplete({ headers }: any, part?: number) {
		if (part) {
			this.etags[part] = headers.etag
		}

		logger.log('onComplete', headers.etag, { part })
	}

	private createFormData(extras?: any) {
		const fd = new FormData()
		if (extras) {
			for (let key in extras) {
				fd.append(key, extras[key])
			}
		}

		const paramKey = 'file'
		fd.append(this.toNamespace(paramKey), this._file)
		return fd
	}

	private toNamespace(name: string) {
		return this.namespace ? `${this.namespace}[${name}]` : name
	}

	/**
	 * instantiate the UploadConductor
	 * @param file
	 * @param options
	 */
	static upload(file: any, options: UploadConductorConfiguration = {}) {
		return new UploadConductor(file, options)
	}
}
