import React from 'react'
import { Grid } from '@mui/material'
import FormRow from './FormRow'

export const FormRows = ({ fields = [], options = {} }: any) => {
	return (
		<Grid container spacing={3}>
			{fields.map((field: any, idx: number) => (
				<Grid key={`row-${idx}`} item md={12}>
					<FormRow fields={field} {...options} />
				</Grid>
			))}
		</Grid>
	)
}
