import { Container } from '@mui/material'
import { makeStyles } from '@mui/styles'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import GetawayConcierge from '@services/getaway/components/GetawayConcierge'
import DragRace from '@services/drag-race/components/DragRaceSection'
import RecentArticles from '@services/blog/components/RecentArticles'
import Trending from '@services/destination/components/Trending'
import { PageSection } from '@services/common/components/PageSection'
import HotGetaways from '@services/getaway/components/HotGetaways'
import UpcomingEvents from '@services/event/components/UpcomingEvents'
import MarketingBlock from '@services/cms/marketing-block/components/MarketingBlock'
import SearchCard from '../components/SearchCard/SearchCard'
import { pageData } from '../constants/homepage'
import { useEffect } from 'react'
import { dispatch } from '@services/store'
import { setHeader } from '@services/header/reducer'

const Main = () => {
	useEffect(() => {
		dispatch(
			setHeader({
				variant: 'transparent',
				lock: false
			})
		)
		return () => {
			dispatch(
				setHeader({
					variant: 'light',
					lock: true
				})
			)
		}
	}, [])

	const classes = useStyles()
	return (
		<Container maxWidth={false} disableGutters={true}>
			<SearchCard
				title={pageData.hero.title}
				text={pageData.hero.text}
				image={pageData.hero.image}
				actions={pageData.hero.actions}
				classes={{
					root: classes.hero
				}}
				sx={{
					container: {
						display: 'flex',
						alignItems: 'flex-start',
						justifyContent: 'flex-end',
						pb: 6
					}
				}}
			/>
			<PageSection header="Trending Destinations">
				<Trending />
			</PageSection>

			<HotGetaways />
			<UpcomingEvents canLoadMore={true} />
			<MarketingBlock contentKey="marketing-block.demo-marketing-block" />
			<GetawayConcierge />
			<DragRace />

			<RecentArticles />

			<NewsletterSignup />
		</Container>
	)
}

const useStyles = makeStyles({
	hero: {
		height: 'calc(100vh - 150px)',
		minHeight: 650
	}
})

export default Main
