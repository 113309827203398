import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Chip } from '@mui/material'
import { noop } from 'lodash'
import { mockHandler } from '../../utils'
import SelectField from './SelectField'
import { SelectVariant } from './SelectField/SelectField'

const DEFAULT_FIELD_PROPS = {
	items: [],
	value: '',
	label: '',
	onChange: noop,
	name: '',
	optionLabelPath: 'label',
	optionValuePath: 'value'
}

export interface ListSelectFieldProps {
	items?: any
	value: string | string[]
	label: string
	onChange?: any
	variant?: SelectVariant
	updateForm?: any
	name: string
	beforeUpdate?: any
	optionLabelPath?: string
	optionValuePath?: string
	component?: ReactElement
}

const ListSelectField = ({
	items = [],
	value,
	label,
	onChange = noop,
	updateForm = noop,
	name,
	variant = 'standard',
	beforeUpdate = mockHandler,
	optionLabelPath = 'label',
	optionValuePath = 'value',
	component
}: ListSelectFieldProps = DEFAULT_FIELD_PROPS) => {
	const [isArray]: any = useState(Array.isArray(value))
	const computedValue: string = useMemo(() => {
		return isArray ? '' : (value as string)
	}, [isArray, value])

	useEffect(() => {
		console.log({ ListSelectField: items })
	}, [items])

	const pushItemsChange = useCallback(
		(key: string) => {
			if (!value.includes(key)) {
				const item = items.find((x: any) => x[optionValuePath] === key)
				if (item) {
					updateForm(beforeUpdate({ [name]: [...(value as string[]), item[optionValuePath]] }))
				}
			}
		},
		[items, updateForm, name, value, optionValuePath, beforeUpdate]
	)

	const handleOptionChange = useCallback(
		({ target }: any) => {
			if (isArray) {
				pushItemsChange(target.value)
			} else {
				const update: any = { [target.name]: target.value }
				onChange(beforeUpdate(update))
			}
		},
		[onChange, beforeUpdate, isArray, pushItemsChange]
	)

	const removeItem = useCallback(
		(item: any) => {
			const newState = (value as string[]).filter((x: any) => x !== item.id)
			updateForm({ [name]: newState })
		},
		[updateForm, name, value]
	)

	const dataProxy: any = useMemo(() => {
		return items.length > 0 ? { value, items } : { value: '', items: [] }
	}, [items, value])

	if (items.length === 0) {
		return null
	}
	return (
		<Box display="flex" flex="1" flexDirection="column">
			<SelectField
				name={name}
				value={computedValue}
				label={label}
				variant={variant}
				options={dataProxy.items}
				onChange={handleOptionChange}
				optionLabelPath={optionLabelPath}
				optionValuePath={optionValuePath}
				component={component}
			/>
			{items.length > 0 && isArray && value.length > 0 && (
				<Box pt={1}>
					{(value as string[]).map((id: string) => {
						const item = items.find((x: any) => x.id === id)
						if (!item) {
							return null
						}
						return (
							<Chip
								key={`chip-${name}-${id}`}
								label={item[optionLabelPath]}
								style={{ marginRight: 8, marginBottom: 8 }}
								onDelete={() => removeItem(item)}
							/>
						)
					})}
				</Box>
			)}
		</Box>
	)
}

export default ListSelectField
