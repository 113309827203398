const evalBool = (value?: string) => {
	return value === 'true' || value === '1'
}

const config = (environment: string): any => {
	const ENV: any = {
		name: process.env.REACT_APP_NAME,
		appId: process.env.REACT_APP_ID,
		description: process.env.REACT_APP_DESCRIPTION,
		modulePrefix: process.env.REACT_APP_MODULE_PREFIX,
		brandIcon: {
			dark: 'https://photos.mygaygetaway.com/site/mgg-logo-white.png',
			light: 'https://photos.mygaygetaway.com/site/mgg-logo.png'
		},
		environment,
		rootURL: process.env.REACT_APP_ROOT_URL,
		namespace: process.env.REACT_APP_NAMESPACE,
		static: process.env.REACT_APP_STATIC_DIR,
		domain: process.env.REACT_APP_DOMAIN,
		secure: true,
		defaultTransport: process.env.REACT_APP_SOCKET_TRANSPORT,
		APP: {
			autoboot: true
		},
		copyright: '© 2022 Stays4Gays, All Rights Reserved',
		mapKey: process.env.REACT_APP_GOOGLE_API_KEY,
		authRequired: evalBool(process.env.REACT_APP_AUTH_REQUIRED),
		features: {
			concierge: evalBool(process.env.REACT_APP_CONCIERGE_ENABLED),
			loginEnabled: evalBool(process.env.REACT_APP_LOGIN_ENABLED),
			listWithUsEnabled: evalBool(process.env.REACT_APP_LIST_WITH_ENABLED),
			signupEabled: evalBool(process.env.REACT_APP_SIGNUP_ENABLED)
		}
	}

	if (environment === 'development') {
		ENV.secure = false
		ENV.host = process.env.REACT_APP_API_HOST
		ENV.namespace = ''
		ENV.wsHost = process.env.REACT_APP_SOCKET_HOST
		ENV.graphQLHost = process.env.REACT_APP_GRAPHQL_HOST
		ENV.graphQLWssHost = process.env.REACT_APP_GRAPHQL_SOCKET_HOST
		ENV.staticHost = process.env.REACT_APP_STATIC_ASSET_HOST
		ENV.videosHost = process.env.REACT_APP_VIDEO_ASSET_HOST
	}

	if (environment === 'test') {
		ENV.APP.autoboot = false
	}

	if (environment === 'production') {
		// ENV.rootURL = ''
		ENV.host = 'https://mygaygetaway.com'
		ENV.wsHost = 'wss://api.mygaygetaway.com'
		ENV.graphQLHost = 'https://api.mygaygetaway.com/graphql'
		ENV.graphQLWssHost = 'wss://api.mygaygetaway.com/graphql'
		ENV.staticHost = 'https://photos.mygaygetaway.com'
		ENV.videoHost = 'https://videos.mygaygetaway.com'
		ENV.google = {
			analytics: {},
			places: ''
		}
	}

	return ENV
}
export default config(process.env.NODE_ENV)
