import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const NoMatch = (props: any) => {
	const classes = useStyles()
	return (
		<Box className={classes.root}>
			<Typography variant="h1" className={classes.text}>
				Sorry No Joy
			</Typography>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%'
	},
	text: { color: 'white' }
}))
