import { FC } from 'react'
import { Typography, Breadcrumbs as BreadcrumbsBase, Theme, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link as RouteLink } from 'react-router-dom'
import classNames from 'classnames'

export interface BreadcrumbOption {
	label: string
	linkTo?: string
}

export interface BreadcrumbsProps {
	breadcrumbs?: BreadcrumbOption[]
	containerClassName?: string
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
	breadcrumbs = [],
	containerClassName
}: BreadcrumbsProps) => {
	const classes = useStyles()
	if (!breadcrumbs.length) {
		return null
	}
	return (
		<BreadcrumbsBase className={classNames(classes.breadcrumbs, containerClassName)}>
			{breadcrumbs.map((breadcrumb: any, idx: number) => {
				if (breadcrumb.linkTo) {
					return (
						<Link
							key={`breadcrumb-${idx}`}
							to={breadcrumb.linkTo}
							component={RouteLink}
							style={{ color: '#adb0bb' }}
						>
							{breadcrumb.label}
						</Link>
					)
				} else if (breadcrumb.component) {
					return <breadcrumb.component key={`breadcrumb-${idx}`} />
				} else {
					return <Typography key={`breadcrumb-${idx}`}>{breadcrumb.label}</Typography>
				}
			})}
		</BreadcrumbsBase>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	breadcrumbs: {
		color: '#fff'
	}
}))
