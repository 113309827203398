import { FC, useState } from 'react'
import { Box, Divider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useAuth } from '@services/auth/hooks/use-auth'
import { LoginForm } from '@services/auth/components/LoginForm'
import { logger } from '@services/common/lib/logger'
import { Button } from '@services/common/components/Button'
import BookingForm from './BookingForm'

export interface TabPanelProps {
	children?: any
	index: any
	value: any
}

export interface BookingDialogProps {
	property?: any
}

export const BookingDialog: FC<BookingDialogProps> = (props: BookingDialogProps) => {
	const [skipAuth, setSkipAuth] = useState(false)
	const { isLoggedIn }: any = useAuth()
	const classes: any = useStyles()
	logger.log('BookingDialog', { isLoggedIn, props })

	return (
		<Box className={classes.root}>
			{isLoggedIn || skipAuth ? (
				<BookingForm {...props} />
			) : (
				<Box className={classes.auth}>
					<Box className={classes.box}>
						<LoginForm />
					</Box>
					<Divider orientation="horizontal" className={classes.divider} />
					<Box className={classes.box}>
						<Button size="large" color="primary" onClick={() => setSkipAuth(true)}>
							Continue as Guest
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 0,
		position: 'relative'
	},
	box: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(3)
	},
	auth: {
		display: 'flex',
		flex: 1,
		paddingBottom: theme.spacing(3)
	},
	divider: {
		height: 'auto',
		width: 1
	}
}))
