import { gql } from '@apollo/client'
import { WHITELIST_FRAGMENT } from './whitelist-frag'

export const LIST_WHITELIST = gql`
  query ListWhitelist(
    $filter: TableWhitelistFilterInput
    $limit: Int
    $offset: Int
  ) {
    listWhitelist(filter: $filter, limit: $limit, offset: $offset) {
      total
      size
      items {
        ...WhitelistInfo
        createdAt
        updatedAt
      }
    }
  }
  ${WHITELIST_FRAGMENT}
`
