import { gql } from '@apollo/client'

export const DELETE_PROPERTY_TYPE = gql`
	mutation DeletePropertyType($input: DeletePropertyTypeInput!) {
		deletePropertyType(input: $input) {
			id
			name
			__typename
		}
	}
`
