import { noop } from 'lodash'
import type { BoolCheckHandler } from './types'

export const valueOrFunc = (value: boolean | BoolCheckHandler<any>, data: any) => {
	if (typeof value === 'function') {
		return value(data)
	}
	return value
}

export const enabledValueOrFunc = (
	enabled: boolean,
	value: boolean | BoolCheckHandler<any>,
	data: any
) => {
	if (!enabled) {
		return enabled
	}
	return valueOrFunc(value, data)
}

export const resetState = (state: any, initialState: any) => {
	for (const [field, value] of Object.entries(initialState)) {
		state[field] = value
	}
}

export const makeFormData = (params: any, file: any) => {
	const fd = new FormData()
	if (params) {
		for (const [key, value] of Object.entries(params)) {
			fd.append(key, value as string)
		}
	}
	fd.append('data', file)
	return fd
}

export const filePrompt = (
	onChange: any = noop,
	accept: string = '*',
	multiple?: boolean
): void => {
	const el = document.createElement('div')
	let inputStr = `<input type="file" accept="${accept}"`
	if (multiple) {
		inputStr += ' multiple'
	}
	inputStr += '>'
	el.innerHTML = inputStr

	const fileInput: any = el.firstChild
	fileInput.addEventListener('change', () => onChange(fileInput.files))
	fileInput.click()
	fileInput.removeEventListener('change', onChange)
}
