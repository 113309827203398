import { Box, Button } from '@mui/material'
import { noop } from 'lodash'

const Actions = ({ actions = [], onClick = noop }: any) => {
	// const classes = useStyles()

	if (actions.length === 0) {
		return null
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			{actions.map((action: any, idx: number) => (
				<Button
					key={`action-${idx}`}
					variant="contained"
					size="large"
					onClick={(e: any) => onClick(e, action)}
					sx={{
						backgroundColor: '#44C2D9',
						color: '#fff',
						fontSize: '20px',
						padding: '8px 81px',
						'&:hover': {
							backgroundColor: '#F97487'
						}
					}}
				>
					{action.label}
				</Button>
			))}
		</Box>
	)
}

export default Actions
