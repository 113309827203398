import { gql } from '@apollo/client'
import { WHITELIST_FRAGMENT } from './whitelist-frag'

export const ON_WHITELIST_UPDATE_SUBSCRIPTION = gql`
  subscription OnUpdateWhitelist {
    onUpdateWhitelist {
      ...WhitelistInfo
    }
  }
  ${WHITELIST_FRAGMENT}
`
