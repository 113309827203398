import { gql } from '@apollo/client'

export const DELETE_EVENT = gql`
	mutation DeleteEvent($input: DeleteEventInput!) {
		deleteEvent(input: $input) {
			id
			__typename
		}
	}
`
