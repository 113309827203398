import { gql } from '@apollo/client'

export const GETAWAY_INFO_FRAG = gql`
	fragment GetawayInfo on Getaway {
		id
		title
		startDate
		endDate
		bucket
		link
		photos {
			id
			image
			originalImage
		}
		settings
		description
		property {
			id
			name
		}
		location {
			streetNumber
			route
			googleMap
			address
			country
			state
			city
			zip
			latitude
			longitude
		}
		status
		featured
		# tagline
		createdAt
		updatedAt
	}
`
