import config from '@core/environment'

export const buildRoutePath = (...parts: string[]): string => {
	const uri: any = []
	// console.log({ buildRoutePath: { rootURL: config.rootURL, parts } })
	if (config.rootURL && config.rootURL !== '/') {
		uri.push(config.rootURL)
	}
	uri.push(...parts)
	const url = uri.join('/')
	return url.startsWith('/') ? url : `/${url}`
}
