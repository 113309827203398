import { useMemo } from 'react'
import { Box, Container } from '@mui/material'
import DragRaceHeader from '../components/DragRaceHeader'
import DragRaceWinner from '../components/DragRaceWinner'
import { LIST_DRAG_RACES } from '../graphql/list-drag-races'
import { useQuery } from '@apollo/client'
import { Loading } from '@services/common/components'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import GetawayConcierge from '@services/getaway/components/GetawayConcierge'

const DragRacePage = () => {
	const { data, loading }: any = useQuery(LIST_DRAG_RACES, {
		variables: {
			filter: {
				status: 'published'
			}
		}
	})

	const dragRaces = useMemo(() => {
		return data ? data.listDragRaces.items : []
	}, [data])

	const dragRaceSeasons = useMemo(() => {
		return dragRaces.length > 0 ? dragRaces.map((dragRace: any) => dragRace.season) : []
	}, [dragRaces])

	if (loading) {
		return <Loading overlay={false} />
	}

	return (
		<Box sx={styles.root}>
			<DragRaceHeader items={dragRaceSeasons} />

			<Container maxWidth="md" style={{ padding: '100px 0' }}>
				{dragRaces.length > 0 &&
					dragRaces.map((dragRace: any, idx: number) => (
						<DragRaceWinner key={`drag-race-${idx}`} item={dragRace} />
					))}
			</Container>

			<GetawayConcierge />
			<NewsletterSignup />
		</Box>
	)
}

const styles = {
	root: {
		width: '100%'
	},
	tabRoot: {
		background:
			'url(https://photos.mygaygetaway.com/site/21fd42c6-03b2-425b-b152-f02e91f81327.png) center no-repeat',
		// background:
		// 'url(https://photos.mygaygetaway.com/properties/5d8284855bdcfacc3b1ee9c2/d7a2bf0f-851f-4f1c-a8ec-02856f4fda01.jpg) center no-repeat',
		backgroundSize: 'cover',
		padding: '88px 0',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundColor: '#0B2238',
			opacity: 0.8,
			zIndex: 0
		}
	}
}

export default DragRacePage
