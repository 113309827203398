import { gql } from '@apollo/client'

export const LIST_AMENITIES = gql`
	query ListAmenities($filter: TableAmenityFilterInput, $limit: Int, $offset: Int) {
		listAmenities(filter: $filter, limit: $limit, offset: $offset) {
			total
			size
			items {
				id
				name
			}
		}
	}
`
