import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { noop } from 'lodash'
import AutoCompleteField from './AutoCompleteField'
import useWindow from '@services/common/hooks/use-window'
import config from '@core/environment'

const libraries: any = ['places']

export interface LocationFieldProps {
	name: string
	form?: any
	data?: any
	updateForm?: any
	updateData?: any
	mapVisible?: boolean
	fieldsVisible?: boolean
	latitude?: number
	longitude?: number
	zoom?: number
}

const LocationField = ({
	name,
	form,
	data,
	mapVisible = true,
	fieldsVisible = false,
	latitude = -118.69260713047134,
	longitude = 34.020728937521135,
	zoom = 10,
	updateForm = noop
}: LocationFieldProps) => {
	const classes = useStyles()
	const { isLoaded } = useJsApiLoader({
		id: 'map',
		googleMapsApiKey: config.mapKey,
		libraries
	})
	const win = useWindow()
	const locationRef = useRef(null)

	// useEffect(() => {
	// 	console.log({ LocationField: { key: config.apiKey, isLoaded, form, config } })
	// }, [isLoaded, form, config])
	const [map, setMap] = useState(null)

	useEffect(() => {
		console.log({ map })
	}, [map])

	const handleLocationChange = useCallback(
		(location: any) => {
			updateForm({ [name]: location })
		},
		[name, updateForm]
	)

	const onLoad = useCallback(
		(map) => {
			const bounds = new win.google.maps.LatLngBounds()
			map.fitBounds(bounds)
			setMap(map)
		},
		[setMap, win]
	)

	const onUnmount = useCallback(
		(map) => {
			setMap(null)
		},
		[setMap]
	)

	const defaultAddress = useMemo(() => {
		return data[name]
	}, [data, name])

	return (
		<Box className={classes.root}>
			{isLoaded && (
				<AutoCompleteField
					ref={locationRef}
					map={map}
					label="Location"
					fieldsVisible={fieldsVisible}
					defaultFormState={defaultAddress}
					initialState={defaultAddress ? defaultAddress.address : ''}
					locationUpdated={handleLocationChange}
				/>
			)}
			{isLoaded && mapVisible && (
				<Box className={classes.mapWrap}>
					<GoogleMap
						mapContainerStyle={{
							width: '100%',
							height: '100%'
						}}
						center={{
							lat: latitude,
							lng: longitude
						}}
						zoom={zoom}
						onLoad={onLoad}
						onUnmount={onUnmount}
					/>
				</Box>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	mapWrap: {
		opacity: 0,
		visibility: 'hidden',
		height: 0
	}
}))

export default LocationField
