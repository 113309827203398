import { Box, Card, CardActionArea, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Image } from '@services/common/components/Image'
import { renderDate } from '@services/common/helpers/render-date'
import { renderLocation } from '@services/common/helpers/render-location'
import { useRedirect } from '@services/common/hooks'
import { buildRoutePath } from '@services/common/utils/app'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import { useMemo, useState } from 'react'
import HostProperty from './HostProperty'

export interface GetawaySearchCardProps {
	item?: any
	variant?: 'default' | 'overlay'
	classes?: any
	sx?: any
}

const GetawaySearchCard = ({
	item,
	propertyVisible = true,
	classes: propClasses = {},
	sx = {}
}: any) => {
	const classes = useStyles()
	const { redirect } = useRedirect()
	const [url]: any = useState(buildRoutePath('getaways', item.id))

	const location = useMemo(() => {
		return item.location
	}, [item])

	const property = useMemo(() => {
		return item && item.property
	}, [item])

	const getawayPhoto = useMemo(() => {
		try {
			const photoResource = item.photos && item.photos.length > 0 ? 'getaways' : 'properties'
			const photo = photoResource === 'getaways' ? item.photos[0] : property.photos[0]
			return buildS3ImageUrl(photoResource, photo)
		} catch (e) {
			return ''
		}
	}, [property, item])

	// const propertyUrl = useMemo(() => {
	// 	return property ? buildRoutePath('properties', property.id) : ''
	// }, [property])

	// const eventDate = useMemo(() => {
	// 	const dates = [moment(item.startDate).format('LL')]
	// 	if (item.endDate) {
	// 		dates.push(moment(item.endDate).format('LL'))
	// 	}
	// 	return dates.join(' - ')
	// }, [item])

	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1,
				height: '100%',
				flexDirection: 'column'
			}}
			className={propClasses.root}
		>
			<Card
				sx={{
					marginBottom: 1,
					...sx.card
				}}
			>
				<CardActionArea onClick={() => redirect(url)}>
					<Image aspectRatio={16 / 9} src={getawayPhoto} />
				</CardActionArea>
			</Card>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					marginBottom: 3
				}}
			>
				<Typography
					sx={{
						color: '#656565',
						textTransform: 'uppercase',
						fontSize: '.8rem'
					}}
				>
					{renderLocation(location)}
				</Typography>
				<Typography
					variant="h3"
					sx={{
						// color: '#fff',
						fontSize: '1.1rem',
						fontWeight: 600
					}}
				>
					{item.title}
				</Typography>
				<time className={classes.date}>{renderDate(item.startDate, item.endDate)}</time>
			</Box>
			{propertyVisible && (
				<Box display="flex" flex={1}>
					<HostProperty property={property} />
				</Box>
			)}
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	date: {
		fontSize: '.8rem'
	}
}))

export default GetawaySearchCard
