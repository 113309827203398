import React from 'react'
import { Collapse } from '@mui/material'
import ListSelectField from '../fields/ListSelectField'
import Query from '../Query'

const DynamicSelectFieldRenderer = ({
	data,
	query,
	visible = true,
	record,
	form,
	...rest
}: any) => {
	return (
		<Collapse in={visible} style={{ width: '100%' }}>
			{visible && (
				<Query data={data} {...query}>
					<ListSelectField items={data.items} form={form} {...rest} data={record} />
				</Query>
			)}
		</Collapse>
	)
}
export default DynamicSelectFieldRenderer
