import PropertyDetail from '../views/PropertyDetail'

const routes = [
	{
		path: 'properties',
		children: [
			{
				path: ':id',
				element: <PropertyDetail />
			}
		]
	}
]
export default routes
