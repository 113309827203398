import moment from 'moment'

export type DateString = Date | string

export const renderDate = (startDate: DateString, endDate: DateString) => {
	const _startDate = new Date(startDate)
	const _endDate = new Date(startDate)

	const parts: any = []

	const startMonth = _startDate.getMonth()
	const endMonth = _endDate.getMonth()

	parts.push(moment(_startDate).format('MMM DD'))
	if (startMonth !== endMonth || !endDate) {
		parts.push(_startDate.getFullYear())
	}

	if (endDate) {
		parts.push('-', moment(endDate).format('MMM DD YYYY'))
	}
	return parts.join(' ')
}
