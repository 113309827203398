import { gql } from '@apollo/client'

export const AUTHENTICATE = gql`
	mutation authenticate($email: String!, $password: String!) {
		authenticate(email: $email, password: $password) {
			auth {
				authToken
				expiresAt
			}
			user {
				id
				firstName
				lastName
				email
				account {
					id
					name
				}
			}
		}
	}
`
