import { createElement, FC, ReactElement, useCallback, useRef } from 'react'
import { noop } from 'lodash'
import { AspectRatio } from '@services/common/components/AspectRatio'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

export interface ImageProps {
	src: string
	handleSize?: any
	variant?: 'default' | 'fill'
	onLoad?: any
	styles?: any
	altText?: string
	width?: string
	height?: string
	classes?: any
	aspectRatio?: number
	sx?: any
}

const Image: FC<ImageProps> = ({
	src,
	handleSize = noop,
	onLoad: handleOnLoad = noop,
	styles = {},
	altText,
	aspectRatio,
	classes: injectedClasses = {
		root: '',
		filled: ''
	},
	variant = 'default',
	height,
	width,
	sx = {},
	...rest
}: ImageProps): ReactElement => {
	const ref: any = useRef(null)
	const classes = useStyles()

	const onLoad = useCallback(
		(e: any) => {
			const { naturalWidth, naturalHeight } = ref.current
			handleSize({
				width: naturalWidth,
				height: naturalHeight
			})
			handleOnLoad(e)
		},
		[handleSize, ref, handleOnLoad]
	)

	const Img = createElement('img', {
		style: styles.image,
		src,
		ref,
		height,
		width,
		alt: altText,
		...rest,
		onLoad
	})

	if (variant === 'fill') {
		return (
			<Box sx={sx.root} className={injectedClasses.root}>
				<div
					style={{ backgroundImage: `url(${src})` }}
					className={classNames(classes.filled, injectedClasses.filled)}
				></div>
			</Box>
		)
	}

	if (aspectRatio) {
		return (
			<AspectRatio
				classes={{
					root: injectedClasses.root
				}}
				sx={{
					root: sx.root
				}}
				ratio={aspectRatio}
			>
				<div style={{ backgroundImage: `url(${src})` }} className={classes.filled}></div>
			</AspectRatio>
		)
	}

	return Img
}

const useStyles = makeStyles(() => ({
	filled: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}
}))

/**
 * @deprecated
 * use default export
 */
export { Image }
export default Image
