import React, { useCallback, useState } from 'react'
import { Grid, Tab, Tabs } from '@mui/material'
import TabPanel from './TabPanel'
import FormRow from './FormRow'
import a11yProps from '../utils/a11y-props'

export const TabForm = ({ fields, options, defaultTab = 0 }: any) => {
	// filter only section fields
	const [sections]: any = useState(fields.filter((field: any) => field.type === 'section'))
	const [tabs] = useState(sections.map((field: any) => field.title))
	const [currentTab, setCurrentTab] = useState(defaultTab)

	const handleChange = useCallback(
		(event: any, tab: number) => {
			console.log({ handleChange: { tab, currentTab } })
			if (tab !== currentTab) {
				setCurrentTab(tab)
			}
		},
		[currentTab, setCurrentTab]
	)

	return (
		<>
			<Tabs
				indicatorColor="secondary"
				textColor="secondary"
				value={currentTab}
				onChange={handleChange}
			>
				{tabs.map((tab: string, idx: number) => (
					<Tab key={`tab-${tab}`} label={tab} {...a11yProps(idx)} />
				))}
			</Tabs>
			{sections.map((section: any, idx: any) => (
				<TabPanel key={`tabpanel-${idx}`} value={currentTab} index={idx}>
					<Grid container spacing={3}>
						<Grid key={`row-${idx}`} item md={12}>
							<FormRow fields={section} {...options} />
						</Grid>
					</Grid>
				</TabPanel>
			))}
		</>
	)
}
