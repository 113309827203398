import { Paper, Grid, Typography, Link, Button } from "@mui/material"
import { DEFAULT_COLORS } from "@services/app/constants/app"
import { buildRoutePath } from "@services/common/utils/app"

const Hero = ({
  title,
  text,
  image
}: any) => {

  return <Paper sx={{ ...styles.mainFeaturedPost, backgroundImage: `url(${image})` }}>
    <div style={styles.overlay} />
    <Grid container>
      <Grid item md={6}>
        <div style={styles.mainFeaturedPostContent}>
          {title && <Typography component="h1" variant="h3" color="inherit" gutterBottom
            dangerouslySetInnerHTML={{ __html: title }} />}
          {text && <Typography variant="h5" color="inherit" paragraph
            dangerouslySetInnerHTML={{ __html: text }} />}
          <Link
            sx={styles.button}
            component={Button}
            href={buildRoutePath('my-account', 'listings', 'new', 'property')}>
            Create a new Listing</Link>

        </div>
      </Grid>
    </Grid>
  </Paper>
}

const styles: any = {
  button: {
    backgroundColor: DEFAULT_COLORS.primary,
    color: '#fff',
    padding: '1.125rem 2.25rem',
    fontSize: '1.2rem',
    textTransform: 'none',
    textDecoration: 'none',
    border: `1px solid ${DEFAULT_COLORS.primary}`,
    '&:hover': {
      color: DEFAULT_COLORS.primary,
      // borderColor: DEFAULT_COLORS.primary
      borderColor: DEFAULT_COLORS.primary,
      backgroundColor: '#fff'
    }
  },
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: '#666',
    color: '#fff',
    marginBottom: 6,
    // backgroundImage: 'url()',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: 2,
    padding: 3,
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)'
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: 3
    // [theme.breakpoints.up('md')]: {
    // 	padding: theme.spacing(6),
    // 	paddingRight: 0
    // }
  }
}

export default Hero