import { LIST_AMENITIES } from '@services/amenity/graphql/list-amenities'
import FormMediaPanel from '@services/form/components/upload/FormMediaPanel'
import renderFormModule from '@services/form/helpers/form-module'
import LocationField from '@services/location/components/LocationField'
import resourceMap from '@services/media/constants/resource-map'
import { beforeMediaRender } from '@services/media/utils/before-media-render'
import { LIST_PROPERTY_TYPES } from '@services/property/property-type/graphql/list-property-types'

const imageActions = [
	{
		label: 'Delete Image',
		event: 'delete',
		text: 'Delete'
	}
]

const handleMediaAction = ({ action, item, form: formData, data }: any) => {
	console.log('property-form', { handleMediaAction: { action, item, form: formData, data } })
	switch (action) {
		case 'delete':
			const photos = formData.photos.filter((photo: string) => photo !== item.id)
			return { photos }
		default:
			return {}
	}
}

const fields = [
	renderFormModule(
		[
			[
				{
					label: 'Name',
					name: 'name',
					required: true,
					variant: 'outlined',
					errorMessage: 'Please enter a Name'
				},
				{
					label: 'Link',
					name: 'link',
					variant: 'outlined',
					errorMessage: ''
				},
				{
					label: 'Phone',
					name: 'phone',
					variant: 'outlined',
					required: true,
					errorMessage: ''
				},
				{
					label: 'Email',
					name: 'email',
					variant: 'outlined',
					required: true,
					errorMessage: ''
				}
				// {
				// 	label: 'Country',
				// 	name: 'country',
				// 	variant: 'outlined',
				// 	type: 'select',
				// 	errorMessage: '',
				// 	query: {
				// 		query: LIST_COUNTRIES,
				// 		action: 'listCountries',
				// 		variables: {
				// 			limit: 1000
				// 		}
				// 	},
				// 	fieldOptions: {
				// 		optionValuePath: 'id',
				// 		optionLabelPath: 'name'
				// 	},
				// 	enabled: false
				// },
				// {
				// 	label: 'State',
				// 	name: 'state',
				// 	variant: 'outlined',
				// 	type: 'select',
				// 	errorMessage: '',
				// 	query: {
				// 		query: LIST_STATES,
				// 		action: 'listStates',
				// 		variables: getStateVariables
				// 	},
				// 	fieldOptions: {
				// 		optionValuePath: 'id',
				// 		optionLabelPath: 'name'
				// 	},
				// 	enabled: false
				// },
				// {
				// 	label: 'City',
				// 	name: 'city',
				// 	variant: 'outlined',
				// 	errorMessage: '',
				// 	enabled: false
				// },
				// {
				// 	label: 'Region',
				// 	name: 'region',
				// 	variant: 'outlined',
				// 	errorMessage: ''
				// }
			]
		],
		{
			title: 'Property Info'
		}
	),
	renderFormModule(
		[
			{
				label: 'Property Details',
				name: 'information',
				required: true,
				variant: 'outlined',
				errorMessage: '',
				multiline: true
			}
		],
		{
			title: 'Property Description'
		}
	),
	renderFormModule(
		[
			{
				label: 'Location',
				name: 'location',
				variant: 'outlined',
				type: 'custom',
				component: LocationField,
				fieldOptions: {
					// fieldsVisible: true
				}
			}
		],
		{
			title: 'Property Address'
		}
	),
	renderFormModule(
		[
			[
				{
					label: 'Why Stay',
					name: 'review',
					variant: 'outlined',
					errorMessage: '',
					multiline: true
				}
			]
		],
		{
			title: 'Why Stay'
		}
	),
	renderFormModule(
		[
			[
				{
					label: 'MGG Exclusive',
					name: 'promoAmenities',
					variant: 'outlined',
					errorMessage: '',
					multiline: true
				}
			]
		],
		{
			title: 'MGG Bonus'
		}
	),
	renderFormModule(
		[
			{
				label: 'Amenities',
				name: 'amenities',
				type: 'autocomplete',
				errorMessage: '',
				query: {
					query: LIST_AMENITIES,
					action: 'listAmenities',
					variables: {
						limit: 1000
					}
				},
				fieldOptions: {
					optionValuePath: 'id',
					optionLabelPath: 'name'
				}
			}
		],
		{
			title: 'Property Amenities',
			// position: 'sidebar'
		}
	),
	renderFormModule(
		[
			{
				label: 'Property Types',
				name: 'types',
				// required: true,
				type: 'autocomplete',
				errorMessage: '',
				query: {
					query: LIST_PROPERTY_TYPES,
					action: 'listPropertyTypes',
					variables: {
						limit: 1000
					}
				},
				fieldOptions: {
					optionValuePath: 'id',
					optionLabelPath: 'name'
				}
			}
		],
		{
			title: 'Property Types',
			// position: 'sidebar'
		}
	),
	renderFormModule(
		[
			{
				label: 'Photos',
				name: 'photos',
				type: 'custom',
				component: FormMediaPanel,
				beforeRender: beforeMediaRender(),
				fieldOptions: {
					resource: 'properties',
					aspectRatio: 4 / 3,
					displayFilename: true,
					resourceMap,
					mediaActions: imageActions,
					handleMediaAction
				}
			}
		],
		{
			title: 'Property Photos'
		}
	)

]
export default fields
