import { useCallback, useContext, useMemo } from 'react'
import { AppContext } from '@services/app/AppProvider'

export const useApp = (appContext: any = AppContext) => {
	const { state, initialState, ...rest }: any = useContext(appContext)

	const context = useMemo(() => {
		return state || initialState
	}, [state, initialState])

	const appName = useMemo(() => {
		return context ? context.name : ''
	}, [context])

	const brandIcon = useMemo(() => {
		if (!context) {
			return ''
		}
		const icons = context.brandIcon
		const state = initialState.header.variant
		return icons[state]
	}, [context, initialState])

	const header = useMemo(() => {
		return context ? context.header : { state: 'off' }
	}, [context])

	const loading: boolean = useMemo(() => {
		return context ? context.loading : false
	}, [context])

	const bottomDrawer = useMemo(() => {
		return context ? context.bottomDrawer : {}
	}, [context])

	const rightDrawer = useMemo(() => {
		return context ? context.rightDrawer : {}
	}, [context])

	const handleClose = useCallback(() => {
		// setOpen(false)
		console.log('closed')
	}, [])

	return {
		appName,
		brandIcon,
		loading,
		header,
		handleClose,
		bottomDrawer,
		rightDrawer,
		listWithUsEnabled: context.listWithUsEnabled,
		...rest
	}
}
