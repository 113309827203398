import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Loading } from '@services/common/components'
import ModalConductor from '@services/common/components/ModalConductor'
import { LoginForm } from './LoginForm'
import { useAuth } from '../hooks/use-auth'

export const LoginPage = (props: any) => {
	const classes = useStyles()
	const { loading }: any = useAuth()

	return (
		<Box id="login" className={classes.root}>
			<Box className={classes.form}>
				{loading && <Loading />}
				<LoginForm
					header="Sign In"
					rootClassName={classes.loginForm}
					headerClassName={classes.formHeader}
				/>
			</Box>

			<ModalConductor />
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundSize: 'cover',
		height: '100vh',
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	form: {
		display: 'flex',
		height: '100vh',
		width: '40%',
		justifyContent: 'center',
		backgroundColor: 'rgba(0,0,0,.7)',
		flexDirection: 'column'
	},
	loginForm: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	formHeader: {
		color: 'white',
		alignText: 'center'
	}
}))
