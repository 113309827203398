import React from 'react'
import { Collapse } from '@mui/material'
import TextField from '../fields/TextField'

const TextFieldRenderer = ({ form, data, visible = true, ...rest }: any) => {
	// logger.log({ renderTextField: { form, data } })
	const computedVisibility = ((visibility: any) => {
		return typeof visibility === 'function' ? visible(form, data) : visible
	})(visible)
	return (
		<Collapse in={computedVisibility} style={{ width: '100%' }}>
			{computedVisibility && <TextField form={form} data={data} {...rest} />}
		</Collapse>
	)
}
export default TextFieldRenderer
