import React, { useMemo } from 'react'
import { Box, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'
import { MediaManagerCard } from './MediaManagerCard'
import { Media } from '@services/app/types'
import { Loading } from '@services/common/components/Loading'
import { useQuery } from '@apollo/client'
import { LIST_MEDIA } from '../graphql/list-media'

export interface MediaSelectorProps {
	onSelected: any
	resource: string
	multiSelect: boolean
	filter: any
	columns: number
}

export const MediaSelector = ({
	onSelected = noop,
	multiSelect = false,
	resource,
	filter = {},
	columns = 3
}: MediaSelectorProps) => {
	const classes: any = useStyles()

	const { loading, data }: any = useQuery(LIST_MEDIA, { variables: { filter } })

	const column: any = useMemo(() => {
		return 12 / columns
	}, [columns])

	const media: Media[] = useMemo(() => {
		return data ? data.listMedia.items : []
	}, [data])

	return (
		<Box className={classes.root}>
			{loading && <Loading overlay={false} />}
			<Grid container spacing={2}>
				{media.map((item: Media) => (
					<Grid item md={column} key={`media-${item.id}`}>
						<MediaManagerCard
							item={item}
							resource={resource}
							aspectRatio={1}
							onClick={onSelected}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		minWidth: 500,
		padding: theme.spacing(2),
		zIndex: theme.zIndex.appBar + 2
	}
}))
