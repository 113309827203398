import { FC, useState } from 'react'
import { Box, Typography, Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useRedirect } from '@services/common/hooks/use-redirect'
import { ActionButton } from '@services/common/components/ActionButton'
import { Breadcrumbs } from '@services/common/components/Breadcrumbs'
// import { ActionButton } from './ActionButton'

export type GroupActionsDisplayVariant = 'default' | 'dial' | 'ellipse'

export interface InterfaceAction {
	name?: string
	icon?: any
	linkTo?: string
	event?: string
	onClick?: any
}

export interface GroupActionDialOptions {
	direction?: any
	tooltipPlacement?: any
	onClick?: any
}

export interface GropupActions {
	variant?: GroupActionsDisplayVariant
	options?: GroupActionDialOptions
	items?: InterfaceAction[]
}

export interface PageHeaderProps {
	title?: string
	text?: string
	breadcrumbs?: any
	actions?: GropupActions
	mainActions?: GropupActions
	component?: any
	sx?: any
}

const PageHeader: FC<PageHeaderProps> = ({
	title,
	text,
	breadcrumbs = [],
	actions = {
		variant: 'default',
		items: []
	},
	mainActions = {
		variant: 'default',
		options: {
			direction: 'top'
		},
		items: []
	},
	component: Component,
	sx = {}
}: PageHeaderProps) => {
	const [isDialVariant]: any = useState(mainActions && mainActions.variant === 'dial')
	const classes = useStyles()
	const { redirect }: any = useRedirect()

	return (
		<Box display="flex" flexDirection="row" sx={sx.root}>
			<Box display="flex" flex={1} flexDirection="column" sx={sx.titleBlock}>
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				{title && (
					<Typography
						variant="h3"
						sx={sx.title}
					>
						{title}
					</Typography>
				)}
				{mainActions && (
					<Box className={classes.mainActions}>
						{isDialVariant ? (
							<ActionButton items={mainActions.items} {...mainActions.options} />
						) : (
							mainActions.items &&
							mainActions.items.length > 0 &&
							mainActions.items.map((action: any) => {
								const buttonOptions: any = {
									key: `header-action-main-${action.label}`,
									variant: action.variant || 'contained',
									color: action.color || 'primary',
									onClick: action.onClick,
									className: classes.mainActionButton
								}
								if (action.linkTo) {
									buttonOptions.onClick = () => redirect(action.linkTo)
								}
								if (action.icon) {
									const Icon: any = action.icon
									buttonOptions.startIcon = <Icon />
								}
								if (action.buttonRef) {
									Object.assign(buttonOptions, { ref: action.buttonRef })
								}
								return <Button {...buttonOptions}>{action.label}</Button>
							})
						)}
					</Box>
				)}

				{Component && <Component />}
			</Box>
			<Box>
				{actions.items &&
					actions.items.map((action: any) => {
						const buttonOptions: any = {
							key: `header-action-${action.label}`,
							variant: action.variant || 'contained',
							color: action.color || 'primary',
							onClick: action.onClick,
							className: classes.mainActionButton
						}
						if (action.icon) {
							const Icon: any = action.icon
							buttonOptions.startIcon = <Icon />
						}
						return <Button {...buttonOptions}>{action.label}</Button>
					})}
			</Box>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	breadcrumbs: {
		color: '#fff'
	},
	mainActions: {
		paddingTop: theme.spacing(2)
	},
	mainActionButton: {
		marginRight: theme.spacing(1)
	},
	actionButton: {
		marginLeft: theme.spacing(1)
	}
}))

export default PageHeader
