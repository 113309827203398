import DragRacePage from '../views/DragRacePage'

const routes = [
	{
		path: 'dragrace',
		children: [
			{
				index: true,
				element: <DragRacePage />
			}
		]
	}
]
export default routes
