import { useMemo } from 'react'
import { DocumentNode, useQuery } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import { logger } from '@services/common/lib/logger'
// import EventCard from '@services/event/components/EventCard'
// import { LIST_EVENTS } from '@services/event/graphql'
// import GetawayCard from '@services/getaway/components/GetawayCard'
// import { LIST_GETAWAYS } from '@services/getaway/graphql'
import PropertyCard from '@services/property/components/PropertyCard'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql'
import Hero from '@services/account/components/Hero'
import { LIST_LISTING_REQUESTS } from '../graphql/list-listing-requests'
import { buildRoutePath } from '@services/common/utils/app'
import { useAuth } from '@services/auth/hooks/use-auth'

const Row = ({
	header,
	query,
	action,
	component: Component
}: {
	header: string
	query: DocumentNode
	action: string
	component: any
}) => {
	const { contextUser } = useAuth()
	const { data, loading } = useQuery(query, {
		variables: {
			limit: 3,
			filter: {
				account: contextUser.account?.id
			}
		}
	})

	const items = useMemo(() => {
		return data ? data[action].items : []
	}, [action, data])

	return (
		<Box sx={{ marginBottom: 3, width: '100%' }}>
			<Grid container spacing={3}>
				<Grid item md={12}>
					<Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 600 }}>
						{header}
					</Typography>
				</Grid>

				{items.length > 0 ?
					items.map((item: any) => (
						<Grid item md={4} key={item.id}>
							<Component item={item.listing} url={buildRoutePath('my-account', 'listings', 'edit', 'property', item.listingId)} />
						</Grid>
					)) : !loading && <Grid item md={12}><Typography>You currently have no listings.</Typography></Grid>}
			</Grid>
		</Box>
	)
}

const Properties = ({ openAdminNav, ...props }: any) => {
	logger.log('MyAccount', props)

	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.property-listings'
		}
	})

	const heroContent = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content,
				image: 'https://photos.mygaygetaway.com/properties/5d82847b5bdcfacc3b1edbbc/4f961619-d9d4-4bfe-bee1-f281c5b70a83.jpg'
			})
		}
		return res
	}, [data])

	return (
		<Box sx={styles.root}>
			<Hero {...heroContent} />

			<Grid container spacing={3}>
				<Grid item md={12}>
					<Row
						header=""
						query={LIST_LISTING_REQUESTS}
						action="listListingRequests"
						component={PropertyCard}
					/>
					{/* <Divider sx={{ width: '100%', margin: '0 16px 24px' }} />
						<Row header="Events" query={LIST_EVENTS} action="listEvents" component={EventCard} />
						<Divider sx={{ width: '100%', margin: '0 16px 24px' }} />
						<Row
							header="Getaways"
							query={LIST_GETAWAYS}
							action="listGetaways"
							component={GetawayCard}
						/> */}
				</Grid>
			</Grid>
		</Box>
	)
}

const styles: any = {
	root: {
		display: 'flex',
		flexDirection: 'column'
		// paddingTop: '50px'
	},
	mainFeaturedPost: {
		position: 'relative',
		backgroundColor: '#666',
		color: '#fff',
		marginBottom: 6,
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		borderRadius: 2,
		padding: 3,
		overflow: 'hidden'
	},
	overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0,.3)'
	},
	mainFeaturedPostContent: {
		position: 'relative',
		padding: 3
		// [theme.breakpoints.up('md')]: {
		// 	padding: theme.spacing(6),
		// 	paddingRight: 0
		// }
	}
}

export default Properties
