import { gql } from "@apollo/client";
import { PROPERTY_INFO_FRAG } from "@services/property/graphql/property-info-frag";

export const UPDATE_LISTING = gql`
  mutation UpdateListing($input: UpdateListingInput!) {
    updateListing(input: $input) {
      ... on Property {
        ...PropertyInfo
      }
    }
  }

	${PROPERTY_INFO_FRAG}
`