export enum HydrateEvent {
	Add,
	Update,
	Remove
}

export interface PageableResult {
	items: any
	total?: number
	size?: number
}
