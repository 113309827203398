import { useMemo } from 'react'
import { Box, Link } from '@mui/material'
import { isOdd } from '../utils/is-odd'
import AspectRatio from './AspectRatio'
import { logger } from '../lib/logger'

const MAX_ITEMS = 5

export interface ItemGridProps {
	gutter?: number
	items?: any
	component?: any
	aspectRatio?: number
	classes?: any
	onClick?: any
	displayHoverState?: boolean
	sx?: any
}

const ItemGrid = ({
	gutter = 0,
	items = [],
	component: Component,
	aspectRatio = 16 / 9,
	onClick,
	displayHoverState = false,
	sx = {}
}: ItemGridProps) => {
	if (items.length > MAX_ITEMS) {
		throw new Error('Too many items passed')
	}

	const totalItems = useMemo(() => {
		return items.length
	}, [items])

	const computedItems = useMemo(() => {
		if (isOdd(totalItems) && totalItems > 1) {
			const [firstItem, ...rest]: any = items
			return [firstItem, rest]
		}
		return items
	}, [items, totalItems])

	logger.log('ItemGrid', { computedItems })

	const renderItems = (nested: any) => {
		return nested.map(renderItem)
	}

	const linkStyles = useMemo(() => {
		const ls = {}
		if (displayHoverState) {
			Object.assign(ls, {
				overflow: 'hidden',
				'&:hover': {
					cursor: 'pointer'
				}
			})
		}
		return styles
	}, [displayHoverState])

	const renderItem = (item: any, idx: number) => {
		if (Array.isArray(item)) {
			return (
				<Box
					key={`item-grid-wrap-${idx}`}
					sx={styles.item}
					flexDirection={totalItems > 3 ? 'row' : 'column'}
				>
					{renderItems(item)}
				</Box>
			)
		}

		const itemSx = { ...styles.item }
		totalItems === 1 && Object.assign(itemSx, styles.single)

		return (
			<Box
				key={`item-grid-${item.id}`}
				// style={{ order: idx + 1 }}
				sx={itemSx}
			>
				{onClick ? (
					<Link onClick={(e) => onClick(e, item)} sx={linkStyles}>
						<Component item={item} />
					</Link>
				) : (
					<Component item={item} />
				)}
			</Box>
		)
	}

	if (computedItems.length === 0) {
		return null
	}

	return (
		<Box
			sx={{
				...styles.root,
				...sx.root
			}}
		>
			<AspectRatio
				ratio={aspectRatio}
				sx={{
					wrap: {
						...styles.container,
						...sx.container
					}
				}}
			>
				{computedItems.map(renderItem)}
			</AspectRatio>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 1
	},
	container: {
		flexWrap: 'wrap',
		gap: 8
	},
	large: {
		// flexGrow: 2
	},
	small: {
		// flexGrow: 1,
		// flexShrink: 2
	},
	item: {
		display: 'flex',
		width: `calc(50% - 4px)`,
		position: 'relative',
		flexWrap: 'wrap',
		gap: 1
	},
	single: {
		width: '100%'
	}
}

export default ItemGrid
