import { FormEvent, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
	Box,
	CircularProgress,
	Container,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Theme,
	Typography
} from '@mui/material'
import { ArrowForwardOutlined, MailOutline } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql'
import CheckmarkAnimated from '@services/common/components/CheckmarkAnimated'
import classNames from 'classnames'
import NEWSLETTER_SIGNUP from '../graphql/newsletter-signup'
import { validEmail } from 'app/packages/form/src/utils'

const CONTENT_KEY = 'marketing-block.newsletter-signup'

export interface NewsletterSignupProps {
	contentKey?: string
	classes?: any
	placeholder?: string
}

const NewsletterSignup = ({
	// title = 'Get the hottest destinations right into. your inbox',
	// text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt tortor rutrum,
	// laoreet purus et, fringilla metus. Nam auctor arcu leo, et ultricies felis tristique eu.`,
	contentKey = CONTENT_KEY,
	placeholder = 'Enter your email...',
	classes: injectedClasses = { root: '', container: '' }
}: NewsletterSignupProps) => {
	const [error, setError] = useState('')
	const [email, setEmail] = useState('')
	const [honeypot, setHoneypot] = useState('')
	const { data: contentData, loading: loadingContent } = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: contentKey
		}
	})
	const [signupNewsletter, { data, loading, error: requestError, reset }] =
		useMutation(NEWSLETTER_SIGNUP)

	useEffect(() => {
		requestError && setError(requestError.message)
	}, [requestError])

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()
		if (!email || !validEmail(email)) {
			setError('Please enter a valid email')
		}
		email &&
			signupNewsletter({
				variables: {
					input: {
						email,
						name: honeypot
					}
				}
			})
	}

	const clearError = () => {
		setError('')
	}

	const success = useMemo(() => {
		return (
			data &&
			data.createNewsletter &&
			(data.createNewsletter.status === 'success' || data.createNewsletter.status === 'pending')
		)
	}, [data])

	const content = useMemo(() => {
		return contentData ? contentData.getContentByKey : {}
	}, [contentData])

	useEffect(() => {
		const clear = () => {
			setTimeout(() => {
				reset()
				setEmail('')
				setHoneypot('')
			}, 3000)
		}
		success && clear()
		// return clear()
	}, [reset, success])

	const handleInputChange = ({ target }: any) => {
		setEmail(target.value)
	}

	const classes = useStyles()

	if (loadingContent) {
		return null
	}
	return (
		<Box className={classNames(classes.root, injectedClasses.root)}>
			<Container maxWidth="md" className={classNames(classes.container, injectedClasses.container)}>
				{content.title && (
					<Typography className={classes.title} variant="h3">
						{content.title}
					</Typography>
				)}
				<form className={classes.form} onSubmit={handleSubmit}>
					<OutlinedInput
						placeholder={placeholder}
						classes={{
							root: classes.inputRoot,
							input: classes.input
						}}
						type="email"
						value={email}
						autoComplete="off"
						fullWidth
						onChange={handleInputChange}
						onFocus={clearError}
						startAdornment={
							<InputAdornment position="start">
								<MailOutline className={classes.inputAdornment} />
							</InputAdornment>
						}
					/>
					<IconButton
						type="submit"
						disabled={loading || !!error || success}
						className={classes.button} //, classes.buttonLabel
					>
						{success && (
							<span className={classes.success}>
								<CheckmarkAnimated />
							</span>
						)}
						<ArrowForwardOutlined />

						{loading && <CircularProgress size={52} className={classes.fabProgress} />}
					</IconButton>
					<input type="hidden" name="name" value={honeypot} />
				</form>
				{error && <Typography className={classes.error}>{error}</Typography>}
				{content.text && <Typography className={classes.text}>{content.text}</Typography>}
			</Container>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: '#F5F5F5'
	},
	container: {
		paddingTop: 100,
		paddingBottom: 100,
		textAlign: 'center'
	},
	title: {
		fontSize: '2rem !important',
		maxWidth: '50%',
		margin: '0em auto 24px!important',
		fontWeight: 600
	},
	text: {
		fontSize: '.9rem',
		maxWidth: '70%',
		fontStyle: 'italic',
		color: '#767676',
		margin: '0em auto'
	},
	form: {
		position: 'relative',
		'& button': {
			position: 'absolute !important',
			top: '50%',
			transform: 'translateY(-50%)'
		}
	},
	inputRoot: {
		borderRadius: '30px !important',
		backgroundColor: '#fff',
		border: 'none'
		// marginBottom: 24
	},
	input: {
		paddingRight: 60
	},
	inputAdornment: {
		color: '#767676'
	},
	button: {
		position: 'absolute',
		right: 5,
		top: 4
	},
	buttonLabel: {
		color: '#767676'
	},
	success: {
		position: 'absolute',
		width: 30,
		height: 30,
		top: 6
	},
	fabProgress: {
		color: '#44C2D9',
		position: 'absolute',
		top: -2,
		left: -2,
		zIndex: 1
	},
	error: {
		color: '#ff000'
	}
}))
export default NewsletterSignup
