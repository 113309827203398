import { ApolloClient } from '@apollo/client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import config from '@core/environment'
import { resolvers } from './resolvers'
import cache from './cache'

const link: any = new HttpLink({
	uri: config.graphQLHost,
	headers: {
		AppId: config.appId
	}
})

const authLink: any = setContext((_, { headers }) => {
	const requestHeaders: any = { ...headers }
	const token = localStorage.getItem('token')
	if (token) {
		requestHeaders.Authorization = `Bearer ${token}`
	}
	return {
		headers: {
			...requestHeaders
		}
	}
})

const client: any = new ApolloClient({
	cache,
	resolvers,
	link: authLink.concat(link),
	name: 'mygaygetaway',
	version: '1.0.6',
	queryDeduplication: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-first'
		},
		query: {
			fetchPolicy: 'cache-first'
		}
	}
})

export default client
