import { gql } from '@apollo/client'
import { WHITELIST_FRAGMENT } from './whitelist-frag'

export const GET_WHITELIST = gql`
  query GetWhitelist($id: ID!) {
    getWhitelist(id: $id) {
      ...WhitelistInfo
    }
  }
  ${WHITELIST_FRAGMENT}
`
