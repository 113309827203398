import { extractDataObjects } from './extract-data-objects'
import { formify } from './formify'
import { nullDefault } from './null-default'
import { pluck } from './pluck'

export const prepareFormState = ({
	record = {},
	initialState = {},
	data = {},
	retentionRules = []
}: any) => {
	return {
		form: {
			...initialState,
			...formify(nullDefault(initialState, pluck(initialState, record, retentionRules)))
		},
		data: { ...data, ...extractDataObjects(record) }
	}
}
