import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'
import { FormRows } from './FormRows'

export interface FormSidebarProps {
	fields?: any
	visible?: boolean
	options?: any
}

const FormSidebar: FC<FormSidebarProps> = ({
	fields = [],
	visible = false,
	options = {}
}: FormSidebarProps) => {
	const classes: any = useStyles()

	if (fields.length === 0) {
		return null
	}
	return (
		<Box className={classes.sidebar}>
			<Box className={classes.content}>
				<FormRows fields={fields} options={options} />
			</Box>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	sidebar: {
		display: 'flex',
		flex: 1
	},
	content: {
		width: '100%'
	}
}))

export default FormSidebar
