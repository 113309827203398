import { gql } from '@apollo/client'

export const PROPERTY_BASIC_FRAG = gql`
	fragment PropertyBasic on Property {
		id
		name
		status
		createdAt
		updatedAt
		__typename
	}
`
