import { useMutation } from '@apollo/client'
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material'
import { TextField } from 'app/packages/form/src'
import { Button } from '@services/common/components/Button'
import { CREATE_CONTACT_REQUEST } from '@services/communication/graphql/contact-request'
import { FormEvent, useContext, useEffect, useMemo, useState } from 'react'
import CheckmarkAnimated from '@services/common/components/CheckmarkAnimated'
import { useParams } from 'react-router-dom'
import { ContactContext } from './ContactContext'

const MESSAGES = {
	'getaway-concierge': 'Hi, I would like to know more about the Getaway Concierge service',
	'list-with-us': 'Hi, I have a property that I believe would be a good fit for your collection',
	'advertise-with-us': 'Hi, I would like to know more about advertising with MyGayGetaway.com'
}

const ContactPage = () => {
	const params: { subject?: string } = useParams()
	const { useContactMessage } = useContext(ContactContext)
	const contactMessage = useContactMessage()

	const [subject] = useState(params.subject || '')
	const [honeypot, setHoneypot] = useState('')
	const [fullName, setFullName] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState(
		[MESSAGES[subject], contactMessage].filter(Boolean).join('\n\n') || ''
	)
	const [send, { data, loading, error, reset }] = useMutation(CREATE_CONTACT_REQUEST)

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault()

		// console.log('ContactPage', { fullName, email, message, honeypot })
		const input: any = {
			fullName,
			email,
			message,
			phone: honeypot
		}
		subject && (input.subject = subject)
		send({
			variables: {
				input
			}
		})
	}

	const success = useMemo(() => {
		return data && data.createContactRequest && !!data.createContactRequest.id
	}, [data])

	useEffect(() => {
		const clear = () => {
			setTimeout(() => {
				reset()
				setFullName('')
				setEmail('')
				setMessage('')
				setHoneypot('')
			}, 3000)
		}
		success && clear()
		return clear()
	}, [reset, success])

	const formStyles = useMemo(() => {
		const out = {
			...styles.formNoFill
		}
		success && Object.assign(out, styles.formHidden)
		return out
	}, [success])

	return (
		<Box sx={styles.root}>
			<Container maxWidth="lg" sx={styles.container}>
				<Grid container spacing={10}>
					<Grid item md={6} sm={12}>
						<Box sx={styles.pageMessage}>
							<Typography variant="h3" sx={styles.title}>
								We'd love to hear from you.
							</Typography>
							<Typography sx={styles.text}>
								Drop us a message and someone will get back to you as soon as possible.
							</Typography>
						</Box>
					</Grid>
					<Grid item md={6} sm={12}>
						<Box sx={styles.formSpacer} />
						<Box sx={styles.formContainer}>
							<Box sx={formStyles}>
								<Typography sx={styles.formTitle} variant="h4">
									Send us a message.
								</Typography>
								{error && <Typography>{error.message}</Typography>}
								<form style={styles.form} onSubmit={handleSubmit}>
									<Box sx={styles.formWrap}>
										<Box sx={styles.inputControl} style={{ marginBottom: 8 }}>
											<TextField
												sx={styles.input}
												name="fullName"
												label="Full Name"
												value={fullName}
												onChange={({ target }: any) => setFullName(target.value)}
												fullWidth={true}
												required={true}
												variant="standard"
											/>
										</Box>
										<Box sx={styles.inputControl} style={{ marginBottom: 8 }}>
											<TextField
												sx={styles.input}
												name="email"
												label="Email"
												value={email}
												onChange={({ target }: any) => setEmail(target.value)}
												fullWidth={true}
												required={true}
												variant="standard"
											/>
										</Box>
										<Box sx={styles.inputControl}>
											<TextField
												sx={styles.input}
												name="message"
												label="Message"
												value={message}
												onChange={({ target }: any) => setMessage(target.value)}
												multiline={true}
												fullWidth={true}
												required={true}
												variant="standard"
											/>
										</Box>
									</Box>
									<Button
										sx={styles.formButton}
										type="submit"
										color="secondary"
										size="large"
										fullWidth={true}
									>
										Send
										{loading && (
											<span>
												<CircularProgress sx={styles.fabProgress} />
											</span>
										)}
									</Button>

									<input type="hidden" name="phone" value={honeypot} />
								</form>
							</Box>
							{success && (
								<Box sx={styles.formSuccess}>
									<span style={styles.success}>
										<CheckmarkAnimated />
									</span>
									<Typography sx={styles.successTitle} variant="h3">
										Sent!
									</Typography>
								</Box>
							)}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

const styles: any = {
	root: {
		background:
			'url(https://photos.mygaygetaway.com/properties/5d82847c5bdcfacc3b1edd31/997eed4a-36b1-423d-861b-e3d7d52b7e91.jpg) center',
		backgroundSize: 'cover',
		// paddingTop: 60,
		minHeight: '100vh',
		display: 'flex',
		flex: 1
	},
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	pageMessage: {
		borderRadius: 2,
		backgroundColor: 'rgba(0,0,0,.7)',
		padding: '32px 120px 32px 32px',
		color: '#fff'
	},
	title: {
		marginBottom: 3,
		fontWeight: 'bold'
	},
	text: {},
	formNoFill: {},
	formContainer: {
		position: 'relative',
		backgroundColor: '#fff',
		borderRadius: 2,
		padding: 4
	},
	formWrap: {
		border: '1px solid rgb(0 0 0 / 42%)',
		borderRadius: 2,
		marginBottom: 5,
		overflow: 'hidden',
		paddingTop: 1,
		paddingBottom: -2
	},
	form: {},
	formTitle: {
		fontWeight: 'bold',
		marginBottom: 3,
		color: '#F97487'
	},
	formSpacer: {
		paddingTop: 8
	},
	formSuccess: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	},
	formHidden: {
		opacity: 0
	},
	success: {
		display: 'inline-flex',
		// position: 'absolute',
		position: 'relative',
		width: 48,
		height: 48, //theme.spacing(7),
		marginBottom: 3
	},
	successTitle: {
		fontWeight: 'bold'
	},
	inputControl: {},
	input: {
		'& .MuiInputBase-input': {
			paddingLeft: 2
		},
		'& .MuiInputLabel-formControl': {
			left: 16
		},
		'&-notchedOutline': {
			border: 'none'
		}
		// '& .MuiInput-underline:before': {
		// 	display: 'none'
		// },
		// '& .Mui-focused:after': {
		// 	display: 'none'
		// }
	},
	formButton: {
		backgroundColor: '#F97487',
		color: '#fff',
		borderRadius: 2,
		padding: '16px 0',
		'& .MuiButton-label': {
			fontSize: 18
		},
		'&:hover': {
			backgroundColor: '#F97487'
		}
	},
	fabProgress: {
		color: '#44C2D9',
		width: '18px !important',
		height: '26px !important',
		marginLeft: 8,
		marginTop: -2,
		// position: 'absolute',
		// top: -2,
		// left: -2,
		zIndex: 1
	}
}
export default ContactPage
