import { gql } from '@apollo/client'

export const DRAG_RACE_FRAG = gql`
	fragment DragRaceInfo on DragRace {
		id
		season
		type
		link
		video
		winner {
			name
			image {
				id
				image
				originalImage
			}
		}
		image {
			id
			image
			originalImage
		}
		status
	}
`
