import { Box, Grid, TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'
import React from 'react'
import { locationState } from '../constants/form'

export interface LocationFieldsProps {
	form?: any
	onSubmit?: any
	disabled?: boolean
	handleInputChange?: any
}

const LocationFields = ({
	form = locationState,
	onSubmit = noop,
	disabled = true,
	handleInputChange = noop
}: LocationFieldsProps) => {
	const classes = useStyles()

	return (
		<Box className={classes.root}>
			<Grid container spacing={3}>
				<Grid item md={12}>
					<TextField
						name="streetNumber"
						label="Street Number"
						value={form.streetNumber || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="route"
						label="Route"
						value={form.route || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="city"
						label="City"
						value={form.city || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="state"
						label="State"
						value={form.state || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="country"
						label="Country"
						value={form.country || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="zip"
						label="Zip"
						value={form.zip || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="latitude"
						label="Latitude"
						value={form.latitude || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="longitude"
						label="Longitude"
						value={form.longitude || ''}
						variant="outlined"
						fullWidth
						disabled={disabled}
						onChange={handleInputChange}
					/>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		backgroundColor: 'rgba(0,0,0,.2)',
		borderRadius: theme.spacing(1),
		marginTop: theme.spacing(3)
	}
}))

export default LocationFields
