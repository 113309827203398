import { RouteObject } from 'react-router-dom'
import account from '@services/account/constants/routes'
import getaways from '@services/getaway/constants/routes'
import events from '@services/event/constants/routes'
import properties from '@services/property/constants/routes'
import blog from '@services/blog/constants/routes'
import dragrace from '@services/drag-race/constants/routes'
import search from '@services/search/constants/routes'
import Main from '../views/Main'
import PartnersPage from '../views/Partners'
import TermsPage from '../views/Terms'
import ContactPage from '../views/Contact'
import ListWithUs from '../../listing/views/ListWithUs'
import { NoMatch } from '../components/NoMatch'

const routes: RouteObject[] = [
	{
		path: '/',
		children: [
			{
				index: true,
				element: <Main />
			},
			...getaways,
			...events,
			...properties,
			...blog,
			...dragrace,
			...search,
			...account,
			{
				path: 'partners',
				element: <PartnersPage />
			},
			{
				path: 'terms',
				element: <TermsPage />
			},
			{
				path: 'contact',
				children: [
					{
						index: true,
						element: <ContactPage />
					},
					{
						path: ':subject',
						element: <ContactPage />
					}
				]
			},
			{
				path: 'list-with-us',
				element: <ListWithUs />
			}
		]
	},
	{
		path: '*',
		element: <NoMatch />
	}
]

export default routes
