import { gql } from '@apollo/client'

const NEWSLETTER_SIGNUP = gql`
	mutation CreateNewsletter($input: CreateNewsletterInput!) {
		createNewsletter(input: $input) {
			email
			status
			createdAt
		}
	}
`
export default NEWSLETTER_SIGNUP
