import { gql } from '@apollo/client'

export const UNSUBSCRIBE_ALERTS = gql`
  mutation unsubscribeAlerts($id: ID!) {
    unsubscribeAlerts(id: $id) {
      account {
        name
        id
      }
      user {
        id
      }
      follow
      subscribe
      notify
    }
  }
`
