import SearchPage from '../views/SearchPage'

const routes = [
	{
		path: 'search',
		children: [
			{
				index: true,
				element: <SearchPage />
			},
			{
				path: ':term',
				element: <SearchPage />
			}
		]
	}
]
export default routes
