import { Box, Link, Typography } from '@mui/material'
import { Image } from '@services/common/components/Image'
import useImage from '@services/common/hooks/use-image'
import { noop } from 'lodash'

const EventCategory = ({ item, onClick = noop }: any) => {
	console.log('EventCategory', { item })
	const image = useImage('taxonomy', (item.images && item.images.length && item.images[0]) || '')

	return (
		<Box sx={styles.root}>
			<Link sx={styles.link} onClick={onClick}>
				{image && (
					<Image
						src={image}
						aspectRatio={1}
						sx={{
							root: styles.image
						}}
					/>
				)}
			</Link>

			<Typography variant="h5" sx={styles.title}>
				{item.name}
			</Typography>
		</Box>
	)
}

const styles = {
	root: {
		position: 'relative',
		width: 200
	},
	link: {
		display: 'flex',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	image: {
		borderRadius: '50%',
		overflow: 'hidden',
		marginBottom: 2,
		backgroundColor: '#666'
	},
	title: {
		fontSize: 15,
		fontWeight: 'bold',
		textAlign: 'center'
	}
}

export default EventCategory
