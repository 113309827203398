import { useQuery } from '@apollo/client'
import { Box, Container, Typography } from '@mui/material'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql'
import { useMemo } from 'react'

const Promote = () => {
	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.we-love-unique-stays'
		}
	})

	const content = useMemo(() => {
		return data?.getContentByKey || {}
	}, [data])

	return (
		<Box sx={styles.root}>
			<Container sx={styles.container}>
				<div
					style={{ padding: 24, borderRadius: 8, backgroundColor: 'rgba(0,0,0,.8)', color: '#fff' }}
				>
					<Typography variant="h5" sx={styles.propertyName}>
						Frog Meadow Farm B&B and
						<br /> Massage Oasis for Men
					</Typography>
					<Typography sx={styles.propertyLocation}>Newfane, Vermont</Typography>
				</div>
				<div
					style={{
						padding: '50px 50px 100px',
						borderRadius: 8,
						backgroundColor: 'rgba(255,255,255,.9)'
					}}
				>
					<Typography
						variant="h5"
						sx={{
							fontSize: '1.2rem',
							textTransform: 'uppercase',
							fontWeight: 600,
							marginBottom: 2
						}}
					>
						With Style
					</Typography>
					{content?.name && (
						<Typography
							variant="h3"
							sx={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: 2 }}
							dangerouslySetInnerHTML={{ __html: content.name }}
						/>
					)}
					{content?.content && <Typography dangerouslySetInnerHTML={{ __html: content.content }} />}
				</div>
			</Container>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		background:
			'url(https://photos.mygaygetaway.com/properties/5d82845f5bdcfacc3b1ea4f2/d83f9d02-91fd-42c3-85ff-eb41f14d1a54.jpg) center no-repeat',
		backgroundSize: 'cover'
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		minHeight: 700,
		padding: '100px 50px',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	propertyName: {
		fontSize: '1.1rem',
		fontWeight: 'bold'
	},
	propertyLocation: {
		fontSize: '.9rem'
	}
}

export default Promote
