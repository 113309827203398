import { SyntheticEvent, useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { SRLWrapper, useLightbox } from 'simple-react-lightbox'
import PhotoLibraryOutlined from '@mui/icons-material/PhotoLibraryOutlined'
import ItemGrid from '@services/common/components/ItemGrid'
import { Button } from '@services/common/components/Button'
import { logger } from '@services/common/lib/logger'
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import PropertyPhoto from '../../components/PropertyPhoto'
import options from '../../components/PhotoGallery/config'
// import { thumbnailSize } from './PhotoGallery/constants'

export interface PropertyImagesProps {
	item?: any
	count?: number
}

const getIndex = (context: string, property: string, source: any) => {
	let idx
	for (let i = 0, n = source.length; i < n; i++) {
		if (source[i][property] === context) {
			idx = i
			break
		}
	}
	return idx
}

const PropertyImages = ({ item, count = 5 }: PropertyImagesProps) => {
	const { openLightbox } = useLightbox()

	const photos = useMemo(() => {
		return item?.photos || []
	}, [item])

	const primaryImages = useMemo(() => {
		return [...photos].slice(0, count)
	}, [photos, count])

	const galleryImages: any = useMemo(() => {
		return photos && photos.length > 0
			? photos.map((photo: any) => ({
					src: buildS3ImageUrl('properties', photo),
					// thumbnail: buildS3ImageUrl('properties', photo, `${thumbnailSize[0]}x${thumbnailSize[1]}`),
					width: 800,
					height: 'auto'
			  }))
			: []
	}, [photos])

	const buttonLabel = useMemo(() => {
		const total = galleryImages.length
		if (total > count) {
			return `+ ${total - count} more`
		}
		return 'View images'
	}, [count, galleryImages])

	const handleItemClick = useCallback(
		(e: SyntheticEvent, item: any) => {
			e.preventDefault()
			const image = buildS3ImageUrl('properties', item)
			let idx = getIndex(image, 'src', galleryImages)
			openLightbox(idx)
		},
		[galleryImages, openLightbox]
	)

	logger.log('PropertyImages', { photos })

	return (
		<Box sx={styles.root}>
			{galleryImages.length > 1 && (
				<Button
					onClick={openLightbox}
					variant="contained"
					size="small"
					color="default"
					sx={styles.button}
				>
					<PhotoLibraryOutlined /> {buttonLabel}
				</Button>
			)}

			<SRLWrapper options={options} elements={galleryImages} />
			<ItemGrid
				sx={{
					root: styles.grid
				}}
				displayHoverState={true}
				aspectRatio={1116 / 433}
				items={primaryImages}
				onClick={handleItemClick}
				component={PropertyPhoto}
			/>
		</Box>
	)
}
const styles = {
	root: {
		position: 'relative',
		borderRadius: 2,
		overflow: 'hidden'
	},
	grid: {
		position: 'relative',
		zIndex: 1
	},
	button: {
		position: 'absolute',
		right: 8,
		bottom: 8,
		zIndex: 9,
		border: 'none',
		backgroundColor: '#999999',
		'&:hover': {
			backgroundColor: '#999999'
		}
	}
}

export default PropertyImages
