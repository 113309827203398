import { useEffect } from 'react'

export const useWindowEvent = (event: any, callback: any) => {
  useEffect(() => {
    window.addEventListener(event, callback)
    return () => window.removeEventListener(event, callback)
  }, [event, callback])
}

export const useWindow = (event: string, callback: any) => {
  return useWindowEvent(event, callback)
}

export const useWindowSize = (callback: any) => {
  return useWindowEvent('resize', callback)
}

export const useWindowScroll = (callback: any) => {
  return useWindowEvent('scroll', callback)
}
