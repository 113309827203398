import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Backup } from '@mui/icons-material'
import Dropzone from '@services/common/components/Dropzone'
import { noop } from 'lodash'

const DEFAULT_MESSAGE = 'Drag and drop an image file here or click'

export interface DropUploadProps {
	uploadRef?: any
	onClick?: any
	onDrop?: any
	message?: string
	sx?: any
}

const DropUpload = ({
	uploadRef,
	onClick = noop(),
	onDrop = noop(),
	message = DEFAULT_MESSAGE,
	sx = {}
}: DropUploadProps) => {
	const classes = useStyles()

	return (
		<div
			id="image-drop"
			ref={uploadRef}
			onClick={onClick}
			onDrop={onDrop}
			style={sx.dropzoneWrap}
			className={classes.dropzoneWrap}
		>
			<Dropzone
				sx={{
					root: {
						padding: 3,
						flex: 1,
						flexGrow: 1,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						textAlign: 'center',
						border: '2px dashed rgb(173, 176, 187)',
						borderRadius: 3,
						...sx.root
					}
				}}
				dropzoneContainerOptions={{
					direction: 'column',
					justify: 'center',
					alignItems: 'center'
				}}
			>
				<Typography
					sx={{
						// color: '#fff',
						fontSize: 24,
						...sx.dropzoneText
					}}
				>
					{message}
				</Typography>
				<Box>
					<Backup
						sx={{
							height: 83,
							width: 'auto',
							// color: '#fff',
							...sx.uploadIcon
						}}
					/>
				</Box>
			</Dropzone>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	dropzoneWrap: {
		width: '100%',
		'&:hover': {
			cursor: 'pointer'
		}
	}
	// dropzone: {
	// 	padding: theme.spacing(3),
	// 	flex: 1,
	// 	flexGrow: 1,
	// 	height: '100%',
	// 	alignItems: 'center',
	// 	textAlign: 'center',
	// 	border: '2px dashed rgb(173, 176, 187)',
	// 	borderRadius: 12
	// },
	// dropzoneText: {
	// 	color: '#fff',
	// 	fontSize: 24
	// },
	// uploadIcon: {
	// 	height: 83,
	// 	width: 'auto',
	// 	color: '#fff'
	// }
}))

export default DropUpload
