import { COMPONENT_FORM, LOC_MAP } from "../constants/form"

export const parseAddress = ({ address_components, formatted_address, url, geometry }: google.maps.places.PlaceResult) => {
  const address: any = {}

  if (address_components) {
    Object.assign(
      address,
      address_components.reduce((o: any, i: any) => {
        const type: string = i.types[0]
        if (COMPONENT_FORM[type]) {
          const val = i[COMPONENT_FORM[type]]
          o[LOC_MAP[type]] = val
        }
        return o
      }, {})
    )
    address.address = formatted_address
  }
  if (url) {
    address.googleMap = url
  }
  if (geometry) {
    const location: any = geometry.location
    address.latitude = location.lat()
    address.longitude = location.lng()
  }
  return address
}