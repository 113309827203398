// import { initialState } from './state'
import { logger } from '@services/common/lib/logger'
import { Actions } from './actions'

// const showModal = (state: any, data: any): any => data
// const closeModal = (state: any): any => initialState
const pushState = (state: any, update: any) => {
	logger.log('AppProvider.reducer.pushState', { prevState: state, update })
	return { ...state, ...update }
}
const setState = (state: any, { prop, value }: any) => {
	logger.log('AppProvider.reducer.setState %s', prop, value)
	const newState: any = { ...state }
	newState[prop] = value
	return newState
}

const reducers: Record<string, any> = {
	// showModal,
	// closeModal,
	[Actions.SET]: setState,
	[Actions.PUSH]: pushState
}

export default reducers
