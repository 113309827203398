import { useMemo } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useQuery } from '@apollo/client'
import { PageSection } from '@services/common/components/PageSection'
import { LIST_ARTICLES } from '../graphql'
import ArticleCard from './ArticleCard'

const RecentArticles = () => {
	const classes = useStyles()

	const { data }: any = useQuery(LIST_ARTICLES, {
		variables: {
			filter: {
				status: 'enabled'
			},
			limit: 4
			// sortAttribute: 'startDate'
		}
	})

	const items = useMemo(() => {
		return data ? data.listArticles.items : []
	}, [data])

	return (
		<PageSection
			header="The Travel Blog"
			classes={{
				header: classes.pageTitle
			}}
			sx={{
				title: {
					color: '#666'
				}
			}}
		>
			<Grid container spacing={3}>
				{items.map((item: any) => (
					<Grid className={classes.item} key={`event-${item.id}`} item md={3}>
						<ArticleCard item={item} />
					</Grid>
				))}
			</Grid>
		</PageSection>
	)
}

const useStyles = makeStyles({
	root: {},
	pageTitle: {
		color: '#666'
	},
	item: {
		display: 'flex'
	}
})

export default RecentArticles
