import { gql } from '@apollo/client'

export const GET_ACCOUNT = gql`
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      status
      description
      featuredImage {
        id
        owner
        filename
      }
      images {
        id
        owner
        filename
      }
    }
  }
`
