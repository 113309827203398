import { gql } from '@apollo/client'
import { TAXONOMY_BASIC_INFO } from './taxonomy-basic-info-frag'

export const TAXONOMY_FRAGMENT = gql`
	fragment TaxonomyInfo on Taxonomy {
		...TaxonomyBasicInfo
		description
		type
		images {
			id
			image
			originalImage
		}
		slug
		status
	}
	${TAXONOMY_BASIC_INFO}
`
