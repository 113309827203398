import { MediaBlock } from 'app/services/common/components/MediaBlock'
import buildS3ImageUrl from 'app/services/media/utils/build-s3-image-url'
import { useMemo } from 'react'

const HostProperty = ({ property }: any) => {
	const propertyPhoto = useMemo(() => {
		try {
			const idx = Math.floor(Math.random() * property.photos.length)
			return buildS3ImageUrl('properties', property.photos[idx])
		} catch (e) {
			return ''
		}
	}, [property])

	return (
		<MediaBlock
			aspectRatio={1}
			title="Host Property"
			content={property.name}
			image={propertyPhoto}
			sx={{
				root: {},
				title: {
					fontSize: '.7rem'
				},
				body: {
					color: '#656565',
					fontSize: '.9rem',
					fontWeight: 500
				},
				image: {
					width: 68,
					height: 68,
					borderRadius: 2,
					overflow: 'hidden'
				},
				content: {
					justifyContent: 'flex-end',
					padding: 1
				}
			}}
		/>
	)
}
export default HostProperty
