import { FC } from 'react'
import { Typography, Box } from '@mui/material'

export type PageSectionHeaderClasses = {
	root?: string
	component?: string
}

export interface PageSectionHeaderProps {
	header?: any //string | ReactElement | ReactNode
	component?: any
	variant?: 'flex' | 'contained'
	moreLink?: string
	moreLinkLabel?: string
	headerClassNames?: string
	classes?: PageSectionHeaderClasses
	styles?: any
	sx?: any
}

export const PageSectionHeader: FC<PageSectionHeaderProps> = ({
	header: Header = '',
	component: Component,
	variant = 'flex',
	moreLink,
	moreLinkLabel = 'View More',
	headerClassNames,
	classes: propClasses = {},
	styles = {},
	sx = {}
}: PageSectionHeaderProps) => {
	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				flexDirection: 'row',
				...sx.root
			}}
		>
			<Typography
				variant="h3"
				sx={{
					display: 'inline-block',
					position: 'relative',
					marginTop: '30px',
					minHeight: '32px',
					color: '#FFFFFF',
					textDecoration: 'none',
					margin: '1.75rem 0 0.875rem',
					fontFamily: `"Roboto Slab", "Times New Roman", serif`,
					...sx.title
				}}
				// className={classNames(classes.title, headerClassNames, propClasses.root)}
			>
				{Header && typeof Header === 'string' ? Header : <Header />}
			</Typography>
			{Component && (
				<Component style={styles.component} className={propClasses.component} sx={sx.component} />
			)}
		</Box>
	)
}
