import config from '@core/environment'
// import { logger } from '@services/common/lib/logger'

const buildS3Url = (
  bucket = 'images',
  path: string,
  filename: string,
  size: string = ''
) => {
  //, bucket
  const parts: string[] = []
  if (bucket === 'videos') {
    parts.push(config.videoHost)
  } else {
    parts.push(config.staticHost)
  }
  parts.push(path, size, filename) //
  // logger.log('buildS3Url', { parts })
  return parts.filter((x) => x).join('/')
}
export default buildS3Url