import { FC, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SpeedDial, SpeedDialIcon, SpeedDialAction, SpeedDialProps } from '@mui/lab'
import { noop } from 'lodash'
import { GroupActionDialOptions } from '@services/app/components/PageHeader'

const useStyles = makeStyles(() => ({
	speedDial: {}
}))

export interface ActionButtonProps {
	items?: any
	hidden?: boolean
	label?: string
	direction?: SpeedDialProps['direction']
	tooltipPlacement?: GroupActionDialOptions['tooltipPlacement']
	onClick?: any
	rootClassName?: string
}

export interface ActionButtonItem extends Record<string, unknown> {
	name?: string
	icon?: any
	label?: string
}

export const ActionButton: FC<ActionButtonProps> = ({
	items = [],
	hidden = false,
	direction = 'up',
	tooltipPlacement = 'left',
	onClick = noop,
	rootClassName = ''
}: ActionButtonProps) => {
	const classes = useStyles()

	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClick = (item: ActionButtonItem) => {
		handleClose()
		onClick(item)
	}

	return (
		<Box component="span" className={rootClassName}>
			<SpeedDial
				ariaLabel="SpeedDial example"
				className={classes.speedDial}
				hidden={hidden}
				icon={<SpeedDialIcon />}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
				direction={direction}
			>
				{items.map((action: ActionButtonItem) => {
					const options: any = {
						key: `action-button-${action.name}`,
						tooltipTitle: action.name,
						tooltipPlacement,
						onClick: () => handleClick(action)
					}
					if (action.icon) {
						options.icon = <action.icon />
					}
					return <SpeedDialAction {...options} />
				})}
			</SpeedDial>
		</Box>
	)
}
