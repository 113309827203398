import { useMemo, useState } from 'react'
import { Box, Chip, Container, Divider, Stack } from '@mui/material'
import { useQuery } from '@apollo/client'
import ConciergePromote from '@services/getaway/components/ConciergePromote'
import PageHeader from '@services/common/components/PageHeader'
import NewsletterSignup from '@services/communication/components/NewsletterSignup'
import { GET_CONTENT_BY_KEY } from '@services/cms/graphql'
import { LIST_TAXONOMIES } from '@services/cms/taxonomy/graphql/list-taxonomies'
import EventCategories from '../components/EventCategories'
import UpcomingEvents from '../components/UpcomingEvents'

const today = Date.now()
const filter = {
	endDate: today
}

const EventsPage = ({ limit = 100 }: any) => {
	const [category, setCategory]: any = useState(undefined)

	const { data }: any = useQuery(GET_CONTENT_BY_KEY, {
		variables: {
			key: 'block.events'
		}
	})

	const { data: categoriesData }: any = useQuery(LIST_TAXONOMIES, {
		variables: {
			filter: {
				type: 'event'
			}
		}
	})

	const categories = useMemo(() => {
		return categoriesData ? categoriesData.listTaxonomies.items : []
	}, [categoriesData])

	const header = useMemo(() => {
		const res = { title: '', text: '' }
		if (data) {
			const content = data.getContentByKey
			Object.assign(res, {
				title: content.name,
				text: content.content
			})
		}
		return res
	}, [data])

	const queryFilter = useMemo(() => {
		return category
			? {
					...filter,
					categories: category.id
			  }
			: filter
	}, [category])

	return (
		<Box sx={styles.root}>
			<PageHeader
				sx={{
					root: styles.header,
					title: styles.title,
					titleBlock: styles.titleBlock,
					text: styles.text,
					content: {
						flex: 1
					}
				}}
				{...header}
			>
				<EventCategories
					categories={categories}
					onClick={(selected: any) => setCategory(selected)}
				/>
			</PageHeader>
			<Divider style={{ margin: '24px 0', width: '100%' }} />
			<Stack gap={4}>
				{categories.length > 0 && (
					<Box
						sx={{
							display: 'flex'
						}}
					>
						<Container>
							<Stack direction="row" gap={1} sx={{ overflowX: 'auto', paddingBottom: 3 }}>
								{categories.map((cat: any) => (
									<Chip
										key={`category-chip-${cat.id}`}
										sx={{
											...(category && category.id === cat.id
												? {
														color: '#fff',
														backgroundColor: '#666',
														borderColor: '#666',
														'&:hover': {
															color: '#666'
														}
												  }
												: {})
										}}
										label={cat.name}
										variant="outlined"
										onClick={() => setCategory(cat)}
									/>
								))}
							</Stack>
						</Container>
					</Box>
				)}
				<UpcomingEvents
					limit={limit}
					canLoadMore={true}
					filter={queryFilter}
					selectedCategory={category}
					clearFilter={() => setCategory(undefined)}
				/>

				<ConciergePromote />
				<NewsletterSignup />
			</Stack>
		</Box>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		paddingTop: '60px'
	},
	container: {},
	content: {},
	header: {
		position: 'relative'
	},
	titleBlock: {
		paddingTop: 10
	},
	title: {
		marginBottom: 4
	},
	text: {
		maxWidth: '60%',
		fontSize: '.9rem'
	},
	tabRoot: {
		background:
			'url(https://photos.mygaygetaway.com/site/21fd42c6-03b2-425b-b152-f02e91f81327.png) center no-repeat',
		// background:
		// 'url(https://photos.mygaygetaway.com/properties/5d8284855bdcfacc3b1ee9c2/d7a2bf0f-851f-4f1c-a8ec-02856f4fda01.jpg) center no-repeat',
		backgroundSize: 'cover',
		padding: '88px 0',
		position: 'relative',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			backgroundColor: '#0B2238',
			opacity: 0.8,
			zIndex: 0
		}
	}
}

export default EventsPage
