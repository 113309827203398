import { FC, ReactNode } from 'react'
import { Box } from '@mui/material'
import AspectRatio from '@services/common/components/AspectRatio'

export type PageSeparatorProps = {
	children: ReactNode
	ratio?: number
}

const PageSeparator: FC<PageSeparatorProps> = ({
	children,
	ratio = 5 / 1.3
}: PageSeparatorProps) => {
	return (
		<Box sx={styles.root}>
			<Box sx={styles.container}>{children}</Box>
			<AspectRatio ratio={ratio} />
		</Box>
	)
}

const styles = {
	root: {
		position: 'relative',
		height: 632
	},
	container: {
		position: 'absolute',
		zIndex: -1,
		transform: 'translateY(-25%)',
		height: 'calc(100vh + 632px)',
		width: '100%',
		'&:before': {
			content: '""',
			position: 'absolute',
			top: -1,
			width: '100%',
			height: 150,
			background:
				'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%,  rgba(255,255,255,0) 100%)',
			zIndex: 1
		},
		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: 0,
			// transform: 'translateY(-150px)',
			width: '100%',
			height: 150,
			background:
				'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%,  rgba(255,255,255,0) 100%)',
			zIndex: 1
		}
	}
}

export default PageSeparator
