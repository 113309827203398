import { useEffect, useMemo } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'
import moment from 'moment'
import TextField from '@form/components/fields/TextField'
import { required } from '@form/utils'
import Image from '@services/common/components/Image'
import useImage from '@services/common/hooks/use-image'
import { logger } from '@services/common/lib/logger'
import useBookingForm from '../BookingProvider/use-booking-form'
import SelectField from '@form/components/fields/SelectField'
import { createSelectorOptions } from 'app/services/property/views/PropertyDetail/utils'
import { FormActions } from '@form'

const BookingForm = ({ property, onSuccess, close = noop }: any) => {
	const { form = {}, updateForm, handleInputChange, onSubmit }: any = useBookingForm()

	useEffect(() => {
		if (property && updateForm && !form.property) {
			updateForm({ property: property.id })
		}
	}, [property, updateForm, form])

	useEffect(() => {
		logger.log('BookingForm', { form, property })
	}, [form, property])

	const classes = useStyles()
	const propertyPhoto = useImage('properties', property.photos[0])

	const startDateValue = useMemo(() => {
		return form.startDate ? moment(form.startDate).format('LL') : form.startDate
	}, [form])

	const endDateValue = useMemo(() => {
		return form.endDate ? moment(form.endDate).format('LL') : form.endDate
	}, [form])

	return (
		<form onSubmit={(e: any) => onSubmit(e, onSuccess)} className={classes.root}>
			<Grid container spacing={3}>
				<Grid item md={6}>
					<TextField
						name="firstName"
						label="First Name"
						variant="outlined"
						required={true}
						value={form.firstName}
						onChange={handleInputChange}
						fullWidth
					/>
				</Grid>
				<Grid item md={6}>
					<TextField
						name="lastName"
						label="Last Name"
						variant="outlined"
						required={true}
						value={form.lastName}
						onChange={handleInputChange}
						validation={required}
						fullWidth
					/>
				</Grid>
				<Grid item md={8}>
					<TextField
						name="phoneNumber"
						label="Phone Number"
						variant="outlined"
						required={true}
						value={form.phoneNumber}
						onChange={handleInputChange}
						fullWidth
						validation={required}
					/>
				</Grid>
				<Grid item md={12}>
					<TextField
						name="email"
						label="Email"
						type="email"
						variant="outlined"
						required={true}
						value={form.email}
						onChange={handleInputChange}
						fullWidth
						validation={required}
					/>
				</Grid>

				<Grid item md={12}>
					<Typography variant="h5">Reservation Details</Typography>
					<Grid container spacing={3}>
						<Grid item md={6}>
							<SelectField
								label="Guests"
								name="guests"
								value={form.guests}
								variant="outlined"
								required={true}
								options={createSelectorOptions()}
								onChange={handleInputChange}
								// validation={required}
							/>
						</Grid>
						<Grid item md={6}>
							<SelectField
								name="rooms"
								label="Rooms"
								variant="outlined"
								value={form.rooms}
								required={true}
								options={createSelectorOptions()}
								onChange={handleInputChange}
								fullWidth
								// validation={required}
							/>
						</Grid>
						<Grid item md={6}>
							<TextField
								name="startDate"
								label="Check In"
								variant="outlined"
								required={true}
								value={startDateValue}
								onChange={handleInputChange}
								fullWidth
								validation={required}
							/>
						</Grid>
						<Grid item md={6}>
							<TextField
								name="endDate"
								label="Check Out"
								variant="outlined"
								required={true}
								value={endDateValue}
								onChange={handleInputChange}
								fullWidth
								validation={required}
							/>
						</Grid>
					</Grid>
				</Grid>

				{property && (
					<Grid item md={12}>
						<Grid container spacing={3}>
							<Grid item md={5}>
								<Image src={propertyPhoto} aspectRatio={16 / 9} />
							</Grid>
							<Grid item md={7}>
								<Typography variant="h6" className={classes.name}>
									{property.name}
								</Typography>
								{property.location && (
									<Typography className={classes.location}>{property.location.address}</Typography>
								)}
							</Grid>
						</Grid>
					</Grid>
				)}

				<Grid item md={12}>
					<FormActions
						primaryActionLabel="Send"
						secondaryActionLabel="Cancel"
						position="relative"
						onCancel={close}
						background={false}
					/>
				</Grid>
			</Grid>
		</form>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3)
	},
	name: {},
	location: {}
}))

export default BookingForm
