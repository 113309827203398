import { gql } from '@apollo/client'
import { PROPERTY_INFO_FRAG } from './property-info-frag'

export const GET_PROPERTY = gql`
	query getProperty($id: ID) {
		getProperty(id: $id) {
			...PropertyInfo
		}
	}
	${PROPERTY_INFO_FRAG}
`
