import ArticleDetail from '../views/ArticleDetail'
import ArticlesPage from '../views/ArticlesPage'

const routes = [
	{
		path: 'blog',
		children: [
			{
				index: true,
				element: <ArticlesPage />
			},
			{
				path: ':id',
				element: <ArticleDetail />
			}
		]
	}
]
export default routes
