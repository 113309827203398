const SELECT_THRESHOLD = 6

export const createSelectorOptions = () => {
  const context = Array.from(Array(SELECT_THRESHOLD).keys())
  return context.map((item: number) => ({
    label: `${item + 1}`,
    value: `${item + 1}`
  })).concat([{
    label: `+ ${SELECT_THRESHOLD}`,
    value: `+ ${SELECT_THRESHOLD}`
  }])
}
