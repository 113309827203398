import { FC, useMemo } from 'react'

interface AspectRatioProps {
	children?: any
	ratio: number
	classes?: any
	styles?: any
	sx?: any
}
const AspectRatio: FC<AspectRatioProps> = ({
	children,
	ratio,
	classes: injectedClasses = {},
	styles = {},
	sx = {}
}: AspectRatioProps) => {
	const rootStyles = useMemo(
		() => ({
			paddingBottom: `${(1 / ratio) * 100}%`,
			...styles.root
		}),
		[ratio, styles.root]
	)
	return (
		<div style={{ ..._styles.root, ...sx.root, ...rootStyles }}>
			<div
				style={{
					..._styles.wrap,
					...styles.wrap,
					...sx.wrap
				}}
			>
				{children}
			</div>
		</div>
	)
}

const _styles = {
	root: {
		position: 'relative',
		width: '100%',
		height: 0
	},
	wrap: {
		display: 'flex',
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0
	}
}

/**
 * @deprecated use default
 */
export { AspectRatio }
export default AspectRatio
