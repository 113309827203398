import { Account, Image } from '@services/app/types'

export const onAuthenticateSuccess = () => (dispatch: any) => {}

export const setInviteToken = (token: string) => (dispatch: any) => {
	dispatch('updateState', { inviteToken: token })
}

export const removeInviteToken = () => (dispatch: any) => {
	dispatch('updateState', { inviteToken: undefined })
}

export const setPostAuthentication = (redirect: any) => (dispatch: any) => {
	dispatch('updateState', { postAuthentication: redirect })
}

export const clearPostAuthentication = () => (dispatch: any) => {
	dispatch('updateState', { postAuthentication: undefined })
}

export const updateAccountImage = (image: Image) => (dispatch: any) => {
	dispatch('updateAccountImage', { image })
}

export const updateAccount = (account: Account) => (dispatch: any) => {
	dispatch('updateAccount', account)
}

export const pushState = (payload: any) => (dispatch: any) => {
	dispatch('updateState', payload)
}

export const resetState = () => (dispatch: any) => {
	dispatch('reset')
}
