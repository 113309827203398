import { useState, useCallback, useMemo, useEffect } from 'react'
import {
	Typography,
	Box,
	Button,
	Link,
	Divider,
	Collapse,
	Alert,
	Stack,
	Checkbox
} from '@mui/material'
import { noop } from 'lodash'
import * as rules from '@form/utils/validation'
import { useAuth } from '@services/auth/hooks/use-auth'
import TextField from '@form/components/fields/TextField'

const initialState: any = {
	accountName: '',
	email: '',
	password: '',
	terms: undefined,
	elist: ''
}

// const validatePassword = (str: string): boolean => {
//   // return !!(
//   //   rules.hasLowerCase(str) &&
//   //   rules.hasUpperCase(str) &&
//   //   rules.hasNumber(str) &&
//   //   rules.hasSymbol(str) &&
//   //   rules.minLength(7, str)
//   // )
//   // return rules.minLength(7, str)
//   return true
// }

export interface SignUpFormProps {
	data?: Record<string, unknown>
	switchForm?: Function
}

export const SignUpForm = ({ switchForm = noop, data = {} }: any) => {
	// console.log('signup form', data)
	const [state, setState]: any = useState(initialState)
	const [errors, setErrors]: any = useState({})
	const { signup, error }: any = useAuth()

	const handleInputChange = ({ target }: any) => {
		setState({ ...state, [target.name]: target.value })
	}

	const handleDateCheckbox = ({ target }: any) => {
		setState({
			...state,
			[target.name]: target.checked ? Date.now() : undefined
		})
	}

	const pushError = useCallback(
		(field: string, message: string) => {
			setErrors({ ...errors, [field]: message })
		},
		[errors, setErrors]
	)

	const clearError = useCallback(
		({ target }: any) => {
			console.log({ errors })
			if (errors[target.name]) {
				const copy: any = { ...errors }
				delete copy[target.name]
				setErrors(copy)
			}
		},
		[errors, setErrors]
	)

	const disabled: boolean = useMemo(() => {
		return (
			!state.email || !state.password || !state.accountName || state.accountName.length <= 3 || !state.terms
		)
	}, [state])

	const handleSubmit = useCallback(
		(e: any) => {
			e.preventDefault()
			if (!disabled) {
				// console.log('handleSubmit', state)
				signup(state)
			}
		},
		[signup, state, disabled]
	)

	const handleAccountNameChange = useCallback(({ target }: any) => {
		const accountName = target.value.replace(/[\s"'~`!@#$%^&()={}[\]:;,.<>+/?]/g, '')
		if (accountName.length > 30) {
			return
		}
		setState({ ...state, [target.name]: accountName })
	}, [state])

	useEffect(() => {
		if (data && data.token && !state.token) {
			setState({ ...state, token: data.token })
		}
	}, [data, state, setState])

	return (
		<Box sx={styles.base}>
			<Box sx={styles.header}>
				<Typography variant="h2" style={{ fontSize: 24, marginBottom: 24 }}>
					Sign Up
				</Typography>

				<Collapse in={!!error}>
					<Alert severity="error">{error}</Alert>
				</Collapse>
			</Box>
			<form style={styles.form} noValidate onSubmit={handleSubmit}>
				<Stack gap={2}>
					<TextField
						value={state.email}
						required
						fullWidth
						label="Email Address"
						name="email"
						error={!!errors.email}
						validation={rules.required}
						onChange={handleInputChange}
						errorMessage="Please enter an Email Address"
						onFocus={clearError}
						onError={pushError}
					/>
					<TextField
						value={state.elist}
						name="elist"
						label="Elist"
						style={{ position: 'absolute', width: 0, height: 0, left: -9999 }}
					/>

					<TextField
						value={state.password}
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						error={!!errors.password}
						validation={rules.required}
						onChange={handleInputChange}
						errorMessage="Please enter an Email Address"
						onFocus={clearError}
						onError={pushError}
					/>
					<Typography style={{ fontSize: 13, color: '#666' }}>
						Password should be at least 8 characters in length
						<br />
						Have at least 1 Upper and Lower case letter
						<br />
						Have 1 number
						<br />
						and 1 special character '@!#$%-_'
					</Typography>

					<TextField
						value={state.accountName}
						required
						fullWidth
						name="accountName"
						label="Account Name"
						helperText="We suggest something catchy"
						error={!!errors.accountName}
						validation={rules.required}
						// validation={(str: string) => rules.requiredLength(4, str)}
						onChange={handleAccountNameChange}
						errorMessage="Please enter an Account Name"
						onFocus={clearError}
						onError={pushError}
					/>
					<Box sx={{
						display: 'flex',
						alignItems: 'center'
					}}>
						<Checkbox
							name="terms"
							id="terms"
							onChange={handleDateCheckbox}
							required
							checked={!!state.terms}
							color="primary"
						/>
						<Typography sx={styles.text}>
							I agree to{' '}
							<a style={styles.link} href="/terms" target="_blank">
								Terms & Conditions
							</a>
						</Typography>
					</Box>
					<Divider />

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						sx={styles.submit}
						disabled={disabled}
					>
						Sign Up
					</Button>
					<Link sx={styles.link} onClick={switchForm.bind(null, 'login')} color="textPrimary">
						Aleady have an Account?
					</Link>
				</Stack>
			</form>
		</Box>
	)
}
const styles: any = {
	base: {
		// height: '100vh'
		display: 'inline-block',
		flex: 'none'
	},
	paper: {
		// margin: theme.spacing(8, 4),
		// width: 300,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	header: {
		// margin: '-24px 24px 0',
		// padding: theme.spacing(3),
		// borderRadius: 4,
		// textAlign: 'center',
		// background: 'linear-gradient(60deg, #ec407a, #d81b60)',
		// boxShadow:
		//   '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4)'
	},
	formWrap: {
		// margin: theme.spacing(1),
		justifyContent: 'center',
		flex: 1,
		// padding: theme.spacing(1, 1),
		maxWidth: 300,
		borderRadius: 2
	},
	avatar: {
		margin: 1,
		// backgroundColor: theme.palette.secondary.main,
		display: 'inline-flex'
	},
	form: {
		width: 300,
		position: 'relative'
		// width: '100%', // Fix IE 11 issue.
		// marginTop: theme.spacing(1),
		// padding: theme.spacing(3)
	},
	socialLogin: {
		color: '#fff'
	},
	submit: {
		// margin: [3, 0, 2],
		padding: [1.5, 2],
		borderRadius: 2,
		backgroundColor: '#f50057',
		fontWeight: 600,
		border: '2px solid #f50057',
		'&:hover': {
			border: '2px solid #f50057',
			backgroundColor: '#fff',
			color: '#f50057'
		},
		'&:disabled': {
			borderColor: '#ccc'
		}
	},
	text: {
		fontSize: 13,
		// paddingTop: '11px'
	},
	link: {
		color: '#90CAF9',
		fontSize: 13
	}
}
