import { gql } from '@apollo/client'

export const DELETE_PROPERTY = gql`
	mutation DeleteProperty($input: DeletePropertyInput!) {
		deleteProperty(input: $input) {
			id
			__typename
		}
	}
`
