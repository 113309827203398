import config from 'app/config/environment'
import { mainNavigation } from 'app/constants/navigation'

export const secondaryNavigation: any = {
	public: [],
	auth: []
}

export const headerState: any = {
	mode: 'full', // 'min'
	onScrollBackgroundColor: 'light',
	state: 'off',
	lock: false,
	mainNavigation,
	secondaryNavigation,
	variant: 'light',
	variantOnScroll: 'light'
}

export const bottomDrawerState: any = {}

export const initialState: any = Object.freeze({
	loading: false,
	name: config.name,
	location: {},
	description: config.description,
	modal: {},
	loadedScripts: [],
	brandIcon: config.brandIcon,
	header: headerState,
	bottomDrawer: {},
	topDrawer: {},
	rightDrawer: {},
	leftDrawer: {},
	listWithUsEnabled: config.features.listWithUsEnabled
})
