
import buildS3ImageUrl from '@services/media/utils/build-s3-image-url'
import { useCallback } from 'react'

const useImage = (resource: any, defaultImage?: any, defaultSize?: string | number) => {
	if (!resource) {
		throw new Error('ResourceNotDefined')
	}

	const computeSize = (size?: string | number): string => {
		if (typeof size === 'string' && size.includes('x')) {
			return size
		} else if (typeof size === 'number' && size > 0) {
			return `${size}x${size}`
		}
		return ''
	}

	const renderImage = useCallback(
		(path: string, sourceImage: any, renderSize?: string | number) => {
			const computedSize = computeSize(defaultSize)
			try {
				return buildS3ImageUrl(resource, defaultImage, computedSize)
			} catch (e) {
				return ''
			}
		},
		[defaultImage, defaultSize, resource]
	)

	if (defaultImage) {
		return renderImage(resource, defaultImage, defaultSize)
	}

	return ((image: any, size?: string | number) => {
		return renderImage(resource, image, size)
	})(resource)
}
export default useImage
