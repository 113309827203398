import { ReactNode } from 'react'
import { Box, Container } from '@mui/material'
import { useTheme } from '@mui/styles'
import { PageSectionHeader } from './PageSectionHeader'

export type PageSectionClasses = {
	root?: string
	/**
	 * @deprecated
	 */
	header?: string
	title?: string
	container?: string
}

export interface PageSectionProps {
	header?: string | ReactNode
	variant?: string
	containerWidth?: number
	moreLink?: string
	moreLinkLabel?: string
	justifyContainer?: string
	headerClassNames?: string[]
	pageSectionClassNames?: string | string[]
	padding?: number | string
	style?: any
	children?: ReactNode | ReactNode[]
	classes?: PageSectionClasses
	maxWidth?: false | 'lg' | 'md' | undefined
	headerComponent?: any
	sx?: any
}

const PageSection = ({
	style = {},
	header,
	moreLink = '',
	moreLinkLabel = 'View More',
	children,
	classes: injectedClasses = {},
	maxWidth = 'lg',
	headerComponent,
	sx = {}
}: PageSectionProps) => {
	const theme: any = useTheme()

	return (
		<Box
			sx={{
				padding: theme.spacing(6, 0),
				marginRight: 'auto',
				marginLeft: 'auto',
				width: '100%',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				...sx.root
			}}
		>
			<Container maxWidth={maxWidth} sx={sx.container}>
				{header && (
					<PageSectionHeader
						component={headerComponent}
						header={header}
						moreLink={moreLink}
						moreLinkLabel={moreLinkLabel}
						sx={{
							title: {
								fontSize: '2rem',
								color: '#000',
								...sx.title
							}
						}}
					/>
				)}
				{children}
			</Container>
		</Box>
	)
}

/**
 * @deprectaed use default export
 */
export { PageSection }

export default PageSection
