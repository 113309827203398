import { gql } from '@apollo/client'

export const IMAGE_INFO_FRAGMENT = gql`
  fragment ImageInfo on Image {
    id
    owner
    filename
    __typename
  }
`
