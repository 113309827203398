import { unitOfTime } from 'moment'
import { capitalize } from 'lodash'

export const chunkStr = (str: string, size: number) => {
	const numChunks = Math.ceil(str.length / size)
	const chunks = new Array(numChunks)

	for (let i = 0, o = 0; i < numChunks; i++, o += size) {
		chunks[i] = str.substr(o, size)
	}

	return chunks
}

export interface ExpirationTimestamp {
	offset?: number
	interval?: unitOfTime.DurationConstructor
	convertSeconds?: boolean
}

export interface TokenGenerationConfiguration {
	type?: Chars
	length?: number
	leadWithAlpha?: boolean
}

export enum Chars {
	alpha,
	alphaLower,
	numeric,
	special,
	alphaNumeric,
	alphaNumericLower,
	full
}

const combinePatterns = (...patterns: string[]): string => patterns.join('')
const patternAlpha: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
const patternAlphaLower: string = 'abcdefghijklmnopqrstuvwxyz'
const patternNumeric: string = '0123456789'
const patternSpecial: string = '!@#$%^&*()-_=+[{]}<>'
const alphaNumeric: string = combinePatterns(patternAlpha, patternNumeric)
/**
 * exports TokenPattern
 */
export const TokenPattern: Map<Chars, string> = new Map<Chars, string>()
TokenPattern.set(Chars.alpha, patternAlpha)
TokenPattern.set(Chars.alphaLower, patternAlphaLower)
TokenPattern.set(Chars.numeric, patternNumeric)
TokenPattern.set(Chars.special, patternSpecial)
TokenPattern.set(Chars.alphaNumeric, alphaNumeric)
TokenPattern.set(Chars.alphaNumericLower, combinePatterns(patternAlphaLower, patternNumeric))
TokenPattern.set(Chars.full, combinePatterns(alphaNumeric, patternSpecial))

/**
 * Generate a token of varied length and type
 * @param {TokenGenerationConfiguration} config
 * @param {Chars} config.type
 * @param {number} config.length
 */
export const generateToken = (config?: TokenGenerationConfiguration): string => {
	let token: any = []
	// get the pattern from the TokenPattern Chars Map
	const charType: Chars = config && config.type ? config.type : Chars.alphaNumeric
	const pattern: any = TokenPattern.get(charType)
	// set the string length to 32 if the length is not provided in the config
	const strLength: number = config && config.length ? config.length : 32
	for (let i = 0; i < strLength; i++) {
		token.push(pattern.charAt(Math.floor(Math.random() * pattern.length)))
	}
	return token.join('')
}

export const titleCase = (str?: string): string => {
	return str ? str.split('-').map(capitalize).join(' ') : ''
}

export const ws = (str: string) => {
	return str.replace(/\s/g, '')
}
