import React, { FC } from 'react'
import { MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { noop } from 'lodash'
import classNames from 'classnames'
import { TextField } from '../TextField'

export type SelectVariant = 'filled' | 'standard' | 'outlined'

export interface SelectOption {
	label: string
	value: string | number
}

export interface SelectFieldProps {
	id?: string
	error?: boolean
	label: string
	placeholder?: string
	helperText?: string
	value: string | number | ''
	name: string
	variant?: SelectVariant
	options?: any
	onChange?: any
	onFocus?: any
	onError?: any
	containerClassName?: string
	optionValuePath?: string
	optionLabelPath?: string
	component?: any
	disabled?: boolean
	fullWidth?: boolean
	required?: boolean
	rootClassName?: string
	SelectProps?: any
}

const buildOption =
	({ component: Component = undefined, optionLabelPath, optionValuePath }: any) =>
	(option: any) => {
		// console.log({ option })
		const value = option[optionValuePath]
		return (
			<MenuItem key={`option-${value}`} value={value}>
				{Component ? <Component item={option} /> : option[optionLabelPath]}
			</MenuItem>
		)
	}

const SelectField: FC<SelectFieldProps> = ({
	name,
	id = `field-${name}`,
	value = '',
	label,
	options = [],
	fullWidth = true,
	onChange = noop,
	disabled = false,
	required = false,
	optionValuePath = 'value',
	optionLabelPath = 'label',
	placeholder = 'Select...',
	onFocus = noop,
	onError = noop,
	component,
	rootClassName,
	...rest
}: SelectFieldProps) => {
	const classes = useStyles()

	return (
		<TextField
			className={classNames(classes.input, rootClassName)}
			name={name}
			fullWidth={fullWidth}
			value={value}
			label={label}
			select
			required={required}
			disabled={disabled}
			onChange={onChange}
			{...rest}
		>
			<MenuItem>{placeholder}</MenuItem>
			{options &&
				options.length &&
				options.map(buildOption({ component, optionLabelPath, optionValuePath }))}
		</TextField>
	)
}

const useStyles = makeStyles({
	input: {},
	formControl: {
		width: '100%'
	}
})
export default SelectField
