import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useCallback } from 'react'
import { mockHandler } from '@form/utils'
import { MediaSelector } from '../components/MediaSelector'
import { DrawerPosition } from '@services/app/AppProvider/types'
import { DrawerHook, useDrawer } from '@services/app/hooks/use-drawer'

export interface MediaSelectorHookProps {
	filter?: any
	onSelected?: Function
}

export interface MediaSelectorHook {
	open: Function
	close: Function
}

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		background: 'rgba(0,0,0,.8)',
		zIndex: 9999
	}
}))

export const useMediaSelector = ({
	filter,
	onSelected = mockHandler
}: MediaSelectorHookProps): MediaSelectorHook => {
	const classes: any = useStyles()

	const { show, hide }: DrawerHook = useDrawer({
		position: DrawerPosition.Right,
		component: MediaSelector
	})

	const open = useCallback(
		(options: any) => {
			show({ onSelected, ...options, options: { classes } })
		},
		[show, onSelected, classes]
	)

	const close = useCallback(() => {
		hide(DrawerPosition.Right)
	}, [hide])

	return {
		open,
		close
	}
}
