import { DocumentNode, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { mockHandler } from '../utils'

export interface LoadFormDataHookProps {
	onComplete?: any
	onError?: any
}

export interface FetchFormDataRequest {
	attribute: string
	action: string
	query: DocumentNode
	variables: Record<string, string | number | boolean>
}

const useLoadFormData = ({
	onComplete = mockHandler,
	onError = mockHandler
}: LoadFormDataHookProps) => {
	const client = useApolloClient()

	const fetchFormData = useCallback(
		({ query, variables, action, attribute }: FetchFormDataRequest) => {
			// console.log({ fetchFormData: { query, variables, action, attribute } })
			client
				.query({
					query,
					variables
				})
				.then(({ data }: any) => {
					onComplete({ [attribute]: data[action] })
				})
				.catch((e: any) => onError(e))
		},
		[client, onComplete, onError]
	)

	return {
		fetchFormData
	}
}
/**
 * @deprecated use default export
 */
export { useLoadFormData }
export default useLoadFormData
