import { gql } from '@apollo/client'

export const MARKETING_BLOCK_FRAG = gql`
	fragment MarketingBlockInfo on MarketingBlock {
		id
		name
		title
		key
		subtitle
		text
		actions {
			label
			event
			variant
			color
			value
		}
		backgroundImage {
			id
			image
			originalImage
		}
		contentImage {
			id
			image
			originalImage
		}
		# settings
		status
		createdAt
		updatedAt
		__typename
	}
`
