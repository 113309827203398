import EventDetail from '../views/EventDetail'
import EventsPage from '../views/EventsPage'

const routes = [
	{
		path: 'events',
		children: [
			{
				index: true,
				element: <EventsPage />
			},
			{
				path: ':id',
				element: <EventDetail />
			}
		]
	}
]
export default routes
