const state = Object.freeze({
	status: 'idle',
	rooms: '',
	guests: '',
	startDate: undefined,
	endDate: undefined,
	email: '',
	phoneNumber: '',
	firstName: '',
	lastName: '',
	property: ''
})
export default state
